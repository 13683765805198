export type TUpdateMeKeys =
  | 'full_name'
  | 'first_name'
  | 'last_name'
  | 'country'
  | 'avatar'
  | 'birth_date'
  | 'twitter'
  | 'facebook'
  | 'instagram'
  | 'federation'
  | 'gender'
  | 'weight'
  | 'height'
  | 'receive_newsletters'
  | 'place_of_birth'
  | 'challenges_allowed';

export type IUpdateMe = {
  [full_name in TUpdateMeKeys]?: string;
};

export interface IIsOnline {
  is_online: boolean;
}

export interface IUpdateMeError {
  [key: string]: string;
}

export interface INextTitleByTypeProgress {
  title: 'ACM' | 'AFM' | 'AIM' | 'AGM';
  category: 'rapid' | 'blitz' | 'bullet';
  games_total: number;
  games_done: number;
  rating: number;
}

export interface INextTitleProgress {
  rapid: INextTitleByTypeProgress;
  blitz: INextTitleByTypeProgress;
  bullet: INextTitleByTypeProgress;
}

export interface ITitleProgress {
  current_title: string | null;
  next_title_progress: INextTitleProgress | null;
}

export enum eFideIdStatus {
  CONNECTED = 'CONNECTED',
  ON_CHECK = 'ON_CHECK',
  REJECTED = 'REJECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
}

export type AccountType = 'free' | 'anonymous' | 'subscribed';

export type AccountTypeInfo = [AccountType, eFideIdStatus | null];
