import { IAwaitingPlayerData, ITourGameData } from 'shared/types';

import {
  IInviteData,
  IBoardResult,
  IInviteBody,
  IInviteGameData,
  Invite,
  CreateInviteParams,
  CreateInviteBodyParams,
} from '@types';
import { BaseService } from './_base.service';
import { httpInstance } from '.';

export class GameService extends BaseService {
  /**
   *  Выполняет запрос на получение данных о  создаваемой партии
   *  @param {string} playerUid  - id пользователеля
   *  @param {string} oppMode  - вид игры
   *  @param {string} rating  - рейтинг пользователя
   *  @param {number} lowerRating  - верхний рейтинг пользователя
   *  @param {number} upperRating  - нижний рейтинг пользователя
   *  @param {number} timecontrolId  - id таймконтрола
   *  @param {string} desiredColor - цвет фигур игрока
   */
  createGameInvite(data: IInviteGameData) {
    const body: IInviteBody = {
      player_uid: data.player_uid,
      opp_mode: data.opp_mode,
      rating: [data.rating],
      time_control: [data.time_control],
    };

    if (data.ratingLimits) {
      body.rating_limits = data.ratingLimits;
    }

    if (data.desired_color) {
      body.desired_color = data.desired_color;
    }

    if (data.opp_uid) {
      body.opp_uid = data.opp_uid;
    }
    if (data.rematch) {
      body.rematch = data.rematch;
    }

    // TODO: выяснить, нужны ли вообще эти параметры (is_new_gaming и is_multiregion),
    // т.к. мы полностью перешли на нг
    const params = data.is_multiregion ? '?is_multiregion=1' : '';

    return this.ajax.post<IInviteData>(`/online/request/${params}`, {
      body,
    });
  }

  createInvite({
    timeControlId,
    desiredColor,
    ownerAnonymousUid,
    ratingFrom,
    ratingTo,
    inviteType,
  }: CreateInviteParams) {
    const body: CreateInviteBodyParams = {
      desired_color: desiredColor,
      rating_from: ratingFrom,
      rating_to: ratingTo,
      time_control_id: timeControlId,
      owner_anonymous_uid: ownerAnonymousUid,
    };

    const isBotGame = inviteType === 'bot';

    const urlInviteType = isBotGame ? 'with-bot/' : '';

    return this.ajax.post<Invite>(
      `/online/challenges/invite/${urlInviteType}`,
      {
        body,
      }
    );
  }

  /**
   * Выполняет запрос на удаление создаваемой игры
   * @param {string} gameUid  - uid игры
   */
  cancelInviteData(gameUid: string, token: string | null) {
    return this.ajax.delete(`/online/request/${gameUid}/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `JWT ${token}` : '',
      },
    });
  }

  /**
   * Выполняет запрос на удаление создаваемой игры
   * @param {string} gameUid  - uid игры
   * @param {string} oppUid - uid оппонента
   */
  cancelRematchData(gameUid: string, token: string | null, oppUid?: string) {
    return this.ajax.delete(`/online/request/${gameUid}/`, {
      headers: {
        Authorization: token ? `JWT ${token}` : '',
        'Content-Type': 'application/json',
      },
      body: oppUid ? JSON.stringify({ opp_uid: oppUid }) : undefined,
    });
  }

  /**
   * Выполняет запрос на создание игры через активацию инвайта или при клике на пользователя в лобби.
   *  @param {string} playerUid - id пользователеля
   *  @param {string} oppMode - вид игры (в данном случае только human или friend)
   *  @param {string} inviteCode - код приглашения в игру
   *  @param {boolean} isMultiregion - игра на региональном сервере?
   *  @param {boolean} skipAndPlayNonRated - true передается для начала non-rated игры
   */
  getGameFromInvite({
    playerUid,
    oppMode,
    inviteCode,
    isMultiregion,
    skipAndPlayNonRated,
  }: {
    playerUid: string;
    oppMode: string;
    inviteCode: string;
    isMultiregion?: boolean;
    skipAndPlayNonRated?: boolean;
  }) {
    let url = '/online/request/invite/';

    if (skipAndPlayNonRated) {
      url += `?play_non_rated=1`;
    }

    return this.ajax.post<IInviteData>(url, {
      body: {
        player_uid: playerUid,
        opp_mode: oppMode,
        invite_code: inviteCode,
        // TODO: уточнить у бэка, нужно ли поле is_multiregion
        is_multiregion: isMultiregion,
      },
    });
  }

  /**
   * Выполняет запрос на отмену инвайта.
   * @param {string} playerUid  - id пользователеля
   * @param {string} oppMode  - вид игры (в данном случае только human или friend)
   * @param {string} inviteCode  - код приглашения в игру
   */
  cancelInvite(playerUid: string, oppMode: string, inviteCode: string) {
    return this.ajax.post<IInviteData>('/online/request/invite/cancel/', {
      body: {
        player_uid: playerUid,
        opp_mode: oppMode,
        invite_code: inviteCode,
      },
    });
  }

  /**
   * Выполняет запрос на поиск заявок на игру.
   */
  getGamesQueue() {
    return this.ajax.get<IAwaitingPlayerData[]>('/online/request/queue/');
  }

  /**
   * Выполняет запрос на получение данных о доске
   *  @param {string} boardId - uid доски
   */
  getBoardData(boardId: string) {
    return this.ajax.get<ITourGameData>(`/online/gaming/${boardId}/`);
  }

  /**
   * Выполняет запрос на получение данных о результате доски
   *  @param {string} boardId - uid доски
   */
  getBoardResult(boardId: string) {
    return this.ajax.get<IBoardResult>(`/online/gaming/${boardId}/result/`);
  }

  /**
   * Выполняет запрос на получение статуса заявки
   *  @param {string} boardUid  - board id
   */
  getInviteStatus(boardUid: string) {
    return this.ajax.get<IInviteData>(`/online/request/${boardUid}/`);
  }

  /**
   * Осуществляет запрос о получении pgn текущей партии.
   *  @param {string} gameId - id текущей партии
   */
  getGamePgn(gameId: string) {
    return this.ajax.get<string>(`/online/gaming/${gameId}/pgn/`);
  }

  /**
   * Осуществляет запрос о получении топ текущей партии в статусе GOES.
   */
  getTopRatedGame() {
    return this.ajax.get<ITourGameData>(`/online/gaming/top-rated-game/`);
  }
}

export const gameService = new GameService({ instance: httpInstance });
