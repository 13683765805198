import React, { memo } from 'react';
import styled from 'styled-components';

import { $TableLayout } from '../_styles';
import { IDesktopTableHead } from '../_types';
import { TR, TH } from 'shared/atoms';
import { transparentColor, textColors } from 'shared/styles';

/**
 * Компонент: заголовок таблицы на десктопе
 * @param {number} gamesWidth - ширина ячейки с очками за игры
 * @param {number} tieBreaksWidth - ширина ячейки с очками tie break
 */
export const DesktopTableHead: React.FC<IDesktopTableHead> = memo(
  function DesktopTableHead({ columns }) {
    return (
      <$HeadRow as={TR}>
        <$Head as={TH} cellSize={12}>
          {}
        </$Head>
        {columns.map(({ title, key, cellSize }) => (
          <$Head key={key} as={TH} cellSize={cellSize}>
            {title}
          </$Head>
        ))}
      </$HeadRow>
    );
  }
);

const $HeadRow = styled.div`
  margin: 0 20px 5px;

  &:first-child {
    border-bottom: 1px solid ${transparentColor(textColors['white'], 0.1)};
  }
`;

const $Head = styled($TableLayout)`
  color: ${textColors.white};
  opacity: 0.4;

  &:not(:first-child) {
    margin-left: 20px;
  }
`;
