import React from 'react';
import styled from 'styled-components';

import { cgDataTypes } from 'chessgun/ui';

import { IBoardPreview } from './_types';

import { LastMove } from './internal/sections/LastMove';
import { Pieces } from './internal/sections/Pieces';
import { PossibleMoves } from './internal/sections/PossibleMoves';
import { Premove } from './internal/sections/Premove';

/**
 * Component for the board theme demonstration
 * @param size - size of the board (must be multiple of 8)
 * @param theme - theme of the board.
 * @param fenPos - position of the pieces in fen standart ("rnbqkbnr/pppppppp/8/8/8/8...")
 * @param lastMove - position of the last move in uci format
 * @param premove - position of the premove move in uci format
 * @returns
 */
export const BoardPreview: React.FC<IBoardPreview> = ({
  size,
  theme,
  fenPos,
  lastMove,
  premove,
  possibleMoves,
  borderRadius = 0,
}) => {
  const cellSize = size / 8;

  return (
    <$Board
      size={size}
      style={{ borderRadius }}
      data-theme={theme}
      data-cg-type={cgDataTypes.BOARD}
    >
      {lastMove && <LastMove cellSize={cellSize} {...lastMove} />}
      {premove && <Premove cellSize={cellSize} uci={premove} />}
      <Pieces cellSize={cellSize} fenPos={fenPos} />
      <PossibleMoves cellSize={cellSize} possibleMoves={possibleMoves} />
    </$Board>
  );
};

const $Board = styled.div<{ size: number }>`
  position: relative;
  width: ${({ size }) => size + 'px'};
  height: ${({ size }) => size + 'px'};
  transition: background-image 555ms ease-out;
`;
