import {
  NotificationsCaptions,
  NotificationsItem,
  NotificationsOnItemClickHandler,
} from '@chessarena/components/lib/layout/Notifications/types';
import {
  ApiNotification,
  ApiNotificationType,
} from '@services/_notifications.service';

export type Notification = NotificationsItem &
  Readonly<{
    type?: ApiNotificationType;
  }>;

export interface INotificationsContextState {
  mounted: boolean;
  items: Notification[];
}

export interface INotificationsContextProviderValue {
  captions: NotificationsCaptions;
  onMakeAllAsReadClick: () => void;
  onItemClick: NotificationsOnItemClickHandler;
  items: NotificationsItem[];
  unread: boolean;
  count?: number | null;
  addNewNotification: (data: ApiNotification) => void;
}

export enum eNotificationsReducerActionTypes {
  SET_MOUNTED,
  SET_ITEMS,
}

interface INotificationsReducerActionSetMounted {
  type: eNotificationsReducerActionTypes.SET_MOUNTED;
  payload: boolean;
}

interface INotificationsReducerActionSetItems {
  type: eNotificationsReducerActionTypes.SET_ITEMS;
  payload: NotificationsItem[];
}

export type INotificationsReducerAction =
  | INotificationsReducerActionSetMounted
  | INotificationsReducerActionSetItems;
