import {
  eNotificationsReducerActionTypes,
  INotificationsReducerAction,
  INotificationsContextState,
} from './_types';

export const notificationsReducer = (
  prevState: INotificationsContextState,
  { type, payload }: INotificationsReducerAction
): INotificationsContextState => {
  switch (type) {
    case eNotificationsReducerActionTypes.SET_MOUNTED:
      return {
        ...prevState,
        mounted: payload,
      };
    case eNotificationsReducerActionTypes.SET_ITEMS:
      return {
        ...prevState,
        items: payload,
      };
    default:
      throw new Error('undefined type of action');
  }
};
