const CHECK_ONLINE_STATUS_INTERVAL = 1000;

/**
 * Выполняет код в коллбэке cb, когда юзер в сети
 */
export const executeWhenOnline = (cb: () => void) => {
  if (typeof navigator === 'undefined') return;

  // console.log(
  //   `~online~ executeWhenOnline > if navigator onLine: `,
  //   navigator.onLine
  // );

  if (navigator.onLine) {
    cb();
    return;
  }

  const intervalId = setInterval(() => {
    // console.log(
    //   `~online~ executeWhenOnline > interval set > execution : navigator onLine: `,
    //   navigator.onLine
    // );

    if (navigator.onLine) {
      cb();
      clearInterval(intervalId);
      // console.log(
      //   `~online~  executeWhenOnline > interval set > inteval cleared > execute cb()`
      // );
      return;
    }

    // console.log(
    //   `~online~  executeWhenOnline > interval set > offline > skip execution`
    // );
  }, CHECK_ONLINE_STATUS_INTERVAL);
};
