import { compareFen } from "./fen/_compareFen";
import { getMoveByAddress, getMoveIdByAddress, getSelectedAddressLineHistory } from "./history";
export const chessgunComputed = {
    lastMove: [
        (state) => {
            const { history, currentFen, selectedMoveAddress } = state;
            if (selectedMoveAddress === null || selectedMoveAddress === void 0 ? void 0 : selectedMoveAddress.length) {
                if (selectedMoveAddress[0] === -1)
                    return null;
                return getMoveByAddress(history, selectedMoveAddress);
            }
            const lastMove = history.find((item) => {
                if (!item || !item.fen)
                    return false;
                return compareFen(currentFen, item.fen);
            }) || null;
            return lastMove;
        },
        ["currentFen", "selectedMoveAddress"],
    ],
    mainLineLastMove: [
        (state) => {
            const { history } = state;
            if (history.length) {
                const lastItem = history[history.length - 1];
                if (lastItem === null || lastItem === void 0 ? void 0 : lastItem.fen)
                    return lastItem;
                const prevLastItem = history[history.length - 2];
                if (prevLastItem === null || prevLastItem === void 0 ? void 0 : prevLastItem.fen)
                    return prevLastItem;
            }
            return null;
        },
        ["history"],
    ],
    selectedMoveId: [
        (state) => {
            const { selectedMoveAddress } = state;
            if (selectedMoveAddress.length)
                return getMoveIdByAddress(selectedMoveAddress);
            return null;
        },
        ["selectedMoveAddress"],
    ],
    selectedMoveHistory: [
        (state) => {
            var _a;
            const { selectedMoveAddress, history } = state;
            if (selectedMoveAddress.length) {
                const list = getSelectedAddressLineHistory(history, selectedMoveAddress);
                if (!((_a = list[list.length - 1]) === null || _a === void 0 ? void 0 : _a.fen))
                    return list.slice(0, -1);
                return list;
            }
            return history;
        },
        ["history", "selectedMoveAddress"],
    ],
    currentAnalysisItem: [
        ({ analysis, currentFen }) => {
            return analysis.find((item) => compareFen(item.fen, currentFen)) || null;
        },
        ["analysis", "currentFen"],
    ],
};
