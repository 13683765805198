import React, { FC } from 'react';
import { AlertPopup } from '../_AlertPopup';
import { ConfirmationPopup } from '../_ConfirmationPopup';

interface ISystemPopups {
  confirm: {
    isConfirmPopupShown: boolean;
    confirmPopupRef: React.Ref<HTMLDivElement> | undefined;
    confirmText: string;
    onAccept: (() => void) | undefined;
    onDecline: (() => void) | undefined;
  };
  alert: {
    isAlertPopupShown: boolean;
    alertPopupRef: React.Ref<HTMLDivElement> | undefined;
    onClose: (() => void) | undefined;
    alertText: string;
  };
}

export const SystemPopups: FC<ISystemPopups> = ({ confirm, alert }) => {
  const {
    isConfirmPopupShown,
    confirmPopupRef,
    confirmText,
    onAccept,
    onDecline,
  } = confirm;
  const { isAlertPopupShown, alertPopupRef, alertText, onClose } = alert;

  return (
    <>
      {isConfirmPopupShown && (
        <ConfirmationPopup
          ref={confirmPopupRef}
          promptText={confirmText}
          onAccept={onAccept}
          onDecline={onDecline}
          width="370px"
        />
      )}

      {isAlertPopupShown && (
        <AlertPopup ref={alertPopupRef} onClick={onClose} text={alertText} />
      )}
    </>
  );
};
