import React, { memo, useCallback } from 'react';
import { $Tab, $Icon } from './_styles';
import { classNames } from '../../../../helpers/_classNames';
export const TabItem = memo(({ disabled, clickHandler, tab, tabsClassName, currentTab, iconClassName, tabText, }) => {
    const tabClasses = classNames({
        active: tab.text === currentTab || tab.value === currentTab,
        disabled: disabled || !!tab.disabled || (disabled && tab.text !== currentTab),
        [tabsClassName]: true,
    });
    const onClick = useCallback(() => {
        clickHandler(tab);
    }, [clickHandler, tab]);
    return (React.createElement($Tab, { key: tab.value ? tab.value : tab.text, className: tabClasses, onClick: onClick },
        tab.icon && (React.createElement($Icon, { className: iconClassName, style: {
                width: tab.iconWidth ? tab.iconWidth : '',
                height: tab.iconHeight ? tab.iconHeight : '',
            } }, React.createElement(tab.icon))),
        tab.text && React.createElement("div", null, tabText(tab.text)),
        tab.element));
});
