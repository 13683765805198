"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useWindowDimension = () => {
    const [windowDimensions, setWindowDimensions] = (0, react_1.useState)({
        width: 0,
        height: 0,
    });
    (0, react_1.useEffect)(() => {
        if (typeof window === 'undefined')
            return;
        setWindowDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
        function handleResize() {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        if (screen && screen.orientation) {
            screen.orientation.addEventListener('change', handleResize);
        }
        else {
            window.addEventListener('orientationchange', handleResize, false);
        }
        return () => {
            window.removeEventListener('resize', handleResize);
            if (screen && screen.orientation) {
                screen.orientation.addEventListener('change', handleResize);
            }
            else {
                window.removeEventListener('orientationchange', handleResize, false);
            }
        };
    }, []);
    return windowDimensions;
};
exports.default = useWindowDimension;
