export var eBoardThemes;
(function (eBoardThemes) {
    eBoardThemes["ARMA"] = "arma";
    eBoardThemes["AQUA"] = "aqua";
    eBoardThemes["STANDARD"] = "standard";
    eBoardThemes["WORLDCHESS"] = "worldchess";
    // NFT = "nft",
    eBoardThemes["TEXTBOOK"] = "textbook";
    eBoardThemes["CURRY"] = "curry";
    eBoardThemes["HIPSTER"] = "hipster";
    eBoardThemes["LEMON_TREE"] = "lemon-tree";
    eBoardThemes["MARBLE"] = "marble";
    eBoardThemes["BUBBLEGUM"] = "bubblegum";
    eBoardThemes["LICHESS"] = "lichess";
    eBoardThemes["PHILIPPINES"] = "philippines";
})(eBoardThemes || (eBoardThemes = {}));
