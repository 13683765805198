import React, { FC, memo, useMemo } from 'react';
import { DesktopTableHead } from './_DesktopTableHead';
import styled from 'styled-components';
import { $EmptyTablePlaceholder, tableStyles } from '../_styles';
import {
  ITableColumn,
  ITableData,
  ITeamsWithPlayersPlayersData,
  ITeamsWithPlayersTeamsData,
  TTeamsWithPlayersGroups,
} from '../_types';
import { PlayersTableRow } from './_PlayersTableRow';
import { TeamsTableRow } from './_TeamsTableRow';
import { Table } from 'shared/atoms/tables/_table/_Table';

interface IDesktopTable {
  columns: ITableColumn[];
  data: ITableData[];
  currentTab: TTeamsWithPlayersGroups;
  userPlayerId?: number;
  profileUrl: string;
}

export const DesktopTable: FC<IDesktopTable> = memo(function DesktopTable({
  currentTab,
  columns,
  data,
  profileUrl,
  userPlayerId,
}) {
  const tableRows = useMemo(() => {
    if (data?.length === 0) {
      return <$EmptyTablePlaceholder>No standings</$EmptyTablePlaceholder>;
    }

    switch (currentTab) {
      case 'teams':
        return (
          <>
            {(data as ITeamsWithPlayersTeamsData[]).map((team, idx) => (
              <TeamsTableRow
                key={team.id}
                columns={columns}
                data={team}
                index={idx + 1}
              />
            ))}
          </>
        );
      case 'players':
        return (
          <>
            {(data as ITeamsWithPlayersPlayersData[]).map((player, idx) => (
              <PlayersTableRow
                key={player.player_id}
                data={player}
                profileUrl={profileUrl}
                userPlayerId={userPlayerId}
                index={idx + 1}
                columns={columns}
              />
            ))}
          </>
        );
    }
  }, [columns, currentTab, data, profileUrl, userPlayerId]);

  return (
    <$Table>
      <DesktopTableHead columns={columns} />

      {tableRows}
    </$Table>
  );
});

const $Table = styled(Table)`
  ${tableStyles};
`;
