import { Store, useStore } from 'storeshed';
import { IEditProfileState } from '@types';

export const initEditProfileStore: IEditProfileState = {
  edit_profile_data: null,
  edit_profile_request: false,
  error: undefined,
};

export const editProfileStore = new Store<IEditProfileState>({
  ...initEditProfileStore,
});

export const useEditProfileStore = <K extends keyof IEditProfileState>(
  key: K
): IEditProfileState[K] => useStore(editProfileStore, key);
