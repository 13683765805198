export type GTMEvent = { [key: string]: string | number | null | undefined };

export const GTM_EVENTS = {
  USER_IS_LOGGED: 'User Is Logged',
  USER_IS_LOGGED_OUT: 'User Is Logged Out',
  SEND_GAME_REACTION: 'Send Game Reaction',
  RECEIVED_GAME_REACTION: 'Received Game Reaction',

  COMPLETE_REGISTRATION: 'CompleteRegistration',
  START_GAME: 'StartGame',
  PLAY_PUZZLE: 'PlayPuzzle',
  NEXT_PUZZLE: 'NextPuzzle',
  LIMIT_PUZZLE: 'LimitPuzzle',
  PURCHASE: 'Purchase',
  PURCHASE_YEAR: 'PurchaseYear',
  PURCHASE_MONTH: 'PurchaseMonth',
  PURCHASE_ANALYSIS: 'PurchaseAnalysis',
  CLICK_MASTERCLASS: 'ClickMasterclass',
  WATCH_MASTERCLASS: 'WatchMasterclass',
  // HOME
  LOBBY_CLICK: 'Lobby Click',
  USERS_ICONS_CLICK: 'Users Icons Click',
  TOP_PROFILE_CLICK: 'Top Profile Click',
  READ_MORE_CLICK: 'Read More Click',
  TOP_GAME_LIVE_CLICK: 'Top Game Live Click',
  INVITE_A_FRIEND_CLICK: 'Invite a Friend Click',
  CREATE_GAME_WITH_FRIEND_CLICK: 'Create Game with Friend Click',
  JOIN_TOURNAMENT_CLICK: 'Join Tournament Click',
  JOINED_TO_TOURNAMENT: 'Joined to Tournament',
  LEAVE_TOURNAMENT_CLICK: 'Leave Tournament Click',
  LEFT_TOURNAMENT: 'Left Tournament',
  MASTERCLASS_LESSON_CLICK: 'Masterclass Lesson Click',
  RATED_PUZZLES_CLICK: 'Rated Puzzles Click',
  UNRATED_PUZZLES_CLICK: 'Unrated Puzzles Click',
  LEAR_MORE_CLICK: 'Learn More Click',
} as const;

let initialized = false;
let eventsQueue: GTMEvent[] = [];

export function gtmPush(event: GTMEvent) {
  if (typeof window === undefined) return;

  if (!initialized) {
    eventsQueue.push(event);
    return;
  }

  window.dataLayer?.push(event);
}

export function gtmInit() {
  if (typeof window === undefined || initialized) return;

  initialized = true;

  const scriptElement = document.createElement('script');
  scriptElement.setAttribute('async', '');
  scriptElement.setAttribute('id', 'gtm');
  scriptElement.setAttribute(
    'src',
    `https://www.googletagmanager.com/gtm.js?id=${process.env.NEXT_PUBLIC_GTM_ID}`
  );
  document.head.appendChild(scriptElement);
  window.dataLayer = window.dataLayer || [];

  gtmPush({ event: 'gtm.js', 'gtm.start': new Date().getTime() });
  eventsQueue.forEach((event) => gtmPush(event));
  eventsQueue = [];
}

export function gtmFind(key: string, value?: string | number | null) {
  if (!initialized) return undefined;
  return window.dataLayer?.find((item) => item[key] === value);
}
