import { useCallback, useState } from 'react';

import { LocaleType } from 'shared/types';

import { translations } from '@utils/_locales';

function isValidLocaleType(value?: string): value is LocaleType {
  return Object.values(LocaleType).includes(value as LocaleType);
}

export const useI18n = (initLocale?: LocaleType) => {
  const checkedLocale = isValidLocaleType(initLocale)
    ? initLocale
    : LocaleType.EN;

  const [locale, setLocale] = useState(checkedLocale);
  const [localization, selLocalization] = useState(translations[checkedLocale]);

  const changeLocale = useCallback((newLocale: LocaleType) => {
    setLocale(newLocale);
    selLocalization(translations[newLocale]);
  }, []);

  return { locale, localization, changeLocale };
};
