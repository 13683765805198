export var eSoundsTheme;
(function (eSoundsTheme) {
    eSoundsTheme["OFF"] = "off";
    eSoundsTheme["ON"] = "on";
})(eSoundsTheme || (eSoundsTheme = {}));
export var ePremovesTheme;
(function (ePremovesTheme) {
    ePremovesTheme["OFF"] = "off";
    ePremovesTheme["ON"] = "on";
})(ePremovesTheme || (ePremovesTheme = {}));
export var eAutoPromotionTheme;
(function (eAutoPromotionTheme) {
    eAutoPromotionTheme["OFF"] = "off";
    eAutoPromotionTheme["ON"] = "on";
})(eAutoPromotionTheme || (eAutoPromotionTheme = {}));
export var BoardType;
(function (BoardType) {
    BoardType[BoardType["CLASSIC"] = 1] = "CLASSIC";
    BoardType[BoardType["RAPID"] = 2] = "RAPID";
    BoardType[BoardType["BLITZ"] = 3] = "BLITZ";
    BoardType[BoardType["ARMAGEDDON"] = 4] = "ARMAGEDDON";
    BoardType[BoardType["BULLET"] = 5] = "BULLET";
    BoardType[BoardType["DAILY"] = 6] = "DAILY";
})(BoardType || (BoardType = {}));
export var TournamentType;
(function (TournamentType) {
    TournamentType[TournamentType["MATCH"] = 0] = "MATCH";
    TournamentType[TournamentType["PLAYOFF"] = 1] = "PLAYOFF";
    TournamentType[TournamentType["SWISS"] = 2] = "SWISS";
    TournamentType[TournamentType["ARENA"] = 3] = "ARENA";
    TournamentType[TournamentType["ARMAGEDDON"] = 4] = "ARMAGEDDON";
})(TournamentType || (TournamentType = {}));
export var TournamentStatus;
(function (TournamentStatus) {
    // для онлайн турниров
    TournamentStatus[TournamentStatus["EXPECTED"] = 1] = "EXPECTED";
    TournamentStatus[TournamentStatus["GOES"] = 2] = "GOES";
    TournamentStatus[TournamentStatus["COMPLETED"] = 3] = "COMPLETED";
    // для бродкаста
    // EXPECTED_UPLOADED = 4,
    // GOES_UPLOADED = 5,
    // COMPLETED_UPLOADED = 6,
})(TournamentStatus || (TournamentStatus = {}));
export var TournamentMode;
(function (TournamentMode) {
    TournamentMode["OFFLINE"] = "offline";
    TournamentMode["ONLINE"] = "online";
})(TournamentMode || (TournamentMode = {}));
export var TournamentRoundStatus;
(function (TournamentRoundStatus) {
    TournamentRoundStatus[TournamentRoundStatus["EXPECTED"] = 1] = "EXPECTED";
    TournamentRoundStatus[TournamentRoundStatus["GOES"] = 2] = "GOES";
    TournamentRoundStatus[TournamentRoundStatus["COMPLETED"] = 3] = "COMPLETED";
})(TournamentRoundStatus || (TournamentRoundStatus = {}));
export var TournamentBoardStatus;
(function (TournamentBoardStatus) {
    TournamentBoardStatus[TournamentBoardStatus["EXPECTED"] = 1] = "EXPECTED";
    TournamentBoardStatus[TournamentBoardStatus["GOES"] = 2] = "GOES";
    TournamentBoardStatus[TournamentBoardStatus["COMPLETED"] = 3] = "COMPLETED";
})(TournamentBoardStatus || (TournamentBoardStatus = {}));
export var TourmanentBroadcastType;
(function (TourmanentBroadcastType) {
    TourmanentBroadcastType[TourmanentBroadcastType["FREE"] = 0] = "FREE";
    TourmanentBroadcastType[TourmanentBroadcastType["PAY"] = 1] = "PAY";
    TourmanentBroadcastType[TourmanentBroadcastType["ONLY_TICKET"] = 2] = "ONLY_TICKET";
})(TourmanentBroadcastType || (TourmanentBroadcastType = {}));
export var GameRatingMode;
(function (GameRatingMode) {
    GameRatingMode["UNRATED"] = "non-rated";
    GameRatingMode["RATED"] = "worldchess";
    GameRatingMode["FIDERATED"] = "fide";
    GameRatingMode["OTB"] = "otb";
})(GameRatingMode || (GameRatingMode = {}));
export var GamePlayerType;
(function (GamePlayerType) {
    GamePlayerType["ANONYMOUS"] = "anonymous";
    GamePlayerType["WORLDCHESS"] = "worldchess";
    GamePlayerType["FIDE"] = "fide";
})(GamePlayerType || (GamePlayerType = {}));
export var eChessColor;
(function (eChessColor) {
    eChessColor["w"] = "white";
    eChessColor["b"] = "black";
})(eChessColor || (eChessColor = {}));
export var LocaleType;
(function (LocaleType) {
    LocaleType["EN"] = "en";
    LocaleType["RU"] = "ru";
    LocaleType["KZ"] = "kz";
})(LocaleType || (LocaleType = {}));
export var ArenaTitleType;
(function (ArenaTitleType) {
    ArenaTitleType["ACM"] = "ACM";
    ArenaTitleType["AFM"] = "AFM";
    ArenaTitleType["AIM"] = "AIM";
    ArenaTitleType["AGM"] = "AGM";
})(ArenaTitleType || (ArenaTitleType = {}));
export var ChatType;
(function (ChatType) {
    ChatType["TOURNAMENT"] = "TOURNAMENT";
    ChatType["GAME"] = "GAME";
    ChatType["COMMUNITY"] = "COMMUNITY";
    ChatType["BROADCAST"] = "BROADCAST";
})(ChatType || (ChatType = {}));
export var ChallengeLifeCycleType;
(function (ChallengeLifeCycleType) {
    ChallengeLifeCycleType["SHORT"] = "short";
    ChallengeLifeCycleType["INVITE"] = "invite";
    ChallengeLifeCycleType["LONG"] = "long";
})(ChallengeLifeCycleType || (ChallengeLifeCycleType = {}));
export var checkEmailErrorCodes;
(function (checkEmailErrorCodes) {
    checkEmailErrorCodes["EMAIL_ALREADY_EXISTS"] = "email_already_exists";
    checkEmailErrorCodes["EMAIL_DOMAIN_IS_BANNED"] = "email_domain_is_banned";
    checkEmailErrorCodes["EMPTY_EMAIL"] = "empty_email";
    checkEmailErrorCodes["EMAIL_ALREADY_EXISTS_NOT_VERIFIED"] = "email_already_exists_not_verified";
    checkEmailErrorCodes["SOMETHING_WENTS_WRONG"] = "something_wents_wrong";
})(checkEmailErrorCodes || (checkEmailErrorCodes = {}));
export var fideAccountSelectErrorCodes;
(function (fideAccountSelectErrorCodes) {
    fideAccountSelectErrorCodes["ALREADY_ASSIGNED"] = "already_assigned";
    fideAccountSelectErrorCodes["PERMISSION_DENIED"] = "permission_denied";
    fideAccountSelectErrorCodes["NOT_AUTHENTICATED"] = "not_authenticated";
})(fideAccountSelectErrorCodes || (fideAccountSelectErrorCodes = {}));
export var ChallengeStatus;
(function (ChallengeStatus) {
    ChallengeStatus[ChallengeStatus["CREATED"] = 1] = "CREATED";
    ChallengeStatus[ChallengeStatus["ACCEPT"] = 2] = "ACCEPT";
    ChallengeStatus[ChallengeStatus["DECLINE"] = 3] = "DECLINE";
})(ChallengeStatus || (ChallengeStatus = {}));
