import { checkEmailErrorCodes, IRegistrationData } from 'shared/types';
import { Store, useStore } from 'storeshed';

export interface IRegistrationStore {
  current_password_error: string | null;
  password_error: string | null;
  registration_request: boolean;
  email_check_error: checkEmailErrorCodes | null;
  end_registration_request: boolean;
  end_registration_error: boolean;

  registration_data: IRegistrationData | null;
}

export const registrationInitialState = {
  current_password_error: null,
  password_error: null,
  registration_request: false,
  email_check_error: null,

  end_registration_request: false,
  end_registration_error: false,
  registration_data: null,
};

export const registrationStore = new Store<IRegistrationStore>({
  ...registrationInitialState,
});

type TUseRegistrationStore = {
  <K extends keyof IRegistrationStore>(key: K): IRegistrationStore[K];
};

export const useRegistrationData: TUseRegistrationStore = (key) => {
  return useStore(registrationStore, key);
};
