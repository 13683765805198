import { BaseService } from './_base.service';
export class NewsService extends BaseService {
    /**
     * Осуществляет запрос на получение новостей по тэгу
     *  @param {string} tag - новостной тэг
     */
    getTaggedNews(tag) {
        this.ajax.removeHeaders('Authorization');
        console.log(process.env.BASE_URL);
        return this.ajax.get(`${process.env.GAME_URL}/news/tags/${tag}/json/`);
    }
}
