export var eChessboardTheme;
(function (eChessboardTheme) {
    eChessboardTheme["ARMA"] = "arma";
    eChessboardTheme["AQUA"] = "aqua";
    eChessboardTheme["STANDARD"] = "standard";
    eChessboardTheme["WORLDCHESS"] = "worldchess";
    eChessboardTheme["TEXTBOOK"] = "textbook";
    eChessboardTheme["CURRY"] = "curry";
    eChessboardTheme["HIPSTER"] = "hipster";
    eChessboardTheme["LEMON_TREE"] = "lemon-tree";
    eChessboardTheme["MARBLE"] = "marble";
    eChessboardTheme["BUBBLEGUM"] = "bubblegum";
    eChessboardTheme["PHILIPPINES"] = "philippines";
})(eChessboardTheme || (eChessboardTheme = {}));
export var eLastMoveTheme;
(function (eLastMoveTheme) {
    eLastMoveTheme["NONE"] = "none";
    eLastMoveTheme["HIGHLIGHT"] = "highlight";
    eLastMoveTheme["ARROW"] = "arrow";
    eLastMoveTheme["ARMA"] = "arma";
})(eLastMoveTheme || (eLastMoveTheme = {}));
export var ePossibleMovesTheme;
(function (ePossibleMovesTheme) {
    ePossibleMovesTheme["NONE"] = "none";
    ePossibleMovesTheme["DOTS"] = "dots";
    ePossibleMovesTheme["SQUARES"] = "squares";
})(ePossibleMovesTheme || (ePossibleMovesTheme = {}));
export var eNotationTitleTheme;
(function (eNotationTitleTheme) {
    eNotationTitleTheme["INNER"] = "inner";
    eNotationTitleTheme["OUTTER"] = "outter";
    eNotationTitleTheme["NONE"] = "none";
})(eNotationTitleTheme || (eNotationTitleTheme = {}));
export var eAnalysisTheme;
(function (eAnalysisTheme) {
    eAnalysisTheme["ARROW"] = "arrow";
    eAnalysisTheme["NONE"] = "none";
})(eAnalysisTheme || (eAnalysisTheme = {}));
export var playerMoveTypes;
(function (playerMoveTypes) {
    playerMoveTypes["MOVE"] = "move";
    playerMoveTypes["PREMOVE"] = "premove";
})(playerMoveTypes || (playerMoveTypes = {}));
