import {
  IUserData,
  IRatingsData,
  IStatisticData,
  IRatingsChartsData,
  IChallengesList,
  IProfileData,
} from 'shared/types';
import { Store, useStore, useStoreComputed } from 'storeshed';

import { ITitleProgress } from '@types';

export interface IUserDataStore {
  data_request: boolean;
  data: IUserData | null; // TODO: вынести IUserData из shared
  ratings_request: boolean;
  ratings: IRatingsData | null;
  stats_request: boolean;
  wc_stats: IStatisticData | null;
  fide_stats: IStatisticData | null;
  otb_stats: IStatisticData | null;
  fide_charts: IRatingsChartsData | null;
  otb_charts: IRatingsChartsData | null;
  wc_charts: IRatingsChartsData | null;
  title_progress_request: boolean;
  title_progress: ITitleProgress | null;
  challenges: IChallengesList | null;
  challenge_opponent_data: IProfileData | null;
  challenges_request: boolean;
  // games_request: boolean;
  // games: IRequestPagination<IGameData> | null;
  // tournaments: IPlayerTournaments | null;
}

export const userDataInitialState = {
  data_request: true, // нужно true, т.к. данные юзера не сразу появляются в сторе
  data: null,
  ratings_request: false,
  ratings: null,
  stats_request: false,
  wc_stats: null,
  fide_stats: null,
  otb_stats: null,
  fide_charts: null,
  wc_charts: null,
  otb_charts: null,
  title_progress_request: false,
  title_progress: null,
  challenges: null,
  challenge_opponent_data: null,
  challenges_request: false,
  // games_request: false,
  // games: null,
  // tournaments: null,
};

export const userDataStore = new Store<IUserDataStore>({
  ...userDataInitialState,
});

type TUseUserDataStore = {
  <K extends keyof IUserDataStore>(key: K): IUserDataStore[K];
};
type TUseUserDataComputed = {
  <K extends keyof IUserDataStore, R>(
    key: K,
    cb: (state: IUserDataStore) => R
  ): R;
};

export const useUserDataStore: TUseUserDataStore = (key) => {
  return useStore(userDataStore, key);
};
export const useUserDataComputed: TUseUserDataComputed = (key, cb) =>
  useStoreComputed(userDataStore, key, cb);
