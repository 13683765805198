import React, { useMemo } from 'react';
import { $Popup } from './_styles';
import { IConfirmationPopup } from './_types';

/**
 * Попап подтверждения, схожий с window.confirm
 */
export const ConfirmationPopup = React.forwardRef<
  HTMLDivElement,
  IConfirmationPopup
>(function ConfirmationPopup({ promptText, onAccept, onDecline, width }, ref) {
  const buttons = useMemo(
    () => [
      {
        text: 'Yes',
        onClick: onAccept,
      },
      {
        text: 'No',
        onClick: onDecline,
      },
    ],
    [onAccept, onDecline]
  );

  return <$Popup width={width} ref={ref} text={promptText} buttons={buttons} />;
});
