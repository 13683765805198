export enum LobbyServerPayloadType {
  RSVD_PING = 'RSVD_PING',
  RSVD_PONG = 'RSVD_PONG',
  RSVD_RSP_TAG_LIST = 'RSVD_RSP_TAG_LIST',
  RSVD_REQ_TAG_EDIT = 'RSVD_REQ_TAG_EDIT',
  GAME_REQUEST_QUEUE_UPDATED = 'GAME_REQUEST_QUEUE_UPDATED',
  GAME_STATE = 'GAME_STATE',
  GAME_ANALYSIS_READY = 'GAME_ANALYSIS_READY',
  NO_GAME_ANALYSIS = 'NO_GAME_ANALYSIS',
}

export enum GameServerPayloadType {
  STATE = 'STATE',
  DEBUG = 'DEBUG',
  LATENCY_REQ = 'LATENCY_REQ',
}

export enum NewGamingActions {
  GAME_STATE = 'GAME_STATE',
  RSVD_DEBUG = 'RSVD_DEBUG',
  RSVD_TEST_PAYLOAD_TYPE = 'RSVD_TEST_PAYLOAD_TYPE',
  GAME_REQUEST_STATE = 'GAME_REQUEST_STATE',
  RSVD_PING = 'RSVD_PING',
}

export enum Phase {
  SCHEDULED = 'SCHEDULED',
  CONNECTING = 'CONNECTING',
  PLAYING = 'PLAYING',
  DONE = 'DONE',
  UNEXPECTED = 'UNEXPECTED',
}

export enum Cause {
  YOU_CONNECTED = 'YOU_CONNECTED',
  RIVAL_CONNECTED = 'RIVAL_CONNECTED',
  RIVAL_DISCONNECTED = 'RIVAL_DISCONNECTED',
  PHASE_CHANGED = 'PHASE_CHANGED',
  FIRST_MOVE_WARN = 'FIRST_MOVE_WARN',
  DRAW_OFFER = 'DRAW_OFFER',
  SCHEDULED_WARN = 'SCHEDULED_WARN',
  CONNECTING_WARN = 'CONNECTING_WARN',
  BAD_MOVE = 'BAD_MOVE',
  MOVED = 'MOVED',
  UNEXPECTED = 'UNEXPECTED',
}

export enum DrawRule {
  DRAW_OFFER_WHITE = 'DRAW_OFFER_WHITE',
  DRAW_OFFER_BLACK = 'DRAW_OFFER_BLACK',
  DRAW_50_MOVES = 'DRAW_50_MOVES',
  DRAW_3_FOLD_REPETITION = 'DRAW_3_FOLD_REPETITION',
}

export enum DoneResult {
  WHITE_WIN = 'WHITE_WIN',
  DRAW = 'DRAW',
  BLACK_WIN = 'BLACK_WIN',
  ABORT = 'ABORT',
  UNEXPECTED = 'UNEXPECTED',
}

export enum DoneReason {
  DRAW_OFFER = 'DRAW_OFFER',
  DRAW_50_MOVES = 'DRAW_50_MOVES', // показывается кнопка Claim draw + последний ход
  DRAW_75_MOVES = 'DRAW_75_MOVES', // партия автоматически заканчивается ничьей + последний ход
  DRAW_3_FOLD_REPETITION = 'DRAW_3_FOLD_REPETITION', // показывается кнопка Claim draw + последний ход
  DRAW_5_FOLD_REPETITION = 'DRAW_5_FOLD_REPETITION', // партия автоматически заканчивается ничьей + последний ход
  DRAW_INSUFFICIENT_MATERIAL = 'DRAW_INSUFFICIENT_MATERIAL', // невозможность поставить мат сопернику + последний ход
  DRAW_INSUFFICIENT_MATERIAL_TIMEOUT_WHITE = 'DRAW_INSUFFICIENT_MATERIAL_TIMEOUT_WHITE', // невозможность поставить мат+окончание времени - нет последнего хода
  DRAW_INSUFFICIENT_MATERIAL_TIMEOUT_BLACK = 'DRAW_INSUFFICIENT_MATERIAL_TIMEOUT_BLACK', // невозможность поставить мат+окончание времени - нет последнего хода
  DRAW_STALE_MATE = 'DRAW_STALE_MATE', // ничья + последний ход (напомнить Владимиру про нейминг)

  WIN_CHECKMATE = 'WIN_CHECKMATE', // шахмат + последний ход
  WIN_RESIGN = 'WIN_RESIGN', // сдаться - нет последнего хода
  WIN_TIME_CONTROL = 'WIN_TIME_CONTROL', // победа когда вышло время - нет последнего хода
  WIN_TIMEOUT_ONE_CONNECTION = 'WIN_TIMEOUT_ONE_CONNECTION', // победа когда не в сети - нет последнего хода

  ABORT_TIMEOUT_BOTH_CONNECTIONS = 'ABORT_TIMEOUT_BOTH_CONNECTIONS', // ?
  ABORT_TIMEOUT_FIRST_MOVE_WHITE = 'ABORT_TIMEOUT_FIRST_MOVE_WHITE', // ?
  ABORT_MANUAL_FIRST_MOVE_WHITE = 'ABORT_MANUAL_FIRST_MOVE_WHITE', // предупреждение что нужно сделать первый ход  - нет последнего хода
  ABORT_MANUAL_FIRST_MOVE_BLACK = 'ABORT_MANUAL_FIRST_MOVE_BLACK', // предупреждение что нужно сделать первый ход  - нет последнего хода

  UNEXPECTED = 'UNEXPECTED',
}

export interface Turn {
  start: {
    absNumber: number; // as opposite to colorNumber it increases when any player makes a move
    color: boolean; // "true" for white, "false" for black
    clk: GameClock; // copy of game clock right before start of the turn
    serverTs: string; // start of turn in server's time
    rivalTs: string; // start of turn in rival's time (with latency compensation, etc.)
  };
  end: {
    longAn: string;
    shortAn: string;
    fen: string;
    clk: GameClock; // copy of game clock right after end of the turn
    serverTs: string; // when turn was completed in server's time
  };
}

export interface GameClock {
  spent: number; // time spent in the game, millis
  left: number; // time left, millis
  limit: number; // maximum length of the game with all increases, millis
  creation: string; // when clock created, ignore it
}

export interface GameCfg {
  gId: string; // game identifier
  wPId: string; // white player identifier
  bPId: string; // black player identifier
  scheduledPhaseCfg: {
    // configuration of "scheduled phase" of the game
    warns: number[]; // when to send warnings to players BEFORE phase end ("start"), millis
    start: string; // when to proceed to next phase
  };
  connectingPhaseCfg: {
    // configuration of "connecting phase" of the game
    warns: number[]; // when to send warnings to players AFTER phase start, millis
    abort: number; // when to abort game if not all players connected AFTER phase start, millis
  };
  playingPhaseCfg: {
    // configuration of "playing phase" of the game
    wFirstMoveCfg: {
      // configuration of first move for white player
      warns: number[]; // when to send warnings to player AFTER start of his turn, millis
      abort: number; // when to abort game if player didn't make his move AFTER start of his turn, millis
    };
    wTc: {
      // white player time control
      initial: number; // initial time, millis
      incrOnEachMove: {
        // how time is increases on each move
        incr: number; // increase, millis
      };
      incrOnNumberedMove: {
        // how time is increased on specified move number, millis
        incr: number; // increase, millis
        colorNumber: number; // as opposite to absNumber it increases only if corresponding color makes a move
      };
    };
    bTc: {
      // black player time control
      initial: number; // see `wTc` above
      incrOnEachMove: {
        // see `wTc` above
        incr: number; // see `wTc` above
      };
      incrOnNumberedMove: {
        // see `wTc` above
        incr: number; // see `wTc` above
        colorNumber: number; // see `wTc` above
      };
    };
  };
  playerSessionCfg: {
    // configuration of player session
    minTurnDuration: number; // minimal duration of turn which server can register, millis
    maxLatency: number; // maximal latency of player session, millis
    minFirstMoveLatency: number; // latency which is applied for first move, millis
  };
  extra: null; // any JSON object, e.g. with tournament description
}

export interface NewGamingStateMessage {
  cause?: Cause; // the reason this message was sent
  currPhase?: Phase; // current game phase
  scheduledPhase?: {
    // state of "scheduling phase"
    phaseEnd: string; // when phase is ended
  };
  connectingPhase?: {
    // state of "connecting phase", null if not yet
    phaseEnd: string;
    // when phase is ended
  };
  playingPhase?: {
    // state of "playing phase", null if not yet
    lastTurn: Turn | null; // last finished turn, null if not yet
    drawSituation: DrawRule[]; // draw possibilities, can be empty if draw is not possible
    wClk: GameClock; // last registered clock state for white player
    bClk: GameClock; // last registered clock state for black player
    phaseStart: string; // when phase is started, effectively start of the game
    turns: Turn[]; // all turns in order
  };
  donePhase?: {
    // state of "done phase", null if not yet
    result: DoneResult; // game result
    reason: DoneReason; // reason why game is ended
    phaseStart: string; // when phase is started, effectively end of the game
  };
  cfg?: GameCfg; // configuration of the game, immutable
}

export enum IMessageAdapter {
  START = 'START',
  FIRST_MOVE_WARN = 'FIRST_MOVE_WARN',
  MOVE = 'MOVE',
  ABORT = 'ABORT',
  RESIGN = 'RESIGN',
}

export type NewGamingMessageType = NewGamingStateMessage;

export interface NewGamingMessage {
  payloadType: NewGamingActions;
  payload: NewGamingMessageType;
}
