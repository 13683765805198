import { figureType, FILES, PIECES, RANKS } from "../_constants";
export const parseSan = (san) => {
    let clearedSan = san.replace(/x|#|\=|\+/g, "");
    let promotionFigure = clearedSan[clearedSan.length - 1];
    if (!PIECES.includes(promotionFigure)) {
        promotionFigure = null;
    }
    else {
        clearedSan = clearedSan.slice(0, -1);
    }
    const to = {
        file: clearedSan[clearedSan.length - 2],
        rank: clearedSan[clearedSan.length - 1],
    };
    const from = {
        file: (FILES.includes(clearedSan[clearedSan.length - 3]) &&
            clearedSan[clearedSan.length - 3]) ||
            undefined,
        rank: (RANKS.includes(clearedSan[clearedSan.length - 3]) &&
            clearedSan[clearedSan.length - 3]) ||
            undefined,
    };
    return {
        san,
        to,
        from,
        piece: ["K", "Q", "R", "B", "N"].includes(san[0])
            ? san[0].toLowerCase()
            : figureType.PAWN,
        promotionFigure: promotionFigure ? promotionFigure.toLowerCase() : null,
        capture: san.includes("x"),
        check: san.includes("+") || san.includes("#"),
        checkmate: san.includes("#"),
    };
};
