import { BLACK_SYMBOLS, chessSide, WHITE_SYMBOLS } from "../_constants";
/**
 * Возвращает данные о фигурах из fen (на доске и захваченные)
 * @param {string} fen
 * @returns {{figures: IParsedFenFigure[], capturedFigures: ICapturedFigures}} result
 */
export const getFiguresFromFen = (fen) => {
    const [$positions] = fen.split(" ");
    const $figures = [];
    $positions.split("/").forEach((line, rankIndex) => {
        const chars = line.split("");
        let $position = rankIndex * 16;
        for (let i = 0; i < chars.length; i++) {
            const char = chars[i];
            if (!WHITE_SYMBOLS.includes(char) && !BLACK_SYMBOLS.includes(char)) {
                $position += parseInt(char);
                continue;
            }
            const color = WHITE_SYMBOLS.includes(char) ? chessSide.WHITE : chessSide.BLACK;
            $figures.push({
                char,
                color,
                position: $position,
                type: char.toLocaleLowerCase(),
            });
            $position++;
        }
    });
    // prettier-ignore
    const $defaultFigures = ['p', 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'q', 'r', 'r', 'b', 'b', 'n', 'n'];
    const $capturedFigures = {
        [chessSide.WHITE]: $defaultFigures,
        [chessSide.BLACK]: $defaultFigures,
    };
    $figures.forEach((f) => {
        $capturedFigures[f.color] = $capturedFigures[f.color]
            .join("")
            .replace(f.type, "")
            .split("");
    });
    return {
        figures: $figures,
        capturedFigures: $capturedFigures,
    };
};
