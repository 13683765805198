import {
  IRegistrationData,
  checkEmailErrorCodes,
  ICheckEmailError,
} from 'shared/types';
import { IRequestError, IUpdateMe } from '@types';
import {
  authActions,
  loginActions,
  registrationActions,
  userDataActions,
} from './index';
import { TMainStore } from '@store/storeshed';
import { userService } from '@services/_user.service';

export const createRegistrationActions = () => {
  /**
   * Задает регистрационные данные юзера (token & uid).
   * @param {Object} data - регистрационные данные юзера.
   */
  const setRegistrationData = (
    { registrationStore }: TMainStore,
    data: IRegistrationData
  ) => {
    registrationStore.dispatch('registration_data', data);
  };

  /**
   * Задает состояние запроса на регистрацию.
   * @param {boolean} inRequest - выполняется ли запрос на регистрацию.
   */
  const setRegistrationRequest = (
    { registrationStore }: TMainStore,
    inRequest: boolean
  ) => {
    registrationStore.dispatch('registration_request', inRequest);
  };

  /**
   * Задает ошибку от запроса на регистрацию.
   * @param {checkEmailErrorCodes} error - текст ошибки.
   */
  const setEmailCheckError = (
    { registrationStore }: TMainStore,
    error: checkEmailErrorCodes | null
  ) => {
    registrationStore.dispatch('email_check_error', error);
  };

  /**
   * Задает состояние запроса на окончание регистрации.
   * @param {boolean} inRequest - выполняется ли запрос на регистрацию.
   */
  const setEndRegistrationRequest = (
    { registrationStore }: TMainStore,
    inRequest: boolean
  ) => {
    registrationStore.dispatch('end_registration_request', inRequest);
  };

  /**
   * Задает ошибку от запроса на окончание регистрации.
   * @param {boolean} error - произошла ли ошибка.
   */
  const setEndRegistrationError = (
    { registrationStore }: TMainStore,
    error: boolean
  ) => {
    registrationStore.dispatch('end_registration_error', error);
  };

  /**
   * Задает ошибку заполнения формы текущего пароля.
   * @param {string | null} error - ошибка заполнения текущего пароля
   */
  const setCurrentPasswordError = (
    { registrationStore }: TMainStore,
    error: string | null
  ) => {
    registrationStore.dispatch('current_password_error', error);
  };

  /**
   * Задает ошибку заполнения формы пароля.
   * @param {string | null} error - ошибка заполнения пароля
   */
  const setPasswordError = (
    { registrationStore }: TMainStore,
    error: string | null
  ) => {
    registrationStore.dispatch('password_error', error);
  };

  /**
   * Выполняет запрос на регистрацию нового пользователя. Сначала делается проверка email.
   * Перед запросом задает user.registration_request значение true, после - false.
   * В случае успешного запроса отправляет проверочный код.
   * @param {string} email - почта пользователя.
   * @param {string} firstName - имя пользователя.
   * @param {string} lastName - фамилия пользователя.
   * @param {string} country - страна пользователя.
   * @param {boolean} isPaid - платная или бесплатная подписка.
   * @param {string} password - пароль пользователя.
   * @param {boolean} freePro - запрос на создание бесплатного про-аккаунта.
   * @param {Function} nextStep - переход к следующему шагу создания аккаунта.
   * @param {Function} setIsVerification - устанавливает статус неверефицированного аккаунта.
   */
  const registration = async (
    {}: TMainStore,
    {
      email,
      firstName,
      lastName,
      country,
      isPaid,
      password,
      freePro,
      nextStep,
      setIsVerification,
    }: {
      email: string;
      firstName: string;
      lastName: string;
      country: string;
      isPaid: boolean;
      password: string;
      freePro: boolean;
      nextStep: () => void;
      setIsVerification: (isVerification: boolean) => void;
    }
  ) => {
    registrationActions.setRegistrationRequest(true);

    try {
      const { ok } = await userService.checkEmail(email);

      if (ok) {
        await registrationActions.getAuthCode({
          email,
          firstName,
          lastName,
          country,
          isPaid,
          password,
          freePro,
          nextStep,
        });
      }
    } catch (err) {
      console.log('err', err);
      const errorData: ICheckEmailError = (
        err as IRequestError<ICheckEmailError>
      ).data;

      if (!errorData?.code) {
        loginActions.setActivationCodeError(
          checkEmailErrorCodes.SOMETHING_WENTS_WRONG
        );
      }

      if (
        errorData?.code ===
        checkEmailErrorCodes.EMAIL_ALREADY_EXISTS_NOT_VERIFIED
      ) {
        setIsVerification(true);
        await loginActions.resendActivationCode(email, nextStep);
      } else {
        registrationActions.setEmailCheckError(errorData.code);
      }
    }

    registrationActions.setRegistrationRequest(false);
  };

  /**
   * Выполняет запрос на отправку проверочного кода на почту пользователя.
   * В случае успешного запроса переходит к следующему шагу.
   * @param {string} email - почта пользователя.
   * @param {string} firstName - имя пользователя.
   * @param {string} lastName - фамилия пользователя.
   * @param {string} country - страна пользователя.
   * @param {boolean} isPaid - платная или бесплатная подписка.
   * @param {boolean} freePro - запрос на создание бесплатного про-аккаунта.
   * @param {Function} nextStep - переход к следующему шагу создания аккаунта.
   */
  const getAuthCode = async (
    {}: TMainStore,
    {
      email,
      firstName,
      lastName,
      country,
      isPaid,
      password,
      freePro,
      nextStep,
    }: {
      email: string;
      firstName: string;
      lastName: string;
      country: string;
      isPaid: boolean;
      password: string;
      freePro: boolean;
      nextStep: () => void;
    }
  ) => {
    try {
      const { ok, data } = await userService.authCode({
        email: email,
        first_name: firstName,
        last_name: lastName,
        country: country,
        is_paid: isPaid,
        password: password,
        free_account_requested: freePro,
      });

      if (ok) {
        authActions.setToken(data.token);
        nextStep();
      }
    } catch (err) {
      const data = (
        err as IRequestError<{
          email: Array<checkEmailErrorCodes>;
        }>
      )?.data;
      if (data?.email) {
        console.log(data);
        registrationActions.setEmailCheckError(data.email[0]);
      }
    }
  };

  /**
   * Выполняет запрос на проверочный кода.
   * Перед запросом задает user.activation_code_request значение true, после - false.
   * В случае успешного запроса задает регистрационные данные переходит к следующему шагу.
   * @param {string} uid - uid, полученный при проверке кода.
   * @param {string} token - token, полученный при проверке кода.
   * @param {string} new_password - пароль пользователя.
   * @param {string} userData- регистрационные данные пользователя.
   * @param {Function} nextStep - переход к следующему шагу создания аккаунта.
   * @param {string} email - почта пользователя.
   */
  const registrationEnd = async (
    {}: TMainStore,
    {
      uid,
      token,
      new_password,
      email,
      userData,
      nextStep,
    }: {
      uid: string;
      token: string;
      new_password: string;
      email?: string | null;
      userData?: IUpdateMe;
      nextStep: () => void;
    }
  ) => {
    registrationActions.setEndRegistrationError(false);
    registrationActions.setEndRegistrationRequest(true);

    try {
      const { ok } = await userService.passwordResetConfirm(
        uid,
        token,
        new_password
      );

      console.log('ok', ok);
      if (ok) {
        if (userData) {
          if (userData.receive_newsletters === 'false') {
            await userService.updateMe(userData);
          }
          userDataActions.getMe();
        }
        if (email) {
          loginActions.login({ email, password: new_password });
        }
        nextStep();
      }
    } catch (err) {
      const data = (
        err as IRequestError<{
          new_password: string;
          code: string;
          detail: string;
        }>
      )?.data;
      console.log('data', data);

      if (data.new_password) {
        registrationActions.setEndRegistrationError(true);
      }

      if (data.code === 'authentication_failed') {
        registrationActions.setPasswordError(data.detail);
      }
    }

    registrationActions.setEndRegistrationRequest(false);
  };

  return {
    setRegistrationData,
    setRegistrationRequest,
    setEmailCheckError,
    setEndRegistrationRequest,
    setEndRegistrationError,
    setCurrentPasswordError,
    setPasswordError,

    registration,
    getAuthCode,
    registrationEnd,
  };
};
