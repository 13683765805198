import { chessSide } from 'chessgun/core';
import { GameStatus } from 'shared/types';

import {
  GameType,
  GameUserType,
  IAppAction,
  IGameState,
  gameActionTypes,
} from '@types';

const initialState: IGameState = {
  game_data: null,
  game_data_request: false,

  game_type: GameType.NONE,
  game_user_type: GameUserType.NONE,

  engine: null,
  liveEngine: null,
  player_turn: chessSide.WHITE,
  is_review_mode: false,
  is_analyze_mode: false,

  board_flipped: false,
  board_rotation: 0,
  game_status: GameStatus.NONE,

  board_id: null,
  game_ended: null,

  player_warning: null,
  opponent_warning: null,

  my_color: chessSide.WHITE,
  moves_history: [],
  selected_move: null,
  selected_move_analysis: null,
  black_player: null,
  white_player: null,
  black_captured: [],
  white_captured: [],
  black_ms_left: 0,
  white_ms_left: 0,
  black_move_end_time: null,
  white_move_end_time: null,
  black_advantage: 0,
  white_advantage: 0,
  white_pulse_history: [],
  black_pulse_history: [],

  opponent_disconnected: false,

  pgn: '',
  pgn_file_name: '',
  pgn_request: false,

  game_viewers_count: 0,
};

export const gameReducer = function (
  state: IGameState = initialState,
  { type, payload }: IAppAction
): IGameState {
  switch (type) {
    // game data
    case gameActionTypes.SET_GAME_DATA:
      return {
        ...state,
        game_data: payload,
      };
    case gameActionTypes.SET_GAME_DATA_REQUEST:
      return {
        ...state,
        game_data_request: payload,
      };

    // game type
    case gameActionTypes.SET_GAME_TYPE:
      return {
        ...state,
        game_type: payload,
      };
    case gameActionTypes.SET_GAME_USER_TYPE:
      return {
        ...state,
        game_user_type: payload,
      };

    // engine
    case gameActionTypes.SET_GAME_ENGINE:
      return {
        ...state,
        engine: payload,
      };

    // live engine
    case gameActionTypes.SET_LIVE_GAME_ENGINE:
      return {
        ...state,
        liveEngine: payload,
      };

    case gameActionTypes.SET_PLAYER_TURN:
      return {
        ...state,
        player_turn: payload,
      };
    case gameActionTypes.SET_BOARD_FLIPPED:
      return {
        ...state,
        board_flipped: payload,
      };
    case gameActionTypes.SET_GAME_STATUS:
      return {
        ...state,
        game_status: payload,
      };

    case gameActionTypes.SET_BOARD_ROTATION:
      return {
        ...state,
        board_rotation: payload,
      };

    case gameActionTypes.SET_GAME_REVIEW_MODE:
      return {
        ...state,
        is_review_mode: payload,
      };
    case gameActionTypes.SET_ANALYZE_MODE:
      return {
        ...state,
        is_analyze_mode: payload,
      };

    case gameActionTypes.SET_GAME_BOARD_ID:
      return {
        ...state,
        board_id: payload,
      };

    case gameActionTypes.SET_MY_COLOR:
      return {
        ...state,
        my_color: payload,
      };

    case gameActionTypes.SET_PLAYER_WARNING:
      return {
        ...state,
        player_warning: payload,
      };
    case gameActionTypes.SET_OPPONENT_WARNING:
      return {
        ...state,
        opponent_warning: payload,
      };

    case gameActionTypes.SET_GAME_ENDED:
      return {
        ...state,
        game_ended: payload,
      };

    // moves
    case gameActionTypes.SET_GAME_MOVES_HISTORY:
      return {
        ...state,
        moves_history: payload,
      };
    case gameActionTypes.SET_GAME_SELECTED_MOVE:
      return {
        ...state,
        selected_move: payload,
      };

    case gameActionTypes.SET_GAME_SELECTED_MOVE_ANALYSIS:
      return {
        ...state,
        selected_move_analysis: payload,
      };

    case gameActionTypes.SET_GAME_VIEWERS_COUNT:
      return {
        ...state,
        game_viewers_count: payload,
      };

    case gameActionTypes.SET_OPPONENT_DISCONNECTED:
      return {
        ...state,
        opponent_disconnected: payload,
      };

    // pgn
    case gameActionTypes.SET_GAME_PGN:
      return {
        ...state,
        pgn: payload,
      };
    case gameActionTypes.SET_PGN_FILE_NAME:
      return {
        ...state,
        pgn_file_name: payload,
      };
    case gameActionTypes.SET_GAME_PGN_REQUEST:
      return {
        ...state,
        pgn_request: payload,
      };

    // game players
    case gameActionTypes.SET_GAME_BLACK_PLAYER:
      return {
        ...state,
        black_player: payload,
      };

    case gameActionTypes.SET_GAME_WHITE_PLAYER:
      return {
        ...state,
        white_player: payload,
      };

    // captured figures
    case gameActionTypes.SET_GAME_BLACK_CAPTURED:
      return {
        ...state,
        black_captured: payload,
      };

    case gameActionTypes.SET_GAME_WHITE_CAPTURED:
      return {
        ...state,
        white_captured: payload,
      };

    // advantage
    case gameActionTypes.SET_GAME_BLACK_ADVANTAGE:
      return {
        ...state,
        black_advantage: payload,
      };

    case gameActionTypes.SET_GAME_WHITE_ADVANTAGE:
      return {
        ...state,
        white_advantage: payload,
      };

    // pulse history
    case gameActionTypes.SET_WHITE_PULSE_HISTORY:
      return {
        ...state,
        white_pulse_history: payload,
      };

    case gameActionTypes.SET_BLACK_PULSE_HISTORY:
      return {
        ...state,
        black_pulse_history: payload,
      };

    // seconds left
    case gameActionTypes.SET_BLACK_PLAYER_MS_LEFT:
      return {
        ...state,
        black_ms_left: payload,
      };

    case gameActionTypes.SET_WHITE_PLAYER_MS_LEFT:
      return {
        ...state,
        white_ms_left: payload,
      };
    case gameActionTypes.SET_BLACK_PLAYER_MOVE_END_TIME:
      return {
        ...state,
        black_move_end_time: payload,
      };

    case gameActionTypes.SET_WHITE_PLAYER_MOVE_END_TIME:
      return {
        ...state,
        white_move_end_time: payload,
      };

    case gameActionTypes.RESET_GAME:
      console.log('RESET GAME');
      return initialState;

    default:
      return state;
  }
};
