import { chessSide } from "../_constants";
/**
 * Возвращает возможные ходы пешки
 * @param {number} index - индекс пешки на доске
 * @param {string[]} board - доска
 * @returns
 */
export const getPawnPossibleMoves = ({ index, board, }) => {
    const possibleMoves = [];
    const PAWN_SHIFTS = {
        p: 16,
        P: -16,
    };
    const shift = PAWN_SHIFTS[board[index]];
    const newPos = index + shift;
    if (!board[newPos]) {
        possibleMoves.push(newPos);
        const color = board[index] === "P" ? chessSide.WHITE : chessSide.BLACK;
        const didntMove = color === chessSide.WHITE ? index >= 96 && index <= 103 : index >= 16 && index <= 23;
        if (didntMove && !board[newPos + shift]) {
            possibleMoves.push(newPos + shift);
        }
    }
    return possibleMoves;
};
