import { gameCommonActions } from './_common.actions';
import { gameMovesActions } from './_moves.actions';
import { gamePgnActions } from './_pgn.actions';
import { gamePlayersActions } from './_players.actions';
import { gameReviewActions } from './_review.actions';

export const gameActions = {
  ...gameCommonActions,
  ...gameMovesActions,
  ...gamePgnActions,
  ...gamePlayersActions,
  ...gameReviewActions,
};
