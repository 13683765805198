import { chessSide, SQUARES, WHITE_SYMBOLS } from "../_constants";
/** Расчитывает возможные рокировки после! хода */
export const getPossibleCastlings = ({ board, possibleCastlings, lan, }) => {
    let $possibleCastlings = Object.assign({}, possibleCastlings);
    const $move = getLanFromTo(lan);
    const $movingPiece = board[$move.from];
    board[$move.from] = "";
    board[$move.to] = $movingPiece;
    $possibleCastlings = getPossibleCastlingsDependingOnMovingPiece($movingPiece, $possibleCastlings, lan);
    $possibleCastlings = checkRooksAfterMove(board, $possibleCastlings);
    return $possibleCastlings;
};
const isKing = (piece) => piece.toLowerCase() === "k";
const isRook = (piece) => piece.toLowerCase() === "r";
const includesRookIndexes = (index) => [0, 7, 112, 119].includes(index);
const getLanFromTo = (lan) => ({
    from: SQUARES[lan.substring(0, 2)],
    to: SQUARES[lan.substring(2, 4)],
});
// Смотрим, кто ходил и меняем возможные рокировки
const getPossibleCastlingsDependingOnMovingPiece = (piece, possibleCastlings, lan) => {
    const $move = getLanFromTo(lan);
    const $pieceColor = WHITE_SYMBOLS.includes(piece) ? chessSide.WHITE : chessSide.BLACK;
    if (isKing(piece)) {
        possibleCastlings[$pieceColor] = {
            kingSide: false,
            queenSide: false,
        };
    }
    else if (isRook(piece) && includesRookIndexes($move.from)) {
        const $side = [0, 112].includes($move.from) ? "queenSide" : "kingSide";
        possibleCastlings[$pieceColor][$side] = false;
    }
    return possibleCastlings;
};
// Проверям, все ли ладьи стоят на своих местах после хода
const checkRooksAfterMove = (board, possibleCastlings) => {
    const $possibleCastlings = Object.assign({}, possibleCastlings);
    const $rookIndexes = [
        { index: 0, piece: "r", castlingSide: "queenSide" },
        { index: 7, piece: "r", castlingSide: "kingSide" },
        { index: 112, piece: "R", castlingSide: "queenSide" },
        { index: 119, piece: "R", castlingSide: "kingSide" },
    ];
    $rookIndexes.forEach(({ index, piece, castlingSide }) => {
        if (board[index] !== piece) {
            const $color = piece === "R" ? chessSide.WHITE : chessSide.BLACK;
            $possibleCastlings[$color][castlingSide] = false;
        }
    });
    return $possibleCastlings;
};
