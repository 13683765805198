import styled from 'styled-components';
import { textColors, buttonColors, shapeColors, transparentColor, baseText, Header_text, baseRow, baseTransition, } from '../../../../styles';
export const $Tab = styled.div `
  position: relative;
  height: 100%;
  flex: 1;
  ${baseRow()};
  padding: 0 5px;
  cursor: pointer;
  ${baseTransition};

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
`;
export const $Icon = styled.div `
  position: relative;
  margin-right: 5px;
  width: 20px;
  height: 15px;
  ${baseRow()};
`;
export const $Container = styled.div `
  position: relative;
  ${baseRow()}
  height: 30px;
  ${Header_text()}

  &.error {
    ${$Tab} {
      border-color: ${textColors['red']};
    }
  }

  &.primary_selector {
    ${$Tab} {
      background: ${buttonColors['grey_dark']};

      &:hover {
        &:not(.disabled):not(.active) {
          color: ${textColors['brown']};
        }
      }

      &.active {
        background: ${buttonColors['black']};

        &:hover {
          color: ${textColors['white']};
        }
      }
    }
  }

  &.secondary_selector {
    ${$Tab} {
      border: 1px solid ${transparentColor(buttonColors['white'], 0.3)};

      &:first-of-type {
        border-radius: 3px 0px 0px 3px;
      }

      &:not(:first-of-type) {
        margin-left: -1px;
      }

      &:last-of-type {
        border-radius: 0px 3px 3px 0px;
      }

      &:hover {
        &:not(.disabled):not(.active) {
          border: 1px solid ${transparentColor(buttonColors['white'], 0.5)};
          background: ${buttonColors['grey_dark']};
        }
      }

      &.active {
        border: 1px solid ${buttonColors['white']};

        z-index: 10;

        &:hover {
          border: 1px solid ${buttonColors['white']};
          background: transparent;
        }
      }
    }
  }

  &.white_selector {
    height: 24px;

    ${$Tab} {
      flex: none;
      padding: 0 12px;
      background-color: ${shapeColors['grey_deep']};
      ${baseText()};

      &:first-of-type {
        border-radius: 2px 0px 0px 2px;
      }

      &:last-of-type {
        border-radius: 0px 2px 2px 0px;
      }

      &:hover {
        &:not(.disabled):not(.active) {
          background-color: ${transparentColor(buttonColors['white'], 0.8)};
          color: ${shapeColors['grey_dark']};
        }
      }

      &.active {
        background-color: ${shapeColors['white']};
        color: ${shapeColors['grey_dark']};

        &:hover {
          background-color: ${shapeColors['white']};
          color: ${shapeColors['grey_dark']};
        }
      }
    }
  }

  &.dark_selector {
    ${$Tab} {
      background: ${shapeColors['grey_deep_lighter']};

      &:first-of-type {
        border-radius: 3px 0 0 0;
      }

      &:last-of-type {
        border-radius: 0 3px 0 0;
      }

      &.active {
        background: ${buttonColors['grey_dark']};
      }
    }
  }

  &.white_outline_selector {
    ${$Tab} {
      flex: none;
      padding: 5px 25px;
      border: 1px solid ${transparentColor(buttonColors['white'], 0.5)};
      ${baseText({ color: textColors['white'], fontSize: '22px' })};

      &:first-of-type {
        border-radius: 3px 0px 0px 3px;
      }

      &:last-of-type {
        border-radius: 0px 3px 3px 0px;
      }

      &:hover {
        &:not(.disabled):not(.active) {
          background-color: ${transparentColor(buttonColors['white'], 0.5)};
          color: ${textColors['black']};
        }
      }

      &.active {
        background-color: ${shapeColors['white']};
        color: ${textColors['black']};

        &:hover {
          background-color: ${shapeColors['white']};
          color: ${textColors['black']};
        }
      }
    }
  }
`;
