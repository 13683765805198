import { useCallback } from 'react';
import {
  IActiveGamesContextDispatch,
  IActiveGamesContextState,
  TGameTypeOptions,
  TRatingTypeOptions,
  TTimeControlOptions,
  eActiveGamesContextActionTypes,
} from './_types';

export const useActiveGamesActions = (
  dispatch: IActiveGamesContextDispatch,
  _: IActiveGamesContextState
) => {
  const setFilterGameType = useCallback(
    (filterGameType: TGameTypeOptions) => {
      dispatch({
        type: eActiveGamesContextActionTypes.SET_FILTER_GAME_TYPE,
        payload: filterGameType,
      });
    },
    [dispatch]
  );

  const setFilterTimeControl = useCallback(
    (filterTimeControl: TTimeControlOptions) => {
      dispatch({
        type: eActiveGamesContextActionTypes.SET_FILTER_TIME_CONTROL,
        payload: filterTimeControl,
      });
    },
    [dispatch]
  );

  const setFilterRatingType = useCallback(
    (filterRatingType: TRatingTypeOptions) => {
      dispatch({
        type: eActiveGamesContextActionTypes.SET_FILTER_RATING_TYPE,
        payload: filterRatingType,
      });
    },
    [dispatch]
  );

  return {
    setFilterGameType,
    setFilterTimeControl,
    setFilterRatingType,
  };
};
