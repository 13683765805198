import React from 'react';
import { shapeColors } from '../../../../styles';
/**
 * Иконка: Мультиборд
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 * @param {string} className - внешний класс
 */
export const TwoBoardsIcon = ({ fill = shapeColors['white'], width = '100%', height = '100%', className = '', }) => (React.createElement("svg", { className: className, width: width, height: height, viewBox: "0 0 16 15", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { stroke: fill, d: "M1.2.5h7.1v7.1H1.2z" }),
    React.createElement("path", { stroke: fill, d: "M8.2.5h7.1v7.1H8.2zM15.7 11.5H.7M15.7 14.5H.7" })));
