import {
  useAuthStore,
  useUserDataComputed,
  useUserDataStore,
} from '@store/storeshed';
import { getUserStatus } from '@utils/_getUserStatus';

export enum UserStatus {
  LOADING = 'loading',
  UNAUTHORIZED = 'unauthorized',
  AUTHORIZED = 'authorized',
  SUBSCRIBED = 'subscribed',
}

export const useUserStatus = () => {
  const userDataRequest = useUserDataStore('data_request');
  const logged = useAuthStore('logged');
  const accessLevel = useUserDataComputed(
    'data',
    ({ data }) => data?.access_level
  );

  return getUserStatus({ accessLevel, logged, isRequest: userDataRequest });
};
