import { IActivationError } from 'shared/types';
import { Store, useStore } from 'storeshed';

export interface ILoginStore {
  show_activation_button: boolean;
  login_request: boolean;
  login_error: null | string;
  activation_code_request: boolean;
  activation_code_error: string | IActivationError | null;
  resend_activation_code_request: boolean;
}

export const loginInitialState = {
  login_request: false,
  login_error: null,
  show_activation_button: false,
  activation_code_request: false,
  activation_code_error: null,
  resend_activation_code_request: false,
};

export const loginStore = new Store<ILoginStore>({ ...loginInitialState });

export const useLoginStore = <K extends keyof ILoginStore>(
  key: K
): ILoginStore[K] => useStore(loginStore, key);
