import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
// TODO: вынести в плагин, чтобы можно было duration юзать со строками
/**
 * Получает длительность в ms
 * В moment.duration можно было передавать строки
 * В dayjs такого нет
 * @param timeControl — строка даты, по умолчанию HH:mm:ss
 * @param format — формат даты
 * @returns продолжительность в ms
 */
export const getDurationMsFromStringDate = (dateTime, format = 'HH:mm:ss') => {
    const timeControlTime = dayjs(dateTime, format);
    const durationInMs = timeControlTime.diff(timeControlTime.startOf('day'));
    return durationInMs;
};
