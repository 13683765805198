import { createAuthorizationHeaders } from '@utils/_requests';
import { BaseService } from './_base.service';
import { httpInstance } from '.';

export enum ApiNotificationType {
  TOURNAMENT_START_REMINDER = 'TOURNAMENT_START_REMINDER',
}

export type ApiNotificationObjectTournamentStartReminder = Readonly<{
  id: number;
  slug?: string;
  title: string;
  start: string;
  time_control: {
    name: string;
  };
}>;

export type ApiNotificationObject =
  ApiNotificationObjectTournamentStartReminder;

export type ApiNotification = Readonly<{
  id: number;
  title: string;
  notification_type: ApiNotificationType;
  notification_object: ApiNotificationObject | null;
  description?: string;
  url?: string;
}>;

export type ApiGetNotificationsResponseData = ApiNotification[];
export type ApiGetNotificationByIdResponseData = ApiNotification;

const SERVICE_URLS = {
  GET_NOTIFICATIONS: '/me/notifications/',
  GET_NOTIFICATION_BY_ID: '/me/notifications/:id/',
  MARK_NOTIFICATION_READ: '/me/notifications/:id/mark-read/',
} as const;

export class NotificationsService extends BaseService {
  getNotifications(jwt?: string) {
    const url = SERVICE_URLS.GET_NOTIFICATIONS;

    return this.ajax.get<ApiGetNotificationsResponseData>(
      url,
      createAuthorizationHeaders(jwt)
    );
  }

  getNotificationById(id: string, jwt?: string) {
    const url = SERVICE_URLS.GET_NOTIFICATION_BY_ID.replace(':id', id);

    return this.ajax.get<ApiGetNotificationByIdResponseData>(
      url,
      createAuthorizationHeaders(jwt)
    );
  }

  markNotificationRead(id: string, jwt?: string) {
    const url = SERVICE_URLS.MARK_NOTIFICATION_READ.replace(':id', id);

    return this.ajax.post(url, createAuthorizationHeaders(jwt));
  }
}

export const notificationsService = new NotificationsService({
  instance: httpInstance,
});
