import { figureType } from "../_constants";
export class PieceType {
    constructor(name, notation, price) {
        this.name = name;
        this.notation = notation;
        this.price = price;
    }
}
export const PawnType = new PieceType(figureType.PAWN, "P", 1);
export const KnightType = new PieceType(figureType.KNIGHT, "N", 3);
export const BishopType = new PieceType(figureType.BISHOP, "B", 3);
export const RookType = new PieceType(figureType.ROOK, "R", 5);
export const QueenType = new PieceType(figureType.QUEEN, "Q", 9);
export const KingType = new PieceType(figureType.KING, "K", 1000);
