export enum ePopupPaths {
  CHESSBOARD_SETTINGS = 'chessboard-settings',
  SIGN_IN = 'sign-in',
  SIGN_IN_INVITE = 'sign-in-invite',
  CHALLENGE = 'challenge',
  REGISTRATION = 'registration',
  CONFIRM = 'confirm',
  PASSWORD = 'password',
  PASSWORD_RESET = 'password-reset',
  PASSWORD_CHANGE = 'password-change',
  SET_PASSWORD = 'set-password',
  FIDE = 'fide',
  FIDE_VERIFICATION = 'fide-verification',
  WIDGET = 'widget',
  TOURNAMENT_PAYMENT = 'tournament-payment',
  ANALYSIS = 'analysis',
  PAYWALL = 'paywall',
}
