import {
  arenaTimeControls,
  loadtestGroupedTimeControls,
  loadtestTimeControls,
  prodGroupedTimeControls,
} from 'shared/constants';
import {
  IBestBoardData,
  IBestPlayer,
  IGroupedTimeControl,
  IPlan,
  IPlayersCount,
  ITimeControl,
} from 'shared/types';
import { httpInstance } from '.';
import { BaseService } from './_base.service';
import { staticSiteGenerationInstance } from './_staticSiteGeneration.service';

// Copied from shared
export class HelpersService extends BaseService {
  /**
   * Осуществляет запрос о списке подписок
   */
  getPlans() {
    return this.ajax.get<IPlan[]>('plans/');
  }

  // TODO: вынести функцию в хелперы -> после того как мы перенесем chessarena на SSR
  /**
   * Возвращает нужный массив timecontrols в зависимости от конфига
   */
  getTimeControls(config = 'loadtest'): ITimeControl[] {
    switch (config) {
      case 'loadtest':
        return loadtestTimeControls;
      case 'prod':
        return arenaTimeControls;
      default:
        return loadtestTimeControls;
    }
  }

  // TODO: вынести функцию в хелперы -> после того как мы перенесем chessarena на SSR
  /**
   * Возвращает нужный массив IGroupedTimeControl в зависимости от конфига
   */
  getGroupedTimeControls(config = 'master'): IGroupedTimeControl[] {
    switch (config) {
      case 'master':
        return prodGroupedTimeControls;
      default:
        return loadtestGroupedTimeControls;
    }
  }

  /**
   * Осуществляет  запрос на получение лучшего игрока.
   */
  getBestPlayer() {
    return this.ajax.get<IBestPlayer[]>('online/best-players/');
  }

  /**
   * Осуществляет  запрос на получение лучших досок.
   */
  getTodayBestBoards() {
    return this.ajax.get<IBestBoardData[]>('online/best-boards/top_list/');
  }

  /**
   * Осуществляет запрос на получение количества зарегистрированных пользователей на сайте.
   */
  getPlayersCount() {
    return this.ajax.get<IPlayersCount>('online/players/count/');
  }
}

export const helpersService = new HelpersService({ instance: httpInstance });
export const helpersSsgService = new HelpersService({
  instance: staticSiteGenerationInstance,
});
