import { outOfBoard } from "../stringBoard/_outOfBoard";
import { chessSide, figureType, PIECE_OFFSETS } from "../_constants";
import { getPieceData } from "./_getPieceData";
export const calculatePiecePossibleMovesWithoutValidation = ({ index, board, possibleCastlings, }) => {
    const piece = board[index];
    const pieceData = getPieceData({ piece, index });
    const pc = possibleCastlings || {
        [chessSide.WHITE]: {
            kingSide: false,
            queenSide: false,
        },
        [chessSide.BLACK]: {
            kingSide: false,
            queenSide: false,
        },
    };
    switch (pieceData.type) {
        case figureType.BISHOP:
        case figureType.QUEEN:
        case figureType.ROOK: {
            const possibleMoves = [];
            const offsets = PIECE_OFFSETS[piece.toLowerCase()];
            offsets.forEach((offset) => {
                let newPos = index;
                let canMove = true;
                while (canMove) {
                    newPos += offset;
                    if (outOfBoard(newPos)) {
                        canMove = false;
                        continue;
                    }
                    possibleMoves.push(newPos);
                }
            });
            return possibleMoves;
        }
        case figureType.PAWN: {
            const possibleMoves = [];
            const PAWN_SHIFTS = {
                p: {
                    move: 16,
                    attacks: [15, 17],
                },
                P: {
                    move: -16,
                    attacks: [-15, -17],
                },
            };
            const shift = PAWN_SHIFTS[board[index]];
            const newPos = index + shift.move;
            possibleMoves.push(newPos);
            const color = board[index] === "P" ? chessSide.WHITE : chessSide.BLACK;
            const didntMove = color === chessSide.WHITE ? index >= 96 && index <= 103 : index >= 16 && index <= 23;
            if (didntMove) {
                possibleMoves.push(newPos + shift.move);
            }
            shift.attacks.forEach((attackShift) => {
                if (!outOfBoard(index + attackShift)) {
                    possibleMoves.push(index + attackShift);
                }
            });
            return possibleMoves;
        }
        case figureType.KNIGHT: {
            const possibleMoves = [];
            const shifts = PIECE_OFFSETS[piece.toLowerCase()];
            shifts.forEach((shift) => {
                const newPos = index + shift;
                if (!outOfBoard(newPos)) {
                    possibleMoves.push(newPos);
                }
            });
            return possibleMoves;
        }
        // king
        default: {
            const possibleMoves = [];
            const shifts = [1, -1, 15, 16, 17, -15, -16, -17];
            shifts.forEach((shift) => {
                const newPos = index + shift;
                if (outOfBoard(newPos))
                    return;
                possibleMoves.push(newPos);
            });
            const castlings = pc[pieceData.color];
            if (castlings.kingSide) {
                possibleMoves.push(pieceData.color === chessSide.WHITE ? 118 : 6);
            }
            if (castlings.queenSide) {
                possibleMoves.push(pieceData.color === chessSide.WHITE ? 114 : 2);
            }
            return possibleMoves;
        }
    }
};
