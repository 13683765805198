import { chessSide } from 'chessgun/core';
export var LastPaymentStatus;
(function (LastPaymentStatus) {
    LastPaymentStatus["FAILED"] = "failed";
    LastPaymentStatus["SUCCESS"] = "success";
})(LastPaymentStatus || (LastPaymentStatus = {}));
export var AccountVerification;
(function (AccountVerification) {
    AccountVerification[AccountVerification["NOT_VERIFIED"] = 0] = "NOT_VERIFIED";
    AccountVerification[AccountVerification["ON_CHECK"] = 1] = "ON_CHECK";
    AccountVerification[AccountVerification["VERIFIED"] = 2] = "VERIFIED";
})(AccountVerification || (AccountVerification = {}));
export var ProStatus;
(function (ProStatus) {
    ProStatus[ProStatus["NEED_TO_SUBSCRIBE"] = 0] = "NEED_TO_SUBSCRIBE";
    ProStatus[ProStatus["NEED_TO_RESUBSCRIBE"] = 1] = "NEED_TO_RESUBSCRIBE";
    ProStatus[ProStatus["ONLY_PAYED"] = 2] = "ONLY_PAYED";
    ProStatus[ProStatus["WAITING_FOR_APPROVE"] = 3] = "WAITING_FOR_APPROVE";
    ProStatus[ProStatus["PAYED_AND_COMPLETELY_FILLED"] = 4] = "PAYED_AND_COMPLETELY_FILLED";
    ProStatus[ProStatus["NOT_APPROVED"] = 5] = "NOT_APPROVED";
    // на всякий случай
    ProStatus[ProStatus["ERROR"] = 6] = "ERROR";
})(ProStatus || (ProStatus = {}));
export var eAccessLevel;
(function (eAccessLevel) {
    eAccessLevel[eAccessLevel["UNAUTHORIZED"] = 0] = "UNAUTHORIZED";
    eAccessLevel[eAccessLevel["FREE"] = 1] = "FREE";
    eAccessLevel[eAccessLevel["PRO"] = 2] = "PRO";
})(eAccessLevel || (eAccessLevel = {}));
export var ePaymentSource;
(function (ePaymentSource) {
    ePaymentSource["STRIPE"] = "stripe";
})(ePaymentSource || (ePaymentSource = {}));
export var ePriceId;
(function (ePriceId) {
    ePriceId["PRO_ANNUAL"] = "pro-annual";
    ePriceId["PRO_MONTHLY"] = "pro-monthly";
})(ePriceId || (ePriceId = {}));
export var SocketStatus;
(function (SocketStatus) {
    SocketStatus["INITIAL_CONNECTING"] = "INITIAL CONNECTING";
    SocketStatus["DISCONNECTED"] = "DISCONNECTED";
    SocketStatus["CONNECTING"] = "CONNECTING";
    SocketStatus["CONNECTED"] = "CONNECTED";
    SocketStatus["RECONNECTING"] = "RECONNECTING";
    SocketStatus["LOGIN_RECONNECTING"] = "LOGIN RECONNECTING";
})(SocketStatus || (SocketStatus = {}));
