import { Store, useStore } from 'storeshed';

export interface IAppStore {
  ready: boolean;
  regions: string[] | null;
  show_search: boolean;
}

export const appStore = new Store<IAppStore>({
  regions: null,
  ready: true,
  show_search: false,
});

export const useAppStore = <K extends keyof IAppStore>(key: K): IAppStore[K] =>
  useStore(appStore, key);
