import React from 'react';
import { TChessPosition } from '../../_types';
import { usePossibleMovesOffsets } from '../hooks/usePossibleMovesOffsets.hook';

import { $Dot } from './_styles';
import { cgDataTypes } from 'chessgun/ui';

export const PossibleMoves: React.FC<{
  cellSize: number;
  possibleMoves?: TChessPosition[];
}> = ({ cellSize, possibleMoves }) => {
  const possibleMovesOffsets = usePossibleMovesOffsets(possibleMoves);
  return (
    <>
      {possibleMovesOffsets &&
        possibleMovesOffsets.map((offsets) => (
          <$Dot
            key={offsets.leftOffset.toString() + offsets.topOffset.toString()}
            data-cg-type={cgDataTypes.squares.POSSIBLE_MOVE}
            size={cellSize}
            className="dots move b"
            {...offsets}
          />
        ))}
    </>
  );
};
