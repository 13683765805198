import styled, { css } from 'styled-components';
import { Popup } from '../_Popup';

export const $Popup = styled(Popup)<{ width?: string }>`
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;
