import { HTTPService, IHeaders } from './_http.service';

export class StaticSiteGenerationService extends HTTPService {
  constructor(
    baseUrl?: string,
    headers: IHeaders = { 'Content-Type': 'application/json' }
  ) {
    super(baseUrl || process.env.NEXT_PUBLIC_API_URL || '/', headers);
  }
}

export const staticSiteGenerationInstance = new StaticSiteGenerationService();
