import { IAnalyzer } from '@types';

export const product = {
  price: 7000,
  stripe_id: 'sku_paid_analysis',
};

export const analyzers: IAnalyzer[] = [
  {
    fide_id: 14102595,
    full_name: 'Evgenij Miroshnichenko',
    photo:
      'https://gaming-images.worldchess.com/media/popups/analysis/miroshnichenko.jpg',
  },
  // {
  //   fide_id: 24131423,
  //   full_name: 'Daniil Yuffa',
  //   photo:
  //     'https://gaming-images.worldchess.com/media/popups/analysis/yuffa.jpg',
  // },
];
