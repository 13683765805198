export var GameResult;
(function (GameResult) {
    GameResult[GameResult["WHITE_WIN"] = 1] = "WHITE_WIN";
    GameResult[GameResult["DRAW"] = 2] = "DRAW";
    GameResult[GameResult["BLACK_WIN"] = 3] = "BLACK_WIN";
    GameResult[GameResult["ABORTED"] = 4] = "ABORTED";
    GameResult[GameResult["CANCELLED"] = 5] = "CANCELLED";
})(GameResult || (GameResult = {}));
export var GameStatus;
(function (GameStatus) {
    GameStatus["NONE"] = "none";
    GameStatus["INITIALIZING"] = "initializing";
    GameStatus["CREATING"] = "creating";
    GameStatus["CREATED"] = "created";
    GameStatus["SUBSCRIBED"] = "subscribed";
    GameStatus["STARTED"] = "started";
    GameStatus["ENDED"] = "ended";
    GameStatus["ABORTED"] = "aborted";
    GameStatus["ERROR"] = "error";
    GameStatus["DISCONNECTED"] = "disconnected";
})(GameStatus || (GameStatus = {}));
export var oppMode;
(function (oppMode) {
    oppMode["HUMAN"] = "human";
    oppMode["FRIEND"] = "friend";
    oppMode["BOT"] = "bot";
})(oppMode || (oppMode = {}));
