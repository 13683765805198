import { IPlan, IUserData, IUserUidData } from 'shared/types';

export interface IUserError {
  code: string;
  detail: string;
}

export class ServerService {
  private _baseUrl;

  constructor() {
    this._baseUrl = process.env.API_URL || process.env.NEXT_PUBLIC_API_URL;
  }

  protected getUrl(route: string) {
    return `${this._baseUrl}${route}`;
  }

  getUid(uid?: string | null) {
    console.log(this.getUrl(`me/uid/${uid ? `?uid=${uid}` : ''}`));

    return fetch(this.getUrl(`me/uid/${uid ? `?uid=${uid}` : ''}`)).then(
      (data) => data.json
    ) as Promise<IUserUidData>;
  }

  getUserData(jwt: string) {
    return fetch(this.getUrl('me/'), {
      headers: { Authorization: `JWT ${jwt}` },
    }).then((data) => data.json()) as Promise<IUserData | IUserError>;
  }

  getPlans() {
    return fetch(this.getUrl('plans/')).then((data) => data.json) as Promise<
      IPlan[]
    >;
  }
}

export const serverService = new ServerService();
