import { ICapturedFigures, chessSide, figureType } from 'chessgun/core';
import { IGamePlayer } from 'shared/types';

import { IAppAction, IPulseItem, ThunkResult, gameActionTypes } from '@types';

export const createGamePlayersActions = () => {
  /**
   * Задает состояние игрока черными фигурами в текущей партии.
   * @param {IGamePlayer} blackPlayer  - игрок черными фигурам.
   */
  const setBlackPlayer = (blackPlayer: IGamePlayer): IAppAction => ({
    type: gameActionTypes.SET_GAME_BLACK_PLAYER,
    payload: blackPlayer,
  });

  /**
   * Задает состояние игрока белыми фигурами в текущей партии.
   * @param {IGamePlayer} whitePlayer - игрок белыми фигурам.
   */
  const setWhitePlayer = (whitePlayer: IGamePlayer): IAppAction => ({
    type: gameActionTypes.SET_GAME_WHITE_PLAYER,
    payload: whitePlayer,
  });

  /**
   * Задает состояние игрокoв в текущей партии.
   * @param {IGamePlayer} players  - игроки черными и белыми фигурам в текущей партии.
   */
  const setPlayers = ({
    whitePlayer,
    blackPlayer,
  }: {
    whitePlayer: IGamePlayer;
    blackPlayer: IGamePlayer;
  }): ThunkResult<void> => {
    return (dispatch) => {
      dispatch({
        type: gameActionTypes.SET_GAME_WHITE_PLAYER,
        payload: whitePlayer,
      });
      dispatch({
        type: gameActionTypes.SET_GAME_BLACK_PLAYER,
        payload: blackPlayer,
      });
    };
  };

  /**
   * Задает состояние захваченных фигур у игрока за черных в текущей партии.
   * @param {figureType[]} capturedFigures  - захваченных черные фигуры
   */

  const setBlackCapturedFigures = (
    capturedFigures: figureType[]
  ): IAppAction => ({
    type: gameActionTypes.SET_GAME_BLACK_CAPTURED,
    payload: capturedFigures,
  });

  /**
   * Задает состояние захваченных фигур у игрока за белых в текущей партии.
   * @param {figureType[]} capturedFigures  - захваченных белые фигуры
   */

  const setWhiteCapturedFigures = (
    capturedFigures: figureType[]
  ): IAppAction => ({
    type: gameActionTypes.SET_GAME_WHITE_CAPTURED,
    payload: capturedFigures,
  });

  /**
   * Задает состояние захваченных фигур
   * @param {FigureController[]} figures - фигуры
   */
  const setCapturedFigures = (figures: ICapturedFigures): ThunkResult<void> => {
    return (dispatch) => {
      dispatch(setBlackCapturedFigures(figures[chessSide.BLACK]));
      dispatch(setWhiteCapturedFigures(figures[chessSide.WHITE]));
    };
  };

  /**
   * Задает состояние числового перевеса у игрока за черных в текущей партии.
   * @param {figureType[]} capturedFigures - перевес черных
   */
  const setBlackAdvantage = (advantage: number): IAppAction => ({
    type: gameActionTypes.SET_GAME_BLACK_ADVANTAGE,
    payload: advantage,
  });

  /**
   * Задает состояние числового перевеса у игрока за белых в текущей партии.
   * @param {figureType[]} capturedFigures - перевес белых
   */
  const setWhiteAdvantage = (advantage: number): IAppAction => ({
    type: gameActionTypes.SET_GAME_WHITE_ADVANTAGE,
    payload: advantage,
  });

  /**
   * Задает состояние числового перевеса
   * @param {number} whiteAdvantage - перевес белых
   * @param {number} blackAdvantage - перевес черных
   */
  const setAdvantage = ({
    white,
    black,
  }: {
    black: number;
    white: number;
  }): ThunkResult<void> => {
    return (dispatch) => {
      dispatch(setWhiteAdvantage(white));
      dispatch(setBlackAdvantage(black));
    };
  };

  /**
   * Задает состояние оставшегося времени у игрока белыми
   * @param {number} ms  - мсек игрока белыми фигурам.
   */
  const setWhiteMsLeft = (ms: number): IAppAction => ({
    type: gameActionTypes.SET_WHITE_PLAYER_MS_LEFT,
    payload: ms,
  });

  /**
   * Задает состояние оставшегося времени у игрока черными
   * @param {number} ms - мсек игрока черными фигурам.
   */
  const setBlackMsLeft = (ms: number): IAppAction => ({
    type: gameActionTypes.SET_BLACK_PLAYER_MS_LEFT,
    payload: ms,
  });

  /**
   * Задает состояние оставшегося времени у игроков
   * @param {number} whiteMsLeft - мсек игрока белыми фигурам.
   * @param {number} blackMsLeft - мсек игрока черными фигурам.
   */
  const setPlayersMsLeft = ({
    whiteMsLeft,
    blackMsLeft,
  }: {
    whiteMsLeft: number;
    blackMsLeft: number;
  }): ThunkResult<void> => {
    return (dispatch) => {
      dispatch(setWhiteMsLeft(whiteMsLeft));
      dispatch(setBlackMsLeft(blackMsLeft));
    };
  };

  /**
   * Задает состояние времени окончания таймера у игрока белыми
   * @param {string} endTime - времени окончания игрока белыми фигурам.
   */
  const setWhiteMoveEndTime = (endTime: string | null): IAppAction => ({
    type: gameActionTypes.SET_WHITE_PLAYER_MOVE_END_TIME,
    payload: endTime,
  });

  /**
   * Задает состояние времени окончания таймера у игрока черными
   * @param {string} endTime - времени окончания игрока черными фигурам.
   */
  const setBlackMoveEndTime = (endTime: string | null): IAppAction => ({
    type: gameActionTypes.SET_BLACK_PLAYER_MOVE_END_TIME,
    payload: endTime,
  });

  /**
   * Задает состояние времени окончания таймеров у игроков
   * @param {string} whiteEndTime - времени окончания игрока белыми фигурам.
   * @param {string} blackEndTime - времени окончания игрока черными фигурам.
   */
  const setPlayersMoveEndTime = ({
    whiteEndTime,
    blackEndTime,
  }: {
    whiteEndTime: string | null;
    blackEndTime: string | null;
  }): ThunkResult<void> => {
    return (dispatch) => {
      dispatch(setWhiteMoveEndTime(whiteEndTime));
      dispatch(setBlackMoveEndTime(blackEndTime));
    };
  };

  /**
   * Задает историю пульса игрока белыми
   * @param {IPulseItem[]} data - история пульса
   */
  const setWhitePulseHistory = (history: IPulseItem[]): IAppAction => ({
    type: gameActionTypes.SET_WHITE_PULSE_HISTORY,
    payload: history,
  });

  /**
   * Задает историю пульса игрока чёрными
   * @param {IPulseItem[]} data - история пульса
   */
  const setBlackPulseHistory = (history: IPulseItem[]): IAppAction => ({
    type: gameActionTypes.SET_BLACK_PULSE_HISTORY,
    payload: history,
  });

  return {
    setBlackPlayer,
    setWhitePlayer,
    setPlayers,
    setBlackCapturedFigures,
    setWhiteCapturedFigures,
    setCapturedFigures,
    setAdvantage,
    setWhiteMsLeft,
    setBlackMsLeft,
    setPlayersMsLeft,
    setWhiteMoveEndTime,
    setBlackMoveEndTime,
    setPlayersMoveEndTime,
    setWhitePulseHistory,
    setBlackPulseHistory,
  };
};

export const gamePlayersActions = createGamePlayersActions();
