import {
  MouseEvent,
  MouseEventHandler,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export interface IPosition {
  topPosition: number;
  leftPosition: number;
}

interface IDropdownParameters {
  dropdownRef: RefObject<HTMLDivElement>;
  isDropdownShown: boolean;
  onDropdownClick: MouseEventHandler<HTMLElement>;
  openDropdown: (evt?: MouseEvent<HTMLElement>) => void;
  closeDropdown: () => void;
  position: IPosition;
  setCloseHandler: (closeHandler?: () => void) => void;
}

// Появляется за пределами экрана, чтобы была возможность не делать скачков и пересчитать
// положение попапа относительно вьюпорта
const defaultPosition: IPosition = {
  topPosition: -1000,
  leftPosition: -1000,
};

/**
 * Хук для обобщения логики открытия/закрытия дропдауна.
 * Дропдаун закрывается по клику снаружи дропдауна и нажатию Esc.
 * Возвращает реф, который необходимо накинуть на компонент/элемент дропдауна в верстке
 * @returns {RefObject<HTMLDivElement>} dropdownRef - реф на дропдаун
 * @returns {boolean} isDropdownShown - показан ли дропдаун
 * @returns {MouseEventHandler<HTMLElement>} onDropdownClick - хендлер нажатия на открытие/закрытие дропдауна
 * @returns {openDropdown} openDropdown - открыть дропдаун, может принимать Event, в связке с position может автоматически выровнять попап учитывая границы экрана
 * @returns {closeDropdown} closeDropdown - закрыть дропдаун
 * @returns {position} position - позиции дропдауна с учетом границ вьюпорта
 */
export function useDropdown(): IDropdownParameters {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const lastOpeningEventInstance = useRef<MouseEvent<HTMLElement> | null>(null);
  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const [{ topPosition, leftPosition }, setPosition] =
    useState(defaultPosition);

  const closeHandler = useRef<(() => void) | undefined>(undefined);

  const setCloseHandler = useCallback((handler?: () => void) => {
    closeHandler.current = handler;
  }, []);

  const onDropdownClick = () => setIsDropdownShown((prev) => !prev);

  const openDropdown = (evt?: MouseEvent<HTMLElement>) => {
    if (evt) {
      lastOpeningEventInstance.current = evt;
    }

    setIsDropdownShown(true);
  };

  const closeDropdown = useCallback(() => {
    closeHandler.current && closeHandler.current();

    setPosition(defaultPosition);
    setIsDropdownShown(false);
  }, [closeHandler.current]);

  const onOutsideClick = useCallback((evt: globalThis.MouseEvent) => {
    const isInnerClick =
      dropdownRef.current && dropdownRef.current.contains(evt.target as Node);

    if (!isInnerClick) {
      closeDropdown();
    }
  }, []);

  const onEscKeydown = useCallback((evt: KeyboardEvent) => {
    if (evt.key === 'Escape') {
      closeDropdown();
    }
  }, []);

  useEffect(() => {
    if (isDropdownShown) {
      const documentWidth = document.documentElement.clientWidth;
      const documentHeight = document.documentElement.clientHeight;
      const evt = lastOpeningEventInstance.current;

      if (dropdownRef.current && evt) {
        const { width, height } = dropdownRef.current.getBoundingClientRect();

        const newTopPosition = evt.clientY;
        const newLeftPosition = evt.clientX;

        const isOutOfViewportRightSide =
          documentWidth < newLeftPosition + width;
        const isOutOfViewportBottomSide =
          documentHeight < newTopPosition + height;

        const newPosition = {
          topPosition: newTopPosition,
          leftPosition: newLeftPosition,
        };

        if (isOutOfViewportRightSide) {
          newPosition.leftPosition = newLeftPosition - width;
        }

        if (isOutOfViewportBottomSide) {
          newPosition.topPosition = newTopPosition - height;
        }

        setPosition(newPosition);
        lastOpeningEventInstance.current = null;
      }
    }
  }, [isDropdownShown]);

  useEffect(() => {
    if (isDropdownShown) {
      window.addEventListener('pointerdown', onOutsideClick);
      window.addEventListener('keydown', onEscKeydown);
    } else {
      window.removeEventListener('pointerdown', onOutsideClick);
      window.removeEventListener('keydown', onEscKeydown);
    }

    return () => {
      window.removeEventListener('pointerdown', onOutsideClick);
      window.removeEventListener('keydown', onEscKeydown);
    };
  }, [isDropdownShown, dropdownRef.current]);

  return {
    dropdownRef,
    isDropdownShown,
    onDropdownClick,
    openDropdown,
    closeDropdown,
    position: {
      topPosition,
      leftPosition,
    },
    setCloseHandler,
  };
}
