import { flipTurn } from "../common";
import { generateFen } from "../fen/_generateFen";
import { generateSan } from "../fen/_generateSan";
import { parseFen } from "../fen/_parseFen";
import { getCheckMates } from "../stringBoard/_getCheckmates";
import { getFile } from "../stringBoard/_getFile";
import { getRank } from "../stringBoard/_getRank";
import { getStringBoard } from "../stringBoard/_getStringBoard";
import { chessSide, figureType, moveTypes, WHITE_SYMBOLS } from "../_constants";
import { getLongSanMoveData } from "./_getLongSanMoveData";
import { getPossibleCastlings } from "./_getPossibleCastlings";
export const getMoveInfo = (fen, lan, withValidation = true) => {
    const { possibleCastlings, figures, enPassant, nextTurn, halfMoveNumber, movesWithoutCapture, capturedFigures, } = parseFen(fen);
    const $newHalfMoveNumber = halfMoveNumber + 1;
    const { wKing, bKing } = getKings(figures);
    if (!wKing || !bKing)
        throw new Error("getMoveInfo: no king");
    const board = getStringBoard(figures);
    const $sanBoard = [...board];
    const moveData = getLongSanMoveData({
        lan,
        board,
        enPassant,
        nextTurn,
        possibleCastlings,
        withValidation,
    });
    const $newPossibleCastlings = getPossibleCastlings({
        board: [...board],
        lan,
        possibleCastlings: Object.assign({}, possibleCastlings),
    });
    const $newCapturedFigures = capturedFigures;
    let $newMovesWithoutCapture = movesWithoutCapture;
    let $newEnPassant = null;
    let $capturedFigure = null;
    const $pieceIndex = moveData.type === moveTypes.CASTLING ? moveData.king.from : moveData.from;
    const $piece = board[$pieceIndex];
    const $pieceColor = WHITE_SYMBOLS.includes($piece) ? chessSide.WHITE : chessSide.BLACK;
    switch (moveData.type) {
        case moveTypes.CASTLING:
            const { king, rook } = moveData;
            const $kingPiece = board[king.from];
            const $rookPiece = board[rook.from];
            board[king.from] = "";
            board[king.to] = $kingPiece;
            board[rook.from] = "";
            board[rook.to] = $rookPiece;
            $newMovesWithoutCapture += 1;
            break;
        case moveTypes.PAWN: {
            const { to, from, capturedFigure, promotion } = moveData;
            $newEnPassant = moveData.newEnPassant;
            $newMovesWithoutCapture = 0;
            $capturedFigure = board[to];
            if (capturedFigure) {
                $capturedFigure = board[capturedFigure];
                board[capturedFigure] = "";
            }
            board[from] = "";
            if (promotion) {
                board[to] =
                    $pieceColor === chessSide.WHITE ? promotion.toUpperCase() : promotion.toLowerCase();
            }
            else {
                board[to] = $piece;
            }
            break;
        }
        case moveTypes.PIECE:
            const { from, to } = moveData;
            $capturedFigure = board[to];
            board[from] = "";
            board[to] = $piece;
            $newMovesWithoutCapture += 1;
    }
    if ($capturedFigure) {
        $newMovesWithoutCapture = 0;
        $newCapturedFigures[flipTurn($pieceColor)].push($capturedFigure.toLowerCase());
    }
    const { check, checkmate, kingIndex } = getCheckMates([...board], $newEnPassant);
    const $san = generateSan({
        board: [...$sanBoard],
        enPassant,
        check,
        checkmate,
        promotion: moveData.type === moveTypes.PAWN ? moveData.promotion : null,
        to: moveData.type === moveTypes.CASTLING ? moveData.king.to : moveData.to,
        from: moveData.type === moveTypes.CASTLING ? moveData.king.from : moveData.from,
    });
    const $fen = generateFen({
        board: [...board],
        nextTurn: flipTurn($pieceColor),
        possibleCastlings: $newPossibleCastlings,
        enPassant: $newEnPassant
            ? {
                file: getFile($newEnPassant),
                rank: getRank($newEnPassant),
            }
            : undefined,
        halfMove: $newHalfMoveNumber,
        movesWithoutCapture: $newMovesWithoutCapture,
    });
    return {
        lan,
        nextTurn,
        san: $san,
        fen: $fen,
        capturedFigures: $newCapturedFigures,
        moveData: moveData,
        movesWithoutCapture: $newMovesWithoutCapture,
        possibleCastlings: $newPossibleCastlings,
        moveNumber: $newHalfMoveNumber,
        piece: $piece.toLowerCase(),
        pieceColor: $pieceColor,
        enPassant: $newEnPassant,
        capture: $sanBoard.includes("x"),
        checkmateData: {
            check,
            checkmate,
            kingIndex,
        },
    };
};
const getKings = (figures) => {
    const wKing = figures.find((f) => f.color === chessSide.WHITE && f.type === figureType.KING);
    const bKing = figures.find((f) => f.color === chessSide.BLACK && f.type === figureType.KING);
    return {
        wKing,
        bKing,
    };
};
// последний фен: "4r1k1/1p6/2p1r3/4pp2/P1Pq1P1p/6bP/P3R1B1/2Q2R1K w - - 0 39"
//  ['d2d4', 'g8f6', 'c2c4', 'g7g6', 'g2g3', 'f8g7', 'f1g2', 'd7d6', 'b1c3', 'e8g8', 'g1f3', 'b8d7', 'e1g1', 'e7e5', 'e2e4', 'e5d4', 'f3d4', 'd7c5', 'h2h3', 'f8e8', 'f1e1', 'c8d7', 'b2b3', 'a7a5', 'a1b1', 'h7h6', 'c1b2', 'd8c8', 'g1h2', 'e8e5', 'd4e2', 'e5e8', 'e2f4', 'd7c6', 'c3d5', 'c6d5', 'f4d5', 'c8d8', 'd5c3', 'c7c6', 'd1c2', 'h6h5', 'b1d1', 'd8c7', 'h2g1', 'e8e6', 'e1e2', 'a8e8', 'd1e1', 'c7b6', 'b2c1', 'f6d7', 'c1e3', 'b6a6', 'f2f3', 'a5a4', 'c3a4', 'c5a4', 'b3a4', 'd7b6', 'e3b6', 'a6b6', 'g1h1', 'h5h4', 'g3g4', 'g7e5', 'c2c1', 'e5g3', 'e1f1', 'b6d4', 'f3f4', 'f7f5', 'g4f5', 'g6f5', 'e4e5', 'd6e5']
