import React, { FC, memo, useMemo } from 'react';
import {
  $Index,
  $ItemBody,
  $Country,
  $LazyAvatar,
  $PlayerAvatar,
  $PlayerName,
  $PlayerInfo,
  $PlayerItem,
  $PlayerLinkWrapper,
  $PlayerTeamName,
  $PointItem,
  $PointsRow,
  $BoardPoint,
  $Points,
} from '../_styles';
import { ITeamsWithPlayersPlayersData } from '../_types';

import { commaNumFormatter } from '../_constants';
import { getPlayerCountry } from 'shared/helpers/_common';
import { getPlayerPoints } from 'shared/helpers/_get_player_points';
import { LazyAvatar } from 'shared/molecules';

interface IPlayersItem {
  data: ITeamsWithPlayersPlayersData;
  index: number;
  profileUrl: string;
  userPlayerId?: number;
}

export const PlayersItem: FC<IPlayersItem> = memo(function PlayersItem({
  data,
  index,
  userPlayerId,
  profileUrl,
}) {
  const isCurrentUser =
    !!userPlayerId && userPlayerId === Number(data.player_id);

  const profileLink = useMemo(
    () => `${profileUrl}/${data.player_id}`,
    [data.player_id, profileUrl]
  );

  return (
    <$PlayerLinkWrapper href={profileLink}>
      <$PlayerItem isHighlighted={isCurrentUser}>
        <$Index>{index}</$Index>

        <$PlayerAvatar>
          <$LazyAvatar
            as={LazyAvatar}
            size="100%"
            image={data.avatar_thumbnails.medium}
          />
        </$PlayerAvatar>

        <$ItemBody>
          <$PlayerName>{data.full_name || 'Anonymous'}</$PlayerName>

          <$PlayerInfo>
            <$PlayerTeamName>{data.team_name}</$PlayerTeamName>{' '}
            <$Country>{getPlayerCountry(Number(data.nationality_id))}</$Country>{' '}
          </$PlayerInfo>

          <$PointsRow>
            {typeof data.points === 'number' && (
              <>
                <$PointItem>
                  {getPlayerPoints(Number(data.points))} pts
                  {!data.tie_breaks?.bh && ':'}
                </$PointItem>{' '}
              </>
            )}
            {typeof data.tie_breaks?.bh === 'number' && (
              <>
                <$PointItem>
                  {commaNumFormatter.format(Number(data.tie_breaks?.bh))} tb:
                </$PointItem>{' '}
              </>
            )}
            <$Points>
              {data.points_per_board?.map(({ points, board_uid }, i) => (
                <$BoardPoint key={board_uid + i}>
                  {getPlayerPoints(points)}
                </$BoardPoint>
              ))}
            </$Points>
          </$PointsRow>
        </$ItemBody>
      </$PlayerItem>
    </$PlayerLinkWrapper>
  );
});
