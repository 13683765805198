import { eAccessLevel } from 'shared/types';
import { UserStatus } from './hooks/_useUserSatatus';

export const getUserStatus = ({
  logged,
  accessLevel,
  isRequest,
}: {
  logged?: boolean;
  accessLevel?: eAccessLevel;
  isRequest?: boolean;
}) => {
  if (isRequest) return UserStatus.LOADING;
  if (accessLevel === eAccessLevel.PRO) return UserStatus.SUBSCRIBED;
  if (logged) return UserStatus.AUTHORIZED;
  return UserStatus.UNAUTHORIZED;
};
