import {
  IChessgunAnalysisItemProps,
  IChessgunHistoryItem,
} from 'chessgun/core';
import { IGameMove } from 'shared/types';

import { GameType, IAppAction, ThunkResult, gameActionTypes } from '@types';

export const createGameMovesActions = () => {
  /**
   * Добавляет все ходы доски в движок (при перезагрузке страницы)
   *  @param {IGameMove} moves - ходы
   */
  const addAllMoves = (moves: IGameMove[]): ThunkResult<void> => {
    return async (dispatch, getState) => {
      const {
        engine: chessEngine,
        liveEngine: chessLiveEngine,
        game_type: gameType,
      } = getState().game;

      const lastMove = moves[moves.length - 1];

      if (chessEngine && lastMove) {
        if (!lastMove.long_san) {
          moves.forEach((move) => {
            chessEngine.move(move.san);
          });
        } else {
          const longSanMoves: string[] = [];

          moves.forEach((move) => {
            longSanMoves.push(move.long_san);
            // chessEngine.loadMove({ notations: move.long_san, type: 'longSan' });
          });

          chessEngine.loadFen(lastMove.fen);

          chessEngine.uploadHistory({
            notations: longSanMoves,
            type: 'longSan',
          });

          console.log('GAME HISTORY:', lastMove.fen, longSanMoves);
        }

        if (gameType === GameType.BROADCAST) {
          const analysisMoves: IChessgunAnalysisItemProps[] = [];

          moves.forEach((move) => {
            if (move.analysis?.multipv) {
              const analysisItem: IChessgunAnalysisItemProps = {
                fen: move.fen,
                multipv: move.analysis.multipv,
                depth: move.analysis.depth,
              };

              analysisMoves.push(analysisItem);
            }
          });

          const prevAnalysis = chessEngine.get('analysis');
          if (
            prevAnalysis.length !== analysisMoves.length ||
            prevAnalysis.toString() !== analysisMoves.toString()
          ) {
            if (prevAnalysis.length) {
              chessEngine.store.dispatch('analysis', []);
            }

            chessEngine.uploadAnalysis(analysisMoves);
          }
        }
      }

      if (chessLiveEngine && lastMove) {
        if (!lastMove.long_san) {
          moves.forEach((move) => {
            chessLiveEngine.move(move.san);
          });
        } else {
          const longSanMoves: string[] = [];

          moves.forEach((move) => {
            longSanMoves.push(move.long_san);
            // chessEngine.loadMove({ notations: move.long_san, type: 'longSan' });
          });

          chessLiveEngine.loadFen(lastMove.fen);

          chessLiveEngine.uploadHistory({
            notations: longSanMoves,
            type: 'longSan',
          });

          console.log('GAME HISTORY:', lastMove.fen, longSanMoves);
        }
      }
    };
  };

  /**
   * Выбирает ход из нотации
   * @param {string} fen - fen выбранного хода
   */
  const selectGameMove = (fen?: string): ThunkResult<void> => {
    return (dispatch, getState) => {
      const {
        moves_history: movesHistory,
        engine: chessgun,
        selected_move: selectedMove,
      } = getState().game;

      const moveIndex = movesHistory.findIndex((move) => move?.fen === fen);

      if (fen && selectedMove?.fen !== fen) {
        dispatch(setSelectedMove(movesHistory[moveIndex]));
        chessgun?.setCurrentMove(fen);
      }
    };
  };

  /**
   * Задает состояние выбранного хода
   * @param {IChessgunHistoryItem | null} move - выбранный ход
   */
  const setSelectedMove = (move: IChessgunHistoryItem | null): IAppAction => ({
    type: gameActionTypes.SET_GAME_SELECTED_MOVE,
    payload: move,
  });

  return {
    addAllMoves,
    selectGameMove,
    setSelectedMove,
  };
};

export const gameMovesActions = createGameMovesActions();
