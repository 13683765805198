import { useCallback, useState } from 'react';

import { ThemeName } from '@chessarena/components/lib/common/types';

import { lsKeys } from '@types';
import { setCookie } from '@utils/_cookies';

const getInitialTheme = (
  documentTheme?: ThemeName | false,
  initTheme?: ThemeName
) => {
  if (documentTheme) return documentTheme;
  if (initTheme) return initTheme;
  return 'light';
};

export const useTheme = (initTheme?: ThemeName) => {
  const documentTheme =
    typeof document !== 'undefined' &&
    (document?.documentElement?.dataset?.theme as ThemeName);

  const initialThemeState = getInitialTheme(documentTheme, initTheme);

  const [current, setCurrent] = useState<ThemeName>(initialThemeState);

  const switchTheme = useCallback(() => {
    let newTheme: ThemeName = 'light';
    if (current === 'light') newTheme = 'dark';

    setCookie(lsKeys.THEME, newTheme);
    localStorage.setItem(lsKeys.THEME, newTheme);
    setCurrent(newTheme);
  }, [current]);

  return { current, switchTheme };
};
