export var eActivationErrorCode;
(function (eActivationErrorCode) {
    eActivationErrorCode["RESEND_THROTTLED"] = "resend_throttled";
    eActivationErrorCode["THROTTLED"] = "throttled";
    eActivationErrorCode["UNKNOWN"] = "unknown";
    eActivationErrorCode["INVALID_CODE"] = "invalid_code";
    eActivationErrorCode["CODE_IS_RESET"] = "code_is_reset";
})(eActivationErrorCode || (eActivationErrorCode = {}));
export var eActivationErrorTextCode;
(function (eActivationErrorTextCode) {
    eActivationErrorTextCode["CODE_IS_RESET"] = "Code is reset, too many tries. Please, resend the verification code.";
    eActivationErrorTextCode["INVALID"] = "Code is invalid or expired";
})(eActivationErrorTextCode || (eActivationErrorTextCode = {}));
// Ratings
// export enum eEloDelta {
//   UP = 'up',
//   DOWN = 'down',
// }
// export type TTimeControl = 'standard' | 'blitz' | 'bullet' | 'rapid';
// export type TPlayerRatings = {
//   [key in TTimeControl]?: number;
// };
// export interface IPlayerData {
//   full_name: string;
//   title: string;
//   nationality_id: number;
//   ratings: TPlayerRatings;
//   avatar: {
//     small: string;
//     full: string;
//     medium: string;
//   };
//   eloDelta?: eEloDelta;
//   player_id?: number;
//   deltas: {
//     rating: number;
//     rank: number;
//   };
//   rank: number;
// }
// export type TPlayerDataByRatings = {
//   [key in TTimeControl]?: IPlayerData[];
// };
// export interface IPlayerInfo {
//   data: IPlayerData;
//   index: number;
//   baseUrl?: string;
//   className?: string;
//   currentTimeControl?: TTimeControl;
//   isCurrentUser?: boolean;
// }
// export interface ITopFivePlayersLink {
//   src: string;
//   text: string;
// }
