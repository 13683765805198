import styled, { css, keyframes } from 'styled-components';
import { baseRow } from '../../../../styles';
export const $Preloader = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${baseRow()};
`;
export const $IconWrapper = styled.div `
  position: absolute;
`;
const spin = keyframes `
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const $PreloaderWrapper = styled.div `
  animation: ${spin} 1.5s infinite linear;
  will-change: transform;
  ${baseRow()}
`;
export const spinnerStyles = css `
  position: relative;
  max-height: 57px;
`;
