import {
  IGameInQueueData,
  IGameInQueueDataResponse,
} from '../../_lobby_context.types';

export const adaptChallengesQueue = (
  data: IGameInQueueDataResponse[]
): IGameInQueueData[] => {
  return data.map((item) => ({
    id: item.id,
    createdAt: item.created_at,
    desiredColor: item.desired_color,
    inviteCode: item.invite_code,
    opponent: item.opponent,
    opponentAnonymousUid: item.opponent_anonymous_uid,
    owner: item.owner,
    ownerAnonymousUid: item.owner_anonymous_uid,
    ratingFrom: item.rating_from,
    ratingTo: item.rating_to,
    ratingType: item.rating_type,
    status: item.status,
    timeControl: item.time_control,
  }));
};
