import kalibri from 'kalibri';
export class BaseService {
    constructor({ baseUrl, ajax } = {
        baseUrl: '/',
        ajax: kalibri.createInstance(),
    }) {
        if (ajax) {
            this.ajax = ajax;
        }
        else {
            this.ajax = kalibri.createInstance();
        }
        this.ajax.setBaseUrl(baseUrl ? baseUrl : '/');
    }
    get baseUrl() {
        return this.ajax.getConfig().baseUrl;
    }
    set baseUrl(url) {
        this.ajax.setBaseUrl(url);
    }
}
