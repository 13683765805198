import { chessSide } from 'chessgun/core';
import {
  ITournamentOrganizer,
  IImageThumbnails,
  ITournamentPlayer,
  IGameMoveAnalysis,
  IGamePlayer,
  GameResult,
  TournamentBoardStatus,
  ITournamentFaq,
  TournamentJoinLimit,
  ITournamentData,
} from 'shared/types';

import { IGameCfg } from '@store/context/_types';
import { TSubscribeOnTournamentGame } from '@store/context/lobby_context/_lobby_context.types';
import { Arbitrator, TournamentParticipantStatus } from './_community.types';

export enum BoardAppearance {
  TWO_BOARDS = 'two boards',
  MULTIBOARD = 'multiboard',
}

export enum TournamentBoardType {
  NONE = 'none',
  ONLINE = 'online',
  BROADCAST = 'broadcast',
}

export interface ITournamentGameTourResult {
  rank_old: number;
  rank_new: number;
  rank_delta: number;
  rating: number;
  rating_delta: number;
  points: number;
  points_delta: number;
  is_last_game: boolean;
  white_calories_loss: number;
  black_calories_loss: number;
}

export interface ITournamentGamePlayerStanding {
  uid: string;
  points: number;
  rank: number;
  ready_for_arena: boolean;
}

export interface ITournamentGameStanding {
  white_player: ITournamentGamePlayerStanding;
  black_player: ITournamentGamePlayerStanding;
}

export interface ITournamentInfoData {
  organizer: ITournamentOrganizer | null;
  participation: string;
  system: string;
  region?: string;
  rating?: string;
  foa_rating?: string;
  players?: string;
  duration?: string;
  rounds?: number;
  status: string;
  pgn?: string;
}

export interface ITournamentInfo {
  infoData: ITournamentInfoData;
  about: string;
  images: IImageThumbnails | null;
  pgnName?: string;
  className?: string;
}

export interface IDesktopTournamentInfo extends ITournamentInfo {
  defaultImage: string;
}

export interface IMobileTournamentInfo extends ITournamentInfo {
  defaultImage: string;
}

export interface ITournamentTableInfo {
  infoData: ITournamentInfoData;
  pgnName?: string;
}

export interface ITournamentSponsorsData {
  count: number;
  next: string | null;
  previous: string | null;
  results: ITournamentSponsor[];
}

export interface ITournamentSponsor {
  id: number;
  image: string;
  logo: string;
  link: string;
  link_text: string;
  text: string;
}

export interface ITournamentStream {
  link: string;
  description: string;
  tour_number?: number | null;
}

export interface IRoundBoardsParams {
  tournamentId: number | string;
  roundId: number | string;
  limit?: number;
  offset?: number;
  status?: TournamentBoardStatus;
}

export interface IPulseItem {
  measure_datetime: string;
  rate: number;
}

export interface ITableTournament {
  day: string;
  time: string;
  event: string;
  status: string | JSX.Element;
  prizeFund?: string;
  monthDate: string;
  user_signed: boolean;
  signup_opened: boolean;
  available: boolean;
  id: number | null;
  allowed_regions: {
    id: number;
    name: string;
  }[];
  msLeftToEnd: number;
}

export interface IScheduleColumn {
  title: string;
  minWidth: string;
}

export interface IBroadcastsData {
  count: number;
  boards_amount: number;
  max_participants: number;
  next: string | null;
  previous: string | null;
  results: ITournamentExpandedData[];
}

export interface ITournamentYou {
  you: ITournamentPlayer | null;
}

export interface IRoundMyBoard {
  timer_end_time?: string;
}

export interface IRoundBoardMove {
  fen: string;
  long_san: string;
  san: string;
  is_white_move: boolean;
  made_in?: string | null;
  move_number: number;
  analysis?: IGameMoveAnalysis;
}

export interface IRoundBoardData {
  active_boards_count: number;
  can_play: boolean;
  count: number;
  next: string;
  previous: string;
  results: IRoundBoard[];
}

export interface IRoundBoard {
  black_player: IGamePlayer;
  board_id: string;
  created: string;
  finished_at: string;
  moves: IRoundBoardMove[];
  result: GameResult | null;
  status: TournamentBoardStatus;
  tour_desk_number: number;
  tour_v2_id: number;
  tournament_v2_id: number;
  white_ms_left: number | null;
  black_ms_left: number | null;
  white_player: IGamePlayer;
  my_board: IRoundMyBoard | null;
  black_uid: string;
  white_uid: string;
}

export interface ISubscribedRoundBoard extends IRoundBoard {
  playerTurn: chessSide;
  white_move_end_time: null | string;
  black_move_end_time: null | string;
  white_pre_move_ms_left: number;
  black_pre_move_ms_left: number;
  best_board?: boolean;
  playingPhCfg: IGameCfg['playingPhCfg'] | null;
  analysis?: IGameMoveAnalysis;
}

export const enum TournamentPhase {
  /** Турнир еще не начался, регистрация еще не открыта */
  UPCOMING_SIGNUP_CLOSED,

  /** Турнир еще не начался, регистрация открыта */
  UPCOMING_SIGNUP_OPENED,

  /** Турнир швейцарка в процессе, мы не участвуем */
  SWISS_LIVE_VIEWER,

  /** Турнир швейцарка в процессе, мы участвуем */
  SWISS_LIVE_PLAYER,

  /** Турнир швейцарка в процессе и одно из условий:
      + Идет пейринг (показываем таймер)
      + Технологическая пауза (показываем таймер)
      + Когда закончили игру в раунде и ждем конца раунда
   */

  /** Закончили игру в раунде, ждем конца раунда */
  SWISS_LIVE_PLAYER_NEXT_ROUND_PENDING,

  /** Техническая пауза / Пейринг */
  SWISS_LIVE_PLAYER_NEXT_ROUND_PAUSE,

  /** Турнир арена в процессе, мы не участвуем */
  ARENA_LIVE_VIEWER,

  /** Турнир арена в процессе, мы участвуем */
  ARENA_LIVE_PLAYER,

  /** Турнир в процессе */
  LIVE,

  /** Турнир закончил, мы не участвуем */
  ENDED_VIEWER,

  /** Турнир закончил, мы участвуем */
  ENDED_PLAYER,

  /** Игроку нельзя участвовать в турнире по какой-то причине */
  NO_PERMISSION,
}

export const enum JoinButtonState {
  /** Обычная кнопка Join */
  COMMON,

  /** Платный Join */
  PAID_TOURNAMENT,

  /** Нужен Про аккаунт */
  NEEDS_PRO_ACCOUNT,

  /** Не подключен FIDE ID */
  FIDE_ID_NOT_CONNECTED,

  /** Аккаунт либо ждет одобрения или отказ в аппруве — показываем отключенный join */
  JOIN_DISABLED_WITH_CAPTION,

  /** Участвует в турнире */
  JOINED,
}

export type TournamentSubscriptions = {
  subscribe: (options: TSubscribeOnTournamentGame) => void;
  unsubscribe: (boardId: string) => void;
};

export enum TournamentPrizeTypes {
  cash = 'cash',
  championship_goods = 'championship_goods',
}

export type TournamentPrizeByRankItem = Readonly<{
  rank: number;
  value?: string;
  name?: string;
}>;

export type TournamentsPrize = Readonly<{
  prize_type: TournamentPrizeTypes;
  fund?: number;
  currency?: string;
  prizes_by_rank?: TournamentPrizeByRankItem[];
}>;

export type TournamentsEntryPrice = {
  id?: string;
  amount?: number;
  currency?: 'eur' | 'usd';
};

export interface ITournamentExpandedData extends ITournamentData {
  about: string; // Html content
  location: null | string;
  event: number | null;
  tours_count: number;
  community_that_organized: ITournamentOrganizer | null;
  max_participants: number;
  sharing_image_medium: { full: string };
  sharing_image_small: { full: string };
  signup_finish: string;
  signup_end_ms_left: number;
  signup_start: string;
  signup_start_ms_left: number;
  // similar_tournaments: []; // какой интерфейс?
  age_max: null | number;
  unsigned_amount: number;
  to_faq: ITournamentFaq[];
  faq: string; // не нужно будет, когда переведем на to_faq
  attendance_info: string | null; // Html content
  schedule_info: string | null; // Html content
  description: string | null; // Html content
  news_tag: string | null;
  city: number | null;
  ready_for_arena: boolean;
  stages?: string[] | null;
  active_stage?: string | null;
  team_play: boolean;
  unavailability_reason?: TournamentJoinLimit | null;
  pause_end_datetime?: string;
  next_tour_start_datetime?: string;
  slug: string;
  player_status: TournamentParticipantStatus;
  arbitrators?: string | Arbitrator[];
  prize?: TournamentsPrize;
  entry_price?: TournamentsEntryPrice | null;

  // TODO: удалить на бэке
  // Эти поля не используются
  // sponsor: string; // ??
  // slug: string; // ??
  // sponsor_title: string; // ??
  // prize_fund: number; // ??
  // prize_fund_currency: string; // ??
  // product: null | string; // ??
  // promoted: boolean; // ??
  // press: null | string; // Html content
  // contacts: null; // Html content
  // available: boolean;
  // move_time_limit: number;
  // broadcast_type: TourmanentBroadcastType;
}
