import { Chessgun } from 'chessgun/core';
import { syncedMoment } from 'shared/helpers/_common';
import { getFiguresAdvantageFromFen } from 'shared/helpers/_game';
import {
  GameResult,
  GameStatus,
  IBoardData,
  ITourGameData,
  TournamentBoardStatus,
} from 'shared/types';

import {
  EndReason,
  GameUserType,
  IBoardEndedData,
  ThunkResult,
  gameActionTypes,
} from '@types';
import { mainStore } from '@store/storeshed';
import { calcTournamentPoints } from '@utils/_helpers';
import { gameActions } from './game';

export const createViewerActions = () => {
  /**
   * Задает состояние окончания игры для зрителя
   * @param {IBoardEndedData} gameEndedData - данные об окончании игры
   */
  const setViewerGameEnded = (
    gameEndedData: IBoardEndedData
  ): ThunkResult<void> => {
    return (dispatch, getState) => {
      dispatch(gameActions.setGameStatus(GameStatus.ENDED));

      const finishedAt = gameEndedData.finished_at
        ? gameEndedData.finished_at
        : syncedMoment().toISOString();

      dispatch(
        gameActions.setGameEndedData({
          ...gameEndedData,
          finished_at: finishedAt,
        })
      );

      dispatch(
        gameActions.setPlayersMsLeft({
          whiteMsLeft: gameEndedData.white_ms_left,
          blackMsLeft: gameEndedData.black_ms_left,
        })
      );
      dispatch(
        gameActions.setPlayersMoveEndTime({
          whiteEndTime: null,
          blackEndTime: null,
        })
      );

      const { game_data: gameData } = getState().game;

      if (gameData) {
        dispatch(
          gameActions.setGameData({
            ...gameData,
            white_player: {
              ...gameData.white_player,
              tournament_points: calcTournamentPoints(
                gameData.white_player.tournament_points,
                gameEndedData.result,
                'white'
              ),
            },
            black_player: {
              ...gameData.black_player,
              tournament_points: calcTournamentPoints(
                gameData.black_player.tournament_points,
                gameEndedData.result,
                'black'
              ),
            },
            result: gameEndedData.result,
            status: TournamentBoardStatus.COMPLETED,
          })
        );
      }
    };
  };

  /**
   * Задает данные для завершённой игры
   * @param {IBoardData | ITourGameData} data - данные завершённой игры
   * @param {boolean} errorGameEnded - было ли сообщение GAMING_ERROR_GAME_ENDED
   */
  const setEndedGameData = (
    data: IBoardData | ITourGameData
  ): ThunkResult<void> => {
    return async (dispatch) => {
      dispatch(
        gameActions.setPlayersMsLeft({
          whiteMsLeft: data.white_ms_left,
          blackMsLeft: data.black_ms_left,
        })
      );

      dispatch(
        setViewerGameEnded({
          result: data.result ? data.result : GameResult.ABORTED,
          reason: EndReason.CLASSIC,
          board_id: data.board_id,
          black_ms_left: data.black_ms_left,
          white_ms_left: data.white_ms_left,
          finished_at: data.finished_at,
        })
      );

      dispatch(gameActions.setGameUserType(GameUserType.VIEWER));

      dispatch(setEndedEngine(data));

      dispatch(gameActions.setGameStatus(GameStatus.ENDED));
    };
  };

  /**
   * Задает движок для завершённой игры
   * @param {IBoardData | ITourGameData} data - данные завершённой игры
   */
  const setEndedEngine = (
    data: IBoardData | ITourGameData
  ): ThunkResult<void> => {
    return async (dispatch, getState) => {
      const userUid = mainStore.authStore.get('uid');

      const boardName = `ended_board ${data.board_id}`;

      const chessEngine = new Chessgun({
        name: boardName,
        withValidation: false,
      });

      if (!getState().game.engine) {
        await dispatch({
          type: gameActionTypes.SET_GAME_ENGINE,
          payload: chessEngine,
        });

        window[`chessEngine: ${boardName}`] = chessEngine;
      }

      const engine = getState().game.engine;

      if (engine) {
        engine.on('history', (history) => {
          dispatch(gameActions.setMovesHistory(history));

          const lastEngineMove = history[history.length - 1];
          dispatch(gameActions.setSelectedMove(lastEngineMove));

          const turn = engine.get().turn;
          dispatch(gameActions.setGamePlayerTurn(turn));
        });

        engine.on('lastMove', (lastMove) => {
          const currentFen = lastMove?.fen;
          const movesHistory = engine.get().history;

          if (!currentFen) return;

          const currentMoveIndex = movesHistory.findIndex(
            (move) => move?.fen === currentFen
          );
          const currentMove = movesHistory[currentMoveIndex];
          const lastMoveIndex = movesHistory.length - 1;
          if (currentMove) {
            dispatch(
              gameActions.setCapturedFigures(currentMove.capturedFigures)
            );

            const turn = engine.get().turn;
            const advantage = getFiguresAdvantageFromFen(currentFen, turn);
            dispatch(gameActions.setAdvantage(advantage));
          }

          dispatch(
            gameActions.handleGameReview({
              currentMoveIndex,
              lastMoveIndex,
            })
          );
        });

        dispatch(
          gameActions.setBoardRotation(
            userUid === data.black_player.uid ? 180 : 0
          )
        );

        const movesAmount = data.moves.length;
        const gameMovesHistory = getState().game.moves_history;

        if (movesAmount > 0 && gameMovesHistory.length !== movesAmount) {
          if (engine && gameMovesHistory.length > 0) {
            console.log('restart engine');
            engine.restart();
          }

          dispatch(gameActions.addAllMoves(data.moves));
        }
      }
    };
  };

  return {
    setViewerGameEnded,
    setEndedGameData,
  };
};

export const viewerActions = createViewerActions();
