"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = formatLabel;
function checkValueInRange(value, min, max) {
    return value >= min && value <= max;
}
function plural(val, [one = '', tens_and_one = '', two_three_foor = '', many = '']) {
    if (val === 1)
        return one;
    if (val % 10 === 1 && val % 100 !== 11)
        return tens_and_one || one;
    if (checkValueInRange(val % 10, 2, 4) && !checkValueInRange(val % 100, 12, 14))
        return two_three_foor || tens_and_one || one;
    if (val === 0 || checkValueInRange(val % 10, 5, 9) || checkValueInRange(val % 100, 1, 14))
        return many || two_three_foor || tens_and_one || one;
    return many || two_three_foor || tens_and_one || one;
}
function formatLabel(label, params) {
    let result = label;
    if (params) {
        Object.keys(params).forEach((key) => {
            result = result.replace(`{:${key}}`, params[key]);
            const pluralRegExp = new RegExp(`\\[:${key}.[^\\]]*]`, 'g');
            const conj = label.match(pluralRegExp);
            if (conj && conj.length) {
                conj.forEach((item) => {
                    const values = item.replace(`[:${key}(`, '').replace(')]', '').split(',');
                    result = result.replace(item, plural(params[key], values));
                });
            }
        });
    }
    return result;
}
