import { ITabElement } from 'shared/atoms';
import {
  ITournamentTeamsPlayTeam,
  ITournamentTeamsPlayPlayer,
  ITournamentPlayer,
} from 'shared/types';

export enum eTeamsWithPlayersGroups {
  TEAMS = 'teams',
  PLAYERS = 'players',
}

export type TTeamsWithPlayersGroups =
  | eTeamsWithPlayersGroups.TEAMS
  | eTeamsWithPlayersGroups.PLAYERS;

export interface ITableColumn {
  title: string;
  key: string;
  cellSize?: number;
}

export type TPointsPerBoard = {
  points: number;
  board_uid: string;
};

export type ITeamsWithPlayersTeamsData = {
  id: number;
  name: string;
  category: string;
  country: number | null;
  event_name: string;
  points: number;
  tie_break: number;
  in_team: boolean;
};

export type ITeamsWithPlayersPlayersData = {
  avatar_thumbnails: {
    medium: string;
    small: string;
  };
  player_id: number;
  full_name: string;
  points: number;
  tie_breaks: {
    bh: number;
  };
  team_name: string;
  nationality_id: number | null;
  points_per_board?: TPointsPerBoard[];
};

export type ITableData = ITournamentTeamsPlayTeam | ITournamentTeamsPlayPlayer;

export interface IGroupedTable<TableType> {
  count: number;
  data: TableType[];
}

export interface IGroupedTableConfig<TableType> {
  columns: ITableColumn[];
  data: IGroupedTable<TableType>;
}

export type TGroupedTables = {
  teams: IGroupedTableConfig<ITournamentTeamsPlayTeam>;
  players: IGroupedTableConfig<ITournamentTeamsPlayPlayer>;
};

export interface ISeriesTournamentPlayer extends ITournamentPlayer {
  eventName: string;
}

export interface ITeamsWithPlayersGroupedResult {
  groupedTables: TGroupedTables;
  tabs?: ITabElement[] | null;
  currentTab: TTeamsWithPlayersGroups;
  setCurrentTab?: (currentTab: string) => void;

  userPlayerId?: number;
  profileUrl: string;
  color?: string;
  inRequest: boolean;
  className?: string;
}

export interface IMobilePlayer {
  player: ITournamentPlayer;
  user?: ITournamentPlayer | null;
  profileUrl: string;
  noPointsText?: string;
  hasLeft: boolean;
}
export interface IDesktopTableHead {
  columns: ITableColumn[];
}

export interface ITableRow {
  data: ITableData;
  index: number;
  className?: string;
  columns: ITableColumn[];
  // player: ITournamentPlayer;
  // user?: ITournamentPlayer | null;
  // profileUrl: string;
  // eventName: string;
}

export interface IPlayerTableRow {
  data: ITeamsWithPlayersPlayersData;
  columns: ITableColumn[];
  index: number;
  userPlayerId?: number;
  profileUrl: string;
}

export interface ITeamsTableRow {
  data: ITeamsWithPlayersTeamsData;
  columns: ITableColumn[];
  index: number;
  // className?: string;
  // userPlayerId?: number;
}
