import React, {
  PropsWithChildren,
  memo,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useGameLobbyActions } from './_actions';
import { createReducerInitialState, gameLobbyContextReducer } from './_reducer';
import { eGameLobbyReducerActionType, IGameLobbyContextProps } from './_types';
import { useGameLobbyContextComputed } from './hooks';
import { useUserDataStore } from '@store/storeshed';
import { ePhase } from '@store/context/_common.types';
import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';
import { eGameRequestState } from '@store/context/lobby_context/ws/_types';

export const GameLobbyContext = React.createContext(
  {} as IGameLobbyContextProps
);

export const GameLobbyContextProvider: React.FC<PropsWithChildren> = memo(
  function GameLobbyContextProvider({ children }) {
    const ratings = useUserDataStore('ratings');

    const {
      state: { game_request, games, playerUid },
      actions: { updateGameRequestState },
    } = useLobbyContext();

    // TODO: delete after backend fix
    useEffect(() => {
      updateGameRequestState(null);
    }, []);

    const [state, dispatch] = React.useReducer(
      gameLobbyContextReducer,
      createReducerInitialState({ ratings })
    );

    const actions = useGameLobbyActions({ state, dispatch });
    const computed = useGameLobbyContextComputed(state);

    const haveActiveGame = useMemo(() => {
      for (const key in games) {
        const game = games[key];
        if (
          (game.state?.cfg?.bPId === playerUid ||
            game.state?.cfg?.wPId === playerUid) &&
          game.state.currPh !== ePhase.DONE
        ) {
          return true;
        }
      }

      return false;
    }, [games, playerUid]);

    useEffect(() => {
      const gameIsStartingValue =
        game_request?.state === eGameRequestState.CREATED;

      dispatch({
        type: eGameLobbyReducerActionType.KeyOf,
        payload: {
          key: 'gameIsStarting',
          value: gameIsStartingValue,
        },
      });
    }, [game_request, haveActiveGame]);

    useEffect(() => {
      const { board_type } = state.currentTimeControl;
      if (ratings) {
        actions.setCurrentUserRating(ratings, state.gameRatingMode, board_type);
      }
    }, [ratings, state.currentTimeControl, state.gameRatingMode]);

    const value: IGameLobbyContextProps = {
      state,
      actions,
      computed,
    };

    return (
      <GameLobbyContext.Provider value={value}>
        {children}
      </GameLobbyContext.Provider>
    );
  }
);

export const useGameLobbyContext = () => useContext(GameLobbyContext);
