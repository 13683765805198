import { HTTPService, IHeaders } from './_http.service';

export abstract class BaseService {
  readonly ajax: HTTPService;

  constructor({
    instance,
    baseUrl,
    headers,
  }: {
    instance?: HTTPService;
    baseUrl?: string;
    headers?: IHeaders;
  }) {
    if (instance) {
      this.ajax = instance;
    } else {
      this.ajax = new HTTPService(baseUrl ? baseUrl : '/', headers);
    }
  }
}
