import React from 'react';
import {
  $PopupButton,
  $PopupButtons,
  $PopupContent,
  $PopupWrapper,
} from './_styles';
import { IPopup } from './_types';

/**
 * Базовый попап схожий с системным
 */
export const Popup = React.forwardRef<HTMLDivElement, IPopup>(function Popup(
  { text, buttons, className },
  ref
) {
  return (
    <$PopupWrapper className={className} ref={ref}>
      <$PopupContent>{text}</$PopupContent>
      <$PopupButtons>
        {buttons?.map(({ text, onClick }) => (
          <$PopupButton key={text} onClick={onClick}>
            {text}
          </$PopupButton>
        ))}
      </$PopupButtons>
    </$PopupWrapper>
  );
});
