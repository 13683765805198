import React from 'react';
import { chessFigureStyle } from './_types';
/**
 * Иконка: Фигура короля
 * @param {string} fill - цвет фигуры
 * @param {number} width - ширина фигуры
 * @param {number} height - высота фигуры
 * @param {string} color - тема фигуры
 * @param {chessFigureStyle} figureStyle - тема фигуры
 * @param {string} className - внешний класс
 */
export const KingIcon = ({ className = '', width = '100%', height = '100%', theme = 'white', fill, figureStyle = chessFigureStyle.FILLED, }) => {
    const kings = {
        [chessFigureStyle.FILLED]: (React.createElement("svg", { className: className, width: width, height: height, fill: "none", viewBox: "0 0 61 60", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M25.7 11.7h3V9h2.6v2.9H34v2.6h-2.7v2.3c2 .5 3.7 2.2 3.7 5 0 .7-.1 1.3-.3 1.8a10 10 0 017.1-2.8c5.6 0 8.8 4 8.8 8.2 0 6.5-4.6 9.6-6.7 11.6-.3.2-.3.5-.3.9l.2 6.2c0 2.6-6.2 4.3-13.7 4.3-7.7 0-13.7-1.7-13.7-4.3v-6.2c0-.5 0-.7-.2-.9-2.6-2.1-6.8-5-6.8-11.8 0-4 3.2-8 8.8-8 2.7 0 5 1 7 2.8A4 4 0 0125 22a5 5 0 013.7-5v-2.4h-2.9v-2.8zM18 23.3c-4.2 0-6.3 3-6.3 6.3 0 4.8 2.6 7 6 10.3 2.6-1.5 8-2.8 11.5-2.8 0-7.2-4.6-13.8-11.2-13.8zm.3 14.7c-3.2-2.9-4.7-5-4.7-8.4 0-2.6 2-4.6 4.7-4.6 4.9 0 8.6 4.3 9.3 10.8-2.8.2-6.7 1-9.3 2.2zm0 5c1.9-1.2 7-2 11.7-2 4.7 0 9.8.8 11.8 2v-1.5A26.1 26.1 0 0030 39.2c-5.5 0-9.3 1-11.7 2.3V43zm0 3.5c1.9-1.2 7-2 11.7-2 4.7 0 9.8.8 11.8 2v-1.6A26.4 26.4 0 0030 43c-5.5 0-9.3.8-11.7 2v1.6zm14.7-24.2c0-1.6-1-3.3-3-3.3s-3 1.6-3 3.3c0 2 1.5 3.3 3 7.8 1.7-4.5 3-5.7 3-7.8zM30 21c.8 0 1.2.8 1.2 1.6s-.4 2-1.2 3.7c-.7-1.7-1.2-3-1.2-3.7 0-1 .5-1.6 1.2-1.6zm12.4 18.7c3.5-3.3 6-5.7 6-10.3 0-3.4-2.1-6.3-6.3-6.3-6.5 0-11.2 6.7-11.2 14a31 31 0 0111.5 2.6zM41.7 25c2.8 0 4.7 2 4.7 4.6 0 3.4-1.6 5.5-4.7 8.4-2.6-1.1-6.5-2-9.4-2.2.8-6.4 4.6-10.8 9.4-10.8z", fill: fill ? fill : theme }))),
        [chessFigureStyle.STROKE]: (React.createElement("svg", { className: className, width: width, height: height, viewBox: "0 0 13 13", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M6.6 11.27c-1.8 0-3.09-.39-3.09-.95l.03-1.18c0-.18 0-.27-.06-.33-.6-.59-1.53-1.21-1.53-2.51 0-1.04.87-1.78 1.95-1.78.84 0 1.36.36 1.66.65-.03-.08-.1-.23-.1-.38 0-.53.3-1 .85-1.1v-.56h-.66v-.59h.66v-.59h.6v.62h.63v.6h-.63v.52c.54.12.84.6.84 1.1 0 .15-.03.3-.09.38.3-.23.84-.62 1.65-.62 1.09 0 1.96.77 1.96 1.78 0 1.36-.9 1.98-1.54 2.54-.09.09-.06.18-.06.33l.03 1.2c.03.42-1.08.87-3.1.87ZM3.55 8.4a6.68 6.68 0 0 1 2.89-.71c-.1-1.57-1.14-2.63-2.53-2.63-.93 0-1.47.62-1.47 1.36 0 .85.6 1.47 1.11 1.98Zm5.71.53c.03-.12.1-.2.15-.27a7.26 7.26 0 0 0-2.8-.53c-1.07 0-2.28.27-2.79.53.06.06.12.15.15.24a6.23 6.23 0 0 1 2.65-.44c1.26 0 2.13.2 2.64.47Zm-.03.74v-.33a7.2 7.2 0 0 0-2.58-.41c-1.02 0-2.1.18-2.59.41 0 .12 0 .21-.03.33a6.79 6.79 0 0 1 2.62-.39c1.2 0 2.04.18 2.58.39Zm-5.1.74c.38.27 1.53.38 2.49.38.9 0 2.01-.11 2.43-.35.1-.06.15-.12.15-.2 0-.13-.2-.22-.36-.25a9.94 9.94 0 0 0-2.22-.26c-.93 0-2.26.15-2.56.38a.22.22 0 0 0-.06.12c0 .09.06.12.12.18ZM7.3 4.85c0-.36-.21-.71-.7-.71-.47 0-.68.35-.68.7 0 .45.3.72.69 1.7.39-.98.69-1.25.69-1.7Zm-.48 2.84a6.3 6.3 0 0 1 2.85.7c.51-.5 1.12-1.09 1.12-2 0-.68-.55-1.36-1.48-1.36-1.35.03-2.4 1.09-2.5 2.66Z", fill: fill ? fill : theme }))),
    };
    return kings[figureStyle];
};
