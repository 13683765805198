import { bindActions } from 'storeshed';
import { TMainStore } from '../_types';
import { mainStore } from '@store/storeshed';
import { appService } from '@services/_app.service';

const createAppActions = () => {
  /**
   * Задает значение отображения поиска.
   */
  const setShowSearch = ({ appStore }: TMainStore, show: boolean) => {
    appStore.dispatch('show_search', show);
  };

  /**
   * Задаёт список регионов для ng
   * @param {string[] | null} regions - список регионов для ng
   */
  const setRegions = ({ appStore }: TMainStore, regions: string[] | null) => {
    appStore.dispatch('regions', regions);
  };

  /**
   * Осуществляет запрос о получении списка регионов ng.
   */
  const getNGAllRegions = async () => {
    const requests: Array<Promise<{ [region: string]: number } | null>> = [];

    try {
      const { ok, data } = await appService.getNGAllRegions();

      if (ok) {
        appActions.setRegions(data);

        data.forEach((regionName) => {
          requests.push(appActions.getNGRegionLiveness(regionName));
        });

        const timeData = await Promise.all(requests);

        let latencies: { [key: string]: number } = {};

        timeData.forEach((regionLatency) => {
          if (regionLatency) {
            latencies = Object.assign(latencies, regionLatency);
          }
        });

        const uid = mainStore.authStore.get('uid');
        if (uid) {
          appActions.setNGRegionsLatencies(uid, latencies);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Осуществляет запрос о получении времени обращения до регионального сервера.
   * @param {string} region - id региона
   */
  const getNGRegionLiveness = async ({}: TMainStore, region: string) => {
    let timeDiff = 0;
    let timeBefore = 0;
    let timeAfter = 0;

    try {
      timeBefore = Date.now();
      const { ok } = await appService.getNGRegionLiveness(region);

      if (ok) {
        timeAfter = Date.now();
        timeDiff = timeAfter - timeBefore;
        console.log('timeBefore', region, timeBefore);
        console.log('timeAfter', region, timeAfter);
        console.log('timeDiff', region, timeDiff);
      }
    } catch (error) {
      console.log(error);
    }

    return timeDiff > 0 ? { [region]: timeDiff } : null;
  };

  /**
   * Осуществляет запрос о сохранении задержек для регионов ng.
   * @param {string} userUid - uid пользователя
   * @param {object} latencies - время задержек для каждого региона
   */
  const setNGRegionsLatencies = async (
    {}: TMainStore,
    uid: string,
    latencies: { [key: string]: number }
  ) => {
    try {
      await appService.setNGRegionsLatencies(uid, latencies);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    setShowSearch,
    setRegions,

    getNGAllRegions,
    getNGRegionLiveness,
    setNGRegionsLatencies,
  };
};

export const appActions = bindActions(mainStore, createAppActions());
