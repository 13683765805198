import {
  arenaPremiumAnnual,
  arenaPremiumMonthly,
  fideIdPlan,
} from 'shared/constants';
import { TMainStore } from '@store/storeshed';
import { helpersService } from '@services/_helpers.service';
import { IPlan } from 'shared/types';

export const createHelpersActions = () => {
  const getPlans = async ({ helpersStore }: TMainStore) => {
    helpersStore.dispatch('plans_request', true);
    try {
      const { ok, data: plans } = await helpersService.getPlans();

      if (ok) {
        let proPlan: IPlan | null = null;
        let proPlanMonthly: IPlan | null = null;
        let proPlanAnnual: IPlan | null = null;

        plans.forEach((plan) => {
          if (plan.stripe_id === fideIdPlan) proPlan = plan;
          if (plan.stripe_id === arenaPremiumMonthly) proPlanMonthly = plan;
          if (plan.stripe_id === arenaPremiumAnnual) proPlanAnnual = plan;
        });

        helpersStore.dispatch('plans', {
          pro_plan: proPlan,
          pro_plan_monthly: proPlanMonthly,
          pro_plan_annual: proPlanAnnual,
        });
      }
    } catch (error) {
      console.log(error);
    }
    helpersStore.dispatch('plans_request', false);
  };

  return {
    getPlans,
  };
};
