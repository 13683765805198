import React, { useMemo } from 'react';
import { $AlertPopup } from './_styles';
import { IAlertPopup } from './_types';

/**
 * Попап оповещения, схожий с window.alert
 */
export const AlertPopup = React.forwardRef<HTMLDivElement, IAlertPopup>(
  function AlertPopup({ text, onClick }, ref) {
    const buttons = useMemo(
      () => [
        {
          text: 'OK',
          onClick,
        },
      ],
      [onClick]
    );

    return <$AlertPopup ref={ref} text={text} buttons={buttons} />;
  }
);
