export interface IResponse<T = undefined> {
  ok: boolean;
  status: number;
  statusText: string;
  headers: object | string;
  data: T;
  url: string;
}

export class URLBuilder {
  private finalUrl = '';

  constructor(baseUrl: string) {
    // if last symbol equals to "/" then delete it
    const length = baseUrl.length - 1;
    this.finalUrl =
      baseUrl[length] === '/' ? baseUrl.substr(0, length) : baseUrl;
  }

  path(path: string) {
    if (/(http(s?)):\/\//i.test(path)) {
      this.finalUrl = path;
    } else if (path[0] === '/') {
      this.finalUrl += path;
    } else {
      this.finalUrl += '/' + path;
    }

    return this;
  }

  query(params?: { [n: string]: string }) {
    if (!params) return this;
    if (Object.keys(params).length === 0) {
      return this;
    }

    if (this.finalUrl[this.finalUrl.length - 1] !== '?') {
      this.finalUrl += '?';
    }

    for (const [key, value] of Object.entries(params)) {
      if (this.finalUrl[this.finalUrl.length - 1] === '?') {
        this.finalUrl += `${key}=${value}`;
      } else {
        this.finalUrl += `&${key}=${value}`;
      }
    }

    return this;
  }

  build() {
    return this.finalUrl;
  }
}

export interface IAnyObject {
  // eslint-disable-next-line
  [n: string]: any;
}

export interface IRequestParams {
  body?: IAnyObject | string;
  query?: IAnyObject;
  headers?: HeadersInit;
  timeout?: number;
}

interface IRequestInitWithTimeout extends RequestInit {
  timeout?: number;
}

export interface IHeaders {
  [n: string]: string;
}

export interface IQuery {
  [n: string]: string;
}

type ContentType =
  | 'application/pdf'
  | 'application/json'
  | 'application/text'
  | null;

export class HTTPService {
  constructor(private baseUrl: string, private headers: IHeaders = {}) {}

  getHeaders() {
    return this.headers;
  }
  addHeaders(headers: IHeaders) {
    this.headers = {
      ...this.headers,
      ...headers,
    };
  }

  removeHeaders(
    headers: keyof typeof this.headers | (keyof typeof this.headers)[]
  ) {
    if (typeof headers === 'string') {
      delete this.headers[headers];
      return;
    }

    if (Array.isArray(headers)) {
      headers.forEach((header) => {
        delete this.headers[header];
      });

      return;
    }

    throw new Error('wrong type of headers');
  }

  private async fetchWithTimeout(
    url: RequestInfo | URL,
    options: IRequestInitWithTimeout = {}
  ): Promise<Response> {
    const { timeout = 10000 } = options;

    const controller = new AbortController();

    const cancelTimeout = setTimeout(() => {
      console.log(`~timeout~ aborting fetch request: ${url}`);
      controller.abort('aborted');
    }, timeout);

    try {
      const response = await fetch(url, {
        ...options,
        signal: controller.signal,
      });
      return response;
    } catch (e) {
      console.log(`~error~ while fetching`, e);
      throw e;
    } finally {
      clearTimeout(cancelTimeout);
    }
  }

  private buildUrl(url: string, query?: IQuery) {
    return new URLBuilder(this.baseUrl).path(url).query(query).build();
  }

  private async handleResult<T>(res: Response) {
    const contentType = res.headers.get('content-type') as ContentType;
    const contentTypeRegex = /(application\/\w+)/;
    const match = contentType?.match(contentTypeRegex);
    const extractedContentType = match ? match[1] : null;

    let data;

    try {
      switch (extractedContentType) {
        case 'application/json':
          data = (await res.json()) as T;
          break;
        case 'application/pdf':
          data = (await res.blob()) as T;
          break;
        default:
          data = (await res.text()) as T;
      }
    } catch (responseError) {
      const error = responseError as TypeError;
      data = error.message as T;
    }

    const result: IResponse<T> = {
      ok: res.ok,
      status: res.status,
      statusText: res.statusText,
      headers: res.headers,
      url: res.url,

      data,
    };

    if (!res.ok) {
      return Promise.reject(result);
    }

    return result;
  }

  private handleError(res: IResponse) {
    return Promise.reject(res);
  }

  get<T>(
    url: string,
    {
      query = undefined,
      headers = undefined,
      timeout,
    }: Omit<IRequestParams, 'body'> = {}
  ) {
    const finalUrl = this.buildUrl(url, query);

    return this.fetchWithTimeout(finalUrl, {
      headers: headers ? headers : this.headers,
      timeout,
    })
      .then(this.handleResult<T>)
      .catch(this.handleError);
  }

  post<T>(
    url: string,
    {
      body = undefined,
      headers = undefined,
      query = undefined,
      timeout,
    }: IRequestParams = {}
  ) {
    const finalUrl = this.buildUrl(url, query);

    return this.fetchWithTimeout(finalUrl, {
      method: 'POST',
      headers: headers ? headers : this.headers,
      body: typeof body === 'string' ? body : JSON.stringify(body),
      timeout,
    })
      .then(this.handleResult<T>)
      .catch(this.handleError);
  }

  patch<T>(
    url: string,
    {
      body = undefined,
      query = undefined,
      headers = undefined,
      timeout,
    }: IRequestParams = {}
  ) {
    const finalUrl = this.buildUrl(url, query);

    const isFormData = body instanceof FormData;

    return this.fetchWithTimeout(finalUrl, {
      method: 'PATCH',
      headers: headers ? headers : this.headers,
      body: isFormData ? body : JSON.stringify(body),
      timeout,
    })
      .then(this.handleResult<T>)
      .catch(this.handleError);
  }

  put<T>(
    url: string,
    { body = undefined, query = undefined, timeout }: IRequestParams = {}
  ) {
    const finalUrl = this.buildUrl(url, query);

    return this.fetchWithTimeout(finalUrl, {
      method: 'PUT',
      body: JSON.stringify(body),
      timeout,
    })
      .then(this.handleResult<T>)
      .catch(this.handleError);
  }

  delete<T>(
    url: string,
    {
      body = undefined,
      query = undefined,
      headers = undefined,
      timeout,
    }: IRequestParams = {}
  ) {
    const finalUrl = this.buildUrl(url, query);

    return this.fetchWithTimeout(finalUrl, {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: headers ? headers : this.headers,
      timeout,
    })
      .then(this.handleResult<T>)
      .catch(this.handleError);
  }
}
