import { IGameState } from '@store/context/_types';
import { chessSide } from 'chessgun/core';

export const getMyColor = (
  playerUid: string | null,
  state: IGameState | undefined
) => {
  if (!playerUid || !state?.cfg?.bPId || !state?.cfg?.wPId) {
    return chessSide.WHITE;
  }

  return state.cfg?.bPId === playerUid ? chessSide.BLACK : chessSide.WHITE;
};
