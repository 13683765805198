export const fideProPlan = 'pro-plan';
export const fideIdPlan = 'fide-id-plan';
export const fideIdPaypalPlan = 'fide-id-paypal-plan';
export const fideIdFreeAccountByTitlePlan = 'fide-id-free-account-by-title-plan';
export const fideIdGiftPlan = 'fide-id-gift-plan';
export const fideIdApplePlan = 'fide-id-apple-plan';
export const arenaPremiumMonthly = 'arena-premium-monthly';
export const arenaPremiumAnnual = 'arena-premium-annual';
export const proPlan = [
    fideProPlan,
    fideIdPlan,
    fideIdPaypalPlan,
    fideIdFreeAccountByTitlePlan,
    fideIdGiftPlan,
    fideIdApplePlan,
];
export const newProPlan = [arenaPremiumMonthly, arenaPremiumAnnual];
