import { useMemo } from 'react';
import { GameRatingMode } from 'shared/types';
import { eGameAllowanceStatus } from '../_types';
import { eFideIdStatus } from '@types';
import { useFideIdStatus } from '@utils/hooks/_useFideIdStatus.hook';
import { UserStatus, useUserStatus } from '@utils/hooks/_useUserSatatus';

export const useGameAllowanceStatus = ({
  userIsLogged,
  gameRatingMode,
}: {
  userIsLogged: boolean;
  gameRatingMode: GameRatingMode;
}) => {
  const userStatus = useUserStatus();
  const fideIdStatus = useFideIdStatus();

  const anonymousUserStatus = useMemo(() => {
    if (gameRatingMode === GameRatingMode.UNRATED)
      return eGameAllowanceStatus.FIND_OPPONENT;

    return eGameAllowanceStatus.CREATE_ACCOUNT;
  }, [gameRatingMode]);

  const registredUserStatus = useMemo(() => {
    if (
      gameRatingMode === GameRatingMode.UNRATED ||
      gameRatingMode === GameRatingMode.RATED
    )
      return eGameAllowanceStatus.FIND_OPPONENT;

    if (userStatus !== UserStatus.SUBSCRIBED) {
      return eGameAllowanceStatus.UPGRADE_NOW;
    }

    if (fideIdStatus === eFideIdStatus.NOT_CONNECTED) {
      return eGameAllowanceStatus.FILL_FORM;
    }

    return eGameAllowanceStatus.FIND_OPPONENT;
  }, [fideIdStatus, gameRatingMode, userStatus]);

  return useMemo<eGameAllowanceStatus>(
    () => (userIsLogged ? registredUserStatus : anonymousUserStatus),
    [anonymousUserStatus, userIsLogged, registredUserStatus]
  );
};
