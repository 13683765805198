import { Store } from 'storeshed';

import { eLastMoveTheme, ePossibleMovesTheme } from 'chessgun/ui';
import {
  IBoardSettingsState,
  eAutoPromotionTheme,
  eBoardThemes,
  ePremovesTheme,
  eSoundsTheme,
} from 'shared/types';
import { SoundsController } from './_SoundsController';
import { CB_SETTINGS } from './_constants';

export interface IChessboardSettings {
  premovesTheme: ePremovesTheme;
  lastMoveTheme: eLastMoveTheme;
  legalMovesTheme: ePossibleMovesTheme;
  soundsTheme: eSoundsTheme;
  boardTheme: eBoardThemes;
  autoPromotion: eAutoPromotionTheme;
}

export const defaultSettings: IChessboardSettings = {
  premovesTheme: ePremovesTheme.ON,
  lastMoveTheme: eLastMoveTheme.NONE,
  legalMovesTheme: ePossibleMovesTheme.NONE,
  soundsTheme: eSoundsTheme.ON,
  boardTheme: eBoardThemes.WORLDCHESS,
  autoPromotion: eAutoPromotionTheme.OFF,
};

const createChessboardSettings = (): IChessboardSettings => {
  if (typeof window === 'undefined') return defaultSettings;

  const settings = localStorage.getItem(CB_SETTINGS);

  if (!settings) return defaultSettings;

  const parsedSettings = JSON.parse(settings);

  return {
    premovesTheme: Object.values(ePremovesTheme)?.includes(
      parsedSettings.premovesTheme
    )
      ? parsedSettings.premovesTheme
      : defaultSettings.premovesTheme,
    lastMoveTheme: Object.values(eLastMoveTheme)?.includes(
      parsedSettings.lastMoveTheme
    )
      ? parsedSettings.lastMoveTheme
      : defaultSettings.lastMoveTheme,
    legalMovesTheme: Object.values(ePossibleMovesTheme)?.includes(
      parsedSettings.legalMovesTheme
    )
      ? parsedSettings.legalMovesTheme
      : defaultSettings.legalMovesTheme,
    soundsTheme: Object.values(eSoundsTheme)?.includes(
      parsedSettings.soundsTheme
    )
      ? parsedSettings.soundsTheme
      : defaultSettings.soundsTheme,
    boardTheme: Object.values(eBoardThemes)?.includes(parsedSettings.boardTheme)
      ? parsedSettings.boardTheme
      : defaultSettings.boardTheme,
    autoPromotion: Object.values(eAutoPromotionTheme)?.includes(
      parsedSettings.autoPromotion
    )
      ? parsedSettings.autoPromotion
      : defaultSettings.autoPromotion,
  };
};

export class BoardSettingsStore extends Store<IBoardSettingsState> {
  public soundsController: SoundsController | null = null;

  constructor() {
    super(createChessboardSettings());

    if (typeof window !== 'undefined') {
      this.soundsController = new SoundsController();

      this.soundsController.sounds = this.state.soundsTheme;

      this.updateLS();
    }
  }

  set boardTheme(theme: eBoardThemes) {
    this.dispatch('boardTheme', theme);
    this.updateLS();
  }

  set lastMoveTheme(theme: eLastMoveTheme) {
    this.dispatch('lastMoveTheme', theme);
    this.updateLS();
  }

  set legalMovesTheme(theme: ePossibleMovesTheme) {
    this.dispatch('legalMovesTheme', theme);
    this.updateLS();
  }

  set soundsTheme(theme: eSoundsTheme) {
    if (!this.soundsController) return;

    this.dispatch('soundsTheme', theme);
    this.updateLS();

    this.soundsController.sounds = theme;
  }

  set premovesTheme(theme: ePremovesTheme) {
    this.dispatch('premovesTheme', theme);
    this.updateLS();
  }

  set autoPromotion(theme: eAutoPromotionTheme) {
    this.dispatch('autoPromotion', theme);
    this.updateLS();
  }

  updateBoardSettings({
    boardTheme,
    soundsTheme,
    lastMoveTheme,
    legalMovesTheme,
    premovesTheme,
    autoPromotion,
  }: IChessboardSettings) {
    this.dispatch({
      premovesTheme,
      lastMoveTheme,
      legalMovesTheme,
      soundsTheme,
      boardTheme,
      autoPromotion,
    });

    this.updateLS();
    if (this.soundsController) {
      this.soundsController.sounds = soundsTheme;
    }
  }

  updateLS() {
    localStorage.setItem(CB_SETTINGS, JSON.stringify(this.get()));
  }
}
