import { Chessgun } from 'chessgun/core';
import { boardSettingsStore } from './stores/_boardSettings/_boardSettings.store';
import { helpersStore } from './stores/_helpers.store';
import { tournamentStore } from './stores/_tournament.store';
import { appStore } from './stores/_app.store';
import { editProfileStore } from './stores/_edit_profile.store';
import { profileStore } from './stores/_profile.store';
import { authStore } from './stores/user/_auth.store';
import { fideSubscriptionStore } from './stores/user/_fide_subscription.store';
import { loginStore } from './stores/user/_login.store';
import { paymentStore } from './stores/user/_payment.store';
import { registrationStore } from './stores/user/_registration.store';
import { userDataStore } from './stores/user/_user_data.store';
import { TMainStore } from './_types';
import { GTMEvent } from '@utils/_gtm';

export const mainStore = {
  appStore,
  boardSettingsStore,
  tournamentStore,
  helpersStore, // TODO: перенести содержимое в стор app, когда он появится
  authStore,
  loginStore,
  registrationStore,
  paymentStore,
  fideSubscriptionStore,
  userDataStore,
  editProfileStore,
  profileStore,
};

declare global {
  interface Window {
    printState: () => void;
    mainStore: TMainStore;
    dataLayer: GTMEvent[];
    // [name: `chessEngine: ${string}`]: Chessgun | undefined;
    [name: string]: Chessgun | undefined;
    timeDiff: number;
  }
}

// if (typeof window !== 'undefined') {
//   window.mainStore = mainStore; // DEBUG
// }
