import React from 'react';
import { TUCI } from '../../_types';
import { useFromTo } from '../hooks/useFromTo.hook';
import { $HighlightedSquare, $Premove } from './_styles';
import { cgDataTypes } from 'chessgun/ui';

export const Premove: React.FC<{ cellSize: number; uci: TUCI }> = ({
  cellSize,
  uci,
}) => {
  const premoveOffsets = useFromTo(uci);

  return (
    <>
      <$HighlightedSquare
        data-cg-type={cgDataTypes.squares.SELECTED}
        size={cellSize}
        {...premoveOffsets.from}
      />
      <$Premove
        data-cg-type={cgDataTypes.squares.PREMOVE}
        size={cellSize}
        {...premoveOffsets.to}
      />
    </>
  );
};
