import { css } from 'styled-components';
import { textColors } from './_variables';
export const baseColumn = (alignItems = 'center') => {
    return css `
    display: flex;
    flex-direction: column;
    align-items: ${alignItems};
  `;
};
export const baseRow = (justifyContent = 'center', alignItems = 'center') => {
    return css `
    display: flex;
    justify-content: ${justifyContent};
    align-items: ${alignItems};
  `;
};
export const baseText = ({ color = textColors['white'], fontSize = '15px', fontWeight = 300, } = {}) => {
    return css `
    font-style: normal;
    font-family: 'Gerbera';
    font-weight: ${fontWeight};
    font-size: ${fontSize};
    color: ${color};
  `;
};
export const link = ({ fontSize = '15px', lineHeight = '22px', reverseColor = false, isCaret = true, size = '8px', borderWidth = '1.5px', arrowMargin = '2px', } = {}) => {
    const $color = reverseColor ? textColors['white'] : textColors['brown'];
    return css `
    display: flex;
    align-items: center;
    font-style: normal;
    font-family: 'Gerbera';
    font-weight: 300;
    font-size: ${fontSize};
    padding-right: 2px;
    text-decoration: none;
    transition: 0.333s ease-out;
    will-change: color;
    color: ${$color};

    &:hover {
      transition: 0s;

      color: ${$color};

      &::after {
        border-right: ${borderWidth} solid ${$color};
        border-top: ${borderWidth} solid ${$color};
      }
    }

    &::after {
      border-right: ${borderWidth} solid ${$color};
      border-top: ${borderWidth} solid ${$color};
      transition: 0s;

      ${isCaret &&
        css `
        content: '';
        display: block;
        transform: rotate(45deg);
        width: ${size};
        height: ${size};
        margin-left: ${arrowMargin};
        transition: 0.333s ease-out;
      `}
    }
  `;
};
export const BebasNeueText = ({ color = textColors['white'], fontSize = '15px', fontWeight = 400, } = {}) => {
    return css `
    font-style: normal;
    font-family: 'BebasNeue';
    font-weight: ${fontWeight};
    font-size: ${fontSize};
    color: ${color};
  `;
};
export const baseTransition = css `
  transition: 333ms ease-out;

  &:hover {
    transition: 0s;
  }
`;
export const visuallyHidden = css `
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
`;
/* ============= Game H ============= */
export const Game_H1 = (color = textColors['white']) => {
    return css `
    ${baseText({ color, fontSize: '30px', fontWeight: 400 })};
    line-height: 35px;
  `;
};
export const Game_H2 = (color = textColors['white']) => {
    return css `
    ${baseText({ color, fontSize: '22px', fontWeight: 400 })}
    line-height: 30px;
  `;
};
/* ============= Game Paragraph ============= */
export const Game_P1 = (color = textColors['white']) => {
    return css `
    ${baseText({ color, fontSize: '15px' })};
    line-height: 20px;
  `;
};
export const Game_P2 = (color = textColors['white']) => {
    return css `
    ${baseText({ color, fontSize: '14px' })};
    line-height: 17px;
  `;
};
export const Game_P3 = (color = textColors['white']) => {
    return css `
    ${baseText({ color, fontSize: '12px' })};
    line-height: 20px;
  `;
};
/* ============= header text ============= */
export const Header_text = (color = textColors['white']) => {
    return css `
    ${baseText({ color, fontSize: '14px' })};
    line-height: 20px;
  `;
};
/* ============= Sing in ============= */
export const Sign_in_H1 = (color = textColors['white']) => {
    return css `
    ${baseText({ color, fontSize: '42px' })};
    line-height: 45px;
  `;
};
export const Sign_in_H2 = (color = textColors['white']) => {
    return css `
    ${baseText({ color, fontSize: '22px' })};
    line-height: 25px;
  `;
};
export const Sign_in_P1 = (color = textColors['white']) => {
    return css `
    ${baseText({ color, fontSize: '15px' })};
    line-height: 20px;
  `;
};
/* ============= Landing ============= */
export const Landing_Main_Title = (color = textColors['white']) => {
    return css `
    ${baseText({ color, fontSize: '40px' })};
    line-height: 45px;
  `;
};
export const Landing_H1 = (color = textColors['white']) => {
    return css `
    ${baseText({ color, fontSize: '30px' })};
    line-height: 38px;
  `;
};
export const Landing_H2 = (color = textColors['white']) => {
    return css `
    ${baseText({ color, fontSize: '22px' })};
    line-height: 25px;
  `;
};
export const Landing_P1 = (color = textColors['white']) => {
    return css `
    ${baseText({ color, fontSize: '18px' })};
    line-height: 24px;
  `;
};
/* ============= Helpers ============= */
const decToHex = (d) => Math.floor(d * 255).toString(16);
export const transparentColor = (color = textColors['white'], opacity = 1) => color + decToHex(opacity);
