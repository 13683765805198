import { DebugSettings } from '@utils/_debugSettings';
import { ePhase } from '../../_common.types';
import { WSController } from './_base.controller';

import {
  eGameServerNonChessPayloadType,
  eGameServerPayloadType,
  IGameReactionRequestState,
  TGameIncomingMessage,
} from './_types';

const MOVE_DELIVERY_CHECK_TIMEOUT = 3000;

const gameWSLog = (message?: unknown, ...optionalParams: unknown[]) =>
  console.log(
    `%c~ws~`,
    'color: yellow; background-color: black; padding: 0 8px;font-size: 14px',
    `[${new Date().toISOString()}]`,
    message,
    ...optionalParams
  );

export class GameWSController extends WSController {
  public boardId = '';
  public playerId: string | null = '';
  public userType: 'player' | 'viewer' = 'player';
  public keyType = 'unsafe';
  public region: string | null = null;

  private moveDeliveryCheckTimeout: NodeJS.Timeout | null = null;
  private moveParams: { absNum: number; uci: string } | null = null;

  private disconnectedByServer = false;

  PING_MESSAGE = eGameServerPayloadType.PING;
  PONG_MESSAGE = eGameServerPayloadType.PONG;

  get origin(): string {
    if (!this.region) return `${process.env.NEXT_PUBLIC_NG_GAME_SOCKET_URL}`;

    return `${process.env.NEXT_PUBLIC_NG_GAME_SOCKET_REGION_URL}`.replace(
      'region',
      this.region
    );
  }

  get debugUrlParams() {
    const debugWebsocketSettings =
      DebugSettings.getInstance().websocketDebugUrlParams;

    if (!debugWebsocketSettings) return '';

    return `&${debugWebsocketSettings}`;
  }

  get url(): string {
    return `${this.origin}/${this.boardId}/ws/${this.userType}/?pId=${this.playerId}&keyType=${this.keyType}${this.debugUrlParams}`;
  }

  get shouldReconnect(): boolean {
    return !this.disconnectedByServer;
  }

  set shouldReconnect(reconnect: boolean) {
    this.disconnectedByServer = !reconnect;
  }

  setTimeoutForMoveDelivery() {
    this.moveDeliveryCheckTimeout = setTimeout(() => {
      this.reconnect();
      this.moveParams = null;

      gameWSLog(
        `move timeout: has not been delivered in ${MOVE_DELIVERY_CHECK_TIMEOUT}ms — reconnect`
      );
    }, MOVE_DELIVERY_CHECK_TIMEOUT);
    gameWSLog(`move timeout set`);
  }

  clearTimeoutForMoveDelivery() {
    if (this.moveDeliveryCheckTimeout) {
      clearTimeout(this.moveDeliveryCheckTimeout);
      this.moveDeliveryCheckTimeout = null;

      gameWSLog(`move timeout cleared! No reconnect, everything is OK!`);
    }
  }

  move(payload: { absNum: number; uci: string }) {
    this.moveParams = payload;

    // set timeout for connectivity
    this.setTimeoutForMoveDelivery();

    this.send(eGameServerPayloadType.MOVE, payload);

    gameWSLog(
      `move timeout, MOVE will be sent {absNum: ${payload.absNum}, uci: ${payload.uci}}`
    );
  }

  ping(): void {
    this.send(eGameServerPayloadType.PING, { key: null });
  }

  reaction(reactionPayload: IGameReactionRequestState) {
    const payload = {
      payloadType: eGameServerNonChessPayloadType.REACTION,
      payload: reactionPayload,
    };
    this.send(eGameServerPayloadType.NON_CHESS, payload);

    gameWSLog(`send reaction ${reactionPayload.id}`);
  }

  disconnect(code?: number) {
    this.shouldReconnect = false;
    super.disconnect(code);
  }

  protected _onMessage(e: MessageEvent): void {
    const { payloadType, payload } = JSON.parse(e.data) as TGameIncomingMessage;

    // if timeout set — check timeout connectivity
    if (
      payloadType === eGameServerPayloadType.STATE &&
      payload.currPh === ePhase.PLAYING &&
      payload.playingPh?.lastTurn?.start.absNum === this.moveParams?.absNum
    ) {
      this.clearTimeoutForMoveDelivery();

      gameWSLog(`move timeout, STATE MOVED`, {
        lastTurn: payload.playingPh?.lastTurn,
        currTurn: payload.playingPh?.currTurn,
        'this.moveParams': this.moveParams,
      });

      this.moveParams = null;
    }

    if (
      payloadType === eGameServerPayloadType.STATE &&
      payload.currPh === ePhase.DONE
    ) {
      this.disconnectedByServer = true;
    }
    super._onMessage(e);
  }
}
