import { getChessPosByIndex } from "../common";
import { chessSide, WHITE_SYMBOLS } from "../_constants";
/**
 * Дает более удобные данные для обработки chessWASM
 * @param {string} piece - фигура
 * @param {number} index - индекс позиции
 * @returns {{
 * index: number;
 * type: figureType;
 * color: chessSide;
 * position: IChessPosition;
 * }} data
 */
export function getPieceData({ piece, index }) {
    if (!piece)
        throw new Error(`Wrong piece: ${piece}`);
    return {
        index,
        type: piece.toLowerCase(),
        color: WHITE_SYMBOLS.includes(piece) ? chessSide.WHITE : chessSide.BLACK,
        position: getChessPosByIndex(index),
    };
}
