import { isKingUnderAttack } from "./_isKingUnderAttack";
import { getPieceData } from "./_getPieceData";
import { calculatePiecePossibleMoves } from "./_calculatePiecePossibleMoves";
import { outOfBoard } from "../stringBoard/_outOfBoard";
/**
 * Проверяет, поставлен ли мат
 * @param {chessSide} color - цвет короля, которому ставят мат
 * @param {VirtualBoard} board - доска
 */
export const isCheckmate = ({ kingColor, enPassant, board, }) => {
    // Если король не под атакой, мата не может быть
    if (!isKingUnderAttack({ board: [...board], kingColor }))
        return false;
    // Массив возможных ходов. Если король под атакой и ни одна фигура не может пойти,
    // значит был объявлен мат
    const possibleMoves = [];
    for (let i = 0; i < board.length; i++) {
        if (outOfBoard(i)) {
            i += 7;
            continue;
        }
        const piece = board[i];
        if (!piece)
            continue;
        const { color } = getPieceData({ piece, index: i });
        if (color !== kingColor)
            continue;
        const moves = calculatePiecePossibleMoves({
            index: i,
            board,
            enPassant,
        });
        possibleMoves.push(...moves);
    }
    return possibleMoves.length === 0;
};
