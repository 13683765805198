export class AChessgunStoreOnGet {
    constructor(state, computed) {
        this.store = new ChessgunStore(state, computed);
    }
    on(key, callback, asynchronous = false) {
        return this.store.on(key, callback, asynchronous);
    }
    get(key) {
        return this.store.get(key);
    }
}
/**
 * Мини стор работающий по принципу подписки.
 * При изменении какого либо параметра сообщает
 * об этом изменении всем подписавшимся
 */
export class ChessgunStore {
    /**
     * @constructor
     * @param {State} state - дефолтный стейт
     */
    constructor(state, computed) {
        this.stateCallbacks = [];
        this.callbacks = {};
        this._computed = null;
        this._computedSubscriptions = {};
        this.state = state;
        if (computed) {
            this._computed = computed;
            Object.keys(computed).forEach((k) => {
                const [cb, stateKeys] = computed[k];
                stateKeys.forEach((stateKey) => {
                    this.on(stateKey, () => {
                        const res = cb(this.state);
                        const keyCallbacks = this._computedSubscriptions[k];
                        if (keyCallbacks) {
                            keyCallbacks.forEach((sub) => sub(res));
                        }
                    }, true);
                });
            });
        }
    }
    get(key) {
        if (typeof key === "string") {
            if (key in this.state)
                return Object.assign({}, this.state)[key];
            if (this._computed && key in this._computed)
                return this._computed[key][0](this.state);
        }
        const computed = {};
        if (this._computed)
            Object.entries(this._computed).forEach(([k, v]) => (computed[k] = v[0](this.state)));
        return Object.assign(Object.assign({}, this.state), computed);
    }
    on(key, callback, asynchronous = false) {
        var _a, _b;
        const cb = { asynchronous, cb: callback };
        if (key === "@state") {
            cb.cb(this.state);
            this.stateCallbacks.push(cb);
            return () => {
                const index = this.stateCallbacks.indexOf(cb);
                this.stateCallbacks.splice(index, 1);
            };
        }
        if (Array.isArray(key)) {
            const unsubs = key.map((key) => {
                return this.on(key, () => {
                    callback(Object.assign({}, this.get()), key);
                }, asynchronous);
            });
            return () => {
                unsubs.map((unsub) => unsub());
            };
        }
        const stateKey = key;
        const computedKey = key;
        if (stateKey in this.state) {
            cb.cb(this.state[stateKey], this.state[stateKey]);
            if (this.callbacks[stateKey]) {
                (_a = this.callbacks[stateKey]) === null || _a === void 0 ? void 0 : _a.push(cb);
            }
            else {
                this.callbacks[stateKey] = [cb];
            }
            return () => {
                var _a, _b;
                const index = (_a = this.callbacks[stateKey]) === null || _a === void 0 ? void 0 : _a.indexOf(cb);
                if (index !== undefined) {
                    (_b = this.callbacks[stateKey]) === null || _b === void 0 ? void 0 : _b.splice(index, 1);
                }
            };
        }
        if (this._computed !== null && computedKey in this._computed) {
            if (this._computedSubscriptions[computedKey]) {
                (_b = this._computedSubscriptions[computedKey]) === null || _b === void 0 ? void 0 : _b.push(callback);
            }
            else {
                this._computedSubscriptions[computedKey] = [callback];
            }
            return () => {
                var _a, _b;
                const index = (_a = this._computedSubscriptions[computedKey]) === null || _a === void 0 ? void 0 : _a.indexOf(callback);
                if (index) {
                    (_b = this._computedSubscriptions[computedKey]) === null || _b === void 0 ? void 0 : _b.splice(index, 1);
                }
            };
        }
        throw new Error(`No key in state or computed: ${key.toString()}`);
    }
    onComputed(keys, callback) { }
    /**
     * Обновляет значение поля в сторе
     * @param {string} key - ключ поля в сторе
     * @param {any} newValue - новое значение
     */
    dispatch(key, newValue) {
        if (typeof key !== "object" && key in this.state && newValue !== undefined) {
            const prevValue = this.state[key];
            const prevState = this.state;
            this.state[key] = newValue;
            this.notify(key, newValue, prevValue);
            this.notify("@state", this.state, prevState);
        }
        else if (typeof key === "object") {
            this.updateMany(key);
        }
        else {
            throw new Error("Chessgun dispatch: invalid type of key/keys");
        }
    }
    computed(key, cb) {
        var _a;
        if (Array.isArray(this._computedSubscriptions[key])) {
            (_a = this._computedSubscriptions[key]) === null || _a === void 0 ? void 0 : _a.push(cb);
        }
        else {
            this._computedSubscriptions[key] = [cb];
        }
    }
    // ===================== PRIVATE ===================== //
    updateMany(params) {
        const prevState = Object.assign({}, this.state);
        this.state = Object.assign(Object.assign({}, this.state), params);
        Object.keys(params).forEach((k) => {
            const prevValue = prevState[k];
            this.notify(k, params[k], prevValue);
        });
        this.notify("@state", this.state, prevState);
    }
    notify(key, newValue, prevValue) {
        const callbacks = key === "@state" ? this.stateCallbacks : this.callbacks[key];
        const computed = this._computedSubscriptions;
        if (callbacks) {
            callbacks.forEach(({ cb, asynchronous }) => {
                if (asynchronous) {
                    requestAnimationFrame(() => {
                        cb(newValue, prevValue);
                    });
                }
                else {
                    cb(newValue, prevValue);
                }
            });
        }
        if (computed) {
            // (Object.keys(computed) as (keyof Computed)[]).forEach((k) => {
            //   computed[k]?.forEach(([cb, stateKeys]) => {});
            // });
        }
    }
}
