"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepareTokens = prepareTokens;
exports.compare = compare;
function getTokensValue(value, globals) {
    return value
        .replace(/^{(.*)}$/, '$1')
        .split('.')
        .reduce((res, i) => res[i], globals).value;
}
function prepareTokensObject(tokens, globals) {
    return Object.keys(tokens).reduce((res, key) => {
        var _a, _b;
        let value = tokens[key];
        if (!((_a = tokens[key]) === null || _a === void 0 ? void 0 : _a.value)) {
            value = prepareTokensObject(tokens[key], globals);
        }
        else {
            value = (_b = tokens[key]) === null || _b === void 0 ? void 0 : _b.value;
            if (value.search(new RegExp('^{.*}$', 'ig')) >= 0 && globals) {
                value = getTokensValue(value, globals);
            }
        }
        return Object.assign(Object.assign({}, res), { [key]: value });
    }, {});
}
function prepareTokens(tokens, globals, fix) {
    const preparedTokens = prepareTokensObject(tokens, globals);
    if (!fix) {
        return preparedTokens;
    }
    return Object.assign(Object.assign({}, preparedTokens), { fix: prepareTokensObject(fix, globals) });
}
function compare(obj1, obj2, previous) {
    for (const p in obj1) {
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
            // eslint-disable-next-line no-console
            console.log('Second theme does not have token:', `${previous}.${p}`);
            return false;
        }
        if (typeof obj1[p] === 'object') {
            if (!compare(obj1[p], obj2[p], `${previous ? `${previous}.` : ''}${p}`))
                return false;
        }
    }
    //Check object 2 for any extra properties
    for (const p in obj2) {
        if (typeof obj1[p] === 'undefined') {
            // eslint-disable-next-line no-console
            console.log('Second theme has extra token:', `${previous}.${p}`);
            return false;
        }
    }
    return true;
}
