import { Dependency } from '../ws/_types';

/**
 * Контроллер зависимостей
 * Позволяет добавлять зависимости, удалять и применять их
 */
export class DependencyController {
  protected _dependencies: Dependency[] = [];

  applyDependencies() {
    // console.log(
    //   `~deps~ dependencies applied`,
    //   this._dependencies.map((d) => d.id)
    // );

    this._dependencies.forEach((dependency) => {
      dependency.callback();
    });
  }

  addDependency(dependency: Dependency) {
    // console.log(`~deps~ dependency added`, dependency);
    this._dependencies.push(dependency);
  }

  clearDependencies() {
    // console.log(`~deps~ dependencies cleared`);
    this._dependencies = [];
  }

  deleteDependency(id: string) {
    const indexToDelete = this._dependencies.findIndex(
      (dependency) => dependency.id === id
    );

    if (indexToDelete >= 0) {
      // console.log(`~deps~ dependency deleted`, id);
      this._dependencies.splice(indexToDelete, 1);
    }
  }
}
