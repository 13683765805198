export const romanizationCharactersMap: Record<string, string> = {
  [`á`]: 'a',
  [`à`]: 'a',
  [`â`]: 'a',
  [`ǎ`]: 'a',
  [`ä`]: 'a',
  [`ã`]: 'a',
  [`ă`]: 'a',
  [`å`]: 'a',
  [`ç`]: 'c',
  [`é`]: 'e',
  [`è`]: 'e',
  [`ê`]: 'e',
  [`ě`]: 'e',
  [`ë`]: 'e',
  [`ĕ`]: 'e',
  [`í`]: 'i',
  [`ì`]: 'i',
  [`î`]: 'i',
  [`ǐ`]: 'i',
  [`ï`]: 'i',
  [`ĩ`]: 'i',
  [`ĭ`]: 'i',
  [`ó`]: 'o',
  [`ò`]: 'o',
  [`ô`]: 'o',
  [`ǒ`]: 'o',
  [`ö`]: 'o',
  [`õ`]: 'o',
  [`ŏ`]: 'o',
  [`ő`]: 'o',
  [`ø`]: 'o',
  [`ú`]: 'u',
  [`ù`]: 'u',
  [`û`]: 'u',
  [`ǔ`]: 'u',
  [`ü`]: 'u',
  [`ũ`]: 'u',
  [`ŭ`]: 'u',
  [`ű`]: 'u',
  [`ł`]: 'l',
  [`а`]: 'a',
  [`б`]: 'b',
  [`в`]: 'v',
  [`г`]: 'g',
  [`д`]: 'd',
  [`е`]: 'e',
  [`ё`]: 'yo',
  [`ж`]: 'zh',
  [`з`]: 'z',
  [`и`]: 'i',
  [`й`]: 'y',
  [`к`]: 'k',
  [`л`]: 'l',
  [`м`]: 'm',
  [`н`]: 'n',
  [`о`]: 'o',
  [`п`]: 'p',
  [`р`]: 'r',
  [`с`]: 's',
  [`т`]: 't',
  [`у`]: 'u',
  [`ў`]: 'u',
  [`ф`]: 'f',
  [`х`]: 'h',
  [`ц`]: 'c',
  [`ч`]: 'ch',
  [`ш`]: 'sh',
  [`щ`]: 'sch',
  [`ы`]: 'y',
  [`ь`]: '',
  // not used yet
  // [`ый`]: 'y',
  // [`ье`]: 'ye',
  // [`ьи`]: 'yi',
  [`ъ`]: '',
  [`э`]: 'e',
  [`ю`]: 'yu',
  [`я`]: 'ya',
  [`є`]: 'ye',
  [`ї`]: 'yi',
  [`і`]: 'i',
  [`ґ`]: 'g',
  [`ѣ`]: 'e',
  [`ѵ`]: 'i',
  [`ѳ`]: 'f',
  [`ә`]: 'a',
  [`ң`]: 'n',
  [`ғ`]: 'g',
  [`ү`]: 'u',
  [`ұ`]: 'u',
  [`қ`]: 'q',
  [`ө`]: 'o',
  [`һ`]: 'h',
};
