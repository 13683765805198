declare global {
  interface Window {
    arena: {
      debug: DebugSettings;
    };
  }
}

const DEBUG_SETTINGS_KEY = 'arena-debug';

type ChannelParams = {
  // задержка в ms
  delay?: string;
  // дроп-рейт сообщений
  drop?: string;
  // Указываем comma-separated строки тех сообщений, на которые хотим применять настройки выше
  contains?: string[];
};

export type DebugWebsocketsSettings = {
  // Параметры исходящих от нас сообщений
  in?: ChannelParams;
  // Параметры входящих нам сообщений
  out?: ChannelParams;
};

export interface DebugFlags {
  websockets: DebugWebsocketsSettings;
}

const convertChannelParams = (params: ChannelParams) => {
  return new URLSearchParams(params as URLSearchParams).toString();
};

export class DebugSettings {
  private static instance: DebugSettings;

  private constructor() {}

  public static getInstance(): DebugSettings {
    if (!DebugSettings.instance) {
      DebugSettings.instance = new DebugSettings();
    }

    return DebugSettings.instance;
  }

  public get settings() {
    const value = window.localStorage.getItem(DEBUG_SETTINGS_KEY);

    if (!value) return null;

    return JSON.parse(value);
  }

  public set settings(value: DebugFlags | null) {
    if (!value) return;

    window.localStorage.setItem(DEBUG_SETTINGS_KEY, JSON.stringify(value));
  }

  public get websocketDebugUrlParams() {
    const websocketsSettings = this.settings?.websockets;

    if (!websocketsSettings) return '';

    const urlParams = [
      `${
        websocketsSettings.in
          ? `inCh&${convertChannelParams(websocketsSettings.in)}`
          : ''
      }`,
      `${
        websocketsSettings.out
          ? `outCh&${convertChannelParams(websocketsSettings.out)}`
          : ''
      }`,
    ]
      .filter(Boolean)
      .join('&');

    return urlParams;
  }

  public reset() {
    window.localStorage.setItem(DEBUG_SETTINGS_KEY, '');
  }

  public setInMoveDrop(dropRate: number) {
    this.settings = {
      websockets: {
        in: {
          drop: String(dropRate),
          contains: ['MOVE'],
        },
      },
    };
  }
}

if (typeof window !== 'undefined') {
  window.arena = {
    debug: DebugSettings.getInstance(),
  };
}
