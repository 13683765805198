import { httpStatuses } from 'shared/constants';
import {
  IActivationError,
  eActivationErrorCode,
  eActivationErrorTextCode,
} from 'shared/types';
import { IRequestError, lsKeys } from '@types';
import {
  authActions,
  loginActions,
  registrationActions,
  userDataActions,
} from './index';
import { authInitialState } from '@store/storeshed';
import { fideSubscriptionInitialState } from '@store/storeshed';
import { loginInitialState } from '@store/storeshed';
import { paymentInitialState } from '@store/storeshed';
import { registrationInitialState } from '@store/storeshed';
import { userDataInitialState } from '@store/storeshed';
import { TMainStore } from '@store/storeshed';
import { deleteCookie } from '@utils/_cookies';
import { userService } from '@services/_user.service';
import {
  CB_SETTINGS,
  PRO_CB_SETTINGS,
} from '@store/storeshed/stores/_boardSettings/_constants';

export const createLoginActions = () => {
  /**
   * Задает состояние запроса на проверку кода подтверждения.
   * @param {boolean} inRequest - выполняется ли запрос на проверку кода подтверждения.
   */
  const setActivationCodeRequest = (
    { loginStore }: TMainStore,
    inRequest: boolean
  ) => {
    loginStore.dispatch('activation_code_request', inRequest);
  };

  /**
   * Задает ошибку от запроса на отправку кода.
   * @param {string} error  - текст ошибки.
   */
  const setActivationCodeError = (
    { loginStore }: TMainStore,
    // TODO: убрать тип "string"
    error: IActivationError | string | null
  ) => {
    loginStore.dispatch('activation_code_error', error);
  };
  /**
   * Задает статус отображения кнопки для перехода к активации аккаунта.
   * @param {boolean} isLogged- залогинен пользователь или нет.
   */
  const setShowActivationButton = (
    { loginStore }: TMainStore,
    show: boolean
  ) => {
    loginStore.dispatch('show_activation_button', show);
  };

  /**
   * Задает состояние запроса на повторную отправку кода подтверждения.
   * @param {boolean} inRequest - выполняется ли запрос на повторную отправку кода подтверждения.
   */
  const setResendActivationCodeRequest = (
    { loginStore }: TMainStore,
    inRequest: boolean
  ) => {
    loginStore.dispatch('resend_activation_code_request', inRequest);
  };

  /**
   * Задает статус пользователя, залогинен он или нет.
   * @param {boolean} isLogged- залогинен пользователь или нет.
   */
  const setLoginError = ({ loginStore }: TMainStore, error: string | null) => {
    loginStore.dispatch('login_error', error);
  };

  /**
   * Выполняет запрос на вход пользователя. Перед запросом задает user.login-request значение true, после - false.
   * В случае успешного запроса задает токен пользователя и выполняет запрос на данные пользователя.
   * Если данные получены, то задает эти данные, и закрывает окно входа.
   * @param {string} email - почта пользователя.
   * @param {string} password - пароль пользователя.
   */
  const login = async (
    { authStore, loginStore }: TMainStore,
    {
      email,
      password,
      callback,
    }: {
      email: string;
      password: string;
      callback?: () => void;
    }
  ) => {
    console.log('login from login actions storeshed');
    loginStore.dispatch({
      login_error: null,
      login_request: true,
      show_activation_button: false,
    });

    try {
      const {
        ok,
        data: { token },
      } = await userService.login(email, password);

      if (ok) {
        authStore.dispatch({ token, logged: true });

        loginStore.dispatch('login_request', false);

        userDataActions.getUserAccount(token);
        callback?.();

        return token;
      }
    } catch (err) {
      const error = err as IRequestError<{ code: string; detail: string }>;
      const statusCode = error.status;
      const errorCode = error.data.code;
      const authError = error.data.detail;

      if (statusCode === httpStatuses.BAD_REQUEST) {
        if (errorCode === 'not_verified') {
          console.log('Show act btn');

          loginStore.dispatch('show_activation_button', true);
        } else {
          loginStore.dispatch('login_error', errorCode);
        }
        throw new Error('');
      } else if (statusCode === httpStatuses.UNAUTHORIZED) {
        if (errorCode === 'player_banned') {
          loginStore.dispatch('login_error', authError);
          throw new Error('');
        }
      }
    }

    loginStore.dispatch('login_request', false);

    return null;
  };

  /**
   * Сбрасывает до изначальных значений состояние юзера
   */
  const resetUserStore = ({
    authStore,
    fideSubscriptionStore,
    loginStore,
    paymentStore,
    registrationStore,
    userDataStore,
  }: TMainStore) => {
    authStore.dispatch({ ...authInitialState });
    fideSubscriptionStore.dispatch({ ...fideSubscriptionInitialState });
    loginStore.dispatch({ ...loginInitialState });
    paymentStore.dispatch({ ...paymentInitialState });
    registrationStore.dispatch({ ...registrationInitialState });
    userDataStore.dispatch({ ...userDataInitialState });
  };

  /**
   * Выполняет выход из аккаунта
   */
  const signOut = ({ authStore }: TMainStore) => {
    localStorage.removeItem(lsKeys.JWT);
    localStorage.removeItem(lsKeys.JWT_SET_TIME);
    localStorage.removeItem(lsKeys.USER_UID);
    localStorage.removeItem(CB_SETTINGS);
    localStorage.removeItem(PRO_CB_SETTINGS);
    deleteCookie(lsKeys.JWT);
    deleteCookie(lsKeys.USER_UID);

    authStore.dispatch('token', null);

    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  };

  /**
   * Выполняет запрос на повторную отправку кода.
   * @param {string} email  - почта пользователя.
   * @param nextStep
   */
  const resendActivationCode = async (
    {}: TMainStore,
    email: string,
    nextStep?: () => void
  ) => {
    loginActions.setResendActivationCodeRequest(true);

    try {
      const { ok } = await userService.resendActivationCode(email);

      if (ok && nextStep) nextStep();
    } catch (err) {
      const error = err as IRequestError<{ code?: string; detail: string }>;
      const errorData = error?.data;

      if (error.status === 404) {
        loginActions.setActivationCodeError(
          'We can’t find an account using this address'
        );
      } else {
        if (errorData?.code) {
          const time =
            errorData.code === eActivationErrorCode.THROTTLED
              ? Number(errorData.detail.replace(/\D+/g, ''))
              : null;
          loginActions.setActivationCodeError({
            code: eActivationErrorCode.RESEND_THROTTLED,
            detail: errorData.detail,
            time,
          });
        } else {
          loginActions.setActivationCodeError(
            'Something wents wrong. Try again'
          );
        }
      }
      console.log(err);
    }

    loginActions.setResendActivationCodeRequest(false);
  };

  /**
   * Выполняет запрос на проверочный кода.
   * Перед запросом задает user.activation_code_request значение true, после - false.
   * В случае успешного запроса переходит к следующему шагу.
   * @param {string} code - проверочный код.
   * @param {string} email - почта пользователя.
   * @param {Function} nextStep - переход к следующему шагу создания аккаунта.
   * @param {boolean} updateAuthToken - нужно ли обновлять токен и авторизовывать юзера. Например для попапа логина при восстановлении пароля это мы не делаем
   */
  const activationCode = async (
    {}: TMainStore,
    {
      code,
      email,
      receiveNewsletters,
      nextStep,
      updateAuthToken = true,
    }: {
      code: string;
      email?: string | null;
      nextStep: () => void;
      receiveNewsletters?: boolean;
      updateAuthToken?: boolean;
    }
  ) => {
    loginActions.setActivationCodeRequest(true);

    try {
      const { ok, data } = await userService.activationCode(
        code,
        email,
        receiveNewsletters
      );

      if (ok) {
        if (updateAuthToken) {
          authActions.setToken(data.access_token || null);
        }

        registrationActions.setRegistrationData({
          token: data.token,
          uid: data.uid,
          email: email,
        });

        if (typeof receiveNewsletters === 'boolean') {
          userDataActions.getMe();
        }

        nextStep();
      }
    } catch (err) {
      const error = err as IRequestError<
        { code: eActivationErrorCode; detail: string } | string
      >;
      const errorData = error.data;
      const defaultErrorText =
        'Invalid code. Make sure that the code matches the one we sent you or resend code';

      if (typeof errorData === 'string') {
        switch (errorData) {
          case eActivationErrorTextCode.INVALID:
            loginActions.setActivationCodeError({
              code: eActivationErrorCode.INVALID_CODE,
              detail: defaultErrorText,
            });
            break;
          case eActivationErrorTextCode.CODE_IS_RESET:
            loginActions.setActivationCodeError({
              code: eActivationErrorCode.CODE_IS_RESET,
              detail: errorData,
            });
            break;

          default:
            loginActions.setActivationCodeError({
              code: eActivationErrorCode.UNKNOWN,
              detail: errorData,
            });
            break;
        }
      }

      if (typeof errorData === 'object' && errorData.code) {
        const time =
          errorData.code === eActivationErrorCode.THROTTLED
            ? Number(errorData.detail.replace(/\D+/g, ''))
            : null;
        loginActions.setActivationCodeError({ ...errorData, time });
      }
    }

    loginActions.setActivationCodeRequest(false);
  };

  return {
    setActivationCodeRequest,
    setActivationCodeError,
    setShowActivationButton,
    setResendActivationCodeRequest,
    setLoginError,

    login,
    resendActivationCode,
    activationCode,
    resetUserStore,
    signOut,
  };
};
