import { SYMBOLS, WHITE_SYMBOLS } from 'chessgun/core';
import { useMemo } from 'react';
import { TFen } from '../../_types';

export const usePieces = (fenPos: TFen) => {
  return useMemo(() => {
    const pieces: {
      topOffset: number;
      leftOffset: number;
      className: string;
    }[] = [];

    const rows = fenPos.split('/');

    rows.forEach((row, topOffset) => {
      let leftOffset = 0;

      row.split('').forEach((piece) => {
        if (SYMBOLS.includes(piece)) {
          const color = WHITE_SYMBOLS.includes(piece) ? 'w' : 'b';

          pieces.push({
            leftOffset,
            topOffset,
            className: color + piece.toLowerCase(),
          });
          leftOffset += 1;
        } else {
          leftOffset += parseInt(piece);
        }
      });
    });

    return pieces;
  }, [fenPos]);
};
