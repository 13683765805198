import React, { useCallback } from 'react';
import { $Container } from './_styles';
import { TabItem } from './_TabItem';
import { classNames } from '../../../../helpers/_classNames';
// TODO: рефактор
/**
 * Селектор вкладки
 * @param {Array} tabs - массив табов
 * @param {string} currentTab - текущий выбранный таб
 * @param {function} setCurrentTab  - функция выбора таба
 * @param {string} type - вариант таба
 * @param {string} iconPosition - позиция иконки (справа или слеа от текста)
 * @param {string} iconClassName - внешний класс иконок
 * @param {boolean} disabled - отключить ли возможность взаимодействия
 * @param {boolean} showError - показывать ли ошибку
 * @param {object} translation - локализация
 * @param {string} className - внешний класс
 * @param {object} style - внешние стили
 */
export const TabSelector = ({ tabs, currentTab, setCurrentTab, tabsClassName = '', type = 'primary', iconPosition = 'left', iconClassName = '', disabled = false, showError = false, translation = null, className = '', style, }) => {
    const selectorClasses = classNames({
        primary_selector: type === 'primary',
        secondary_selector: type === 'secondary',
        white_selector: type === 'white',
        dark_selector: type === 'dark',
        white_outline_selector: type === 'white_outline',
        right_icon: iconPosition === 'right',
        error: !!showError,
        [className]: true,
    });
    const tabText = useCallback((text) => {
        if (translation && translation[text]) {
            return translation[text];
        }
        return text;
    }, [translation]);
    const clickHandler = (tab) => {
        if (disabled || tab.disabled)
            return;
        if (tab.value) {
            setCurrentTab(tab.value);
            return;
        }
        if (tab.text) {
            setCurrentTab(tab.text);
            return;
        }
    };
    return (React.createElement($Container, { className: selectorClasses, style: style }, tabs.map((tab) => (React.createElement(TabItem, { key: tab.id, tab: tab, tabText: tabText, currentTab: currentTab, clickHandler: clickHandler, disabled: disabled, iconClassName: iconClassName, tabsClassName: tabsClassName })))));
};
