import React, { useCallback } from 'react';

import Grid, { Item } from '@chessarena/components/lib/general/Grid';
import Button from '@chessarena/components/lib/general/Button';
import IconMail from '@chessarena/components/lib/icons/IconMail';
import LogoApple from '@chessarena/components/lib/logos/LogoApple';
import LogoGoogle from '@chessarena/components/lib/logos/LogoGoogle';
import LogoFacebook from '@chessarena/components/lib/logos/LogoFacebook';
import { ButtonClickEventParams } from '@chessarena/components/lib/general/Button/types';
import { useSocialAuthActions } from '@utils/hooks/_useSocialAuthActions.hook';

type UnauthorizedNotificationDescriptionProps = Readonly<{
  makeUnauthorizedNotificationRead: () => void;
}>;

export const UnauthorizedNotificationDescription: React.FC<UnauthorizedNotificationDescriptionProps> =
  React.memo(function UnauthorizedNotificationDescription(props) {
    const { makeUnauthorizedNotificationRead } = props;

    const { authWithApple, authWithFacebook, authWithGoogle } =
      useSocialAuthActions();

    const handleAppleAuthClick = useCallback(
      ({ event }: ButtonClickEventParams) => {
        event?.preventDefault();
        event?.stopPropagation();
        makeUnauthorizedNotificationRead();
        authWithApple();
      },
      [makeUnauthorizedNotificationRead, authWithApple]
    );

    const handleGoogleAuthClick = useCallback(
      ({ event }: ButtonClickEventParams) => {
        event?.preventDefault();
        event?.stopPropagation();
        makeUnauthorizedNotificationRead();
        authWithGoogle();
      },
      [makeUnauthorizedNotificationRead, authWithGoogle]
    );

    const handleFacebookAuthClick = useCallback(
      ({ event }: ButtonClickEventParams) => {
        event?.preventDefault();
        event?.stopPropagation();
        makeUnauthorizedNotificationRead();
        authWithFacebook();
      },
      [makeUnauthorizedNotificationRead, authWithFacebook]
    );

    return (
      <Grid spacing="m">
        <Item>
          <Button type="tertiary" icon size="s">
            <IconMail size="s" />
          </Button>
        </Item>
        <Item>
          <Button type="tertiary" icon size="s" onClick={handleAppleAuthClick}>
            <LogoApple size="s" />
          </Button>
        </Item>
        <Item>
          <Button type="tertiary" icon size="s" onClick={handleGoogleAuthClick}>
            <LogoGoogle size="s" />
          </Button>
        </Item>
        <Item>
          <Button
            type="tertiary"
            icon
            size="s"
            onClick={handleFacebookAuthClick}
          >
            <LogoFacebook size="s" />
          </Button>
        </Item>
      </Grid>
    );
  });
