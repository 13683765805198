import { flipTurn } from "../common";
import { castleSquares, chessSide } from "../_constants";
import { getPieceData } from "./_getPieceData";
import { getPiecePossibleAttacks } from "./_getPiecePossibleAttacks";
import { getPiecePossibleMoves } from "./_getPiecePossibleMoves";
import { isSquareUnderAttack } from "./_isSquareUnderAttack";
/**
 * Возвращает возможные ходы короля
 * @param {number}  index - индекс короля на доске
 * @returns {IPossibleCastlings} possibleCastlings - возможные рокировки
 * @returns {string[]} board - доска
 */
export const getKingPossibleMoves = ({ index, possibleCastlings, board, }) => {
    const canCastle = (positions) => {
        for (let i = 0; i < positions.length; i++) {
            const { piece, index, validate } = positions[i];
            const isUnderAttack = validate
                ? isSquareUnderAttack({
                    index,
                    board,
                    attackersColor: flipTurn(color),
                })
                : false;
            if (board[index] !== piece || isUnderAttack) {
                return false;
            }
        }
        return true;
    };
    const piece = board[index];
    const { color } = getPieceData({ piece, index });
    const possibleMoves = [
        ...getPiecePossibleMoves({
            index,
            board,
        }),
        ...getPiecePossibleAttacks(index, board),
    ];
    const pc = possibleCastlings[color];
    const stc = castleSquares[color];
    if (pc.kingSide) {
        if (canCastle(stc.kingSide)) {
            possibleMoves.push(color === chessSide.WHITE ? 118 : 6);
        }
    }
    if (pc.queenSide) {
        if (canCastle(stc.queenSide)) {
            possibleMoves.push(color === chessSide.WHITE ? 114 : 2);
        }
    }
    return possibleMoves;
};
