/**
 * Добавляет ключ-значение в локалсторадж
 * @param {string} key - ключ
 * @param {any} value - значение
 */
export const setLSItem = (
  key: string,
  value: any // eslint-disable-line
) => {
  if (typeof window === 'undefined') return;

  localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Получить значение из LS по ключу
 * @param {string} key - ключ
 */
export const getLSItem = (key: string) => {
  if (typeof window === 'undefined') return;

  const value = localStorage.getItem(key);

  if (value === null) return null;

  try {
    return JSON.parse(value);
  } catch (err) {
    return value;
  }
};

/**
 * Удалить значение из LS по ключу
 * @param {string} key - ключ
 */
export const removeLSItem = (key: string) => {
  if (typeof window === 'undefined') return;

  localStorage.removeItem(key);
};
