import { calculatePiecePossibleMoves } from "../calculations";
import { generateError } from "../common/_generateError";
import { isLan } from "../fen/_isLan";
import { isLegalMove } from "../fen/_isLegalMove";
import { BLACK_SYMBOLS, chessSide, figureType, moveTypes, SQUARES, SYMBOLS, WHITE_SYMBOLS, } from "../_constants";
import { getCastlingRook } from "./_getCastlingRook";
export function getLongSanMoveData({ lan, board, possibleCastlings, enPassant, nextTurn, withValidation = false, }) {
    if (!isLan(lan)) {
        throw new Error(`Wrong lan: ${lan}`);
    }
    lan = lan.replace("=", "");
    const $fromString = lan.slice(0, 2);
    const $toString = lan.slice(2, 4);
    const $promotion = lan[4] ? lan[4] : null;
    const $from = SQUARES[$fromString];
    const $to = SQUARES[$toString];
    let $capturedFigure = board[$to] ? $to : null;
    if (!(nextTurn === chessSide.WHITE ? WHITE_SYMBOLS : BLACK_SYMBOLS).includes(board[$from])) {
        throw generateError({
            board,
            index: $from,
            targetIndex: $to,
            message: `Expected ${nextTurn} to move: ${lan}`,
        });
    }
    const $isLegal = withValidation
        ? isLegalMove({
            board,
            index: $from,
            targetIndex: $to,
            possibleCastlings,
            enPassant,
        })
        : true;
    if (!$isLegal) {
        console.log(board[$from], board[35], calculatePiecePossibleMoves({
            board,
            index: $from,
            possibleCastlings,
            enPassant,
        }));
        throw generateError({
            board,
            index: $from,
            targetIndex: $to,
            message: `Lan move is not valid: ${lan}`,
        });
    }
    const $piece = board[$from];
    if (!SYMBOLS.includes($piece))
        throw generateError({
            board,
            message: `Can't find piece. Lan: ${lan}`,
            index: $from,
            targetIndex: $to,
        });
    const $pieceColor = WHITE_SYMBOLS.includes($piece) ? chessSide.WHITE : chessSide.BLACK;
    switch ($piece.toLowerCase()) {
        case figureType.PAWN:
            let $newEnPassant = null;
            if (Math.abs($from - $to) / 2 === 16) {
                $newEnPassant = $pieceColor === chessSide.WHITE ? $from - 16 : $from + 16;
            }
            if (enPassant && $to === enPassant) {
                $capturedFigure = $pieceColor === chessSide.WHITE ? $to + 16 : $to - 16;
            }
            return {
                type: moveTypes.PAWN,
                to: $to,
                from: $from,
                color: $pieceColor,
                promotion: $promotion,
                piece: figureType.PAWN,
                newEnPassant: $newEnPassant,
                capturedFigure: $capturedFigure,
            };
        case figureType.KING:
            const $king = board[$from];
            if ($king.toLowerCase() !== figureType.KING)
                throw generateError({
                    board,
                    index: $from,
                    targetIndex: $to,
                    message: `Can't find king. lan: ${lan}`,
                });
            const $castling_indexes = {
                [chessSide.WHITE]: {
                    from: 116,
                    data: [
                        {
                            index: 118,
                            possible: possibleCastlings[chessSide.WHITE].kingSide,
                        },
                        {
                            index: 114,
                            possible: possibleCastlings[chessSide.WHITE].queenSide,
                        },
                    ],
                },
                [chessSide.BLACK]: {
                    from: 4,
                    data: [
                        {
                            index: 6,
                            possible: possibleCastlings[chessSide.BLACK].kingSide,
                        },
                        {
                            index: 2,
                            possible: possibleCastlings[chessSide.BLACK].queenSide,
                        },
                    ],
                },
            };
            const $ci = $castling_indexes[$pieceColor];
            if ($ci.from === $from) {
                for (let i = 0; i < $ci.data.length; i++) {
                    const { index, possible } = $ci.data[i];
                    if ($to === index) {
                        if (!possible) {
                            throw generateError({
                                board,
                                message: `Castling not Allowed. lan: ${lan}`,
                            });
                        }
                        const $rook = getCastlingRook({
                            board,
                            kingTargetIndex: $to,
                            turn: $pieceColor,
                        });
                        if ($rook === null)
                            throw generateError({
                                board,
                                message: `Can't find rook. lan: ${lan}`,
                            });
                        return {
                            type: moveTypes.CASTLING,
                            color: $pieceColor,
                            king: {
                                from: $from,
                                to: $to,
                            },
                            rook: $rook,
                        };
                    }
                }
            }
        default:
            return {
                type: moveTypes.PIECE,
                piece: $piece.toLowerCase(),
                color: $pieceColor,
                from: $from,
                to: $to,
                capturedFigure: $capturedFigure,
            };
    }
}
