export const cgTagNames = {
    BOARD: "cg-board",
    PIECE: "cg-piece",
    PROMOTION: "cg-promotion",
    TITLES: "cg-titles",
    SQUARES: "cg-squares",
    squares: {
        SELECTED: "cg-selected-square",
        HOVER: "cg-hover-square",
        LAST_MOVE: "cg-last-move-square",
        PREMOVE: "cg-premove-square",
        POSSIBLE_MOVE: "cg-possible-move-square",
        CHECK: "cg-check-square",
    },
};
