import Router from 'next/router';

export const openPopup = (url: string) => {
  const asPath = Router.asPath.split('?')[0];
  // const params = new URLSearchParams(url);

  Router.push(
    {
      pathname: Router.pathname,
      search: url,
    },
    { pathname: asPath, search: url },
    { shallow: true }
  );
};

export const closePopup = () => {
  const popupKeys = ['popup', 'free_pro', 'from', 'email', 'analyzer'];

  const asPath = Router.asPath.split('?')[0];
  const query: { [key: string]: string } = {};

  Object.keys(Router.query).forEach((key) => {
    if (!popupKeys.includes(key) && Router.query[key]) {
      query[key] = Router.query[key] as string;
    }
  });

  Router.push({ pathname: Router.pathname, query: query }, asPath, {
    shallow: true,
  });
};
