/* eslint-disable @typescript-eslint/no-explicit-any */

// TODO: поправить any, или удалить эти типы (используются только на странице античит)
export type IContextDispatch<T> = React.Dispatch<{
  type: T;
  payload?: any;
}>;

export interface IContextActionProps<State, ActionType> {
  state: State;
  dispatch: IContextDispatch<ActionType>;
}

export type IContextAction<State, ActionType> = (
  props: {
    state: State;
    dispatch: IContextDispatch<ActionType>;
  },
  ...args: any[]
) => void;

export interface IContextProps<State, Computed extends object, ActionType> {
  state: State;
  computed: Computed;
  dispatch: (props: { type: ActionType; payload?: any }) => void;
}

export enum ePhase {
  SCHEDULED = 'SCHEDULED',
  CONNECTING = 'CONNECTING',
  PLAYING = 'PLAYING',
  DONE = 'DONE',
  UNEXPECTED = 'UNEXPECTED',
}

export enum eDoneResult {
  WHITE_WIN = 'WHITE_WIN',
  DRAW = 'DRAW',
  BLACK_WIN = 'BLACK_WIN',
  ABORT = 'ABORT',
  UNEXPECTED = 'UNEXPECTED',
}

export enum eDoneReason {
  DRAW_OFFER = 'DRAW_OFFER',
  DRAW_50_MOVES = 'DRAW_50_MOVES',
  DRAW_75_MOVES = 'DRAW_75_MOVES',
  DRAW_3_FOLD_REPETITION = 'DRAW_3_FOLD_REPETITION',
  DRAW_5_FOLD_REPETITION = 'DRAW_5_FOLD_REPETITION',
  DRAW_INSUFFICIENT_MATERIAL = 'DRAW_INSUFFICIENT_MATERIAL',
  DRAW_INSUFFICIENT_MATERIAL_TIMEOUT_WHITE = 'DRAW_INSUFFICIENT_MATERIAL_TIMEOUT_WHITE',
  DRAW_INSUFFICIENT_MATERIAL_TIMEOUT_BLACK = 'DRAW_INSUFFICIENT_MATERIAL_TIMEOUT_BLACK',
  WIN_CHECKMATE = 'WIN_CHECKMATE',
  WIN_STALE_MATE = 'WIN_STALE_MATE',
  WIN_RESIGN = 'WIN_RESIGN',
  WIN_TIME_CONTROL = 'WIN_TIME_CONTROL',
  WIN_TIMEOUT_ONE_CONNECTION = 'WIN_TIMEOUT_ONE_CONNECTION',
  ABORT_TIMEOUT_BOTH_CONNECTIONS = 'ABORT_TIMEOUT_BOTH_CONNECTIONS',
  ABORT_TIMEOUT_FIRST_MOVE_WHITE = 'ABORT_TIMEOUT_FIRST_MOVE_WHITE',
  ABORT_TIMEOUT_FIRST_MOVE_BLACK = 'ABORT_TIMEOUT_FIRST_MOVE_BLACK',
  ABORT_MANUAL_CONNECTING_WHITE = 'ABORT_MANUAL_CONNECTING_WHITE',
  ABORT_MANUAL_CONNECTING_BLACK = 'ABORT_MANUAL_CONNECTING_BLACK',
  ABORT_MANUAL_FIRST_MOVE_WHITE = 'ABORT_MANUAL_FIRST_MOVE_WHITE',
  ABORT_MANUAL_FIRST_MOVE_BLACK = 'ABORT_MANUAL_FIRST_MOVE_BLACK',
  WIN_DISCONNECTED = 'WIN_DISCONNECTED',
  UNEXPECTED = 'UNEXPECTED',
}
