import { useEffect } from 'react';

import { getVhVwUnits } from '../_helpers';

export const useHandleVhVwChange = () => {
  const updateVwVh = () => {
    const units = getVhVwUnits();
    if (!units) return;

    const { vh, vw } = units;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  };

  useEffect(() => {
    updateVwVh();

    window.visualViewport?.addEventListener('resize', updateVwVh);

    return () => {
      window.visualViewport?.removeEventListener('resize', updateVwVh);
    };
  }, []);
};
