import React from 'react';
import { shapeColors } from '../../../../styles';
/**
 * Иконка: многоугольник wc
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 * @param {string} className - CSS класс иконки
 */
export const PreloaderIcon = ({ fill = shapeColors['white'], width = '100%', height = '100%', className = '', }) => (React.createElement("svg", { className: className, width: width, height: height, viewBox: "0 0 34 34", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M20.2 1a3 3 0 0 1 3 .8l9.9 9.9c.7.7 1 1.8.7 2.9L30.2 28a3 3 0 0 1-2.1 2.2l-13.6 3.6a3 3 0 0 1-2.9-.8l-9.9-10a3 3 0 0 1-.8-2.8L4.6 6.7a3 3 0 0 1 2-2L20.3 1Z", fill: fill })));
