import { useMemo } from 'react';
import { TChessPosition, TUCI } from '../../_types';
import { getFileIndex, getRankIndex } from '../helpers/rankAndFileIndex';
import { splitPosition } from '../helpers/splitPosition';

export const useFromTo = (fromTo: TUCI) => {
  return useMemo(() => {
    const fromToArr = fromTo.split('');

    const from = (fromToArr[0] + fromToArr[1]) as TChessPosition;
    const to = (fromToArr[2] + fromToArr[3]) as TChessPosition;

    const [fromFile, fromRank] = splitPosition(from);

    const [toFile, toRank] = splitPosition(to);

    return {
      from: {
        topOffset: getRankIndex(fromRank),
        leftOffset: getFileIndex(fromFile),
      },
      to: {
        topOffset: getRankIndex(toRank),
        leftOffset: getFileIndex(toFile),
      },
    };
  }, [fromTo]);
};
