import { fideTabs } from './_constants';
import {
  IPopupsContextState,
  IRegistrationPopupsStore,
  IFidePopupsStore,
  TPopupsContextReducer,
  IPopupsAction,
  ePopupsContextActionTypes,
} from './_types';

const initRegistrationState: IRegistrationPopupsStore = {
  updates: true,
  currentTab: null,
  freePro: false,
  isVerification: false,
};

const initFideState: IFidePopupsStore = {
  fideId: null,
  currentTab: fideTabs[0].value,
  photo: '',
};

export const initState: IPopupsContextState = {
  registration: initRegistrationState,
  fide: initFideState,
};

export const PopupsContextReducer: TPopupsContextReducer = (
  state: IPopupsContextState,
  { type, payload }: IPopupsAction
) => {
  switch (type) {
    case ePopupsContextActionTypes.SET_REGISTRATION_POPUPS_UPDATES: {
      return {
        ...state,
        registration: {
          ...state.registration,
          updates: payload,
        },
      };
    }
    case ePopupsContextActionTypes.SET_REGISTRATION_POPUPS_CURRENT_TAB: {
      return {
        ...state,
        registration: {
          ...state.registration,
          currentTab: payload,
        },
      };
    }
    case ePopupsContextActionTypes.SET_REGISTRATION_POPUPS_FREE_PRO: {
      return {
        ...state,
        registration: {
          ...state.registration,
          freePro: payload,
        },
      };
    }
    case ePopupsContextActionTypes.SET_REGISTRATION_POPUPS_IS_VERIFICATION: {
      return {
        ...state,
        registration: {
          ...state.registration,
          isVerification: payload,
        },
      };
    }
    case ePopupsContextActionTypes.RESET_REGISTRATION_POPUPS: {
      return {
        ...state,
        registration: initRegistrationState,
      };
    }

    case ePopupsContextActionTypes.SET_FIDE_POPUPS_FIDE_ID: {
      return {
        ...state,
        fide: {
          ...state.fide,
          fideId: payload,
        },
      };
    }
    case ePopupsContextActionTypes.SET_FIDE_POPUPS_CURRENT_TAB: {
      return {
        ...state,
        fide: {
          ...state.fide,
          currentTab: payload,
        },
      };
    }
    case ePopupsContextActionTypes.SET_FIDE_POPUPS_PHOTO: {
      return {
        ...state,
        fide: {
          ...state.fide,
          photo: payload,
        },
      };
    }
    case ePopupsContextActionTypes.RESET_FIDE_POPUPS: {
      return {
        ...state,
        fide: initFideState,
      };
    }

    default:
      return state;
  }
};
