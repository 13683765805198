import { chessSide } from 'chessgun/core';
import {
  addMilisecondsToNow,
  getMoveEndTime,
  msFromLastMoveToEnd,
  syncedDate,
} from 'shared/helpers';
import { Store } from 'storeshed';
import { IRoundBoardMove, ITournamentState } from '@types';
import { GameResult, IGameMove, TournamentBoardStatus } from 'shared/types';
import { tournamentActions } from './index';
import { TMainStore } from '@store/storeshed';
import { formatNGResult } from '@utils/_helpers';
import {
  getBoardStatus,
  getFormattedMove,
  getPlayersMsLeft,
  getPlayersPreMoveMs,
} from '@utils/_ng_helpers';
import {
  IBroadcastBoardTimeChanged,
  IBroadcastMoveAnalyzed,
  IBroadcastMoved,
  IGameState,
} from '@store/context/_types';

// TODO: продумать какой-то общий экшен, у имеющихся экшенов похожая структура
export const createTournamentBoardActions = () => {
  const getBoardById = (store: Store<ITournamentState>, boardId: string) => {
    const roundBoards = store.get('round_boards');

    const newBoards = roundBoards.slice();

    const board = newBoards.find((newBoard) => newBoard.board_id === boardId);

    return { newBoards, board };
  };

  /**
   * Задает статус доски
   * @param {TournamentBoardStatus} status - статус игры
   * @param {string} boardId - id доски
   */
  const setBoardStatus = (
    { tournamentStore: store }: TMainStore,
    status: TournamentBoardStatus,
    boardId: string
  ) => {
    const { newBoards, board } = getBoardById(store, boardId);

    if (board) {
      board.status = status;
      store.dispatch('round_boards', newBoards);
    }
  };

  /**
   * Задает результат доски
   * @param {GameResult} result - результат игры
   * @param {string} boardId - id доски
   */
  const setBoardResult = (
    { tournamentStore: store }: TMainStore,
    result: GameResult | null,
    boardId: string
  ) => {
    const { newBoards, board } = getBoardById(store, boardId);

    if (board) {
      board.result = result;
      store.dispatch('round_boards', newBoards);
    }
  };

  /**
   * Задает текущую сторону хода
   * @param {string} boardId - id доски
   * @param {chessSide} playerTurn - текущая сторона хода
   */
  const setBoardPlayerTurn = (
    { tournamentStore: store }: TMainStore,
    boardId: string,
    playerTurn: chessSide
  ) => {
    const { newBoards, board } = getBoardById(store, boardId);

    if (board) {
      board.playerTurn = playerTurn;
      store.dispatch('round_boards', newBoards);
    }
  };

  /**
   * Обновляет данные для доски из нг турнира
   * @param store
   * @param authStore
   * @param {IBoardSubscribe} gameState - данные доски
   */
  const updateNGTourBoardData = (
    { tournamentStore: store, authStore }: TMainStore,
    gameState: IGameState
  ) => {
    const boardId = gameState.cfg?.gId;
    const { newBoards, board: selectedBoard } = getBoardById(store, boardId);
    const uid = authStore.get('uid');

    const standingSelectedGame = store.get('standing_selected_game');

    let board = selectedBoard;

    if (!board) {
      if (standingSelectedGame?.board_id === boardId) {
        board = standingSelectedGame;
      } else return;
    }

    const playingPhCfg =
      gameState.cfg?.playingPhCfg ?? board.playingPhCfg ?? null;
    const { whiteMsLeft, blackMsLeft } = getPlayersMsLeft({
      ...gameState,
      cfg: {
        ...gameState.cfg,
        playingPhCfg: gameState.cfg?.playingPhCfg || playingPhCfg,
      },
    });
    const {
      whitePreMoveMsLeft,
      blackPreMoveMsLeft,
      whitePreMoveMsAdditional,
      blackPreMoveMsAdditional,
    } = getPlayersPreMoveMs({
      currentMoveData: gameState.playingPh?.currTurn,
      playingPhCfg: playingPhCfg,
    });

    board.white_ms_left = whiteMsLeft;
    board.black_ms_left = blackMsLeft;
    board.status = getBoardStatus(gameState.currPh);
    board.white_pre_move_ms_left = whitePreMoveMsLeft;
    board.black_pre_move_ms_left = blackPreMoveMsLeft;
    board.playingPhCfg = playingPhCfg;

    const start = gameState.cfg?.scheduledPhCfg?.start;
    const isMyBoard = board.white_uid === uid || board.black_uid === uid;

    if (start) {
      const currentTime = syncedDate().getTime();
      const msToStart = start - currentTime;
      if (
        isMyBoard &&
        board.status === TournamentBoardStatus.EXPECTED &&
        !board.my_board?.timer_end_time &&
        msToStart > 0
      ) {
        board.my_board = { timer_end_time: addMilisecondsToNow(msToStart) };
      }
    }

    const playingPh = gameState.playingPh;
    const lastMove = playingPh?.lastTurn;
    if (lastMove) {
      const absNum = lastMove.start.absNum;

      // номер нового хода меньше уже имеющегося количства
      // или номер нового хода 2 или более превышает уже
      // имеющееся количство (ходы пропущены)
      const needsUpdateMoves =
        absNum < board.moves.length || absNum - 1 > board.moves.length;

      const turns = playingPh.turns;
      if ((!board.moves.length || needsUpdateMoves) && turns) {
        const moves: IRoundBoardMove[] = [];
        turns.forEach((move) => {
          moves.push(getFormattedMove(move));
        });
        board.moves = moves;
      } else {
        const absNum = lastMove.start.absNum;
        // восстановление стейта доски, если были пропущены/исправлены ходы
        if (needsUpdateMoves) {
          tournamentActions.getRoundNGBoardsData([board]);

          return;
        }

        if (absNum > board.moves.length) {
          const formattedLastMove = getFormattedMove(lastMove);
          board.moves.push(formattedLastMove);
        }
      }
    }

    const currTurn = playingPh?.currTurn;
    if (currTurn) {
      const isWhiteMove = currTurn.clr;

      if (isWhiteMove) {
        board.white_move_end_time = getMoveEndTime(
          whiteMsLeft + whitePreMoveMsAdditional
        );
        board.black_move_end_time = null;
      } else {
        board.black_move_end_time = getMoveEndTime(
          blackMsLeft + blackPreMoveMsAdditional
        );
        board.white_move_end_time = null;
      }

      board.playerTurn = isWhiteMove ? chessSide.WHITE : chessSide.BLACK;
    }

    const donePh = gameState.donePh;
    if (donePh) {
      board.result = formatNGResult(donePh.result);
    }

    if (selectedBoard) {
      store.dispatch('round_boards', newBoards);
    }

    if (standingSelectedGame?.board_id === boardId) {
      store.dispatch('standing_selected_game', { ...board });
    }
  };

  /**
   * Задает новый ход на доске в лобби бродкаста
   * @param {IBroadcastMoved} data - данные хода
   * @param {string} boardId - id доски
   */
  const setBroadcastBoardMove = (
    { tournamentStore: store }: TMainStore,
    data: IBroadcastMoved,
    boardId: string
  ) => {
    const { newBoards, board } = getBoardById(store, boardId);

    if (board) {
      const lastMoveTurn = board.playerTurn;
      const currTurnIsWhite = lastMoveTurn === chessSide.BLACK;

      const whiteMsLeft = data.wTime;
      const blackMsLeft = data.bTime;

      board.white_ms_left = whiteMsLeft;
      board.black_ms_left = blackMsLeft;
      board.playerTurn = currTurnIsWhite ? chessSide.BLACK : chessSide.WHITE;

      const move: IRoundBoardMove = {
        fen: data.move.fen,
        long_san: data.move.uci,
        san: data.move.san,
        is_white_move: currTurnIsWhite,
        move_number: data.move.moveNumber,
      };

      board.moves.push(move);

      if (currTurnIsWhite) {
        board.black_move_end_time = getMoveEndTime(blackMsLeft);
        board.white_move_end_time = null;
      } else {
        board.white_move_end_time = getMoveEndTime(whiteMsLeft);
        board.black_move_end_time = null;
      }

      store.dispatch('round_boards', newBoards);
    }
  };

  /**
   * Переписывает ходы на доске в лобби бродкаста
   * @param {IBroadcastMoved} data - список новых ходов
   * @param {string} boardId - id доски
   */
  const setBroadcastBoardMovesChanged = (
    { tournamentStore: store }: TMainStore,
    moves: IGameMove[],
    boardId: string
  ) => {
    const { newBoards, board } = getBoardById(store, boardId);

    if (board) {
      board.moves = moves;

      const lastMove = moves[moves.length - 1];

      const currTurnIsWhite = !lastMove.is_white_move;

      const blackMsLeft =
        !currTurnIsWhite && lastMove.made_in
          ? msFromLastMoveToEnd(lastMove.made_in, lastMove.black_ms_left)
          : lastMove.black_ms_left;
      const whiteMsLeft =
        currTurnIsWhite && lastMove.made_in
          ? msFromLastMoveToEnd(lastMove.made_in, lastMove.white_ms_left)
          : lastMove.white_ms_left;

      board.white_ms_left = whiteMsLeft;
      board.black_ms_left = blackMsLeft;

      if (currTurnIsWhite) {
        board.black_move_end_time = getMoveEndTime(blackMsLeft);
        board.white_move_end_time = null;
      } else {
        board.white_move_end_time = getMoveEndTime(whiteMsLeft);
        board.black_move_end_time = null;
      }

      store.dispatch('round_boards', newBoards);
    }
  };

  /**
   * Обновляет оставшееся время игроков в последнем ходе
   * @param {IBroadcastBoardTimeChanged} timeData - данные о времени
   * @param {string} boardId - id доски
   */
  const setBroadcastBoardPlayersMsLeft = (
    { tournamentStore: store }: TMainStore,
    timeData: IBroadcastBoardTimeChanged,
    boardId: string
  ) => {
    const { newBoards, board } = getBoardById(store, boardId);

    if (board) {
      const lastMoveTurn = board.playerTurn;
      const currTurnIsWhite = lastMoveTurn === chessSide.BLACK;

      const blackMsLeft =
        !currTurnIsWhite && timeData.lastMoveMadeIn
          ? msFromLastMoveToEnd(timeData.lastMoveMadeIn, timeData.bTime)
          : timeData.bTime;
      const whiteMsLeft =
        currTurnIsWhite && timeData.lastMoveMadeIn
          ? msFromLastMoveToEnd(timeData.lastMoveMadeIn, timeData.wTime)
          : timeData.wTime;

      board.white_ms_left = whiteMsLeft;
      board.black_ms_left = blackMsLeft;

      if (currTurnIsWhite) {
        board.black_move_end_time = getMoveEndTime(blackMsLeft);
        board.white_move_end_time = null;
      } else {
        board.white_move_end_time = getMoveEndTime(whiteMsLeft);
        board.black_move_end_time = null;
      }

      store.dispatch('round_boards', newBoards);
    }
  };

  /**
   * Задает анализ на доске
   * @param {IBroadcastMoveAnalyzed} move - анализ хода
   */
  const setBroadcastBoardAnalysis = (
    { tournamentStore: store }: TMainStore,
    moveAnalysis: IBroadcastMoveAnalyzed,
    boardId: string
  ) => {
    const { newBoards, board } = getBoardById(store, boardId);

    if (board) {
      const moves = board.moves;

      if (moves.length > 0) {
        const lastMoveNumber = moves[moves.length - 1].move_number;

        const multipv = moveAnalysis.analysis.multipv;
        if (lastMoveNumber === moveAnalysis.moveNumber && multipv) {
          board.analysis = moveAnalysis.analysis;

          store.dispatch('round_boards', newBoards);
        }
      }
    }
  };

  return {
    setBoardStatus,
    setBoardResult,
    setBoardPlayerTurn,

    updateNGTourBoardData,
    setBroadcastBoardMove,
    setBroadcastBoardMovesChanged,
    setBroadcastBoardPlayersMsLeft,
    setBroadcastBoardAnalysis,
  };
};
