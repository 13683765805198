import { NewsService } from 'shared/services/_news.service';
import kalibri from 'kalibri';

const kalibriInstance = kalibri.createInstance();

const apiUrl = process.env.API_URL || process.env.NEXT_PUBLIC_API_URL;

if (apiUrl) {
  kalibriInstance.setBaseUrl(apiUrl);
}

const options = {
  baseUrl: apiUrl || '/',
  ajax: kalibriInstance,
};

export const newsService = new NewsService(options);
