import { useMemo } from 'react';
import { IBoardPreview } from '../../_types';
import { getFileIndex, getRankIndex } from '../helpers/rankAndFileIndex';
import { splitPosition } from '../helpers/splitPosition';

export const usePossibleMovesOffsets = (
  possibleMoves: IBoardPreview['possibleMoves']
) => {
  return useMemo(() => {
    if (!possibleMoves?.length) return;

    return possibleMoves.map((pos) => {
      const [file, rank] = splitPosition(pos);

      return {
        topOffset: getRankIndex(rank),
        leftOffset: getFileIndex(file),
      };
    });
  }, [possibleMoves]);
};
