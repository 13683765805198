import { useRef, useEffect } from 'react';

/**
 * Хук сохраняет предыдущее значение переменной value
 * @param value – переменная за которой надо следить
 * @param updateMs – количество мс, через которое обновится значение value
 * @returns предыдущее значение
 */
export function usePrevious<T>(value: T, updateMs = 0): T | undefined {
  const ref = useRef<T | undefined>();

  useEffect(() => {
    if (updateMs) {
      setTimeout(() => {
        ref.current = value;
      }, updateMs);
    } else {
      ref.current = value;
    }
  }, [value]);

  return ref.current;
}
