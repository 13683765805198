import {
  IActiveGamesContextState,
  TActiveGamesContextReducer,
  IActiveGamesAction,
  eActiveGamesContextActionTypes,
} from './_types';

export const initState: IActiveGamesContextState = {
  filterGameType: 'allGames',
  filterTimeControl: 'any',
  filterRatingType: 'all',
};

export const ActiveGamesContextReducer: TActiveGamesContextReducer = (
  state: IActiveGamesContextState,
  { type, payload }: IActiveGamesAction
) => {
  switch (type) {
    case eActiveGamesContextActionTypes.SET_FILTER_GAME_TYPE:
      return {
        ...state,
        filterGameType: payload,
      };

    case eActiveGamesContextActionTypes.SET_FILTER_TIME_CONTROL:
      return {
        ...state,
        filterTimeControl: payload,
      };

    case eActiveGamesContextActionTypes.SET_FILTER_RATING_TYPE:
      return {
        ...state,
        filterRatingType: payload,
      };

    default:
      return state;
  }
};
