import { useCallback, useMemo, useState } from 'react';
const defaultInputState = {
    value: '',
    defaultValue: '',
    required: false,
    isChanged: false,
    errorMessage: null,
    validator: () => null,
    formatter: (val) => val,
};
/**
 * Хук для валидации формы. Принимает на вход следующие параметры:
 * @param {object} inputs  - содержит информацию по каждому инпуту.
 * @param {object} inputs[inputName] - содержит информацию по конкретному инпуту.
 * @param {string} inputs[inputName].value - значение поля.
 * @param {string} inputs[inputName].defaultValue - значение по умолчанию.
 * @param {boolean} inputs[inputName].required - должен ли инпут быть заполнен.
 * @param {boolean} inputs[inputName].isChanged - изменилось ли значение поля.
 * @param {Function} inputs[inputName].validator - валидирует значение инпута при вводе. Должен возвращать
 * @param inputs[inputName].errorMessage - сообщение об ошибке, полученое из валидатора.
 * либо null, либо ошибку string.
 * @param {Function} inputs[inputName].formatter - форматирует значение при вводе.
 * @param {Function} submitHandler - функция подтверждения формы. Принимает на вход параметр state {UseFormState<Inputs>}.
 *
 * @returns {object} result - объект с состоянием и функциями.
 * @returns {object} result.inputState - состояние всех инпутов.
 * @returns {Function} result.onChange - функция для инпута.
 * @returns {Function} result.updateValue - функция для обновления значения поля (в т.ч. дефолтного).
 * @returns {boolean} result.isValid - все ли поля валидны.
 * @returns {Function} result.onSubmit - функция для подтверждения формы.
 *
 *
 */
export function useForm(inputs, submitHandler) {
    const [state, setState] = useState(initState(inputs));
    function initState($inputs) {
        let initValue = {};
        Object.entries($inputs).forEach(([name, inputData]) => {
            let inputState = Object.assign({}, defaultInputState);
            if (typeof inputData === 'string') {
                inputState = Object.assign(Object.assign({}, inputState), { value: inputData });
            }
            if (typeof inputData === 'object') {
                inputState = Object.assign(Object.assign({}, inputState), inputData);
            }
            initValue = Object.assign(Object.assign({}, initValue), { [name]: Object.assign(Object.assign({}, inputState), { defaultValue: inputState.value }) });
        });
        return initValue;
    }
    const isValid = useMemo(() => {
        const haveErrors = Object.keys(state).find((key) => {
            return (state[key].errorMessage !== null ||
                (state[key].required && state[key].value.length === 0));
        });
        return !(haveErrors === null || haveErrors === void 0 ? void 0 : haveErrors.length);
    }, [state]);
    const isChanged = useMemo(() => {
        const fieldsChanged = Object.keys(state).find((key) => {
            return state[key].value !== state[key].defaultValue;
        });
        return !!(fieldsChanged === null || fieldsChanged === void 0 ? void 0 : fieldsChanged.length);
    }, [state]);
    const onChange = (event) => {
        const { target: { name = null, value }, } = event;
        if (!name) {
            throw new Error("useForm onChange: input don't have name");
        }
        const $name = name;
        if (state[$name] === undefined) {
            throw new Error("useForm onChange: state don't have field with provided name");
        }
        const { validator, formatter } = state[$name];
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { [name]: Object.assign(Object.assign({}, prevState[$name]), { value: formatter(value), errorMessage: validator(value), isChanged: value !== prevState[$name].defaultValue }) })));
    };
    const updateValue = (name, value, updateDefault = false) => {
        if (!name) {
            throw new Error("useForm updateValue: input don't have name");
        }
        const $name = name;
        if (state[$name] === undefined) {
            throw new Error("useForm updateValue: state don't have field with provided name");
        }
        const { validator, formatter } = state[$name];
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { [$name]: Object.assign(Object.assign({}, prevState[$name]), { value: formatter(value), errorMessage: validator(value), defaultValue: updateDefault
                    ? formatter(value)
                    : prevState[$name].defaultValue, isChanged: !updateDefault && value !== prevState[$name].defaultValue }) })));
    };
    const setFieldError = useCallback((fieldName, error) => {
        if (!fieldName || !error)
            return;
        const $name = fieldName;
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { [$name]: Object.assign(Object.assign({}, prevState[$name]), { errorMessage: error, isChanged: false }) })));
    }, []);
    const onSubmit = (e) => {
        e.preventDefault();
        if (!!submitHandler && typeof submitHandler === 'function') {
            submitHandler(state, setState);
        }
    };
    return {
        inputState: state,
        onChange,
        updateValue,
        onSubmit,
        isValid,
        isChanged,
        setFieldError,
    };
}
