/**
 * Функция для создания ссылки переадресации. Ссылка используется
 * после прохождения аутентификации в сторонних сервисах (Google, Facebook, Apple, etc)
 * для получения данных от вышеупомянутых сервисов.
 * @param {string} path - путь, по которому осуществляется переадресация. Должен начинаться с символа "/".
 * @returns {string} redirectUrl - ссылка для переадресации
 */
export const createSocialAuthRedirectUri = (
  path: string,
  devHost = 'https://localhost:3003'
) => {
  return (
    (process.env.NODE_ENV === 'development'
      ? devHost
      : process.env.NEXT_PUBLIC_GAME_URL) + path
  );
};
