import React, { PropsWithChildren, memo } from 'react';

import { useActiveGamesActions } from './_actions';
import { ActiveGamesContextReducer, initState } from './_reducer';
import { IActiveGamesContextProps } from './_types';
import { useComputed } from './_useComputed.hook';

export const ActiveGamesContext = React.createContext(
  {} as IActiveGamesContextProps
);

export const ActiveGamesContextProvider: React.FC<PropsWithChildren> = memo(
  function ActiveGamesContextProvider({ children }) {
    const [state, dispatch] = React.useReducer(
      ActiveGamesContextReducer,
      initState
    );

    const actions = useActiveGamesActions(dispatch, state);
    const computed = useComputed(state);

    return (
      <ActiveGamesContext.Provider value={{ state, actions, computed }}>
        {children}
      </ActiveGamesContext.Provider>
    );
  }
);

export const useActiveGamesContext = () => React.useContext(ActiveGamesContext);
