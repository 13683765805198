import { outOfBoard } from "../stringBoard/_outOfBoard";
import { BLACK_SYMBOLS, PIECE_OFFSETS, WHITE_SYMBOLS } from "../_constants";
/**
 * Возвращает возможные атаки фигуры (не валидирует ходы на наличие шаха после него)
 * @param {number} index - индекс фигуры на доске
 * @param {string[]} board - доска
 * @returns
 */
export const getPiecePossibleAttacks = (index, board) => {
    const square = board[index];
    const possibleAttacks = [];
    const offsets = PIECE_OFFSETS[square.toLowerCase()];
    const opositePieces = WHITE_SYMBOLS.includes(square) ? BLACK_SYMBOLS : WHITE_SYMBOLS;
    /* Queen, bishop or rook */
    if (["q", "b", "r"].includes(square.toLowerCase())) {
        offsets.forEach((offset) => {
            let newPos = index;
            let canMove = true;
            while (canMove) {
                newPos += offset;
                if (outOfBoard(newPos)) {
                    canMove = false;
                    continue;
                }
                const newSquare = board[newPos];
                if (newSquare === "")
                    continue;
                canMove = false;
                if (opositePieces.includes(newSquare)) {
                    possibleAttacks.push(newPos);
                }
            }
        });
        return possibleAttacks;
    }
    offsets.forEach((offset) => {
        const newPos = index + offset;
        if (outOfBoard(newPos))
            return;
        if (opositePieces.includes(board[newPos])) {
            possibleAttacks.push(newPos);
        }
    });
    return possibleAttacks;
};
