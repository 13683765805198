import { bindActions } from 'storeshed';

import { createTournamentBoardActions } from './_board.actions';
import { createTournamentBoardsActions } from './_boards.actions';
import { createTournamentBroadcastActions } from './_broadcast.actions';
import { createTournamentCommonActions } from './_common.actions';
import { createTournamentRoundsActions } from './_rounds.actions';
import { createTournamentStandingActions } from './_standing.actions';
import { createTournamentUpdatesActions } from './_updates.actions';
import { mainStore } from '@store/storeshed';

export const tournamentActions = {
  ...bindActions(mainStore, createTournamentBoardActions()),
  ...bindActions(mainStore, createTournamentBoardsActions()),
  ...bindActions(mainStore, createTournamentBroadcastActions()),
  ...bindActions(mainStore, createTournamentCommonActions()),
  ...bindActions(mainStore, createTournamentRoundsActions()),
  ...bindActions(mainStore, createTournamentStandingActions()),
  ...bindActions(mainStore, createTournamentUpdatesActions()),
};
