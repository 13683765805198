import { useMemo } from 'react';
import { settingsPopupBoards } from '../../constants';
import { useUserDataStore } from '@store/storeshed';
import { IBoard } from '@components/molecules/popups/ChessboardSettingsPopup/internal/types';

/**
 * This hook returns settings board themes
 */
export const useSettingsBoardThemes = (): IBoard[] => {
  const userData = useUserDataStore('data');
  return useMemo(
    () =>
      settingsPopupBoards.filter(
        (board) =>
          board.countryId === null || userData?.country === board.countryId // disabling boards for a specific countries user not from
      ),
    [userData]
  );
};
