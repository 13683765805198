/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import {
  ISystemPopups,
  ITournamentPlayer,
  TournamentMode,
  TournamentStatus,
  TournamentType,
} from 'shared/types';
import { devConsole } from 'shared/helpers/_dev_console';

import { LobbyServerTags, SoundType, TournamentBoardType } from '@types';
import { eLobbyServerPayloadType, MessageType } from '../ws/_types';
import {
  ILobbyContextActions,
  ILobbyContextState,
} from '../_lobby_context.types';
import {
  mainStore,
  soundsActions,
  tournamentActions,
  userDataActions,
  useTournamentStore,
} from '@store/storeshed';
import { useNotificationsContext } from '@store/context/notifications/_context';
import { adaptChallengesQueue } from './adapters/adaptChallengesQueue';
import { filterMyself } from './_filterMyself';

export const applyEventsOnLobbyWs = (
  state: ILobbyContextState,
  actions: ILobbyContextActions,
  popups: ISystemPopups
) => {
  const ws = state.ws;
  const { alert } = popups;

  const {
    setActiveBoardsAmount,
    setTournamentYou,
    getTournamentRounds,
    updateTournamentRounds,
    getRoundBoards,
  } = tournamentActions;

  const tournamentYou = useTournamentStore('tournament_you');

  const { addNewNotification } = useNotificationsContext();

  const subscriptions = useMemo(
    () => ({
      subscribe: actions.subscribeOnTournamentGame,
      unsubscribe: actions.unsubscribeFromTournamentGame,
    }),
    [actions.subscribeOnTournamentGame, actions.unsubscribeFromTournamentGame]
  );

  const onMessage = useCallback(
    (message: MessageType) => {
      const { payload, payloadType } = message;

      switch (payloadType) {
        case eLobbyServerPayloadType.RSVD_PONG:
          if (payload?.ts) window['timeDiff'] = dayjs(payload.ts).diff(dayjs());

          break;

        case eLobbyServerPayloadType.RSVD_RSP_TAG_LIST:
          if ('playerUid' in payload.tag_value && payload.tag_value.playerUid) {
            actions.setPlayerUID(payload.tag_value.playerUid[0]);
          }
          break;
        case eLobbyServerPayloadType.GAME_STATE:
          // внутри проверка, есть ли доска в списке
          tournamentActions.updateNGTourBoardData(payload);

          const userUid = mainStore.authStore.get('uid');
          const isMyBoard =
            userUid === payload.cfg?.bPId || userUid === payload.cfg?.wPId;

          if (isMyBoard) actions.updateGameState({ gameState: payload });

          break;
        case eLobbyServerPayloadType.GAME_REQUEST_STATE:
          actions.updateGameRequestState(payload);
          break;

        case eLobbyServerPayloadType.GAME_ANALYSIS_READY: {
          const tagBoardId = payload?.boardUid;

          if (state.games[tagBoardId]) {
            actions.setAnalysis(true, tagBoardId);

            actions.wsLobbyServerUnsubscribeTag(
              LobbyServerTags.GAME_ANALYSIS,
              tagBoardId
            );
          }

          break;
        }
        case eLobbyServerPayloadType.NO_GAME_ANALYSIS: {
          const tagBoardId = payload?.boardUid;

          if (state.gamesData[tagBoardId]) {
            actions.setAnalysis(false, tagBoardId);

            actions.wsLobbyServerUnsubscribeTag(
              LobbyServerTags.GAME_ANALYSIS,
              tagBoardId
            );
          }

          break;
        }

        // обновление очеререди игроков
        case eLobbyServerPayloadType.GAME_REQUEST_QUEUE_UPDATED: {
          // actions.requestGamesInQueueData();
          break;
        }

        // дисквалификация юзера в турнире
        case eLobbyServerPayloadType.USER_DISQUALIFIED: {
          if (tournamentYou) {
            const youDisqualified: ITournamentPlayer = {
              ...tournamentYou,
              disqualified: true,
            };
            setTournamentYou(youDisqualified);
            alert(
              'Oops! You have been withdrawn from the Swiss system tournament because you missed two games in a row.'
            );
          }
          break;
        }

        // обновление количества активных досок в турнире
        case eLobbyServerPayloadType.TOURNAMENT_BOARDS_COUNT: {
          const tournamentId = payload?.tournament_id;
          const activeBoardsAmount = payload?.active_boards_count;

          const tournamentData =
            mainStore.tournamentStore.get('tournament_data');

          if (tournamentData?.id === tournamentId) {
            setActiveBoardsAmount(activeBoardsAmount);
          }

          break;
        }

        // раунд создался
        case eLobbyServerPayloadType.TOURNAMENT_ROUND_CREATED: {
          const tournamentId = payload.tournament_id;

          const tournamentData =
            mainStore.tournamentStore.get('tournament_data');
          const boardsType = mainStore.tournamentStore.get('boards_type');
          const isBroadcast = boardsType === TournamentBoardType.BROADCAST;

          if (tournamentData?.id === tournamentId) {
            if (!isBroadcast) {
              soundsActions.playSound(SoundType.start);
            }

            getTournamentRounds({
              tournamentId,
              ngRoundCreated: !isBroadcast,
              popups,
              tournamentType: boardsType,
              subscriptions,
            });
          }
          break;
        }

        // раунд начался
        case eLobbyServerPayloadType.TOURNAMENT_ROUND_STARTED: {
          const tournamentId = payload.tournament_id;

          const tournamentData =
            mainStore.tournamentStore.get('tournament_data');

          if (tournamentData?.id === tournamentId) {
            updateTournamentRounds(payload, subscriptions);
          }
          break;
        }

        // раунд закончился
        case eLobbyServerPayloadType.TOURNAMENT_ROUND_ENDED: {
          const tournamentId = payload.tournament_id;

          const tournamentData =
            mainStore.tournamentStore.get('tournament_data');

          if (tournamentData?.id === tournamentId) {
            updateTournamentRounds(payload);
          }
          break;
        }

        // обновление стейта турнира
        case eLobbyServerPayloadType.TOURNAMENT_STATE_UPDATE: {
          const tournamentId = payload.state.id;

          const tournamentData =
            mainStore.tournamentStore.get('tournament_data');

          if (tournamentData?.id === tournamentId) {
            if (payload.state.status === TournamentStatus.COMPLETED) {
              soundsActions.playSound(SoundType.tourend);
            }

            tournamentActions.updateTournamentState(payload.state);
          }

          break;
        }

        // обновление стендинга турнира
        case eLobbyServerPayloadType.TOURNAMENT_STANDING_UPDATED: {
          const tournamentId = payload.tournament_id;
          const tournamentData =
            mainStore.tournamentStore.get('tournament_data');
          const token = mainStore.authStore.get('token');
          const selected_round =
            mainStore.tournamentStore.get('selected_round');

          if (tournamentData?.id === tournamentId) {
            tournamentActions.updateTournamentStanding(tournamentData.id);

            if (
              tournamentData?.mode === TournamentMode.OFFLINE &&
              selected_round?.id
            ) {
              getRoundBoards({
                tournamentId,
                roundId: Number(selected_round?.id),
              });
            }

            if (tournamentData.team_play) {
              const currentGroup = mainStore.tournamentStore.get(
                'team_play_current_group'
              );
              const userPlayerId =
                mainStore.userDataStore.get('data')?.player.player_id;
              tournamentActions.updateTeamPlayData({
                currentGroup,
                tournamentId: tournamentData.id,
                userPlayerId,
              });
            }

            if (token && tournamentData?.user_signed) {
              tournamentActions.getTournamentYou(tournamentData.id);
            }
          }

          break;
        }

        // добавилась доска в арена турнире
        case eLobbyServerPayloadType.TOURNAMENT_BOARDS_UPDATED: {
          const tournamentId = payload.tournament_id;

          const tournamentData =
            mainStore.tournamentStore.get('tournament_data');

          if (
            tournamentData?.id === tournamentId &&
            tournamentData.kind === TournamentType.ARENA
          ) {
            tournamentActions.getRoundBoards({
              tournamentId,
              roundId: payload.tour_id,
            });
          }
          break;
        }

        // старт технологической паузы в турнире
        case eLobbyServerPayloadType.TOURNAMENT_ROUND_SCHEDULED: {
          const tournamentId = payload.tournament_id;

          const tournamentData =
            mainStore.tournamentStore.get('tournament_data');

          if (tournamentData?.id === tournamentId) {
            tournamentActions.setTournamentPause(payload.pause_end_datetime);
          }
          break;
        }

        case eLobbyServerPayloadType.BROADCAST_BOARD_STATE: {
          const boardId = payload.boardId;
          actions.updateBroadcastBoard(payload, boardId);
          break;
        }

        // пришло новое сообщение для пользователя
        case eLobbyServerPayloadType.USER_NOTIFICATION_NEW: {
          addNewNotification(payload);
          break;
        }

        // получен новый long челленж
        case eLobbyServerPayloadType.CHALLENGE_LONG_CREATED: {
          userDataActions.addLongChallenge(payload);
          break;
        }
        // полученный long челлендж отменён
        case eLobbyServerPayloadType.CHALLENGE_LONG_DELETED: {
          userDataActions.removeLongChallenge(payload);
          break;
        }
        // полученный long челлендж принят
        case eLobbyServerPayloadType.CHALLENGE_LONG_ACCEPTED: {
          userDataActions.removeLongChallenge(payload);
          break;
        }
        // полученный long челлендж отклонен
        case eLobbyServerPayloadType.CHALLENGE_LONG_DECLINED: {
          userDataActions.removeLongChallenge(payload);
          break;
        }
        // получен новый инвайн челендж
        case eLobbyServerPayloadType.CHALLENGE_INVITE_CREATED: {
          actions.setInviteChallengeData(payload);
          break;
        }
        // удален инвайт челендж
        case eLobbyServerPayloadType.CHALLENGE_INVITE_DELETED: {
          actions.setInviteChallengeData(null);
          break;
        }
        // челлендж принят
        case eLobbyServerPayloadType.CHALLENGE_ACCEPTED: {
          userDataActions.updateIncomingChallenges(payload.incoming || 0);
          userDataActions.updateOutcomingChallenges(payload.outcoming || 0);

          if (!payload.incoming && !payload.outcoming) {
            userDataActions.getChallengesList();
          }
          break;
        }
        // отправленный челлендж отклонён
        case eLobbyServerPayloadType.CHALLENGE_DECLINED: {
          userDataActions.updateOutcomingChallenges(payload.outcoming || 0);
          if (!payload.incoming && !payload.outcoming) {
            userDataActions.getChallengesList();
          }
          break;
        }
        // челлендж отменён сервером по истечению времени???
        case eLobbyServerPayloadType.CHALLENGE_TIMEOUT: {
          userDataActions.updateIncomingChallenges(payload.incoming || 0);
          userDataActions.updateOutcomingChallenges(payload.outcoming || 0);
          break;
        }

        // new api
        case eLobbyServerPayloadType.CHALLENGE_SHORT_DELETED: {
          actions.removeShortChallengeData(payload.id || 0);
          break;
        }
        case eLobbyServerPayloadType.CHALLENGE_SHORT_MATCHING_STOPPED: {
          actions.setRequestExpired(true);
          break;
        }
        case eLobbyServerPayloadType.CHALLENGE_SHORT_UPDATED: {
          const adaptedChallenges = adaptChallengesQueue(payload);
          const gamesWithoutMe = filterMyself(adaptedChallenges, state.uid);

          actions.setGamesInQueueData(gamesWithoutMe);

          break;
        }

        default:
          break;
      }
    },
    [
      actions.setPlayerUID,
      actions.updateGameRequestState,
      actions.updateGameState,
      state.shortChallenges,
      state.gamesData,
      addNewNotification,
      subscriptions,
    ]
  );

  const onClose = useCallback(() => {
    actions.wsLobbyServerResetTags();
  }, [actions.wsLobbyServerResetTags]);

  useEffect(() => {
    ws.onMessage = onMessage;
  }, [onMessage]);

  useEffect(() => {
    ws.onStatusChange = (status) => {
      devConsole.log('Status: ' + status);
      actions.setWsStatus(status);
    };
  }, [ws]);

  useEffect(() => {
    ws.onClose = onClose;
  }, [ws, onClose]);
};
