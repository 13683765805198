import {
  eLobbyContextActionType,
  ILobbyContextState,
  ILobbyContextAction,
} from './_lobby_context.types';
import { persistTurns } from './helpers/_persistTurns';

export const lobbyReducer = (
  state: ILobbyContextState,
  { type, payload }: ILobbyContextAction
): ILobbyContextState => {
  switch (type) {
    case eLobbyContextActionType.SET_WS_STATUS:
      return {
        ...state,
        wsStatus: payload,
      };
    case eLobbyContextActionType.SET_GAME_REQUEST_STATE:
      return {
        ...state,
        game_request: payload,
      };
    case eLobbyContextActionType.SET_PINGS_WITHOUT_ANSWER:
      return {
        ...state,
        pingsWithoutAnswer: payload,
      };
    case eLobbyContextActionType.SET_SHORT_CHALLENGES:
      return {
        ...state,
        shortChallenges: payload,
      };
    case eLobbyContextActionType.ADD_SHORT_CHALLENGE_DATA:
      return {
        ...state,
        shortChallenges: [...state.shortChallenges, payload],
      };
    case eLobbyContextActionType.REMOVE_SHORT_CHALLENGE_DATA:
      const challenges = state.shortChallenges.filter(
        (item) => item.id !== payload
      );
      return {
        ...state,
        shortChallenges: [...challenges],
      };
    case eLobbyContextActionType.ADD_SHORT_CHALLENGE_DATA_REQUEST:
      return {
        ...state,
        shortChallengeRequests: [...state.shortChallengeRequests, payload],
      };
    case eLobbyContextActionType.REMOVE_SHORT_CHALLENGE_DATA_REQUEST:
      const challengeRequests = state.shortChallengeRequests.filter(
        (item) =>
          item.ratingType !== payload.ratingType &&
          item.timeControlId !== payload.timeControlId
      );
      return {
        ...state,
        shortChallengeRequests: [...challengeRequests],
      };
    case eLobbyContextActionType.ADD_CANCEL_SHORT_CHALLENGE_REQUEST:
      return {
        ...state,
        cancelShortChallengeRequests: [
          ...state.cancelShortChallengeRequests,
          payload,
        ],
      };
    case eLobbyContextActionType.REMOVE_CANCEL_SHORT_CHALLENGE_REQUEST:
      const cancelChallengeRequests = state.cancelShortChallengeRequests.filter(
        (item) => item !== payload
      );
      return {
        ...state,
        cancelShortChallengeRequests: [...cancelChallengeRequests],
      };

    case eLobbyContextActionType.SET_INVITE_CHALLENGE_DATA:
      return {
        ...state,
        inviteChallenge: payload,
      };
    case eLobbyContextActionType.SET_INVITE_CHALLENGE_DATA_REQUEST:
      return {
        ...state,
        inviteChallengeRequest: payload,
      };
    case eLobbyContextActionType.SET_CANCEL_INVITE_CHALLENGE_DATA_REQUEST:
      return {
        ...state,
        cancelInviteChallengeRequest: payload,
      };
    case eLobbyContextActionType.SET_CLEAR_SHORT_CHALLENGES_REQUEST:
      return {
        ...state,
        clearShortChallengesRequest: payload,
      };
    case eLobbyContextActionType.SET_SHORT_CHALLENGES_REQUEST:
      return {
        ...state,
        shortChallengesRequest: payload,
      };
    case eLobbyContextActionType.SET_INVITE_DATA:
      return {
        ...state,
        inviteData: payload,
      };
    case eLobbyContextActionType.SET_INVITE_DATA_REQUEST:
      return {
        ...state,
        inviteDataRequest: payload,
      };
    case eLobbyContextActionType.SET_REQUEST_EXPIRED:
      return {
        ...state,
        requestExpired: payload,
      };
    case eLobbyContextActionType.CANCEL_INVITE_REQUEST:
      return {
        ...state,
        cancelInviteRequest: payload,
      };
    case eLobbyContextActionType.SET_SELECTED_INVITE:
      return {
        ...state,
        selectedInvite: payload,
      };
    case eLobbyContextActionType.SET_INVITE_FRIEND_NAME:
      return {
        ...state,
        inviteFriendName: payload,
      };

    case eLobbyContextActionType.RESET_GAMES: {
      return {
        ...state,
        games: {},
        gamesData: {},
      };
    }

    case eLobbyContextActionType.RESET_GAME: {
      const boardId = payload;

      const newGames = { ...state.games };
      delete newGames[boardId];

      const newGamesData = { ...state.gamesData };
      delete newGamesData[boardId];

      return {
        ...state,
        games: newGames,
        gamesData: newGamesData,
      };
    }

    case eLobbyContextActionType.UPDATE_GAME: {
      const { gId, gameState } = payload;

      const turns = persistTurns(state, payload);

      return {
        ...state,
        games: {
          ...state.games,
          [gId]: {
            ...state.games[gId],
            state: {
              ...state.games[gId]?.state,
              ...gameState,
              playingPh: {
                ...gameState.playingPh,
                turns,
              },
            },
          },
        } as ILobbyContextState['games'],
      };
    }

    case eLobbyContextActionType.UPDATE_GAME_DATA_BY_ID:
      const { gameId, lobbyGame } = payload;

      const gameStateInStore = state?.games[gameId]?.state;
      const chessgunInStore = state?.games[gameId]?.chessgun;
      const wsInStore = state?.games[gameId]?.ws;

      const newGames = {
        [gameId]: lobbyGame,
      };

      if (gameStateInStore && !lobbyGame.state) {
        lobbyGame.state = gameStateInStore;
      }

      const cgFromStoreHasMoreMoves =
        chessgunInStore?.get('history').length >
        lobbyGame.chessgun?.get('history').length;

      if (gameStateInStore && chessgunInStore && cgFromStoreHasMoreMoves) {
        lobbyGame.chessgun = chessgunInStore;
      }

      if (gameStateInStore && wsInStore) {
        lobbyGame.ws = wsInStore;
      }

      return {
        ...state,
        games: { ...state.games, ...newGames },
      };

    case eLobbyContextActionType.UPDATE_GAMES:
      return {
        ...state,
        games: { ...state.games, ...payload },
      };

    case eLobbyContextActionType.SET_BOARD_DATA: {
      const { gId, data } = payload;

      return {
        ...state,
        gamesData: {
          ...state.gamesData,
          [gId]: {
            ...state.gamesData[gId],
            boardData: data,
          },
        },
      };
    }
    case eLobbyContextActionType.SET_BOARD_DATA_REQUEST: {
      const { gId, inRequest } = payload;

      return {
        ...state,
        gamesData: {
          ...state.gamesData,
          [gId]: {
            ...state.gamesData[gId],
            boardDataRequest: inRequest,
          },
        },
      };
    }
    case eLobbyContextActionType.SET_ANALYSIS: {
      const { gId, analysis } = payload;

      return {
        ...state,
        gamesData: {
          ...state.gamesData,
          [gId]: {
            ...state.gamesData[gId],
            analysis: analysis,
          },
        },
      };
    }

    case eLobbyContextActionType.SET_UID:
      return {
        ...state,
        uid: payload,
      };
    case eLobbyContextActionType.SET_PLAYER_UID:
      localStorage.setItem('playerUid', payload as string);

      return {
        ...state,
        playerUid: payload,
      };

    case eLobbyContextActionType.WS_LOBBY_SERVER_SUBSCRIBE_TAG: {
      const newTags = [...state.subscribedTags];
      newTags.push({
        name: payload.name,
        pages: payload.pages,
        id: payload.id,
      });

      return {
        ...state,
        subscribedTags: newTags,
      };
    }
    case eLobbyContextActionType.WS_LOBBY_SERVER_UNSUBSCRIBE_TAG: {
      const filteredTags = state.subscribedTags.filter(
        (tag) => tag.name !== payload.name && tag.id !== payload.id
      );

      return {
        ...state,
        subscribedTags: filteredTags,
      };
    }
    case eLobbyContextActionType.WS_LOBBY_SERVER_RESET_TAGS: {
      return {
        ...state,
        subscribedTags: [],
      };
    }

    case eLobbyContextActionType.SET_GAMES_IN_QUEUE_DATA: {
      return {
        ...state,
        gamesInQueue: payload,
      };
    }

    default:
      return state;
  }
};
