export enum LobbyServerActions {
  RSVD_PING = 'RSVD_PING',
  RSVD_REQ_TAG_EDIT = 'RSVD_REQ_TAG_EDIT',
  GAME_REQUEST_QUEUE_UPDATED = 'GAME_REQUEST_QUEUE_UPDATED',
}

export enum LobbyServerTags {
  GAME_REQUEST_QUEUE_UPDATED = 'game_request_queue_updated',
  GAME_ANALYSIS = 'game_analysis',
  TOURNAMENT = 'tntId',
  TOURNAMENT_OVER = 'tntIdOver',
  BOARD = 'boardUid',
  BOARD_ID = 'boardId',
  MULTIBOARD = 'multiboard',
  CHALLENGE_SHORT_UPDATED = 'challanges_short_updated',
}
