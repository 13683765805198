import { createGlobalStyle } from "styled-components";
import { eChessboardTheme } from "../ui/_settings.types";
import { aquaBoard } from "./_aqua";
import { armaBoard } from "./_arma";
import { bubblegumBoard } from "./_bubblegum";
import { curryBoard } from "./_curry";
import { hipsterBoard } from "./_hipster";
import { lemonTreeBoard } from "./_lemonTree";
import { marbleBoard } from "./_marble";
import { philippinesBoard } from "./_philippines";
import { standardBoard } from "./_standard";
import { textbookBoard } from "./_textbook";
import { worldchessBoard } from "./_worldchess";
import { getBoardsStyles } from "./getBoardStyles";
export * from "./types";
export const boards = [
    { key: eChessboardTheme.WORLDCHESS, board: worldchessBoard },
    { key: eChessboardTheme.TEXTBOOK, board: textbookBoard },
    { key: eChessboardTheme.STANDARD, board: standardBoard },
    { key: eChessboardTheme.ARMA, board: armaBoard },
    { key: eChessboardTheme.AQUA, board: aquaBoard },
    { key: eChessboardTheme.CURRY, board: curryBoard },
    { key: eChessboardTheme.MARBLE, board: marbleBoard },
    { key: eChessboardTheme.HIPSTER, board: hipsterBoard },
    { key: eChessboardTheme.BUBBLEGUM, board: bubblegumBoard },
    { key: eChessboardTheme.LEMON_TREE, board: lemonTreeBoard },
    { key: eChessboardTheme.PHILIPPINES, board: philippinesBoard },
];
export const boardStyles = getBoardsStyles();
export const $BoardStyles = createGlobalStyle `${boardStyles}`;
