import styled from 'styled-components';

const getCellOffsetInPixels = (cells: number, cellSize: number) =>
  cells * cellSize + 'px';

export const $BaseCell = styled.div<{
  size: number;
  topOffset: number;
  leftOffset: number;
}>`
  position: absolute;
  width: ${({ size }) => size + 'px'};
  height: ${({ size }) => size + 'px'};
  transition: background-image 555ms ease-out;

  top: ${({ topOffset, size }) => getCellOffsetInPixels(topOffset, size)};
  left: ${({ leftOffset, size }) => getCellOffsetInPixels(leftOffset, size)};
`;

export const $Piece = styled($BaseCell)``;

export const $LastMove = styled($BaseCell)``;

export const $HighlightedSquare = styled($BaseCell)``;

export const $Premove = styled($BaseCell)``;

export const $Dot = styled($BaseCell)``;

export const Arrow: React.FC<{ size: number }> = ({ size }) => (
  <svg
    style={{
      position: 'absolute',
      top: size / 2 + 'px',
      left: size / 2 + 'px',
    }}
    className="arrows"
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="62"
    viewBox="0 0 33 62"
    fill="none"
  >
    <path
      className="last-move b"
      style={{
        transition: '555ms ease-out',
      }}
      d="M31.355 61.2364C31.2704 61.4902 30.9961 61.6274 30.7423 61.5428L26.6067 60.1642C26.3529 60.0796 26.2157 59.8053 26.3003 59.5515C26.3849 59.2977 26.6592 59.1606 26.913 59.2452L30.5892 60.4706L31.8145 56.7944C31.8991 56.5406 32.1735 56.4035 32.4272 56.4881C32.681 56.5727 32.8182 56.847 32.7336 57.1008L31.355 61.2364ZM0.974583 0.158301L31.3287 60.8666L30.4623 61.2999L0.108105 0.591536L0.974583 0.158301Z"
    />
  </svg>
);
