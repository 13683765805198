import { outOfBoard } from "../stringBoard/_outOfBoard";
import { PIECE_OFFSETS } from "../_constants";
/**
 * Возвращает возможные ходы фигуры(кроме пешки, не учитывает атаки,
 * не валидирует ходы на наличие шаха после него)
 * @param {number} index - индекс фигуры на доске
 * @param {string[]} board - доска
 * @returns {number[]} possibleMoves
 */
export const getPiecePossibleMoves = ({ index, board, }) => {
    const square = board[index].toLowerCase();
    const possibleMoves = [];
    const offsets = PIECE_OFFSETS[square];
    if (["q", "b", "r"].includes(square)) {
        offsets.forEach((offset) => {
            let newPos = index;
            let canMove = true;
            while (canMove) {
                newPos += offset;
                if (outOfBoard(newPos)) {
                    canMove = false;
                    continue;
                }
                const newSquare = board[newPos];
                if (newSquare === "") {
                    possibleMoves.push(newPos);
                }
                else {
                    canMove = false;
                }
            }
        });
        return possibleMoves;
    }
    offsets.forEach((offset) => {
        const newPos = index + offset;
        if (outOfBoard(newPos))
            return;
        if (board[newPos] === "") {
            possibleMoves.push(newPos);
        }
    });
    return possibleMoves;
};
