import { Store, useStore, useStoreComputed } from 'storeshed';
import { ITournamentState, TournamentBoardType } from '@types';
import { eTeamsWithPlayersGroups } from '@components/molecules/tournament/_teamGroupedResult';

export const initTournamentState: ITournamentState = {
  tournament_data: null,
  tournament_data_request: false,
  participation_request: false,
  tournament_players_data: null,
  tournament_players_data_request: false,
  tournament_update_players_data_request: false,
  tournament_update_players_data_loading: false,
  tournament_you: null,
  tournament_you_request: false,
  tournament_you_loading: true,
  loaded_players_count: 0,

  tournament_rounds: [],
  tournament_rounds_request: false,
  selected_round: null,

  tournament_winners: [],
  tournament_winners_request: false,

  round_boards: [],
  round_boards_game_server_subscriptions: new Set(),
  round_boards_multiboard_subscribed_games: new Set(),
  round_boards_amount: 0,
  active_boards_amount: 0,
  round_boards_request: false,
  boards_type: TournamentBoardType.NONE,
  round_boards_status: null,
  round_boards_page_number: 1,
  round_boards_page_limit: 8,
  is_round_boards_ready: false,
  join_limit: null,

  tournament_certificate_pdf: '',
  tournament_certificate_pdf_request: false,
  tournament_certificate_image: '',
  tournament_certificate_image_request: false,

  tournament_sponsors: [],
  tournament_sponsors_request: false,

  tournament_streams: [],
  tournament_streams_request: false,

  tournament_pgn: null,
  tournament_pgn_request: false,

  ready_for_arena: false,
  ready_for_arena_request: false,

  stages_tabs: null,
  current_stage: null,
  current_stage_players: null,
  current_stage_players_request: false,

  team_play_data_request: false,
  team_play_players: null,
  team_play_teams: null,
  team_play_update_request: false,
  team_play_current_group: eTeamsWithPlayersGroups.TEAMS,

  tagged_news: null,
  tagged_news_request: false,

  standing_page_number: 1,
  standing_page_limit: 10,

  standing_selected_game: null,
};

export const tournamentStore = new Store<ITournamentState>({
  ...initTournamentState,
});

type TUseTournamentStore = {
  <K extends keyof ITournamentState>(key: K): ITournamentState[K];
};
type TUseTournamentComputed = {
  <K extends keyof ITournamentState, R>(
    key: K,
    cb: (state: ITournamentState) => R
  ): R;
};

export const useTournamentStore: TUseTournamentStore = (key) =>
  useStore(tournamentStore, key);
export const useTournamentComputed: TUseTournamentComputed = (key, cb) =>
  useStoreComputed(tournamentStore, key, cb);
