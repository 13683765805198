export const arenaTimeControls = [
    {
        id: 16,
        name: 'Blitz 3+2',
        board_type: 3,
        board_type_name: 'blitz',
        start_time: '00:03:00',
        black_start_time: '00:03:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:02',
        increment_start_move: 1,
        white_start_time: '00:03:00',
    },
    {
        id: 11,
        name: 'Blitz 3 min',
        board_type: 3,
        board_type_name: 'blitz',
        start_time: '00:03:00',
        black_start_time: '00:03:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:03:00',
    },
    {
        id: 17,
        name: 'Blitz 5+3',
        board_type: 3,
        board_type_name: 'blitz',
        start_time: '00:05:00',
        black_start_time: '00:05:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:03',
        increment_start_move: 1,
        white_start_time: '00:05:00',
    },
    {
        id: 12,
        name: 'Blitz 5 min',
        board_type: 3,
        board_type_name: 'blitz',
        start_time: '00:05:00',
        black_start_time: '00:05:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:05:00',
    },
    {
        id: 14,
        name: 'Bullet 1+1',
        board_type: 5,
        board_type_name: 'bullet',
        start_time: '00:01:00',
        black_start_time: '00:01:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:01',
        increment_start_move: 1,
        white_start_time: '00:01:00',
    },
    {
        id: 15,
        name: 'Bullet 1+2',
        board_type: 5,
        board_type_name: 'bullet',
        start_time: '00:01:00',
        black_start_time: '00:01:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:02',
        increment_start_move: 1,
        white_start_time: '00:01:00',
    },
    {
        id: 9,
        name: 'Bullet 1 min',
        board_type: 5,
        board_type_name: 'bullet',
        start_time: '00:01:00',
        black_start_time: '00:01:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:01:00',
    },
    {
        id: 10,
        name: 'Bullet 2 min',
        board_type: 5,
        board_type_name: 'bullet',
        start_time: '00:02:00',
        black_start_time: '00:02:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:02:00',
    },
    {
        id: 18,
        name: 'Rapid 15 min',
        board_type: 2,
        board_type_name: 'rapid',
        start_time: '00:15:00',
        black_start_time: '00:15:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:15:00',
    },
    {
        id: 13,
        name: 'Rapid 25 min',
        board_type: 2,
        board_type_name: 'rapid',
        start_time: '00:25:00',
        black_start_time: '00:25:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:25:00',
    },
    {
        id: 34,
        name: 'Blitz 10 min',
        board_type: 3,
        board_type_name: 'blitz',
        start_time: '00:10:00',
        black_start_time: '00:10:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:10:00',
    },
    {
        id: 35,
        name: 'Blitz 3+3',
        board_type: 3,
        board_type_name: 'blitz',
        start_time: '00:03:00',
        black_start_time: '00:03:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:03',
        increment_start_move: 1,
        white_start_time: '00:03:00',
    },
    {
        id: 36,
        name: 'Rapid 45 min',
        board_type: 2,
        board_type_name: 'rapid',
        start_time: '00:45:00',
        black_start_time: '00:45:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:45:00',
    },
    {
        id: 37,
        name: 'Rapid 10+10',
        board_type: 2,
        board_type_name: 'rapid',
        start_time: '00:10:00',
        black_start_time: '00:10:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:10',
        increment_start_move: 1,
        white_start_time: '00:10:00',
    },
    {
        id: 38,
        name: 'Rapid 15+10',
        board_type: 2,
        board_type_name: 'rapid',
        start_time: '00:15:00',
        black_start_time: '00:15:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:10',
        increment_start_move: 1,
        white_start_time: '00:15:00',
    },
    {
        id: 39,
        name: 'Rapid 25+10',
        board_type: 2,
        board_type_name: 'rapid',
        start_time: '00:25:00',
        black_start_time: '00:25:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:10',
        increment_start_move: 1,
        white_start_time: '00:25:00',
    },
    {
        id: 40,
        name: 'Rapid 45+10',
        board_type: 2,
        board_type_name: 'rapid',
        start_time: '00:45:00',
        black_start_time: '00:45:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:10',
        increment_start_move: 1,
        white_start_time: '00:45:00',
    },
    {
        id: 43,
        name: 'Bullet 2+1',
        board_type: 5,
        board_type_name: 'bullet',
        start_time: '00:02:00',
        black_start_time: '00:02:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:01',
        increment_start_move: 1,
        white_start_time: '00:02:00',
    },
    {
        id: 53,
        name: 'Blitz 3+1',
        board_type: 3,
        board_type_name: 'blitz',
        start_time: '00:03:00',
        black_start_time: '00:03:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:01',
        increment_start_move: 1,
        white_start_time: '00:03:00',
    },
];
export const loadtestTimeControls = [
    {
        id: 7,
        name: 'Blitz 10 min',
        board_type: 3,
        board_type_name: 'blitz',
        start_time: '00:10:00',
        black_start_time: '00:10:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:10:00',
    },
    {
        id: 14,
        name: 'Blitz 3+2',
        board_type: 3,
        board_type_name: 'blitz',
        start_time: '00:03:00',
        black_start_time: '00:03:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:02',
        increment_start_move: 1,
        white_start_time: '00:03:00',
    },
    {
        id: 9,
        name: 'Blitz 3 min',
        board_type: 3,
        board_type_name: 'blitz',
        start_time: '00:03:00',
        black_start_time: '00:03:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:03:00',
    },
    {
        id: 15,
        name: 'Blitz 5+3',
        board_type: 3,
        board_type_name: 'blitz',
        start_time: '00:05:00',
        black_start_time: '00:05:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:03',
        increment_start_move: 1,
        white_start_time: '00:05:00',
    },
    {
        id: 10,
        name: 'Blitz 5 min',
        board_type: 3,
        board_type_name: 'blitz',
        start_time: '00:05:00',
        black_start_time: '00:05:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:05:00',
    },
    {
        id: 12,
        name: 'Bullet 1+1',
        board_type: 5,
        board_type_name: 'bullet',
        start_time: '00:01:00',
        black_start_time: '00:01:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:01',
        increment_start_move: 1,
        white_start_time: '00:01:00',
    },
    {
        id: 13,
        name: 'Bullet 1+2',
        board_type: 5,
        board_type_name: 'bullet',
        start_time: '00:01:00',
        black_start_time: '00:01:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:02',
        increment_start_move: 1,
        white_start_time: '00:01:00',
    },
    {
        id: 8,
        name: 'Bullet 2 min',
        board_type: 5,
        board_type_name: 'bullet',
        start_time: '00:02:00',
        black_start_time: '00:02:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:02:00',
    },
    {
        id: 16,
        name: 'Rapid 15+10',
        board_type: 2,
        board_type_name: 'rapid',
        start_time: '00:15:00',
        black_start_time: '00:15:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:10',
        increment_start_move: 1,
        white_start_time: '00:15:00',
    },
    {
        id: 87,
        name: 'Rapid 25+10',
        board_type: 2,
        board_type_name: 'rapid',
        start_time: '00:25:00',
        black_start_time: '00:25:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:10',
        increment_start_move: 1,
        white_start_time: '00:25:00',
    },
    {
        id: 88,
        name: 'Rapid 45+10',
        board_type: 2,
        board_type_name: 'rapid',
        start_time: '00:45:00',
        black_start_time: '00:45:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:10',
        increment_start_move: 1,
        white_start_time: '00:45:00',
    },
    {
        id: 89,
        name: 'Rapid 45 min',
        board_type: 2,
        board_type_name: 'rapid',
        start_time: '00:45:00',
        black_start_time: '00:45:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:45:00',
    },
    {
        id: 90,
        name: 'Rapid 25 min',
        board_type: 2,
        board_type_name: 'rapid',
        start_time: '00:25:00',
        black_start_time: '00:25:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:25:00',
    },
    {
        id: 91,
        name: 'Blitz 10 min',
        board_type: 3,
        board_type_name: 'blitz',
        start_time: '00:10:00',
        black_start_time: '00:10:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:10:00',
    },
    {
        id: 92,
        name: 'Rapid 10+10',
        board_type: 2,
        board_type_name: 'rapid',
        start_time: '00:10:00',
        black_start_time: '00:10:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:10',
        increment_start_move: 1,
        white_start_time: '00:10:00',
    },
    {
        id: 93,
        name: 'Rapid 15 min',
        board_type: 2,
        board_type_name: 'rapid',
        start_time: '00:15:00',
        black_start_time: '00:15:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:15:00',
    },
    {
        id: 99,
        name: 'Blitz 3+3',
        board_type: 3,
        board_type_name: 'blitz',
        start_time: '00:03:00',
        black_start_time: '00:03:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:03',
        increment_start_move: 1,
        white_start_time: '00:03:00',
    },
    {
        id: 100,
        name: 'Bullet 1 min',
        board_type: 5,
        board_type_name: 'bullet',
        start_time: '00:01:00',
        black_start_time: '00:01:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:00',
        increment_start_move: 0,
        white_start_time: '00:01:00',
    },
    {
        id: 101,
        name: 'Bullet 2+1',
        board_type: 5,
        board_type_name: 'bullet',
        start_time: '00:02:00',
        black_start_time: '00:02:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:01',
        increment_start_move: 1,
        white_start_time: '00:02:00',
    },
    {
        id: 114,
        name: 'Blitz 3+1',
        board_type: 3,
        board_type_name: 'blitz',
        start_time: '00:03:00',
        black_start_time: '00:03:00',
        additional_time: '00:00:00',
        additional_time_move: 0,
        increment: '00:00:01',
        increment_start_move: 1,
        white_start_time: '00:03:00',
    },
];
export const loadtestGroupedTimeControls = [
    {
        board_type: 2,
        name: 'rapid',
        controls: [
            {
                id: 93,
                name: 'Rapid 15 min',
                board_type: 2,
                board_type_name: 'rapid',
                start_time: '00:15:00',
                black_start_time: '00:15:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:00',
                increment_start_move: 0,
                increment_number: 0,
                start_time_number: 15,
                white_start_time: '00:15:00',
            },
            {
                id: 90,
                name: 'Rapid 25 min',
                board_type: 2,
                board_type_name: 'rapid',
                start_time: '00:25:00',
                black_start_time: '00:25:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:00',
                increment_start_move: 0,
                increment_number: 0,
                start_time_number: 25,
                white_start_time: '00:25:00',
            },
            {
                id: 89,
                name: 'Rapid 45 min',
                board_type: 2,
                board_type_name: 'rapid',
                start_time: '00:45:00',
                black_start_time: '00:45:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:00',
                increment_start_move: 0,
                increment_number: 0,
                start_time_number: 45,
                white_start_time: '00:45:00',
            },
            {
                id: 92,
                name: 'Rapid 10+10',
                board_type: 2,
                board_type_name: 'rapid',
                start_time: '00:10:00',
                black_start_time: '00:10:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:10',
                increment_start_move: 1,
                increment_number: 10,
                start_time_number: 10,
                white_start_time: '00:10:00',
            },
            {
                id: 16,
                name: 'Rapid 15+10',
                board_type: 2,
                board_type_name: 'rapid',
                start_time: '00:15:00',
                black_start_time: '00:15:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:10',
                increment_start_move: 1,
                increment_number: 10,
                start_time_number: 15,
                white_start_time: '00:15:00',
            },
            {
                id: 87,
                name: 'Rapid 25+10',
                board_type: 2,
                board_type_name: 'rapid',
                start_time: '00:25:00',
                black_start_time: '00:25:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:10',
                increment_start_move: 1,
                increment_number: 10,
                start_time_number: 25,
                white_start_time: '00:25:00',
            },
            {
                id: 88,
                name: 'Rapid 45+10',
                board_type: 2,
                board_type_name: 'rapid',
                start_time: '00:45:00',
                black_start_time: '00:45:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:10',
                increment_start_move: 1,
                increment_number: 10,
                start_time_number: 45,
                white_start_time: '00:45:00',
            },
        ],
    },
    {
        board_type: 3,
        name: 'blitz',
        controls: [
            {
                id: 9,
                name: 'Blitz 3 min',
                board_type: 3,
                board_type_name: 'blitz',
                start_time: '00:03:00',
                black_start_time: '00:03:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:00',
                increment_start_move: 0,
                increment_number: 0,
                start_time_number: 3,
                white_start_time: '00:03:00',
            },
            {
                id: 10,
                name: 'Blitz 5 min',
                board_type: 3,
                board_type_name: 'blitz',
                start_time: '00:05:00',
                black_start_time: '00:05:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:00',
                increment_start_move: 0,
                increment_number: 0,
                start_time_number: 5,
                white_start_time: '00:05:00',
            },
            {
                id: 7,
                name: 'Blitz 10 min',
                board_type: 3,
                board_type_name: 'blitz',
                start_time: '00:10:00',
                black_start_time: '00:10:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:00',
                increment_start_move: 0,
                increment_number: 0,
                start_time_number: 10,
                white_start_time: '00:10:00',
            },
            {
                id: 14,
                name: 'Blitz 3+2',
                board_type: 3,
                board_type_name: 'blitz',
                start_time: '00:03:00',
                black_start_time: '00:03:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:02',
                increment_start_move: 1,
                increment_number: 2,
                start_time_number: 3,
                white_start_time: '00:03:00',
            },
            {
                id: 99,
                name: 'Blitz 3+3',
                board_type: 3,
                board_type_name: 'blitz',
                start_time: '00:03:00',
                black_start_time: '00:03:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:03',
                increment_start_move: 1,
                increment_number: 3,
                start_time_number: 3,
                white_start_time: '00:03:00',
            },
            {
                id: 15,
                name: 'Blitz 5+3',
                board_type: 3,
                board_type_name: 'blitz',
                start_time: '00:05:00',
                black_start_time: '00:05:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:03',
                increment_start_move: 1,
                increment_number: 3,
                start_time_number: 5,
                white_start_time: '00:05:00',
            },
        ],
    },
    {
        board_type: 5,
        name: 'bullet',
        controls: [
            {
                id: 100,
                name: 'Bullet 1 min',
                board_type: 5,
                board_type_name: 'bullet',
                start_time: '00:01:00',
                black_start_time: '00:01:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:00',
                increment_start_move: 0,
                increment_number: 0,
                start_time_number: 1,
                white_start_time: '00:01:00',
            },
            {
                id: 8,
                name: 'Bullet 2 min',
                board_type: 5,
                board_type_name: 'bullet',
                start_time: '00:02:00',
                black_start_time: '00:02:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:00',
                increment_start_move: 0,
                increment_number: 0,
                start_time_number: 2,
                white_start_time: '00:02:00',
            },
            {
                id: 12,
                name: 'Bullet 1+1',
                board_type: 5,
                board_type_name: 'bullet',
                start_time: '00:01:00',
                black_start_time: '00:01:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:01',
                increment_start_move: 1,
                increment_number: 1,
                start_time_number: 1,
                white_start_time: '00:01:00',
            },
            {
                id: 13,
                name: 'Bullet 1+2',
                board_type: 5,
                board_type_name: 'bullet',
                start_time: '00:01:00',
                black_start_time: '00:01:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:02',
                increment_start_move: 1,
                increment_number: 2,
                start_time_number: 1,
                white_start_time: '00:01:00',
            },
            {
                id: 101,
                name: 'Bullet 2+1',
                board_type: 5,
                board_type_name: 'bullet',
                start_time: '00:02:00',
                black_start_time: '00:02:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:01',
                increment_start_move: 1,
                increment_number: 1,
                start_time_number: 2,
                white_start_time: '00:02:00',
            },
        ],
    },
];
export const prodGroupedTimeControls = [
    {
        board_type: 2,
        name: 'rapid',
        controls: [
            {
                id: 18,
                name: 'Rapid 15 min',
                board_type: 2,
                board_type_name: 'rapid',
                start_time: '00:15:00',
                black_start_time: '00:15:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:00',
                increment_start_move: 0,
                increment_number: 0,
                start_time_number: 15,
                white_start_time: '00:15:00',
            },
            {
                id: 13,
                name: 'Rapid 25 min',
                board_type: 2,
                board_type_name: 'rapid',
                start_time: '00:25:00',
                black_start_time: '00:25:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:00',
                increment_start_move: 0,
                increment_number: 0,
                start_time_number: 25,
                white_start_time: '00:25:00',
            },
            {
                id: 36,
                name: 'Rapid 45 min',
                board_type: 2,
                board_type_name: 'rapid',
                start_time: '00:45:00',
                black_start_time: '00:45:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:00',
                increment_start_move: 0,
                increment_number: 0,
                start_time_number: 45,
                white_start_time: '00:45:00',
            },
            {
                id: 37,
                name: 'Rapid 10+10',
                board_type: 2,
                board_type_name: 'rapid',
                start_time: '00:10:00',
                black_start_time: '00:10:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:10',
                increment_start_move: 1,
                increment_number: 10,
                start_time_number: 10,
                white_start_time: '00:10:00',
            },
            {
                id: 38,
                name: 'Rapid 15+10',
                board_type: 2,
                board_type_name: 'rapid',
                start_time: '00:15:00',
                black_start_time: '00:15:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:10',
                increment_start_move: 1,
                increment_number: 10,
                start_time_number: 15,
                white_start_time: '00:15:00',
            },
            {
                id: 39,
                name: 'Rapid 25+10',
                board_type: 2,
                board_type_name: 'rapid',
                start_time: '00:25:00',
                black_start_time: '00:25:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:10',
                increment_start_move: 1,
                increment_number: 10,
                start_time_number: 25,
                white_start_time: '00:25:00',
            },
            {
                id: 40,
                name: 'Rapid 45+10',
                board_type: 2,
                board_type_name: 'rapid',
                start_time: '00:45:00',
                black_start_time: '00:45:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:10',
                increment_start_move: 1,
                increment_number: 10,
                start_time_number: 45,
                white_start_time: '00:45:00',
            },
        ],
    },
    {
        board_type: 3,
        name: 'blitz',
        controls: [
            {
                id: 11,
                name: 'Blitz 3 min',
                board_type: 3,
                board_type_name: 'blitz',
                start_time: '00:03:00',
                black_start_time: '00:03:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:00',
                increment_start_move: 0,
                increment_number: 0,
                start_time_number: 3,
                white_start_time: '00:03:00',
            },
            {
                id: 12,
                name: 'Blitz 5 min',
                board_type: 3,
                board_type_name: 'blitz',
                start_time: '00:05:00',
                black_start_time: '00:05:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:00',
                increment_start_move: 0,
                increment_number: 0,
                start_time_number: 5,
                white_start_time: '00:05:00',
            },
            {
                id: 34,
                name: 'Blitz 10 min',
                board_type: 3,
                board_type_name: 'blitz',
                start_time: '00:10:00',
                black_start_time: '00:10:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:00',
                increment_start_move: 0,
                increment_number: 0,
                start_time_number: 10,
                white_start_time: '00:10:00',
            },
            {
                id: 16,
                name: 'Blitz 3+2',
                board_type: 3,
                board_type_name: 'blitz',
                start_time: '00:03:00',
                black_start_time: '00:03:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:02',
                increment_start_move: 1,
                increment_number: 2,
                start_time_number: 3,
                white_start_time: '00:03:00',
            },
            {
                id: 35,
                name: 'Blitz 3+3',
                board_type: 3,
                board_type_name: 'blitz',
                start_time: '00:03:00',
                black_start_time: '00:03:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:03',
                increment_start_move: 1,
                increment_number: 3,
                start_time_number: 3,
                white_start_time: '00:03:00',
            },
            {
                id: 17,
                name: 'Blitz 5+3',
                board_type: 3,
                board_type_name: 'blitz',
                start_time: '00:05:00',
                black_start_time: '00:05:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:03',
                increment_start_move: 1,
                increment_number: 3,
                start_time_number: 5,
                white_start_time: '00:05:00',
            },
        ],
    },
    {
        board_type: 5,
        name: 'bullet',
        controls: [
            {
                id: 9,
                name: 'Bullet 1 min',
                board_type: 5,
                board_type_name: 'bullet',
                start_time: '00:01:00',
                black_start_time: '00:01:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:00',
                increment_start_move: 0,
                increment_number: 0,
                start_time_number: 1,
                white_start_time: '00:01:00',
            },
            {
                id: 10,
                name: 'Bullet 2 min',
                board_type: 5,
                board_type_name: 'bullet',
                start_time: '00:02:00',
                black_start_time: '00:02:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:00',
                increment_start_move: 0,
                increment_number: 0,
                start_time_number: 2,
                white_start_time: '00:02:00',
            },
            {
                id: 14,
                name: 'Bullet 1+1',
                board_type: 5,
                board_type_name: 'bullet',
                start_time: '00:01:00',
                black_start_time: '00:01:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:01',
                increment_start_move: 1,
                increment_number: 1,
                start_time_number: 1,
                white_start_time: '00:01:00',
            },
            {
                id: 15,
                name: 'Bullet 1+2',
                board_type: 5,
                board_type_name: 'bullet',
                start_time: '00:01:00',
                black_start_time: '00:01:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:02',
                increment_start_move: 1,
                increment_number: 2,
                start_time_number: 1,
                white_start_time: '00:01:00',
            },
            {
                id: 43,
                name: 'Bullet 2+1',
                board_type: 5,
                board_type_name: 'bullet',
                start_time: '00:02:00',
                black_start_time: '00:02:00',
                additional_time: '00:00:00',
                additional_time_move: 0,
                increment: '00:00:01',
                increment_start_move: 1,
                increment_number: 1,
                start_time_number: 2,
                white_start_time: '00:02:00',
            },
        ],
    },
];
