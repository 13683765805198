import { useMemo } from 'react';
import {
  IGameLobbyContextComputed,
  IGameLobbyContextState,
  TGameLobbyTimeControls,
} from '../_types';
import { useGameAllowanceStatus } from './_useGameAllowanceStatus';
import { useAuthStore } from '@store/storeshed';

export const useGameLobbyContextComputed = (
  state: IGameLobbyContextState
): IGameLobbyContextComputed => {
  const logged = useAuthStore('logged');

  const currentRatingLimits = useMemo(() => {
    return state.gameRatingLimits[state.gameRatingMode][
      state.currentTimeControl.board_type as TGameLobbyTimeControls
    ];
  }, [state.gameRatingLimits, state.gameRatingMode, state.currentTimeControl]);

  const gameAllowanceStatus = useGameAllowanceStatus({
    userIsLogged: logged,
    gameRatingMode: state.gameRatingMode,
  });

  return { currentRatingLimits, gameAllowanceStatus };
};
