import { figureType } from 'chessgun/core';
import { BishopIcon, KingIcon, KnightIcon, PawnIcon, QueenIcon, RookIcon, } from '../components/atoms/icons';
export const figures = {
    [figureType.PAWN]: PawnIcon,
    [figureType.KING]: KingIcon,
    [figureType.QUEEN]: QueenIcon,
    [figureType.KNIGHT]: KnightIcon,
    [figureType.BISHOP]: BishopIcon,
    [figureType.ROOK]: RookIcon,
};
