import React, { memo, useEffect, useState } from 'react';

import styled from 'styled-components';

import { IPlayerTableRow } from '../_types';
import {
  $Link,
  $DesktopTableRow,
  $TableCell,
  $TablePlayer,
  $BoardPoint,
} from '../_styles';
import { commaNumFormatter } from '../_constants';
import { TR, TD, Avatar } from 'shared/atoms';
import { getPlayerCountry } from 'shared/helpers/_common';
import { getPlayerPoints } from 'shared/helpers/_get_player_points';
import { useClassNames } from 'shared/hooks/_useClassNames.hook';
import { LazyAvatar } from 'shared/molecules';

/**
 * Компонент: строка таблицы с участиником турнира
 * @param {ITournamentPlayer} player - данные игрока из таблицы
 * @param {string} profileUrl - базовый урл на профиль
 * @param {ITournamentPlayer} user - данные текущего юзера
 * @param {string} eventName - название текущего ивента
 */
export const PlayersTableRow: React.FC<IPlayerTableRow> = memo(
  function PlayersTableRow({ data, profileUrl, userPlayerId, index, columns }) {
    // const hasLeft = (player: ITournamentPlayer): boolean => player.has_left;
    const [fullName, score, tb, teamName, country, games] = columns;

    const [gamesWidth, setGamesWidth] = useState(games.cellSize);

    const className = useClassNames({
      current_user: !!userPlayerId && userPlayerId === Number(data.player_id),
      // player_left: hasLeft(player),
    });

    useEffect(() => {
      if (data.points_per_board) {
        const gameWidth = 12;
        const gameMargin = 10;

        const newGamesWidth =
          (gameWidth + gameMargin) * data.points_per_board.length;
        setGamesWidth(Math.max(newGamesWidth, games.cellSize || 0));
      }
    }, [data.points_per_board, games.cellSize]);

    return (
      <$DesktopTableRow as={TR} className={className}>
        <$TableCell as={TD} cellSize={12}>
          {index}
        </$TableCell>
        <$TablePlayer as={TD} cellSize={fullName.cellSize}>
          <$Link href={`${profileUrl}/${data.player_id}`}>
            <$Avatar
              as={LazyAvatar}
              size="23px"
              preloaderSize="23px"
              image={data.avatar_thumbnails.small}
            />
            <span>{data.full_name || 'Anonymous'}</span>

            {/* <PlayerTitle
              fideTitle={player.fide_title}
              foaTitle={player.foa_title}
            /> */}
          </$Link>
        </$TablePlayer>
        <$TableCell as={TD} cellSize={score.cellSize}>
          {typeof data.points === 'number'
            ? getPlayerPoints(Number(data.points))
            : ''}
        </$TableCell>
        <$TableCell as={TD} cellSize={tb.cellSize}>
          {typeof data.tie_breaks?.bh === 'number'
            ? commaNumFormatter.format(Number(data.tie_breaks?.bh))
            : ''}
        </$TableCell>
        <$TableCell as={TD} cellSize={teamName.cellSize}>
          {data.team_name}
        </$TableCell>

        <$TableCell
          as={TD}
          cellSize={country.cellSize}
          numeric
          style={{ letterSpacing: `0.1em` }}
        >
          {getPlayerCountry(Number(data.nationality_id))}
        </$TableCell>
        <$TableCell as={TD} cellSize={gamesWidth}>
          {data.points_per_board?.map(({ points, board_uid }, i) => (
            <$BoardPoint key={board_uid + i}>
              {getPlayerPoints(points)}
            </$BoardPoint>
          ))}
        </$TableCell>
      </$DesktopTableRow>
    );
  }
);

const $Avatar = styled(Avatar)`
  margin-right: 8px;
  min-width: 23px;
`;
