import { generateError } from "../common/_generateError";
import { chessSide, FILES, RANKS, SQUARES, WHITE_SYMBOLS } from "../_constants";
/**
 * Возвращает данные ладьи для рокировки
 */
export function getCastlingRook({ board, turn, kingTargetIndex, }) {
    const $rookFromRank = turn === chessSide.WHITE ? RANKS[0] : RANKS[7];
    const $rookFromFile = kingTargetIndex === 2 || kingTargetIndex === 114 ? FILES[0] : FILES[7];
    const $rookFrom = SQUARES[($rookFromFile + $rookFromRank)];
    let $rookTo;
    switch (kingTargetIndex) {
        case 2:
            $rookTo = 3;
            break;
        case 6:
            $rookTo = 5;
            break;
        case 114:
            $rookTo = 115;
            break;
        case 118:
            $rookTo = 117;
            break;
    }
    if (!$rookTo) {
        throw generateError({
            board,
            message: `Can't find castling rook.`,
            targetIndex: kingTargetIndex,
        });
    }
    for (let i = 0; i < board.length; i++) {
        const $piece = board[i];
        if (!$piece)
            continue;
        const $pieceColor = WHITE_SYMBOLS.includes($piece) ? chessSide.WHITE : chessSide.BLACK;
        if ($pieceColor !== turn)
            continue;
        if (i === $rookFrom) {
            return {
                from: $rookFrom,
                to: $rookTo,
            };
        }
    }
    return null;
}
