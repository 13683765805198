import { generateError } from "../common/_generateError";
import { chessSide, figureType } from "../_constants";
import { getKingPossibleMoves } from "./_getKingPossibleMoves";
import { getPawnPossibleAttacks } from "./_getPawnPossibleAttacks";
import { getPawnPossibleMoves } from "./_getPawnPossibleMoves";
import { getPieceData } from "./_getPieceData";
import { getPiecePossibleAttacks } from "./_getPiecePossibleAttacks";
import { getPiecePossibleMoves } from "./_getPiecePossibleMoves";
import { validatePossibleMoves } from "./_validatePossibleMoves";
export const calculatePiecePossibleMoves = ({ index, enPassant, board, possibleCastlings, }) => {
    const piece = board[index];
    if (!piece)
        throw generateError({
            index,
            board,
            message: "Can't find piece",
        });
    const pieceData = getPieceData({ piece, index });
    const pc = possibleCastlings || {
        [chessSide.WHITE]: {
            kingSide: false,
            queenSide: false,
        },
        [chessSide.BLACK]: {
            kingSide: false,
            queenSide: false,
        },
    };
    let possibleMoves = [];
    switch (pieceData.type) {
        case figureType.PAWN:
            possibleMoves = [
                ...getPawnPossibleMoves({
                    index,
                    board,
                }),
                ...getPawnPossibleAttacks({
                    index,
                    enPassant,
                    board,
                }),
            ];
            break;
        case figureType.KING:
            possibleMoves = [
                ...getKingPossibleMoves({
                    index,
                    board,
                    possibleCastlings: pc,
                }),
            ];
            break;
        default:
            possibleMoves = [
                ...getPiecePossibleAttacks(index, board),
                ...getPiecePossibleMoves({ index, board }),
            ];
    }
    return validatePossibleMoves({
        index,
        board,
        enPassant,
        possibleMoves,
        color: pieceData.color,
    });
};
