import { IPlan } from 'shared/types';
import { Store, useStore } from 'storeshed';

export enum eProPlans {
  PRO_PLAN = 'pro_plan',
  PRO_PLAN_MONTHLY = 'pro_plan_monthly',
  PRO_PLAN_ANNUAL = 'pro_plan_annual',
}

export interface IHelpersState {
  plans: { [key in eProPlans]: IPlan | null } | null;
  plans_request: boolean;
}

const initHelpersState: IHelpersState = {
  plans: null,
  plans_request: false,
};

export const helpersStore = new Store<IHelpersState>({
  ...initHelpersState,
});

type TUseHelpersStore = {
  <K extends keyof IHelpersState>(key: K): IHelpersState[K];
};

export const useHelpersStore: TUseHelpersStore = (key) =>
  useStore(helpersStore, key);
