import React from 'react';
import { chessFigureStyle } from './_types';
/**
 * Иконка: Фигура слона
 * @param {string} fill - цвет фигуры
 * @param {number} width - ширина фигуры
 * @param {number} height - высота фигуры
 * @param {string} color - тема фигуры
 * @param {chessFigureStyle} figureStyle - тема фигуры
 * @param {string} className - внешний класс
 */
export const BishopIcon = ({ className = '', width = '100%', height = '100%', theme = 'white', fill, figureStyle = chessFigureStyle.FILLED, }) => {
    const bishops = {
        [chessFigureStyle.FILLED]: (React.createElement("svg", { className: className, width: width, height: height, fill: "none", viewBox: "0 0 60 60", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M30.5 8c1.7 0 3 1.7 3 3 0 1.6-1 2.7-2.4 3.2.7 1 2.4 1.7 4.2 2.8 4 2.4 7.4 5 7.4 9.4 0 4-1.3 5.6-2.8 7-.6.6-.2 4.4-.2 7.4 0 1.6-3 2.4-6.3 2.8.3.7 1 1.4 2.5 1.4l7.6-.2c5 0 7.7 2.9 7.7 2.9L48.4 52s-3-2.6-10.6-2.6c-4.2 0-7.2-2-7.7-3.6-.3 1.6-2.7 3.6-7.5 3.6-6.8 0-10.9 2.6-10.9 2.6L9 47.7s2.6-2.9 7.3-2.9l8.1.2c1.4 0 2.2-.8 2.5-1.4-3.4-.4-6.3-1.2-6.3-2.8 0-2.5.3-6.6-.4-7.3-1-1.2-2.7-2.8-2.7-7 0-5.5 4.9-7.9 8-9.8 1.3-.8 2.7-1.5 3.4-2.4-1.1-.5-2.1-1.6-2.1-2.8.2-1.6 2-3.5 3.7-3.5zm-.4 26.6c2.8 0 5.3.3 7.7 1.5v-1.6a17 17 0 00-7.7-1.8c-2.8 0-5.7.8-7.7 1.9V36a19 19 0 017.7-1.5zm0 4.2c2.3 0 6 .3 7.7 1v-1.4c-1.8-1-5-1.5-7.7-1.5-2.8 0-6 .6-7.8 1.6V40c1.4-.7 4.9-1.2 7.8-1.2zm1.4-9.3v-3.2h3.2v-2.5h-3.2v-3.2h-2.6v3.2h-3v2.5h3v3.2h2.6z", fill: fill ? fill : theme }))),
        [chessFigureStyle.STROKE]: (React.createElement("svg", { className: className, width: width, height: height, fill: "none", viewBox: "0 0 13 13", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M6.63 1.73c.44 0 .85.41.85.8 0 .28-.2.54-.47.69.09.15.38.32.64.47.79.43 1.72.93 1.72 2.12 0 .94-.4 1.23-.61 1.46-.12.15-.06 1.05-.06 1.49 0 .3-.52.52-1.28.64.11.09.26.09.43.09.38 0 .76-.26 1.52-.23 1.2 0 1.8.6 2.01.78l-.81 1.23a4.15 4.15 0 0 0-2.13-.62c-.96 0-1.66-.14-1.9-.5-.23.36-.93.5-1.9.5-1.04 0-1.86.47-2.09.62l-.82-1.23c.2-.17.82-.78 2.01-.78.76 0 1.14.23 1.5.23.17 0 .31 0 .43-.09-.82-.09-1.25-.35-1.25-.64 0-.44.08-1.34-.06-1.49-.24-.23-.62-.52-.62-1.46 0-1.19.94-1.66 1.72-2.12.27-.15.62-.35.65-.47a.76.76 0 0 1-.38-.64c-.03-.44.43-.85.9-.85Zm1.72 8.43c1 0 1.7.29 2.04.44l.32-.53a2.36 2.36 0 0 0-1.37-.44c-.67 0-1.1.15-1.49.15-.5 0-.78-.15-.93-.38h-.79c-.14.23-.46.38-.99.38-.35 0-.75-.15-1.45-.15-.67 0-1.14.27-1.4.44l.35.53a4.34 4.34 0 0 1 2-.44c.85 0 1.55-.09 1.82-.44h.08c.3.38.9.44 1.81.44ZM4.74 7.45c.4-.24 1.07-.35 1.77-.35.73 0 1.34.14 1.78.35 0-.18.06-.35.09-.41.17-.23.5-.5.5-1.23 0-.99-.97-1.48-1.52-1.83-.3-.2-.82-.5-.85-.61-.03.11-.52.4-.84.6-.56.36-1.52.82-1.52 1.84 0 .73.32 1.03.5 1.23.06.06.09.2.09.4Zm.02.38v.46c.3-.2.94-.35 1.75-.35.82 0 1.43.15 1.75.32v-.43a4 4 0 0 0-1.75-.3 4.3 4.3 0 0 0-1.75.3Zm1.78 1.25c.47 0 1.75-.06 1.75-.35 0-.23-.9-.35-1.75-.35-.78 0-1.75.15-1.75.35-.03.3 1.14.35 1.75.35ZM6.25 5.7h-.7v-.53h.7V4.5h.56v.67h.7v.53h-.7v.7h-.56v-.7Zm.3-2.77c.2 0 .43-.18.43-.4 0-.16-.15-.39-.35-.39-.23 0-.44.23-.44.44 0 .14.18.35.35.35Z", fill: fill ? fill : theme }))),
    };
    return bishops[figureStyle];
};
