import { isCheckmate, isKingUnderAttack } from "../calculations";
import { B_KING, chessSide, W_KING } from "../_constants";
export const getCheckMates = (board, enPassant) => {
    const whiteCheck = isKingUnderAttack({
        board: [...board],
        kingColor: chessSide.WHITE,
    });
    const blackCheck = isKingUnderAttack({
        board: [...board],
        kingColor: chessSide.BLACK,
    });
    const whiteCheckmate = isCheckmate({
        enPassant,
        board: [...board],
        kingColor: chessSide.WHITE,
    });
    const blackCheckmate = isCheckmate({
        enPassant,
        board: [...board],
        kingColor: chessSide.BLACK,
    });
    const check = whiteCheck || blackCheck;
    const checkmate = whiteCheckmate || blackCheckmate;
    const kingIndex = (() => {
        if (!check)
            return -1;
        return board.indexOf(whiteCheck ? W_KING : B_KING);
    })();
    return {
        check,
        checkmate,
        kingIndex,
    };
};
