import { parseFen } from "./fen/_parseFen";
import { Piece } from "./Piece";
import { DEFAULT_POSITION } from "./_constants";
/**
 * Создает дефолтный стэйт для ChessgunStore
 * @param options - параметры, передаваемые при создании движка
 * @param initFen - fen позиции
 */
export function createDefaultState({ name = "", loading = false, withValidation = true, enPassant = null, movesWithoutCapture = 0, currentFen = DEFAULT_POSITION, commenting = false, }) {
    const { nextTurn: turn, possibleCastlings, checkmate, figures, capturedFigures, } = parseFen(currentFen);
    const pieces = Piece.createPiecesFromParsedData({
        figures,
        fen: currentFen,
        checkmateData: checkmate,
    });
    return {
        name,
        turn,
        pieces,
        loading,
        enPassant,
        currentFen,
        commenting,
        withValidation,
        capturedFigures,
        possibleCastlings,
        movesWithoutCapture,
        history: [],
        analysis: [],
        checkmateData: checkmate,
        selectedMoveAddress: [],
    };
}
