export const regions = [
    {
        id: 1,
        name: 'ASIA',
        name_localization: {
            en: 'Asia',
            kz: 'Asia',
            ru: 'Азия',
        },
    },
    {
        id: 2,
        name: 'EUROPE',
        name_localization: {
            en: 'Europe',
            kz: 'Europe',
            ru: 'Европа',
        },
    },
    {
        id: 3,
        name: 'AFRICA',
        name_localization: {
            en: 'Africa',
            kz: 'Africa',
            ru: 'Африка',
        },
    },
    {
        id: 9,
        name: 'AMERICA',
        name_localization: {
            en: 'America',
            kz: 'America',
            ru: 'Америка',
        },
    },
];
export const countries = [
    {
        id: 83,
        name_localization: {
            en: 'Afghanistan',
            kz: 'Afghanistan',
            ru: 'Афганистан',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/af.svg',
        },
        name: 'Afghanistan',
        code: 'AF',
        long_code: 'AFG',
        federation_ru: 'Афганская',
        federation_en: 'Afghan',
        region: 1,
    },
    {
        id: 135,
        name_localization: {
            en: 'Albania',
            kz: 'Albania',
            ru: 'Албания',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/al.svg',
        },
        name: 'Albania',
        code: 'AL',
        long_code: 'ALB',
        federation_ru: 'Албанийская',
        federation_en: 'Albanian',
        region: 2,
    },
    {
        id: 1,
        name_localization: {
            en: 'Algeria',
            kz: 'Algeria',
            ru: 'Алжир',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/dz.svg',
        },
        name: 'Algeria',
        code: 'DZ',
        long_code: 'ALG',
        federation_ru: 'Алжирская',
        federation_en: 'Algerian',
        region: 3,
    },
    {
        id: 136,
        name_localization: {
            en: 'Andorra',
            kz: 'Andorra',
            ru: 'Андорра',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ad.svg',
        },
        name: 'Andorra',
        code: 'AD',
        long_code: 'AND',
        federation_ru: 'Андоррская',
        federation_en: 'Andorrano',
        region: 2,
    },
    {
        id: 2,
        name_localization: {
            en: 'Angola',
            kz: 'Angola',
            ru: 'Ангола',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ao.svg',
        },
        name: 'Angola',
        code: 'AO',
        long_code: 'ANG',
        federation_ru: 'Ангольская',
        federation_en: 'Angolan',
        region: 3,
    },
    {
        id: 48,
        name_localization: {
            en: 'Antigua and Barbuda',
            kz: 'Antigua and Barbuda',
            ru: 'Antigua and Barbuda',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ag.svg',
        },
        name: 'Antigua and Barbuda',
        code: 'AG',
        long_code: 'ANT',
        federation_ru: 'Антигуа и Барбуда',
        federation_en: 'Antigua and Barbuda',
        region: 9,
    },
    {
        id: 49,
        name_localization: {
            en: 'Argentina',
            kz: 'Argentina',
            ru: 'Аргентина',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ar.svg',
        },
        name: 'Argentina',
        code: 'AR',
        long_code: 'ARG',
        federation_ru: 'Аргентинская',
        federation_en: 'Argentino',
        region: 9,
    },
    {
        id: 137,
        name_localization: {
            en: 'Armenia',
            kz: 'Armenia',
            ru: 'Армения',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/am.svg',
        },
        name: 'Armenia',
        code: 'AM',
        long_code: 'ARM',
        federation_ru: 'Армянская',
        federation_en: 'Armenian',
        region: 2,
    },
    {
        id: 50,
        name_localization: {
            en: 'Aruba',
            kz: 'Aruba',
            ru: 'Аруба',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/aw.svg',
        },
        name: 'Aruba',
        code: 'AW',
        long_code: 'ARU',
        federation_ru: 'Арубайская',
        federation_en: 'Aruban',
        region: 9,
    },
    {
        id: 84,
        name_localization: {
            en: 'Australia',
            kz: 'Australia',
            ru: 'Австралия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/au.svg',
        },
        name: 'Australia',
        code: 'AU',
        long_code: 'AUS',
        federation_ru: 'Австралийская',
        federation_en: 'Ozzie',
        region: 1,
    },
    {
        id: 138,
        name_localization: {
            en: 'Austria',
            kz: 'Austria',
            ru: 'Австрия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/at.svg',
        },
        name: 'Austria',
        code: 'AT',
        long_code: 'AUT',
        federation_ru: 'Австрийская',
        federation_en: 'Austrian',
        region: 2,
    },
    {
        id: 139,
        name_localization: {
            en: 'Azerbaijan',
            kz: 'Azerbaijan',
            ru: 'Азербайджан',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/az.svg',
        },
        name: 'Azerbaijan',
        code: 'AZ',
        long_code: 'AZE',
        federation_ru: 'Азербайджанская',
        federation_en: "Azeri'S",
        region: 2,
    },
    {
        id: 51,
        name_localization: {
            en: 'Bahamas',
            kz: 'Bahamas',
            ru: 'Багамские острова',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/bs.svg',
        },
        name: 'Bahamas',
        code: 'BS',
        long_code: 'BAH',
        federation_ru: 'Багамская',
        federation_en: 'Bosnian',
        region: 9,
    },
    {
        id: 85,
        name_localization: {
            en: 'Bahrain',
            kz: 'Bahrain',
            ru: 'Бахрейн',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/bh.svg',
        },
        name: 'Bahrain',
        code: 'BH',
        long_code: 'BRN',
        federation_ru: 'Бахрейнская',
        federation_en: 'Bahraini',
        region: 1,
    },
    {
        id: 86,
        name_localization: {
            en: 'Bangladesh',
            kz: 'Bangladesh',
            ru: 'Бангладеш',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/bd.svg',
        },
        name: 'Bangladesh',
        code: 'BD',
        long_code: 'BAN',
        federation_ru: 'Бангладешская',
        federation_en: 'Bangladeshi',
        region: 1,
    },
    {
        id: 52,
        name_localization: {
            en: 'Barbados',
            kz: 'Barbados',
            ru: 'Барбадос',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/bb.svg',
        },
        name: 'Barbados',
        code: 'BB',
        long_code: 'BAR',
        federation_ru: 'Барбадосская',
        federation_en: 'Barbadian',
        region: 9,
    },
    {
        id: 140,
        name_localization: {
            en: 'Belarus',
            kz: 'Belarus',
            ru: 'Беларусь',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/by.svg',
        },
        name: 'Belarus',
        code: 'BY',
        long_code: 'BLR',
        federation_ru: 'Беларуская',
        federation_en: 'Belarusian',
        region: 2,
    },
    {
        id: 141,
        name_localization: {
            en: 'Belgium',
            kz: 'Belgium',
            ru: 'Бельгия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/be.svg',
        },
        name: 'Belgium',
        code: 'BE',
        long_code: 'BEL',
        federation_ru: 'Бельгийская',
        federation_en: 'Belgian',
        region: 2,
    },
    {
        id: 200,
        name_localization: {
            en: 'Belize',
            kz: 'Belize',
            ru: 'Белиз',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/Belize.svg',
        },
        name: 'Belize',
        code: 'BZ',
        long_code: 'BLZ',
        federation_ru: 'Белизская',
        federation_en: 'Belize',
        region: 9,
    },
    {
        id: 53,
        name_localization: {
            en: 'Bermuda',
            kz: 'Bermuda',
            ru: 'Бермудские острова',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/bm.svg',
        },
        name: 'Bermuda',
        code: 'BM',
        long_code: 'BER',
        federation_ru: 'Бермудская',
        federation_en: 'Bermudian',
        region: 9,
    },
    {
        id: 87,
        name_localization: {
            en: 'Bhutan',
            kz: 'Bhutan',
            ru: 'Бутан',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/bt.svg',
        },
        name: 'Bhutan',
        code: 'BT',
        long_code: 'BHU',
        federation_ru: 'Бутанская',
        federation_en: 'Bhutanese',
        region: 1,
    },
    {
        id: 54,
        name_localization: {
            en: 'Bolivia',
            kz: 'Bolivia',
            ru: 'Боливия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/bo.svg',
        },
        name: 'Bolivia',
        code: 'BO',
        long_code: 'BOL',
        federation_ru: 'Боливийская',
        federation_en: 'Bolivian',
        region: 9,
    },
    {
        id: 142,
        name_localization: {
            en: 'Bosnia & Herzegovina',
            kz: 'Bosnia & Herzegovina',
            ru: 'Босния и Герцеговина',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ba.svg',
        },
        name: 'Bosnia & Herzegovina',
        code: 'BA',
        long_code: 'BIH',
        federation_ru: 'Босния и Герцеговина',
        federation_en: 'Bosnia and Herzegovina',
        region: 2,
    },
    {
        id: 3,
        name_localization: {
            en: 'Botswana',
            kz: 'Botswana',
            ru: 'Ботсвана',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/bw.svg',
        },
        name: 'Botswana',
        code: 'BW',
        long_code: 'BOT',
        federation_ru: 'Ботсвинская',
        federation_en: 'Motswana',
        region: 3,
    },
    {
        id: 55,
        name_localization: {
            en: 'Brazil',
            kz: 'Brazil',
            ru: 'Бразилия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/br.svg',
        },
        name: 'Brazil',
        code: 'BR',
        long_code: 'BRA',
        federation_ru: 'Бразильская',
        federation_en: 'Brazilian',
        region: 9,
    },
    {
        id: 56,
        name_localization: {
            en: 'British Virgin Islands',
            kz: 'British Virgin Islands',
            ru: 'Британские Виргинские острова',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/vg.svg',
        },
        name: 'British Virgin Islands',
        code: 'VG',
        long_code: 'IVB',
        federation_ru: 'Виргинская',
        federation_en: 'Virgin',
        region: 9,
    },
    {
        id: 88,
        name_localization: {
            en: 'Brunei Darussalam',
            kz: 'Brunei Darussalam',
            ru: 'Brunei Darussalam',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/bn.svg',
        },
        name: 'Brunei Darussalam',
        code: 'BN',
        long_code: 'BRU',
        federation_ru: 'Бруней Даруссаламская',
        federation_en: 'Nation of Brunei',
        region: 1,
    },
    {
        id: 190,
        name_localization: {
            en: 'Bulgaria',
            kz: 'Bulgaria',
            ru: 'Болгария',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/bg.svg',
        },
        name: 'Bulgaria',
        code: 'BG',
        long_code: 'BUL',
        federation_ru: 'Болгарская',
        federation_en: 'Bulgarian',
        region: 2,
    },
    {
        id: 4,
        name_localization: {
            en: 'Burkina Faso',
            kz: 'Burkina Faso',
            ru: 'Буркина Фасо',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/bf.svg',
        },
        name: 'Burkina Faso',
        code: 'BF',
        long_code: 'BUR',
        federation_ru: 'Буркина-Фасо',
        federation_en: 'Burkinabè',
        region: 3,
    },
    {
        id: 5,
        name_localization: {
            en: 'Burundi',
            kz: 'Burundi',
            ru: 'Бурунди',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/bi.svg',
        },
        name: 'Burundi',
        code: 'BI',
        long_code: 'BDI',
        federation_ru: 'Бурундийская',
        federation_en: 'Burundian',
        region: 3,
    },
    {
        id: 7,
        name_localization: {
            en: 'Cabo Verde',
            kz: 'Cabo Verde',
            ru: 'Cabo Verde',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/cv.svg',
        },
        name: 'Cabo Verde',
        code: 'CV',
        long_code: 'CPV',
        federation_ru: 'Кабо-Верде',
        federation_en: 'Cabo Verdean',
        region: 3,
    },
    {
        id: 89,
        name_localization: {
            en: 'Cambodia',
            kz: 'Cambodia',
            ru: 'Камбоджа',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/kh.svg',
        },
        name: 'Cambodia',
        code: 'KH',
        long_code: 'CAM',
        federation_ru: 'Камбоджийская',
        federation_en: 'Cambodian',
        region: 1,
    },
    {
        id: 6,
        name_localization: {
            en: 'Cameroon',
            kz: 'Cameroon',
            ru: 'Камерун',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/cm.svg',
        },
        name: 'Cameroon',
        code: 'CM',
        long_code: 'CMR',
        federation_ru: 'Камерунская',
        federation_en: 'Cameroonian',
        region: 3,
    },
    {
        id: 57,
        name_localization: {
            en: 'Canada',
            kz: 'Canada',
            ru: 'Канада',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ca.svg',
        },
        name: 'Canada',
        code: 'CA',
        long_code: 'CAN',
        federation_ru: 'Канадская',
        federation_en: 'Canadian',
        region: 9,
    },
    {
        id: 201,
        name_localization: {
            en: 'Cayman Islands',
            kz: 'Cayman Islands',
            ru: 'Острова Кайман',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/Cayman_Islands.svg',
        },
        name: 'Cayman Islands',
        code: 'KY',
        long_code: 'CYM',
        federation_ru: 'Cayman Islands',
        federation_en: 'Cayman Islands',
        region: 9,
    },
    {
        id: 8,
        name_localization: {
            en: 'Central African Republic',
            kz: 'Central African Republic',
            ru: 'Центральноафриканская Республика',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/cf.svg',
        },
        name: 'Central African Republic',
        code: 'CF',
        long_code: 'CAF',
        federation_ru: 'Центрально-африканская',
        federation_en: 'Central African',
        region: 3,
    },
    {
        id: 197,
        name_localization: {
            en: 'Chad',
            kz: 'Chad',
            ru: 'Чад',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/chad.svg',
        },
        name: 'Chad',
        code: 'TD',
        long_code: 'TCD',
        federation_ru: 'Чадская',
        federation_en: 'Chad',
        region: 3,
    },
    {
        id: 58,
        name_localization: {
            en: 'Chile',
            kz: 'Chile',
            ru: 'Чили',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/cl.svg',
        },
        name: 'Chile',
        code: 'CL',
        long_code: 'CHI',
        federation_ru: 'Чилийская',
        federation_en: 'Chilean',
        region: 9,
    },
    {
        id: 90,
        name_localization: {
            en: 'China',
            kz: 'China',
            ru: 'Китай',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/cn.svg',
        },
        name: 'China',
        code: 'CN',
        long_code: 'CHN',
        federation_ru: 'Китайская',
        federation_en: 'Chinese',
        region: 1,
    },
    {
        id: 91,
        name_localization: {
            en: 'Chinese Taipei',
            kz: 'Chinese Taipei',
            ru: 'Chinese Taipei',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/tp.svg',
        },
        name: 'Chinese Taipei',
        code: 'TP',
        long_code: 'TPE',
        federation_ru: 'Китайская',
        federation_en: 'Chinese Taipei',
        region: 1,
    },
    {
        id: 59,
        name_localization: {
            en: 'Colombia',
            kz: 'Colombia',
            ru: 'Колумбия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/co.svg',
        },
        name: 'Colombia',
        code: 'CO',
        long_code: 'COL',
        federation_ru: 'Колумбийская',
        federation_en: 'Colombian',
        region: 9,
    },
    {
        id: 9,
        name_localization: {
            en: 'Comoros Islands',
            kz: 'Comoros Islands',
            ru: 'Comoros Islands',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/km.svg',
        },
        name: 'Comoros Islands',
        code: 'KM',
        long_code: 'COM',
        federation_ru: 'Коморская',
        federation_en: 'Comoros',
        region: 3,
    },
    {
        id: 10,
        name_localization: {
            en: 'Congo',
            kz: 'Congo',
            ru: 'Congo',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/cd.svg',
        },
        name: 'Congo',
        code: 'CD',
        long_code: 'CGO',
        federation_ru: 'Конго',
        federation_en: 'Congo',
        region: 3,
    },
    {
        id: 60,
        name_localization: {
            en: 'Costa Rica',
            kz: 'Costa Rica',
            ru: 'Коста-Рика',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/cr.svg',
        },
        name: 'Costa Rica',
        code: 'CR',
        long_code: 'CRC',
        federation_ru: 'Коста-Риканская',
        federation_en: 'Costa Rican',
        region: 9,
    },
    {
        id: 11,
        name_localization: {
            en: 'Cote d’Ivoire',
            kz: 'Cote d’Ivoire',
            ru: 'Cote d’Ivoire',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ci.svg',
        },
        name: 'Cote d’Ivoire',
        code: 'CI',
        long_code: 'CIV',
        federation_ru: "Кот-д'Ивуарская",
        federation_en: "Côte d'Ivoire",
        region: 3,
    },
    {
        id: 143,
        name_localization: {
            en: 'Croatia',
            kz: 'Croatia',
            ru: 'Хорватия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/hr.svg',
        },
        name: 'Croatia',
        code: 'HR',
        long_code: 'CRO',
        federation_ru: 'Хорватская',
        federation_en: 'Croatian',
        region: 2,
    },
    {
        id: 61,
        name_localization: {
            en: 'Cuba',
            kz: 'Cuba',
            ru: 'Куба',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/cu.svg',
        },
        name: 'Cuba',
        code: 'CU',
        long_code: 'CUB',
        federation_ru: 'Кубинская',
        federation_en: 'Cuban',
        region: 9,
    },
    {
        id: 144,
        name_localization: {
            en: 'Cyprus',
            kz: 'Cyprus',
            ru: 'Кипр',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/cy.svg',
        },
        name: 'Cyprus',
        code: 'CY',
        long_code: 'CYP',
        federation_ru: 'Кипрская',
        federation_en: 'Cypriote',
        region: 2,
    },
    {
        id: 145,
        name_localization: {
            en: 'Czech Republic',
            kz: 'Czech Republic',
            ru: 'Czech Republic',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/cz.svg',
        },
        name: 'Czech Republic',
        code: 'CZ',
        long_code: 'CZE',
        federation_ru: 'Чешская',
        federation_en: 'Czech',
        region: 2,
    },
    {
        id: 192,
        name_localization: {
            en: 'Democratic Republic of the Congo',
            kz: 'Democratic Republic of the Congo',
            ru: 'Democratic Republic of the Congo',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/cd.svg',
        },
        name: 'Democratic Republic of the Congo',
        code: 'CD',
        long_code: 'COD',
        federation_ru: 'Конго',
        federation_en: 'Congo',
        region: 3,
    },
    {
        id: 146,
        name_localization: {
            en: 'Denmark',
            kz: 'Denmark',
            ru: 'Дания',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/dk.svg',
        },
        name: 'Denmark',
        code: 'DK',
        long_code: 'DEN',
        federation_ru: 'Датская',
        federation_en: 'Dansker',
        region: 2,
    },
    {
        id: 12,
        name_localization: {
            en: 'Djibouti',
            kz: 'Djibouti',
            ru: 'Джибути',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/dj.svg',
        },
        name: 'Djibouti',
        code: 'DJ',
        long_code: 'DJI',
        federation_ru: 'Джибутийская',
        federation_en: 'Djiboutian',
        region: 3,
    },
    {
        id: 202,
        name_localization: {
            en: 'Dominica',
            kz: 'Dominica',
            ru: 'Доминикана',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/Dominica.svg',
        },
        name: 'Dominica',
        code: 'DM',
        long_code: 'DMA',
        federation_ru: 'Доминиканская',
        federation_en: 'Dominica',
        region: 9,
    },
    {
        id: 62,
        name_localization: {
            en: 'Dominican Republic',
            kz: 'Dominican Republic',
            ru: 'Доминиканская республика',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/do.svg',
        },
        name: 'Dominican Republic',
        code: 'DO',
        long_code: 'DOM',
        federation_ru: 'Доминиканская',
        federation_en: 'Dominican',
        region: 9,
    },
    {
        id: 63,
        name_localization: {
            en: 'Ecuador',
            kz: 'Ecuador',
            ru: 'Эквадор',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ec.svg',
        },
        name: 'Ecuador',
        code: 'EC',
        long_code: 'ECU',
        federation_ru: 'Эквадорская',
        federation_en: 'Ecuadoran',
        region: 9,
    },
    {
        id: 13,
        name_localization: {
            en: 'Egypt',
            kz: 'Egypt',
            ru: 'Египет',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/eg.svg',
        },
        name: 'Egypt',
        code: 'EG',
        long_code: 'EGY',
        federation_ru: 'Египетская',
        federation_en: 'Egyptian',
        region: 3,
    },
    {
        id: 64,
        name_localization: {
            en: 'El Salvador',
            kz: 'El Salvador',
            ru: 'Сальвадор',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/sv.svg',
        },
        name: 'El Salvador',
        code: 'SV',
        long_code: 'ESA',
        federation_ru: 'Сальвадорская',
        federation_en: 'Salvadoreño',
        region: 9,
    },
    {
        id: 147,
        name_localization: {
            en: 'England',
            kz: 'England',
            ru: 'England',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/en.svg',
        },
        name: 'England',
        code: 'EN',
        long_code: 'ENG',
        federation_ru: 'Английская',
        federation_en: 'England',
        region: 2,
    },
    {
        id: 194,
        name_localization: {
            en: 'Equatorial Guinea',
            kz: 'Equatorial Guinea',
            ru: 'Экваториальная Гвинея',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/gq.svg',
        },
        name: 'Equatorial Guinea',
        code: 'GQ',
        long_code: 'GEQ',
        federation_ru: 'Гвинейская',
        federation_en: 'Equatorial Guinea',
        region: 3,
    },
    {
        id: 14,
        name_localization: {
            en: 'Eritrea',
            kz: 'Eritrea',
            ru: 'Эритрея',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/er.svg',
        },
        name: 'Eritrea',
        code: 'ER',
        long_code: 'ERI',
        federation_ru: 'Эритрийскя',
        federation_en: 'Eritrean',
        region: 3,
    },
    {
        id: 148,
        name_localization: {
            en: 'Estonia',
            kz: 'Estonia',
            ru: 'Эстония',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ee.svg',
        },
        name: 'Estonia',
        code: 'EE',
        long_code: 'EST',
        federation_ru: 'Эстонская',
        federation_en: 'Estonian',
        region: 2,
    },
    {
        id: 198,
        name_localization: {
            en: 'Eswatini',
            kz: 'Eswatini',
            ru: 'Эсватини',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/Eswatini.svg',
        },
        name: 'Eswatini',
        code: 'SZ',
        long_code: 'SWZ',
        federation_ru: 'Эсватинская',
        federation_en: 'Eswatini',
        region: 3,
    },
    {
        id: 15,
        name_localization: {
            en: 'Ethiopia',
            kz: 'Ethiopia',
            ru: 'Эфиопия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/et.svg',
        },
        name: 'Ethiopia',
        code: 'ET',
        long_code: 'ETH',
        federation_ru: 'Эфиопская',
        federation_en: 'Ethiopian',
        region: 3,
    },
    {
        id: 149,
        name_localization: {
            en: 'Faroe Islands',
            kz: 'Faroe Islands',
            ru: 'Фарерские острова',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/fo.svg',
        },
        name: 'Faroe Islands',
        code: 'FO',
        long_code: 'FAI',
        federation_ru: 'Фарерская',
        federation_en: 'Faroese',
        region: 2,
    },
    {
        id: 189,
        name_localization: {
            en: 'Fide',
            kz: 'Fide',
            ru: 'Fide',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/fd.svg',
        },
        name: 'Fide',
        code: 'FD',
        long_code: 'FID',
        federation_ru: 'ФИДЕ',
        federation_en: 'Fide',
        region: 2,
    },
    {
        id: 92,
        name_localization: {
            en: 'Fiji',
            kz: 'Fiji',
            ru: 'Фиджи',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/fj.svg',
        },
        name: 'Fiji',
        code: 'FJ',
        long_code: 'FIJ',
        federation_ru: 'Фиджийская',
        federation_en: 'Fijian',
        region: 1,
    },
    {
        id: 150,
        name_localization: {
            en: 'Finland',
            kz: 'Finland',
            ru: 'Финляндия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/fi.svg',
        },
        name: 'Finland',
        code: 'FI',
        long_code: 'FIN',
        federation_ru: 'Финская',
        federation_en: 'Finnish',
        region: 2,
    },
    {
        id: 151,
        name_localization: {
            en: 'Former YUG Rep of Macedonia',
            kz: 'Former YUG Rep of Macedonia',
            ru: 'Former YUG Rep of Macedonia',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/mk.svg',
        },
        name: 'Former YUG Rep of Macedonia',
        code: 'MK',
        long_code: 'MKD',
        federation_ru: 'Македонская',
        federation_en: 'Macedonian',
        region: 2,
    },
    {
        id: 152,
        name_localization: {
            en: 'France',
            kz: 'France',
            ru: 'Франция',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/fr.svg',
        },
        name: 'France',
        code: 'FR',
        long_code: 'FRA',
        federation_ru: 'Французская',
        federation_en: 'French',
        region: 2,
    },
    {
        id: 16,
        name_localization: {
            en: 'Gabon',
            kz: 'Gabon',
            ru: 'Габон',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ga.svg',
        },
        name: 'Gabon',
        code: 'GA',
        long_code: 'GAB',
        federation_ru: 'Габонская',
        federation_en: 'Gabonese',
        region: 3,
    },
    {
        id: 17,
        name_localization: {
            en: 'Gambia',
            kz: 'Gambia',
            ru: 'Гамбия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/gm.svg',
        },
        name: 'Gambia',
        code: 'GM',
        long_code: 'GAM',
        federation_ru: 'Гамбийская',
        federation_en: 'Gambian',
        region: 3,
    },
    {
        id: 153,
        name_localization: {
            en: 'Georgia',
            kz: 'Georgia',
            ru: 'Грузия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ge.svg',
        },
        name: 'Georgia',
        code: 'GE',
        long_code: 'GEO',
        federation_ru: 'Грузинская',
        federation_en: 'Georgian',
        region: 1,
    },
    {
        id: 154,
        name_localization: {
            en: 'Germany',
            kz: 'Germany',
            ru: 'Германия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/de.svg',
        },
        name: 'Germany',
        code: 'DE',
        long_code: 'GER',
        federation_ru: 'Германская',
        federation_en: 'German',
        region: 2,
    },
    {
        id: 18,
        name_localization: {
            en: 'Ghana',
            kz: 'Ghana',
            ru: 'Гана',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/gh.svg',
        },
        name: 'Ghana',
        code: 'GH',
        long_code: 'GHA',
        federation_ru: 'Ганская',
        federation_en: 'Ghanaian',
        region: 3,
    },
    {
        id: 155,
        name_localization: {
            en: 'Greece',
            kz: 'Greece',
            ru: 'Греция',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/gr.svg',
        },
        name: 'Greece',
        code: 'GR',
        long_code: 'GRE',
        federation_ru: 'Греческая',
        federation_en: 'Greek',
        region: 2,
    },
    {
        id: 195,
        name_localization: {
            en: 'Grenada',
            kz: 'Grenada',
            ru: 'Гренада',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/grd.svg',
        },
        name: 'Grenada',
        code: 'GD',
        long_code: 'GRD',
        federation_ru: 'Гренада',
        federation_en: 'Grenada',
        region: 9,
    },
    {
        id: 93,
        name_localization: {
            en: 'Guam',
            kz: 'Guam',
            ru: 'Гуам',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/gu.svg',
        },
        name: 'Guam',
        code: 'GU',
        long_code: 'GUM',
        federation_ru: 'Гуамская',
        federation_en: 'Guambat',
        region: 1,
    },
    {
        id: 65,
        name_localization: {
            en: 'Guatemala',
            kz: 'Guatemala',
            ru: 'Гватемала',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/gt.svg',
        },
        name: 'Guatemala',
        code: 'GT',
        long_code: 'GUA',
        federation_ru: 'Гватемальская',
        federation_en: 'Guatemalan',
        region: 9,
    },
    {
        id: 156,
        name_localization: {
            en: 'Guernsey',
            kz: 'Guernsey',
            ru: 'Гернси',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/gg.svg',
        },
        name: 'Guernsey',
        code: 'GG',
        long_code: 'GCI',
        federation_ru: 'Гернская',
        federation_en: 'Guern',
        region: 2,
    },
    {
        id: 193,
        name_localization: {
            en: 'Guinea',
            kz: 'Guinea',
            ru: 'Гвинея',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/gn.svg',
        },
        name: 'Guinea',
        code: 'GN',
        long_code: 'GUI',
        federation_ru: 'Гвинейская',
        federation_en: 'Guinean',
        region: 3,
    },
    {
        id: 66,
        name_localization: {
            en: 'Guyana',
            kz: 'Guyana',
            ru: 'Гайана',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/gy.svg',
        },
        name: 'Guyana',
        code: 'GY',
        long_code: 'GUY',
        federation_ru: 'Гайанская',
        federation_en: 'Guyanese',
        region: 9,
    },
    {
        id: 67,
        name_localization: {
            en: 'Haiti',
            kz: 'Haiti',
            ru: 'Гаити',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ht.svg',
        },
        name: 'Haiti',
        code: 'HT',
        long_code: 'HAI',
        federation_ru: 'Гаитянская',
        federation_en: 'Haitian',
        region: 9,
    },
    {
        id: 68,
        name_localization: {
            en: 'Honduras',
            kz: 'Honduras',
            ru: 'Гондурас',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/hn.svg',
        },
        name: 'Honduras',
        code: 'HN',
        long_code: 'HON',
        federation_ru: 'Гондурасская',
        federation_en: 'Honduran',
        region: 9,
    },
    {
        id: 94,
        name_localization: {
            en: 'Hong Kong',
            kz: 'Hong Kong',
            ru: 'Hong Kong',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/hk.svg',
        },
        name: 'Hong Kong',
        code: 'HK',
        long_code: 'HKG',
        federation_ru: 'Гонконгская',
        federation_en: 'Hongkongish',
        region: 1,
    },
    {
        id: 157,
        name_localization: {
            en: 'Hungary',
            kz: 'Hungary',
            ru: 'Венгрия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/hu.svg',
        },
        name: 'Hungary',
        code: 'HU',
        long_code: 'HUN',
        federation_ru: 'Венгерская',
        federation_en: 'Hungarian',
        region: 2,
    },
    {
        id: 158,
        name_localization: {
            en: 'Iceland',
            kz: 'Iceland',
            ru: 'Исландия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/is.svg',
        },
        name: 'Iceland',
        code: 'IS',
        long_code: 'ISL',
        federation_ru: 'Исландская',
        federation_en: 'Icelandic',
        region: 2,
    },
    {
        id: 95,
        name_localization: {
            en: 'India',
            kz: 'India',
            ru: 'Индия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/in.svg',
        },
        name: 'India',
        code: 'IN',
        long_code: 'IND',
        federation_ru: 'Индийская',
        federation_en: 'Indian',
        region: 1,
    },
    {
        id: 96,
        name_localization: {
            en: 'Indonesia',
            kz: 'Indonesia',
            ru: 'Индонезия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/id.svg',
        },
        name: 'Indonesia',
        code: 'ID',
        long_code: 'INA',
        federation_ru: 'Индонезийская',
        federation_en: 'Indonesian',
        region: 1,
    },
    {
        id: 97,
        name_localization: {
            en: 'Iran',
            kz: 'Iran',
            ru: 'Иран',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ir.svg',
        },
        name: 'Iran',
        code: 'IR',
        long_code: 'IRN',
        federation_ru: 'Иранская',
        federation_en: 'Iranian',
        region: 1,
    },
    {
        id: 98,
        name_localization: {
            en: 'Iraq',
            kz: 'Iraq',
            ru: 'Ирак',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/iq.svg',
        },
        name: 'Iraq',
        code: 'IQ',
        long_code: 'IRQ',
        federation_ru: 'Иракская',
        federation_en: 'Iraqi',
        region: 1,
    },
    {
        id: 159,
        name_localization: {
            en: 'Ireland',
            kz: 'Ireland',
            ru: 'Ирландия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ie.svg',
        },
        name: 'Ireland',
        code: 'IE',
        long_code: 'IRL',
        federation_ru: 'Ирландская',
        federation_en: 'Irish',
        region: 2,
    },
    {
        id: 204,
        name_localization: {
            en: 'Isle of Man',
            kz: 'Isle of Man',
            ru: 'Остров Мэн',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/Isle_of_Man.svg',
        },
        name: 'Isle of Man',
        code: 'IM',
        long_code: 'IMN',
        federation_ru: 'Остров Мэн',
        federation_en: 'Isle of Man',
        region: 2,
    },
    {
        id: 160,
        name_localization: {
            en: 'Israel',
            kz: 'Israel',
            ru: 'Израиль',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/il.svg',
        },
        name: 'Israel',
        code: 'IL',
        long_code: 'ISR',
        federation_ru: 'Израильская',
        federation_en: 'Israelite',
        region: 2,
    },
    {
        id: 161,
        name_localization: {
            en: 'Italy',
            kz: 'Italy',
            ru: 'Италия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/it.svg',
        },
        name: 'Italy',
        code: 'IT',
        long_code: 'ITA',
        federation_ru: 'Итальянская',
        federation_en: 'Italian',
        region: 2,
    },
    {
        id: 69,
        name_localization: {
            en: 'Jamaica',
            kz: 'Jamaica',
            ru: 'Ямайка',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/jm.svg',
        },
        name: 'Jamaica',
        code: 'JM',
        long_code: 'JAM',
        federation_ru: 'Ямайская',
        federation_en: 'Jamaican',
        region: 9,
    },
    {
        id: 99,
        name_localization: {
            en: 'Japan',
            kz: 'Japan',
            ru: 'Япония',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/jp.svg',
        },
        name: 'Japan',
        code: 'JP',
        long_code: 'JPN',
        federation_ru: 'Японская',
        federation_en: 'Japanese',
        region: 1,
    },
    {
        id: 162,
        name_localization: {
            en: 'Jersey',
            kz: 'Jersey',
            ru: 'Джерси',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/je.svg',
        },
        name: 'Jersey',
        code: 'JE',
        long_code: 'JCI',
        federation_ru: 'Джерсийская',
        federation_en: 'Jerseyan',
        region: 2,
    },
    {
        id: 100,
        name_localization: {
            en: 'Jordan',
            kz: 'Jordan',
            ru: 'Иордания',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/jo.svg',
        },
        name: 'Jordan',
        code: 'JO',
        long_code: 'JOR',
        federation_ru: 'Иорданская',
        federation_en: 'Jordanian',
        region: 1,
    },
    {
        id: 101,
        name_localization: {
            en: 'Kazakhstan',
            kz: 'Kazakhstan',
            ru: 'Казахстан',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/kz.svg',
        },
        name: 'Kazakhstan',
        code: 'KZ',
        long_code: 'KAZ',
        federation_ru: 'Казахстанская',
        federation_en: 'Kazakhstani',
        region: 1,
    },
    {
        id: 19,
        name_localization: {
            en: 'Kenya',
            kz: 'Kenya',
            ru: 'Кения',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ke.svg',
        },
        name: 'Kenya',
        code: 'KE',
        long_code: 'KEN',
        federation_ru: 'Кенийская',
        federation_en: 'Kenyan',
        region: 3,
    },
    {
        id: 163,
        name_localization: {
            en: 'Kosovo',
            kz: 'Kosovo',
            ru: 'Косово',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/xxk.svg',
        },
        name: 'Kosovo',
        code: 'RS',
        long_code: 'KOS',
        federation_ru: 'Косово',
        federation_en: 'Kosovo',
        region: 2,
    },
    /*{
      id: 196,
      name_localization: {
        en: 'Kosovo',
        kz: 'Kosovo',
        ru: 'Косово',
      },
      flag_image: {
        svg: 'https://gaming-images.worldchess.com/media/flags/xxk.svg',
      },
      name: 'Kosovo',
      code: 'XK',
      long_code: 'XXK',
      federation_ru: 'Косово',
      federation_en: 'Kosovo',
      region: 2,
    },  */
    {
        id: 102,
        name_localization: {
            en: 'Kuwait',
            kz: 'Kuwait',
            ru: 'Кувейт',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/kw.svg',
        },
        name: 'Kuwait',
        code: 'KW',
        long_code: 'KUW',
        federation_ru: 'Кувейтская',
        federation_en: 'Kuwaiti',
        region: 1,
    },
    {
        id: 103,
        name_localization: {
            en: 'Kyrgyzstan',
            kz: 'Kyrgyzstan',
            ru: 'Киргизия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/kg.svg',
        },
        name: 'Kyrgyzstan',
        code: 'KG',
        long_code: 'KGZ',
        federation_ru: 'Кыргызстанская',
        federation_en: 'Kyrgyz',
        region: 1,
    },
    {
        id: 104,
        name_localization: {
            en: 'Laos',
            kz: 'Laos',
            ru: 'Лаос',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/la.svg',
        },
        name: 'Laos',
        code: 'LA',
        long_code: 'LAO',
        federation_ru: 'Лаосская',
        federation_en: 'Lao Republic',
        region: 1,
    },
    {
        id: 164,
        name_localization: {
            en: 'Latvia',
            kz: 'Latvia',
            ru: 'Латвия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/lv.svg',
        },
        name: 'Latvia',
        code: 'LV',
        long_code: 'LAT',
        federation_ru: 'Латвийская',
        federation_en: 'Latvian',
        region: 2,
    },
    {
        id: 105,
        name_localization: {
            en: 'Lebanon',
            kz: 'Lebanon',
            ru: 'Ливан',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/lb.svg',
        },
        name: 'Lebanon',
        code: 'LB',
        long_code: 'LBN',
        federation_ru: 'Ливанская',
        federation_en: 'Lebanese',
        region: 1,
    },
    {
        id: 20,
        name_localization: {
            en: 'Lesotho',
            kz: 'Lesotho',
            ru: 'Лесото',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ls.svg',
        },
        name: 'Lesotho',
        code: 'LS',
        long_code: 'LES',
        federation_ru: 'Лесотская',
        federation_en: 'Lesotho',
        region: 3,
    },
    {
        id: 21,
        name_localization: {
            en: 'Liberia',
            kz: 'Liberia',
            ru: 'Либерия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/lr.svg',
        },
        name: 'Liberia',
        code: 'LR',
        long_code: 'LBR',
        federation_ru: 'Либерийская',
        federation_en: 'Liberian',
        region: 3,
    },
    {
        id: 22,
        name_localization: {
            en: 'Libya',
            kz: 'Libya',
            ru: 'Ливия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ly.svg',
        },
        name: 'Libya',
        code: 'LY',
        long_code: 'LBA',
        federation_ru: 'Ливийскя',
        federation_en: 'Libyan',
        region: 3,
    },
    {
        id: 165,
        name_localization: {
            en: 'Liechtenstein',
            kz: 'Liechtenstein',
            ru: 'Лихтенштейн',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/li.svg',
        },
        name: 'Liechtenstein',
        code: 'LI',
        long_code: 'LIE',
        federation_ru: 'Лихтенштейнская',
        federation_en: 'Liechtensteiner',
        region: 2,
    },
    {
        id: 166,
        name_localization: {
            en: 'Lithuania',
            kz: 'Lithuania',
            ru: 'Литва',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/lt.svg',
        },
        name: 'Lithuania',
        code: 'LT',
        long_code: 'LTU',
        federation_ru: 'Литовская',
        federation_en: 'Lithuanian',
        region: 2,
    },
    {
        id: 167,
        name_localization: {
            en: 'Luxembourg',
            kz: 'Luxembourg',
            ru: 'Люксембург',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/lu.svg',
        },
        name: 'Luxembourg',
        code: 'LU',
        long_code: 'LUX',
        federation_ru: 'Люксембургская',
        federation_en: 'Luxembourgish',
        region: 2,
    },
    {
        id: 106,
        name_localization: {
            en: 'Macau',
            kz: 'Macau',
            ru: 'Macau',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/mo.svg',
        },
        name: 'Macau',
        code: 'MO',
        long_code: 'MAC',
        federation_ru: 'Макао',
        federation_en: 'Macao',
        region: 1,
    },
    {
        id: 23,
        name_localization: {
            en: 'Madagascar',
            kz: 'Madagascar',
            ru: 'Мадагаскар',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/mg.svg',
        },
        name: 'Madagascar',
        code: 'MG',
        long_code: 'MAD',
        federation_ru: 'Мадагаскарская',
        federation_en: 'Malagasy',
        region: 3,
    },
    {
        id: 24,
        name_localization: {
            en: 'Malawi',
            kz: 'Malawi',
            ru: 'Малави',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/mw.svg',
        },
        name: 'Malawi',
        code: 'MW',
        long_code: 'MAW',
        federation_ru: 'Малавийская',
        federation_en: 'Malawian',
        region: 3,
    },
    {
        id: 107,
        name_localization: {
            en: 'Malaysia',
            kz: 'Malaysia',
            ru: 'Малайзия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/my.svg',
        },
        name: 'Malaysia',
        code: 'MY',
        long_code: 'MAS',
        federation_ru: 'Малайзийская',
        federation_en: 'Malaysian',
        region: 1,
    },
    {
        id: 108,
        name_localization: {
            en: 'Maldives',
            kz: 'Maldives',
            ru: 'Мальдивы',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/mv.svg',
        },
        name: 'Maldives',
        code: 'MV',
        long_code: 'MDV',
        federation_ru: 'Мальдивская',
        federation_en: 'Maldivian',
        region: 1,
    },
    {
        id: 25,
        name_localization: {
            en: 'Mali',
            kz: 'Mali',
            ru: 'Мали',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ml.svg',
        },
        name: 'Mali',
        code: 'ML',
        long_code: 'MLI',
        federation_ru: 'Малийскя',
        federation_en: 'Malinese',
        region: 3,
    },
    {
        id: 168,
        name_localization: {
            en: 'Malta',
            kz: 'Malta',
            ru: 'Мальта',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/mt.svg',
        },
        name: 'Malta',
        code: 'MT',
        long_code: 'MLT',
        federation_ru: 'Мальтийская',
        federation_en: 'Maltese',
        region: 2,
    },
    {
        id: 26,
        name_localization: {
            en: 'Mauritania',
            kz: 'Mauritania',
            ru: 'Мавритания',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/mr.svg',
        },
        name: 'Mauritania',
        code: 'MR',
        long_code: 'MTN',
        federation_ru: 'Мавританская',
        federation_en: 'Mauritanian',
        region: 3,
    },
    {
        id: 27,
        name_localization: {
            en: 'Mauritius',
            kz: 'Mauritius',
            ru: 'Маврикий',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/mu.svg',
        },
        name: 'Mauritius',
        code: 'MU',
        long_code: 'MRI',
        federation_ru: 'Маврикийскя',
        federation_en: 'Mauritian',
        region: 3,
    },
    {
        id: 70,
        name_localization: {
            en: 'Mexico',
            kz: 'Mexico',
            ru: 'Мексика',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/mx.svg',
        },
        name: 'Mexico',
        code: 'MX',
        long_code: 'MEX',
        federation_ru: 'Мексиканаская',
        federation_en: 'Mexicano',
        region: 9,
    },
    {
        id: 169,
        name_localization: {
            en: 'Moldova',
            kz: 'Moldova',
            ru: 'Молдавия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/md.svg',
        },
        name: 'Moldova',
        code: 'MD',
        long_code: 'MDA',
        federation_ru: 'Молдовская',
        federation_en: 'Moldovian',
        region: 2,
    },
    {
        id: 170,
        name_localization: {
            en: 'Monaco',
            kz: 'Monaco',
            ru: 'Монако',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/mc.svg',
        },
        name: 'Monaco',
        code: 'MC',
        long_code: 'MNC',
        federation_ru: 'Монакская',
        federation_en: 'Monaco',
        region: 2,
    },
    {
        id: 109,
        name_localization: {
            en: 'Mongolia',
            kz: 'Mongolia',
            ru: 'Монголия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/mn.svg',
        },
        name: 'Mongolia',
        code: 'MN',
        long_code: 'MGL',
        federation_ru: 'Монгольская',
        federation_en: 'Mongolian',
        region: 1,
    },
    {
        id: 171,
        name_localization: {
            en: 'Montenegro',
            kz: 'Montenegro',
            ru: 'Черногория',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/me.svg',
        },
        name: 'Montenegro',
        code: 'ME',
        long_code: 'MNE',
        federation_ru: 'Черногорская',
        federation_en: 'Montenegrin',
        region: 2,
    },
    {
        id: 28,
        name_localization: {
            en: 'Morocco',
            kz: 'Morocco',
            ru: 'Марокко',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ma.svg',
        },
        name: 'Morocco',
        code: 'MA',
        long_code: 'MAR',
        federation_ru: 'Мароккская',
        federation_en: 'Moroccan',
        region: 3,
    },
    {
        id: 29,
        name_localization: {
            en: 'Mozambique',
            kz: 'Mozambique',
            ru: 'Мозамбик',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/mz.svg',
        },
        name: 'Mozambique',
        code: 'MZ',
        long_code: 'MOZ',
        federation_ru: 'Мозамбикская',
        federation_en: 'Mozambican',
        region: 3,
    },
    {
        id: 110,
        name_localization: {
            en: 'Myanmar',
            kz: 'Myanmar',
            ru: 'Myanmar',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/mm.svg',
        },
        name: 'Myanmar',
        code: 'MM',
        long_code: 'MYA',
        federation_ru: 'Мьянмская',
        federation_en: 'Myanmarese',
        region: 1,
    },
    {
        id: 30,
        name_localization: {
            en: 'Namibia',
            kz: 'Namibia',
            ru: 'Намибия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/na.svg',
        },
        name: 'Namibia',
        code: 'NA',
        long_code: 'NAM',
        federation_ru: 'Намибийская',
        federation_en: 'Namibian',
        region: 3,
    },
    {
        id: 111,
        name_localization: {
            en: 'Nauru',
            kz: 'Nauru',
            ru: 'Науру',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/nr.svg',
        },
        name: 'Nauru',
        code: 'NR',
        long_code: 'NRU',
        federation_ru: 'Науруаньская',
        federation_en: 'Nauruan',
        region: 1,
    },
    {
        id: 112,
        name_localization: {
            en: 'Nepal',
            kz: 'Nepal',
            ru: 'Непал',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/np.svg',
        },
        name: 'Nepal',
        code: 'NP',
        long_code: 'NEP',
        federation_ru: 'Непальская',
        federation_en: 'Nepali',
        region: 1,
    },
    {
        id: 172,
        name_localization: {
            en: 'Netherlands',
            kz: 'Netherlands',
            ru: 'Нидерланды',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/nl.svg',
        },
        name: 'Netherlands',
        code: 'NL',
        long_code: 'NED',
        federation_ru: 'Голландская',
        federation_en: 'Netherlandic',
        region: 2,
    },
    {
        id: 71,
        name_localization: {
            en: 'Netherlands Antilles',
            kz: 'Netherlands Antilles',
            ru: 'Нидерландские Антильские острова',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ah.svg',
        },
        name: 'Netherlands Antilles',
        code: 'AH',
        long_code: 'AHO',
        federation_ru: 'Антильская',
        federation_en: 'Netherlands Antilles',
        region: 9,
    },
    {
        id: 113,
        name_localization: {
            en: 'New Zealand',
            kz: 'New Zealand',
            ru: 'Новая Зеландия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/nz.svg',
        },
        name: 'New Zealand',
        code: 'NZ',
        long_code: 'NZL',
        federation_ru: 'Новозеландская',
        federation_en: 'New Zeland',
        region: 1,
    },
    {
        id: 72,
        name_localization: {
            en: 'Nicaragua',
            kz: 'Nicaragua',
            ru: 'Никарагуа',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ni.svg',
        },
        name: 'Nicaragua',
        code: 'NI',
        long_code: 'NCA',
        federation_ru: 'Никарагуанская',
        federation_en: 'Nicoya',
        region: 9,
    },
    {
        id: 199,
        name_localization: {
            en: 'Niger',
            kz: 'Niger',
            ru: 'Нигер',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/Niger.svg',
        },
        name: 'Niger',
        code: 'NE',
        long_code: 'NER',
        federation_ru: 'Нигерийская',
        federation_en: 'Niger',
        region: 3,
    },
    {
        id: 31,
        name_localization: {
            en: 'Nigeria',
            kz: 'Nigeria',
            ru: 'Нигерия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ng.svg',
        },
        name: 'Nigeria',
        code: 'NG',
        long_code: 'NGR',
        federation_ru: 'Нигерийская',
        federation_en: 'Nigerian',
        region: 3,
    },
    {
        id: 205,
        name_localization: {
            en: 'North Macedonia',
            kz: 'North Macedonia',
            ru: 'Северная Македония',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/North_Macedonia.svg',
        },
        name: 'North Macedonia',
        code: 'MK',
        long_code: 'MKD',
        federation_ru: 'Македонская',
        federation_en: 'North Macedonia',
        region: 2,
    },
    {
        id: 173,
        name_localization: {
            en: 'Norway',
            kz: 'Norway',
            ru: 'Норвегия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/no.svg',
        },
        name: 'Norway',
        code: 'NO',
        long_code: 'NOR',
        federation_ru: 'Норвежская',
        federation_en: 'Norwegian',
        region: 2,
    },
    {
        id: 114,
        name_localization: {
            en: 'Oman',
            kz: 'Oman',
            ru: 'Оман',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/om.svg',
        },
        name: 'Oman',
        code: 'OM',
        long_code: 'OMA',
        federation_ru: 'Оманская',
        federation_en: 'Omani',
        region: 1,
    },
    {
        id: 115,
        name_localization: {
            en: 'Pakistan',
            kz: 'Pakistan',
            ru: 'Пакистан',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/pk.svg',
        },
        name: 'Pakistan',
        code: 'PK',
        long_code: 'PAK',
        federation_ru: 'Пакистанская',
        federation_en: 'Pakistani',
        region: 1,
    },
    {
        id: 116,
        name_localization: {
            en: 'Palau',
            kz: 'Palau',
            ru: 'Палау',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/pw.svg',
        },
        name: 'Palau',
        code: 'PW',
        long_code: 'PLW',
        federation_ru: 'Палауанская',
        federation_en: 'Palauan',
        region: 1,
    },
    {
        id: 117,
        name_localization: {
            en: 'Palestine',
            kz: 'Palestine',
            ru: 'Palestine',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ps.svg',
        },
        name: 'Palestine',
        code: 'PS',
        long_code: 'PLE',
        federation_ru: 'Палестинская',
        federation_en: 'State of Palestine',
        region: 1,
    },
    {
        id: 73,
        name_localization: {
            en: 'Panama',
            kz: 'Panama',
            ru: 'Панама',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/pa.svg',
        },
        name: 'Panama',
        code: 'PA',
        long_code: 'PAN',
        federation_ru: 'Панамская',
        federation_en: 'Panamanian',
        region: 9,
    },
    {
        id: 118,
        name_localization: {
            en: 'Papua New Guinea',
            kz: 'Papua New Guinea',
            ru: 'Папуа – Новая Гвинея',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/pg.svg',
        },
        name: 'Papua New Guinea',
        code: 'PG',
        long_code: 'PNG',
        federation_ru: 'Папуаская',
        federation_en: 'Papuan',
        region: 1,
    },
    {
        id: 74,
        name_localization: {
            en: 'Paraguay',
            kz: 'Paraguay',
            ru: 'Парагвай',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/py.svg',
        },
        name: 'Paraguay',
        code: 'PY',
        long_code: 'PAR',
        federation_ru: 'Парагвайская',
        federation_en: 'Paraguayan',
        region: 9,
    },
    {
        id: 75,
        name_localization: {
            en: 'Peru',
            kz: 'Peru',
            ru: 'Перу',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/pe.svg',
        },
        name: 'Peru',
        code: 'PE',
        long_code: 'PER',
        federation_ru: 'Перуанская',
        federation_en: 'Peruvian',
        region: 9,
    },
    {
        id: 119,
        name_localization: {
            en: 'Philippines',
            kz: 'Philippines',
            ru: 'Филиппины',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ph.svg',
        },
        name: 'Philippines',
        code: 'PH',
        long_code: 'PHI',
        federation_ru: 'Филиппинская',
        federation_en: 'Philippine',
        region: 1,
    },
    {
        id: 174,
        name_localization: {
            en: 'Poland',
            kz: 'Poland',
            ru: 'Польша',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/pl.svg',
        },
        name: 'Poland',
        code: 'PL',
        long_code: 'POL',
        federation_ru: 'Польская',
        federation_en: 'Polish',
        region: 2,
    },
    {
        id: 175,
        name_localization: {
            en: 'Portugal',
            kz: 'Portugal',
            ru: 'Португалия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/pt.svg',
        },
        name: 'Portugal',
        code: 'PT',
        long_code: 'POR',
        federation_ru: 'Португальская',
        federation_en: 'Portuguese',
        region: 2,
    },
    {
        id: 76,
        name_localization: {
            en: 'Puerto Rico',
            kz: 'Puerto Rico',
            ru: 'Пуэрто-Рико',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/pr.svg',
        },
        name: 'Puerto Rico',
        code: 'PR',
        long_code: 'PUR',
        federation_ru: 'Пуэрто-Риканская',
        federation_en: 'Puerto Rican',
        region: 9,
    },
    {
        id: 120,
        name_localization: {
            en: 'Qatar',
            kz: 'Qatar',
            ru: 'Катар',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/qa.svg',
        },
        name: 'Qatar',
        code: 'QA',
        long_code: 'QAT',
        federation_ru: 'Катарская',
        federation_en: 'Qatari',
        region: 1,
    },
    {
        id: 176,
        name_localization: {
            en: 'Romania',
            kz: 'Romania',
            ru: 'Румыния',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ro.svg',
        },
        name: 'Romania',
        code: 'RO',
        long_code: 'ROU',
        federation_ru: 'Румынская',
        federation_en: 'Romanian',
        region: 2,
    },
    {
        id: 177,
        name_localization: {
            en: 'Russia',
            kz: 'Russia',
            ru: 'Россия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ru.svg',
        },
        name: 'Russia',
        code: 'RU',
        long_code: 'RUS',
        federation_ru: 'Российская',
        federation_en: 'Russian',
        region: 1,
    },
    {
        id: 32,
        name_localization: {
            en: 'Rwanda',
            kz: 'Rwanda',
            ru: 'Руанда',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/rw.svg',
        },
        name: 'Rwanda',
        code: 'RW',
        long_code: 'RWA',
        federation_ru: 'Руандийскя',
        federation_en: 'Rwandese',
        region: 3,
    },
    {
        id: 206,
        name_localization: {
            en: 'Saint Kitts and Nevis',
            kz: 'Saint Kitts and Nevis',
            ru: 'Saint Kitts and Nevis',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/kna.png',
        },
        name: 'Saint Kitts and Nevis',
        code: 'KN',
        long_code: 'KNA',
        federation_ru: 'Saint Kitts and Nevis',
        federation_en: 'Saint Kitts and Nevis',
        region: 9,
    },
    {
        id: 191,
        name_localization: {
            en: 'Saint Lucia',
            kz: 'Saint Lucia',
            ru: 'Saint Lucia',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/lc.svg',
        },
        name: 'Saint Lucia',
        code: 'LC',
        long_code: 'LCA',
        federation_ru: 'Сент-Люсийская',
        federation_en: 'Saint Lucian',
        region: 9,
    },
    {
        id: 203,
        name_localization: {
            en: 'Saint Vincent',
            kz: 'Saint Vincent',
            ru: 'Сент-Винсент',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/Saint_Vincent_and_the_Grenadines.svg',
        },
        name: 'Saint Vincent',
        code: 'VC',
        long_code: 'VCT',
        federation_ru: 'Сент-Винсент',
        federation_en: 'Saint Vincent',
        region: 9,
    },
    {
        id: 178,
        name_localization: {
            en: 'San Marino',
            kz: 'San Marino',
            ru: 'Сан-Марино',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/sm.svg',
        },
        name: 'San Marino',
        code: 'SM',
        long_code: 'SMR',
        federation_ru: 'Сан-Маринская',
        federation_en: 'San Marino',
        region: 2,
    },
    {
        id: 33,
        name_localization: {
            en: 'Sao Tome and Principe',
            kz: 'Sao Tome and Principe',
            ru: 'Sao Tome and Principe',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/st.svg',
        },
        name: 'Sao Tome and Principe',
        code: 'ST',
        long_code: 'STP',
        federation_ru: 'Сан-Томе и Принсипи',
        federation_en: 'Tomé and Príncipe',
        region: 3,
    },
    {
        id: 121,
        name_localization: {
            en: 'Saudi Arabia',
            kz: 'Saudi Arabia',
            ru: 'Саудовская Аравия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/sa.svg',
        },
        name: 'Saudi Arabia',
        code: 'SA',
        long_code: 'KSA',
        federation_ru: 'Саудоаравийская',
        federation_en: 'Saudi Arabian',
        region: 1,
    },
    {
        id: 179,
        name_localization: {
            en: 'Scotland',
            kz: 'Scotland',
            ru: 'Scotland',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/scotland.svg',
        },
        name: 'Scotland',
        code: 'SZ',
        long_code: 'SCO',
        federation_ru: 'Шотландская',
        federation_en: 'Scottish',
        region: 2,
    },
    {
        id: 34,
        name_localization: {
            en: 'Senegal',
            kz: 'Senegal',
            ru: 'Сенегал',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/sn.svg',
        },
        name: 'Senegal',
        code: 'SN',
        long_code: 'SEN',
        federation_ru: 'Сенегалская',
        federation_en: 'Senegalese',
        region: 3,
    },
    {
        id: 180,
        name_localization: {
            en: 'Serbia',
            kz: 'Serbia',
            ru: 'Сербия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/rs.svg',
        },
        name: 'Serbia',
        code: 'RS',
        long_code: 'SRB',
        federation_ru: 'Сербская',
        federation_en: 'Serbian',
        region: 2,
    },
    {
        id: 35,
        name_localization: {
            en: 'Seychelles',
            kz: 'Seychelles',
            ru: 'Сейшельские острова',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/sc.svg',
        },
        name: 'Seychelles',
        code: 'SC',
        long_code: 'SEY',
        federation_ru: 'Сейшельская',
        federation_en: 'Seychellois',
        region: 3,
    },
    {
        id: 36,
        name_localization: {
            en: 'Sierra Leone',
            kz: 'Sierra Leone',
            ru: 'Сьерра-Леоне',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/sl.svg',
        },
        name: 'Sierra Leone',
        code: 'SL',
        long_code: 'SLE',
        federation_ru: 'Сьерра-Леонская',
        federation_en: 'Sierra Leonean',
        region: 3,
    },
    {
        id: 122,
        name_localization: {
            en: 'Singapore',
            kz: 'Singapore',
            ru: 'Сингапур',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/sg.svg',
        },
        name: 'Singapore',
        code: 'SG',
        long_code: 'SGP',
        federation_ru: 'Сингапурская',
        federation_en: 'Singaporean',
        region: 1,
    },
    {
        id: 181,
        name_localization: {
            en: 'Slovakia',
            kz: 'Slovakia',
            ru: 'Словакия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/sk.svg',
        },
        name: 'Slovakia',
        code: 'SK',
        long_code: 'SVK',
        federation_ru: 'Словакская',
        federation_en: 'Slovakian',
        region: 2,
    },
    {
        id: 182,
        name_localization: {
            en: 'Slovenia',
            kz: 'Slovenia',
            ru: 'Словения',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/si.svg',
        },
        name: 'Slovenia',
        code: 'SI',
        long_code: 'SLO',
        federation_ru: 'Словенская',
        federation_en: 'Slovenian',
        region: 2,
    },
    {
        id: 123,
        name_localization: {
            en: 'Solomon Islands',
            kz: 'Solomon Islands',
            ru: 'Соломоновы острова',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/sb.svg',
        },
        name: 'Solomon Islands',
        code: 'SB',
        long_code: 'SOL',
        federation_ru: 'Соломоновская',
        federation_en: 'Solomon Islander',
        region: 1,
    },
    {
        id: 37,
        name_localization: {
            en: 'Somalia',
            kz: 'Somalia',
            ru: 'Сомали',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/so.svg',
        },
        name: 'Somalia',
        code: 'SO',
        long_code: 'SOM',
        federation_ru: 'Сомалийская',
        federation_en: 'Somalian',
        region: 3,
    },
    {
        id: 38,
        name_localization: {
            en: 'South Africa',
            kz: 'South Africa',
            ru: 'Южная Африка',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/za.svg',
        },
        name: 'South Africa',
        code: 'ZA',
        long_code: 'RSA',
        federation_ru: 'Южно Африканская',
        federation_en: 'South African',
        region: 3,
    },
    {
        id: 124,
        name_localization: {
            en: 'South Korea',
            kz: 'South Korea',
            ru: 'Южная Корея',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/kr.svg',
        },
        name: 'South Korea',
        code: 'KR',
        long_code: 'KOR',
        federation_ru: 'Корейская',
        federation_en: 'Korean',
        region: 1,
    },
    {
        id: 39,
        name_localization: {
            en: 'South Sudan',
            kz: 'South Sudan',
            ru: 'Южный Судан',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ss.svg',
        },
        name: 'South Sudan',
        code: 'SS',
        long_code: 'SSD',
        federation_ru: 'Южно Суданская',
        federation_en: 'South Sudanese',
        region: 3,
    },
    {
        id: 183,
        name_localization: {
            en: 'Spain',
            kz: 'Spain',
            ru: 'Испания',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/es.svg',
        },
        name: 'Spain',
        code: 'ES',
        long_code: 'ESP',
        federation_ru: 'Испанская',
        federation_en: 'Spanish',
        region: 2,
    },
    {
        id: 125,
        name_localization: {
            en: 'Sri Lanka',
            kz: 'Sri Lanka',
            ru: 'Шри-Ланка',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/lk.svg',
        },
        name: 'Sri Lanka',
        code: 'LK',
        long_code: 'SRI',
        federation_ru: 'Шри-Ланкская',
        federation_en: 'Sri Lankan',
        region: 1,
    },
    {
        id: 40,
        name_localization: {
            en: 'Sudan',
            kz: 'Sudan',
            ru: 'Судан',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/sd.svg',
        },
        name: 'Sudan',
        code: 'SD',
        long_code: 'SUD',
        federation_ru: 'Суданская',
        federation_en: 'Sudanese',
        region: 3,
    },
    {
        id: 77,
        name_localization: {
            en: 'Suriname',
            kz: 'Suriname',
            ru: 'Суринам',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/sr.svg',
        },
        name: 'Suriname',
        code: 'SR',
        long_code: 'SUR',
        federation_ru: 'Суринамская',
        federation_en: 'Surinamese',
        region: 9,
    },
    {
        id: 41,
        name_localization: {
            en: 'Swaziland',
            kz: 'Swaziland',
            ru: 'Swaziland',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/sz.svg',
        },
        name: 'Swaziland',
        code: 'SZ',
        long_code: 'SWZ',
        federation_ru: 'Свазилендская',
        federation_en: 'Swaziland',
        region: 3,
    },
    {
        id: 184,
        name_localization: {
            en: 'Sweden',
            kz: 'Sweden',
            ru: 'Швеция',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/se.svg',
        },
        name: 'Sweden',
        code: 'SE',
        long_code: 'SWE',
        federation_ru: 'Шведская',
        federation_en: 'Swedish',
        region: 2,
    },
    {
        id: 185,
        name_localization: {
            en: 'Switzerland',
            kz: 'Switzerland',
            ru: 'Швейцария',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ch.svg',
        },
        name: 'Switzerland',
        code: 'CH',
        long_code: 'SUI',
        federation_ru: 'Швейцарская',
        federation_en: 'Swiss',
        region: 2,
    },
    {
        id: 126,
        name_localization: {
            en: 'Syria',
            kz: 'Syria',
            ru: 'Сирия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/sy.svg',
        },
        name: 'Syria',
        code: 'SY',
        long_code: 'SYR',
        federation_ru: 'Сирийская',
        federation_en: 'Syrian',
        region: 1,
    },
    {
        id: 127,
        name_localization: {
            en: 'Tajikistan',
            kz: 'Tajikistan',
            ru: 'Таджикистан',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/tj.svg',
        },
        name: 'Tajikistan',
        code: 'TJ',
        long_code: 'TJK',
        federation_ru: 'Таджикистанская',
        federation_en: 'Tajikistani',
        region: 1,
    },
    {
        id: 42,
        name_localization: {
            en: 'Tanzania',
            kz: 'Tanzania',
            ru: 'Танзания',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/tz.svg',
        },
        name: 'Tanzania',
        code: 'TZ',
        long_code: 'TAN',
        federation_ru: 'Танзанийския',
        federation_en: 'Republic of Tanzania',
        region: 3,
    },
    {
        id: 128,
        name_localization: {
            en: 'Thailand',
            kz: 'Thailand',
            ru: 'Тайланд',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/th.svg',
        },
        name: 'Thailand',
        code: 'TH',
        long_code: 'THA',
        federation_ru: 'Таиландская',
        federation_en: 'Thai',
        region: 1,
    },
    {
        id: 129,
        name_localization: {
            en: 'Timor-Leste',
            kz: 'Timor-Leste',
            ru: 'Тимор-Лесте',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/tl.svg',
        },
        name: 'Timor-Leste',
        code: 'TL',
        long_code: 'TLS',
        federation_ru: 'Восточнотиморская',
        federation_en: 'Timorese',
        region: 1,
    },
    {
        id: 43,
        name_localization: {
            en: 'Togo',
            kz: 'Togo',
            ru: 'Того',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/tg.svg',
        },
        name: 'Togo',
        code: 'TG',
        long_code: 'TOG',
        federation_ru: 'Тогийская',
        federation_en: 'Togolese',
        region: 3,
    },
    {
        id: 78,
        name_localization: {
            en: 'Trinidad & Tobago',
            kz: 'Trinidad & Tobago',
            ru: 'Тринидад и Тобаго',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/tt.svg',
        },
        name: 'Trinidad & Tobago',
        code: 'TT',
        long_code: 'TTO',
        federation_ru: 'Тринидадская',
        federation_en: 'Republic of Trinidad and Tobago',
        region: 9,
    },
    {
        id: 44,
        name_localization: {
            en: 'Tunisia',
            kz: 'Tunisia',
            ru: 'Тунис',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/tn.svg',
        },
        name: 'Tunisia',
        code: 'TN',
        long_code: 'TUN',
        federation_ru: 'Тунисская',
        federation_en: 'Tunisian',
        region: 3,
    },
    {
        id: 186,
        name_localization: {
            en: 'Turkey',
            kz: 'Turkey',
            ru: 'Турция',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/tr.svg',
        },
        name: 'Turkey',
        code: 'TR',
        long_code: 'TUR',
        federation_ru: 'Турецкая',
        federation_en: 'Turkish',
        region: 2,
    },
    {
        id: 130,
        name_localization: {
            en: 'Turkmenistan',
            kz: 'Turkmenistan',
            ru: 'Туркмения',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/tm.svg',
        },
        name: 'Turkmenistan',
        code: 'TM',
        long_code: 'TKM',
        federation_ru: 'Туркменистанская',
        federation_en: 'Turkmenistani',
        region: 1,
    },
    {
        id: 45,
        name_localization: {
            en: 'Uganda',
            kz: 'Uganda',
            ru: 'Уганда',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ug.svg',
        },
        name: 'Uganda',
        code: 'UG',
        long_code: 'UGA',
        federation_ru: 'Угандская',
        federation_en: 'Ugandan',
        region: 3,
    },
    {
        id: 187,
        name_localization: {
            en: 'Ukraine',
            kz: 'Ukraine',
            ru: 'Украина',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ua.svg',
        },
        name: 'Ukraine',
        code: 'UA',
        long_code: 'UKR',
        federation_ru: 'Украинская',
        federation_en: 'Ukrainian',
        region: 2,
    },
    {
        id: 131,
        name_localization: {
            en: 'United Arab Emirates',
            kz: 'United Arab Emirates',
            ru: 'Объединённые Арабские Эмираты',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ae.svg',
        },
        name: 'United Arab Emirates',
        code: 'AE',
        long_code: 'UAE',
        federation_ru: 'Объединённые Арабские Эмираты',
        federation_en: 'Emirian',
        region: 1,
    },
    {
        id: 79,
        name_localization: {
            en: 'United States of America',
            kz: 'United States of America',
            ru: 'United States of America',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/us.svg',
        },
        name: 'United States of America',
        code: 'US',
        long_code: 'USA',
        federation_ru: 'Американская',
        federation_en: 'US',
        region: 9,
    },
    {
        id: 80,
        name_localization: {
            en: 'Uruguay',
            kz: 'Uruguay',
            ru: 'Уругвай',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/uy.svg',
        },
        name: 'Uruguay',
        code: 'UY',
        long_code: 'URU',
        federation_ru: 'Уругвайская',
        federation_en: 'Uruguayan',
        region: 9,
    },
    {
        id: 81,
        name_localization: {
            en: 'US Virgin Islands',
            kz: 'US Virgin Islands',
            ru: 'Вирджинские острова',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/vi.svg',
        },
        name: 'US Virgin Islands',
        code: 'VI',
        long_code: 'ISV',
        federation_ru: 'Вирджинская',
        federation_en: 'Virgin Islands',
        region: 9,
    },
    {
        id: 132,
        name_localization: {
            en: 'Uzbekistan',
            kz: 'Uzbekistan',
            ru: 'Узбекистан',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/uz.svg',
        },
        name: 'Uzbekistan',
        code: 'UZ',
        long_code: 'UZB',
        federation_ru: 'Узбекистанская',
        federation_en: 'Uzbekistani',
        region: 1,
    },
    {
        id: 82,
        name_localization: {
            en: 'Venezuela',
            kz: 'Venezuela',
            ru: 'Венесуэла',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ve.svg',
        },
        name: 'Venezuela',
        code: 'VE',
        long_code: 'VEN',
        federation_ru: 'Боливарианская',
        federation_en: 'Bolivarian Republic of Venezuela',
        region: 9,
    },
    {
        id: 133,
        name_localization: {
            en: 'Vietnam',
            kz: 'Vietnam',
            ru: 'Вьетнам',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/vn.svg',
        },
        name: 'Vietnam',
        code: 'VN',
        long_code: 'VIE',
        federation_ru: 'Вьетнамская',
        federation_en: 'Vietnamese',
        region: 1,
    },
    {
        id: 188,
        name_localization: {
            en: 'Wales',
            kz: 'Wales',
            ru: 'Wales',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/wales.svg',
        },
        name: 'Wales',
        code: 'WA',
        long_code: 'WLS',
        federation_ru: 'Уэльская',
        federation_en: 'Wales',
        region: 2,
    },
    {
        id: 134,
        name_localization: {
            en: 'Yemen',
            kz: 'Yemen',
            ru: 'Йемен',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/ye.svg',
        },
        name: 'Yemen',
        code: 'YE',
        long_code: 'YEM',
        federation_ru: 'Йеменская',
        federation_en: 'Yemenite',
        region: 1,
    },
    {
        id: 46,
        name_localization: {
            en: 'Zambia',
            kz: 'Zambia',
            ru: 'Замбия',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/zm.svg',
        },
        name: 'Zambia',
        code: 'ZM',
        long_code: 'ZAM',
        federation_ru: 'Замбийская',
        federation_en: 'Zambian',
        region: 3,
    },
    {
        id: 47,
        name_localization: {
            en: 'Zimbabwe',
            kz: 'Zimbabwe',
            ru: 'Зимбабве',
        },
        flag_image: {
            svg: 'https://gaming-images.worldchess.com/media/flags/zw.svg',
        },
        name: 'Zimbabwe',
        code: 'ZW',
        long_code: 'ZIM',
        federation_ru: 'Зимбабвийская',
        federation_en: 'Zimbabwean',
        region: 3,
    },
];
