"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const style_1 = require("./style");
const Loader = (props) => {
    const { size = 'm', display = 'block', centered, isStatic = false } = props;
    const renderLoader = (0, react_1.useMemo)(() => ((0, jsx_runtime_1.jsx)(style_1.$Loader, { "data-component": "Loader", "$size": size, "$display": display, children: (0, jsx_runtime_1.jsx)(style_1.$LoaderInner, { "$isStatic": isStatic, children: (0, jsx_runtime_1.jsx)(style_1.$LoaderSvg, { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", focusable: "false", children: (0, jsx_runtime_1.jsx)("path", { d: "M23.3660254,11.5 C23.5446582,11.8094011 23.5446582,12.1905989 23.3660254,12.5 L18.1160254,21.5932667 C17.9373926,21.9026678 17.6072656,22.0932667 17.25,22.0932667 L6.75,22.0932667 C6.39273441,22.0932667 6.06260739,21.9026678 5.8839746,21.5932667 L0.633974596,12.5 C0.455341801,12.1905989 0.455341801,11.8094011 0.633974596,11.5 L5.8839746,2.40673326 C6.06260739,2.09733218 6.39273441,1.90673326 6.75,1.90673326 L17.25,1.90673326 C17.6072656,1.90673326 17.9373926,2.09733218 18.1160254,2.40673326 L23.3660254,11.5 Z" }) }) }) })), [display, isStatic, size]);
    if (centered) {
        return (0, jsx_runtime_1.jsx)(style_1.$LoaderWrapper, { children: renderLoader });
    }
    return renderLoader;
};
exports.default = Loader;
