import { chessSide } from 'chessgun/core';
import {
  TGameTypeOptions,
  ActiveGame,
  TRatingTypeOptions,
  TTimeControlOptions,
} from './_types';

export const getChessSideFromBoolean = (booleanSide: boolean) =>
  booleanSide ? chessSide.WHITE : chessSide.BLACK;

const filterByGameType = (gameType: TGameTypeOptions) => (game: ActiveGame) => {
  if (gameType === 'tournaments')
    return Boolean(game.boardData?.tournament?.id);

  if (gameType === 'single') return !game.boardData?.tournament;

  return true;
};

const filterByRatingType =
  (ratingType: TRatingTypeOptions) => (game: ActiveGame) => {
    if (ratingType === 'all') return true;

    return ratingType === game.boardData?.rating;
  };

const filterByTimeControl =
  (timeControl: TTimeControlOptions) => (game: ActiveGame) => {
    if (timeControl === 'any') return true;

    return timeControl === game.boardData?.time_control.board_type_name;
  };

export const getFilteredGames = (
  games: ActiveGame[],
  {
    filterGameType,
    filterRatingType,
    filterTimeControl,
  }: {
    filterGameType: TGameTypeOptions;
    filterRatingType: TRatingTypeOptions;
    filterTimeControl: TTimeControlOptions;
  }
) => {
  let filteredGames = games;
  if (filterGameType !== 'allGames') {
    filteredGames = filteredGames.filter(filterByGameType(filterGameType));
  }

  if (filterRatingType !== 'all') {
    filteredGames = filteredGames.filter(filterByRatingType(filterRatingType));
  }

  if (filterTimeControl !== 'any') {
    filteredGames = filteredGames.filter(
      filterByTimeControl(filterTimeControl)
    );
  }

  return filteredGames;
};
