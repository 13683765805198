import React, {
  PropsWithChildren,
  useMemo,
  useReducer,
  useCallback,
  useEffect,
} from 'react';

import favicon from '@chessarena/components/lib/utils/favicon';
import { NotificationsOnItemClickParams } from '@chessarena/components/lib/layout/Notifications/types';
import { UnauthorizedNotificationDescription } from '@components/molecules/notifications/_UnauthorizedNotificationDescription';

import { useApplicationContext } from '@application';
import { ePopupPaths } from '@constants';
import { mainStore } from '@store/storeshed';
import { useAuthStore } from '@store/storeshed';
import { useUserDataStore } from '@store/storeshed';
import { openPopup } from '@utils/_router';
import {
  ApiNotification,
  notificationsService,
} from '@services/_notifications.service';

import { SESSION_STORAGE_KEYS } from './_constants';
import { NotificationsContext } from './_context';
import { notificationsReducer } from './_reducer';
import { useActions } from './_actions';
import { INotificationsContextProviderValue } from './_types';
import { adaptNotification } from './_utils';

export const NotificationsContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { localization: l, locale } = useApplicationContext();

  const [state, dispatch] = useReducer(notificationsReducer, {
    mounted: false,
    items: [],
  });

  const { items, mounted } = state;

  const { setMounted: setMountedAction, setItems: setItemsAction } =
    useActions(dispatch);

  const token = useAuthStore('token');
  const logged = useAuthStore('logged');
  const userDataRequest = useUserDataStore('data_request');

  const getNotificationsFromServer = useCallback(async () => {
    const token = mainStore.authStore.get('token') || undefined;
    try {
      const result = await notificationsService.getNotifications(token);
      return result.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }, []);

  const markNotificationReadOnServer = useCallback(async (id: string) => {
    const token = mainStore.authStore.get('token') || undefined;
    try {
      await notificationsService.markNotificationRead(id, token);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const markNotificationRead = useCallback(
    async (id: string) => {
      const { items } = state;
      const currentItem = items.find((item) => item.id === id);
      if (currentItem?.unread) {
        setItemsAction(
          items.map((item) =>
            item.id === id ? { ...item, unread: false } : item
          )
        );
        await markNotificationReadOnServer(id);
      }
    },
    [markNotificationReadOnServer, setItemsAction, state]
  );

  const markAllNotificationsRead = useCallback(async () => {
    const { items } = state;
    const unreadItems = items.filter((item) => item.unread);
    if (unreadItems.length) {
      await Promise.allSettled(
        unreadItems.map((item) => markNotificationReadOnServer(item.id))
      );
      setItemsAction(items.map((item) => ({ ...item, unread: false })));
    }
  }, [markNotificationReadOnServer, setItemsAction, state]);

  const captions = useMemo(
    () => ({
      title: l?.notifications?.title,
      empty: l?.notifications?.empty,
      makeAllAsRead: l?.notifications?.make_all_as_read,
    }),
    [l]
  );

  const makeUnauthorizedNotificationRead = useCallback(() => {
    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.UNAUTHORIZED_NOTIFICATION_READ,
      'true'
    );
    setItemsAction(
      items.map((item) =>
        item.id === 'unauthorizedNotification'
          ? { ...item, unread: false }
          : item
      )
    );
  }, [items, setItemsAction]);

  const unauthorizedNotification = useMemo(() => {
    return {
      id: 'unauthorizedNotification',
      title: l?.notifications?.unauthorized_notification?.title,
      description: (
        <UnauthorizedNotificationDescription
          makeUnauthorizedNotificationRead={makeUnauthorizedNotificationRead}
        />
      ),
    };
  }, [l, makeUnauthorizedNotificationRead]);

  const onMakeAllAsReadClick = useCallback(() => {
    if (!logged) {
      makeUnauthorizedNotificationRead();
    } else {
      markAllNotificationsRead();
    }
  }, [logged, makeUnauthorizedNotificationRead, markAllNotificationsRead]);

  const onItemClick = useCallback(
    ({ id }: NotificationsOnItemClickParams) => {
      if (id === 'unauthorizedNotification') {
        makeUnauthorizedNotificationRead();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        openPopup(`?popup=${ePopupPaths.SIGN_IN}`);
      } else {
        markNotificationRead(id);
      }
    },
    [makeUnauthorizedNotificationRead, markNotificationRead]
  );

  const unread = useMemo(() => {
    if (typeof window === undefined) return false;
    return !!items.find((item) => item.unread);
  }, [items]);

  const count = useMemo(() => {
    if (typeof window === undefined) return null;
    return items.filter((item) => item.unread).length;
  }, [items]);

  const addNewNotification = useCallback(
    (data: ApiNotification) => {
      setItemsAction([adaptNotification(data, l, locale), ...items]);
    },
    [items, l, locale, setItemsAction]
  );

  const value = useMemo(
    (): INotificationsContextProviderValue => ({
      captions,
      onMakeAllAsReadClick,
      onItemClick,
      items,
      unread,
      count,
      addNewNotification,
    }),
    [
      captions,
      onMakeAllAsReadClick,
      onItemClick,
      items,
      unread,
      count,
      addNewNotification,
    ]
  );

  useEffect(() => {
    if (mounted) return;
    favicon.init();
    setMountedAction(true);
  }, [mounted, setMountedAction]);

  useEffect(() => {
    if (!mounted) return;
    if (unread) favicon.attention('alert');
    else favicon.reset();

    return () => {
      favicon.reset();
    };
  }, [mounted, unread]);

  useEffect(() => {
    async function updateItems() {
      if (token) {
        const data = await getNotificationsFromServer();
        setItemsAction(data.map((item) => adaptNotification(item, l, locale)));
      }
    }

    if (userDataRequest) return;

    if (!logged) {
      setItemsAction([
        {
          ...unauthorizedNotification,
          unread:
            sessionStorage.getItem(
              SESSION_STORAGE_KEYS.UNAUTHORIZED_NOTIFICATION_READ
            ) !== 'true',
        },
      ]);
    } else {
      updateItems();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged, userDataRequest, l, locale, token]);

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};
