import { IncomingMessage, ServerResponse } from 'http';

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
}

export const createCookieExpirationTimeString = (days = 30) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  return 'expires=' + date.toUTCString();
};

export const createCookieString = (
  key: string,
  value: string,
  expDays = 30
) => {
  const expires = createCookieExpirationTimeString(expDays);
  return `${key}=${value}; ${expires}; path=/`;
};

export const setCookie = (key: string, value: string) => {
  document.cookie = createCookieString(key, value);
};
/**
 * Удалить куку в браузере
 * @param key
 */
export const deleteCookie = (key: string) => {
  document.cookie = key + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

/**
 * Удалить куку на сервере
 * @param res - oбъект ServerResponse для установки заголовка ответа и удаления куки
 * @param cookieName - имя куки
 */
export const deleteServerCookie = (
  res: ServerResponse<IncomingMessage>,
  cookieName: string
) => {
  res.setHeader(
    'Set-Cookie',
    `${cookieName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  );
};
