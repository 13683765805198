import { IBoardSettingsState } from 'shared/types';
import { useStore } from 'storeshed';
import { BoardSettingsStore } from './_BoardSettingsStore';

export const boardSettingsStore = new BoardSettingsStore();

type TUseBoardSettingsData = {
  <K extends keyof IBoardSettingsState>(key: K): IBoardSettingsState[K];
};

export const useBoardSettingsData: TUseBoardSettingsData = (key) => {
  return useStore(boardSettingsStore, key);
};
