import { figureType, moveTypes } from "./_constants";
import { pieceToType } from "./Piece";
export const setPiecePositions = ({ fen, pieces, moveData, }) => {
    switch (moveData.type) {
        case moveTypes.CASTLING: {
            const { king: { to: $kTo }, rook: { from: $rFrom, to: $rTo }, color, } = moveData;
            const king = pieces.find((piece) => !piece.dead && piece.color.value === color && piece.type.name === figureType.KING);
            const rook = pieces.find((piece) => !piece.dead &&
                piece.color.value === color &&
                piece.type.name === figureType.ROOK &&
                piece.position === $rFrom);
            if (!rook || !king) {
                throw new Error(`Can't find rook: ${rook} or king: ${king}. fen: ${fen}. moveData: ${moveData}`);
            }
            king.position = $kTo;
            rook.position = $rTo;
            break;
        }
        case moveTypes.PIECE: {
            const { from, to, color, capturedFigure } = moveData;
            const movingPiece = pieces.find((piece) => !piece.dead && piece.color.value === color && piece.position === from);
            if (!movingPiece) {
                throw new Error(`Can't find moving piece: ${moveData}. fen: ${fen}. moveData: ${moveData}`);
            }
            const capturedPiece = pieces.find((piece) => !piece.dead && piece.position === capturedFigure);
            if (capturedPiece) {
                capturedPiece.dead = true;
            }
            movingPiece.position = to;
            break;
        }
        case moveTypes.PAWN: {
            const { from, to, color, promotion, capturedFigure } = moveData;
            const movingPiece = pieces.find((piece) => !piece.dead && piece.color.value === color && piece.position === from);
            if (!movingPiece) {
                throw new Error(`Can't find moving pawn: ${moveData}. fen: ${fen}. moveData: ${moveData}`);
            }
            const capturedPiece = pieces.find((piece) => !piece.dead && piece.position === capturedFigure);
            if (capturedPiece) {
                capturedPiece.dead = true;
            }
            movingPiece.position = to;
            if (promotion) {
                movingPiece.type = pieceToType[promotion.toLowerCase()];
            }
        }
    }
    return pieces;
};
