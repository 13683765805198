import { isKingUnderAttack } from "./_isKingUnderAttack";
import { chessSide } from "../_constants";
/**
 * Проверяет возможные ходы, будет ли король после них под атакой
 * @param index {number} - Индекс фигуры на доске
 * @param possibleMoves {number[]} - Возможные ходы
 * @param color - {chessSide} - Цвет фигуры
 */
export const validatePossibleMoves = ({ index, possibleMoves, color, board, enPassant, }) => {
    possibleMoves.forEach((move, i) => {
        const $board = [...board];
        const piece = $board[index];
        $board[index] = "";
        if (enPassant && ["P", "p"].includes(piece) && move === enPassant) {
            const enPassantTargetIndex = color === chessSide.WHITE ? move + 16 : move - 16;
            $board[enPassantTargetIndex] = "";
        }
        const targetPiece = $board[move];
        $board[move] = piece;
        if (targetPiece === "k") {
            possibleMoves[i] = -1;
        }
        else if (isKingUnderAttack({ board: $board, kingColor: color })) {
            possibleMoves[i] = -1;
        }
    });
    return possibleMoves.filter((move) => move !== -1);
};
