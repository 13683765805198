import React from 'react';
import { shapeColors } from '../../../../styles';
/**
 * Иконка: Twitter
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 * @param {string} className - CSS класс иконки
 */
export const TwitterIcon = ({ fill = shapeColors['white'], width = '100%', height = '100%', className = '', }) => (React.createElement("svg", { className: className, width: width, height: height, viewBox: "0 0 14 12" },
    React.createElement("path", { d: "M4.39776 12C9.68067 12 12.5714 7.38189 12.5714 3.38386C12.5714 3.25394 12.5714 3.12402 12.5658 2.99409C13.1261 2.5689 13.6134 2.0315 14 1.42323C13.4846 1.66535 12.93 1.8248 12.3473 1.90157C12.9412 1.52953 13.395 0.933071 13.6134 0.224409C13.0588 0.572835 12.4426 0.820866 11.7871 0.956693C11.2605 0.366142 10.5154 0 9.69188 0C8.10644 0 6.81793 1.35827 6.81793 3.02953C6.81793 3.26575 6.84594 3.49606 6.89076 3.72047C4.5042 3.59646 2.38655 2.38583 0.969188 0.555118C0.722689 1.00394 0.582633 1.52362 0.582633 2.07874C0.582633 3.12992 1.09244 4.05709 1.85994 4.60039C1.38936 4.58268 0.946779 4.44685 0.560224 4.22244C0.560224 4.23425 0.560224 4.24606 0.560224 4.26378C0.560224 5.72835 1.55182 6.95669 2.86275 7.23425C2.62185 7.30512 2.36975 7.34055 2.10644 7.34055C1.92157 7.34055 1.7423 7.32283 1.56863 7.2874C1.93277 8.49213 2.9972 9.36614 4.2521 9.38976C3.26611 10.2047 2.02801 10.689 0.683473 10.689C0.453782 10.689 0.22409 10.6772 0 10.6476C1.26611 11.498 2.77871 12 4.39776 12Z", fill: fill })));
