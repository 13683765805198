import { GameRatingMode, BoardType } from 'shared/types';

export const DEFAULT_RATING_LIMINTS = {
  [GameRatingMode.FIDERATED]: {
    [BoardType.BLITZ]: { lower: 0, upper: 0 },
    [BoardType.RAPID]: { lower: 0, upper: 0 },
    [BoardType.BULLET]: { lower: 0, upper: 0 },
  },
  [GameRatingMode.RATED]: {
    [BoardType.BLITZ]: { lower: 0, upper: 0 },
    [BoardType.RAPID]: { lower: 0, upper: 0 },
    [BoardType.BULLET]: { lower: 0, upper: 0 },
  },
  [GameRatingMode.UNRATED]: {
    [BoardType.BLITZ]: { lower: 0, upper: 0 },
    [BoardType.RAPID]: { lower: 0, upper: 0 },
    [BoardType.BULLET]: { lower: 0, upper: 0 },
  },
};

export const STORAGE_KEYS = {
  RATING_LIMITS: 'gameRatingLimits',
  RATING_MODE: 'gameRatingMode',
  TIME_CONTROL: 'time_control',
};
