import { calculatePiecePossibleMoves, calculatePiecePossibleMovesWithoutValidation, } from "../calculations";
export const isLegalMove = ({ index, enPassant, possibleCastlings, board, targetIndex, withValidation = true, }) => {
    const possibleMoves = withValidation
        ? calculatePiecePossibleMoves({
            index,
            enPassant,
            board,
            possibleCastlings,
        })
        : calculatePiecePossibleMovesWithoutValidation({
            index,
            board,
            possibleCastlings,
        });
    return possibleMoves.includes(targetIndex);
};
