import { eCause } from '@store/context/_types';
import { ILobbyContextState, IUpdateGame } from '../_lobby_context.types';

/**
 * Хелпер для сохранение ходов при каждом updateGame событии
 * Возвращает ходы
 */
export const persistTurns = (
  state: ILobbyContextState,
  payload: IUpdateGame['payload']
) => {
  const { gId, gameState } = payload;

  const currentGameState = state.games?.[gId]?.state;
  const turns = currentGameState?.playingPh?.turns;
  const lastMove = gameState?.playingPh?.lastTurn;

  const newTurns = turns?.slice() ?? [];

  if (
    !turns?.find((turn) => turn.end.ts === lastMove?.end.ts) &&
    lastMove &&
    gameState.cause === eCause.MOVED
  ) {
    newTurns.push(lastMove);
  }

  return newTurns;
};
