import { cgDataTypes, eLastMoveTheme } from 'chessgun/ui';
import React from 'react';
import { TUCI } from '../../_types';
import { useFromTo } from '../hooks/useFromTo.hook';
import { $LastMove, Arrow } from './_styles';

export const LastMove: React.FC<{
  type: eLastMoveTheme;
  uci: TUCI;
  cellSize: number;
}> = ({ type, uci, cellSize }) => {
  const lastMoveOffsets = useFromTo(uci);
  return type === eLastMoveTheme.ARROW ? (
    <Arrow size={cellSize} />
  ) : (
    lastMoveOffsets && (
      <>
        <$LastMove
          data-cg-type={cgDataTypes.squares.LAST_MOVE}
          size={cellSize}
          {...lastMoveOffsets.from}
        />
        <$LastMove
          data-cg-type={cgDataTypes.squares.LAST_MOVE}
          size={cellSize}
          {...lastMoveOffsets.to}
        />
      </>
    )
  );
};
