import {
  GameType,
  ITournamentExpandedData,
  ITournamentSponsor,
  ITournamentStream,
  IUpdateMeError,
  TimeLimitWarning,
  TournamentBoardType,
} from '@types';
import {
  Chessgun,
  IChessgunAnalysisItem,
  IChessgunHistoryItem,
  chessSide,
  figureType,
} from 'chessgun/core';
import { ITabElement } from 'shared/atoms';
import {
  GameStatus,
  IGameData,
  IGamePlayer,
  INewsCommonData,
  IPlayerTournaments,
  IProSubscription,
  IProfileData,
  IProfileEvent,
  IRatingsChartsData,
  IRatingsData,
  IRequestPagination,
  IStatisticData,
  IStoreAction,
  ITourGameData,
  ITournamentData,
  ITournamentPlayer,
  ITournamentPlayersData,
  ITournamentRound,
  ITournamentTeamPlayPlayersData,
  ITournamentTeamPlayTeamsData,
  IUserData,
  ThunkResult as SharedThunkResult,
  TournamentBoardStatus,
  TournamentJoinLimit,
} from 'shared/types';
import { GameUserType, IBoardEndedData } from './_game.types';
import { IPulseItem, ISubscribedRoundBoard } from './_tournament.types';
import { eTeamsWithPlayersGroups } from '@components/molecules/tournament/_teamGroupedResult';

/* ========================== edit profile ========================== */

export interface IEditProfileState {
  edit_profile_data: IUserData | null;
  edit_profile_request: boolean;
  error: undefined | IUpdateMeError;
}

/* ========================== profile ========================== */

export interface IProfileState {
  data: IProfileData | null;
  data_request: boolean;
  game_history: IRequestPagination<IGameData> | null;
  game_history_request: boolean;
  events_history: IProfileEvent[] | null;
  events_history_request: boolean;
  ratings: IRatingsData | null;
  ratings_request: boolean;
  tournaments: IPlayerTournaments | null;
  tournaments_request: boolean;
  wc_stats: IStatisticData | null;
  fide_stats: IStatisticData | null;
  otb_stats: IStatisticData | null;
  wc_charts: IRatingsChartsData | null;
  fide_charts: IRatingsChartsData | null;
  otb_charts: IRatingsChartsData | null;
  stats_request: boolean;
  pro_subscription: IProSubscription | null;
  upcoming_tournaments: ITournamentData[] | null;
  upcoming_tournaments_request: boolean;
  is_online: boolean;
}

/* ========================== game ========================== */

export enum gameActionTypes {
  SET_GAME_DATA = 'set game data',
  SET_GAME_DATA_REQUEST = 'set game data request',

  SET_GAME_TYPE = 'set game type',
  SET_GAME_USER_TYPE = 'set game user type',
  SET_GAME_ENGINE = 'set game engine',
  SET_LIVE_GAME_ENGINE = 'set live game engine',
  SET_BOARD_FLIPPED = 'set board flipped',
  SET_PLAYER_TURN = 'set player turn',
  SET_GAME_STATUS = 'set game status',

  SET_GAME_REVIEW_MODE = 'set game review mode',
  SET_ANALYZE_MODE = 'set analyze mode',

  SET_GAME_BOARD_ID = 'set game board id',

  SET_PLAYER_WARNING = 'set player warning',
  SET_OPPONENT_WARNING = 'set opponent warning',

  SET_GAME_ENDED = 'set game ended',
  ADD_MOVE = 'add move',
  SET_OPPONENT_DISCONNECTED = 'set opponent disconnected',

  SET_GAME_PLAYERS = 'set game players',
  SET_GAME_BLACK_PLAYER = 'set game black player',
  SET_GAME_WHITE_PLAYER = 'set game white player',
  SET_MY_COLOR = 'set my color',
  SET_BOARD_ROTATION = 'set board rotation',

  SET_GAME_MOVES_HISTORY = 'set game moves history',
  SET_GAME_SELECTED_MOVE = 'set game selected move',
  SET_GAME_SELECTED_MOVE_ANALYSIS = 'set game selected move analysis',

  SET_GAME_PGN_REQUEST = 'set game pgn request',
  SET_GAME_PGN = 'set game pgn',
  SET_PGN_FILE_NAME = 'set pgn file name',

  SET_GAME_BLACK_CAPTURED = 'set game black captured',
  SET_GAME_WHITE_CAPTURED = 'set game white captured',

  SET_GAME_BLACK_ADVANTAGE = 'set game black advantage',
  SET_GAME_WHITE_ADVANTAGE = 'set game white advantage',

  SET_BLACK_PLAYER_MS_LEFT = 'set black player ms left',
  SET_WHITE_PLAYER_MS_LEFT = 'set white player ms left',
  SET_BLACK_PLAYER_MOVE_END_TIME = 'set black player move end time',
  SET_WHITE_PLAYER_MOVE_END_TIME = 'set white player move end time',

  SET_WHITE_PULSE_HISTORY = 'set white pulse history',
  SET_BLACK_PULSE_HISTORY = 'set black pulse history',

  SET_GAME_VIEWERS_COUNT = 'set game viewers count',

  RESET_GAME = 'reset game',
}

export interface IGameState {
  game_data: ITourGameData | null;
  game_data_request: boolean;

  game_type: GameType;
  game_user_type: GameUserType;

  engine: Chessgun | null;
  liveEngine: Chessgun | null;
  is_review_mode: boolean;
  is_analyze_mode: boolean;

  board_id: string | null;
  game_ended: IBoardEndedData | null;
  game_status: GameStatus;
  board_flipped: boolean;
  board_rotation: number;
  player_turn: chessSide;

  player_warning: TimeLimitWarning | null;
  opponent_warning: TimeLimitWarning | null;

  opponent_disconnected: boolean;

  black_player: IGamePlayer | null;
  white_player: IGamePlayer | null;
  black_captured: figureType[];
  white_captured: figureType[];
  my_color: chessSide;

  moves_history: (IChessgunHistoryItem | null)[];
  selected_move: IChessgunHistoryItem | null;
  selected_move_analysis: IChessgunAnalysisItem | null;

  pgn: string;
  pgn_file_name: string;
  pgn_request: boolean;

  black_ms_left: number;
  white_ms_left: number;
  black_move_end_time: string | null;
  white_move_end_time: string | null;
  black_advantage: number;
  white_advantage: number;
  white_pulse_history: IPulseItem[];
  black_pulse_history: IPulseItem[];

  game_viewers_count: number;
}

/* ========================== tournament ========================== */

export interface ITournamentState {
  tournament_data: ITournamentExpandedData | null;
  tournament_data_request: boolean;

  participation_request: boolean;
  join_limit: TournamentJoinLimit | null;

  tournament_players_data: ITournamentPlayersData | null;
  tournament_players_data_request: boolean;
  tournament_update_players_data_loading: boolean;
  tournament_update_players_data_request: boolean;
  tournament_you: ITournamentPlayer | null;
  tournament_you_request: boolean;
  tournament_you_loading: boolean;
  loaded_players_count: number;

  tournament_rounds: ITournamentRound[];
  tournament_rounds_request: boolean;
  selected_round: ITournamentRound | null;

  tournament_winners: ITournamentPlayer[];
  tournament_winners_request: boolean;

  is_round_boards_ready: boolean;
  round_boards_request: boolean;
  round_boards: ISubscribedRoundBoard[];
  // Subscription management
  round_boards_multiboard_subscribed_games: Set<string>;
  round_boards_game_server_subscriptions: Set<string>;

  round_boards_amount: number;
  active_boards_amount: number;
  boards_type: TournamentBoardType;
  round_boards_status: TournamentBoardStatus | null;
  round_boards_page_number: number;
  round_boards_page_limit: number;

  tournament_certificate_pdf: string;
  tournament_certificate_pdf_request: boolean;
  tournament_certificate_image: string;
  tournament_certificate_image_request: boolean;

  tournament_sponsors: ITournamentSponsor[];
  tournament_sponsors_request: boolean;

  tournament_streams: ITournamentStream[];
  tournament_streams_request: boolean;

  tournament_pgn: string | null;
  tournament_pgn_request: boolean;

  ready_for_arena: boolean;
  ready_for_arena_request: boolean;

  stages_tabs: ITabElement[] | null;
  current_stage: string | null;
  current_stage_players: ITournamentPlayersData | null;
  current_stage_players_request: boolean;

  team_play_data_request: boolean;
  // team_play_players: ITournamentTeamsPlayPlayer[];
  // team_play_teams: ITournamentTeamsPlayTeam[];
  team_play_teams: ITournamentTeamPlayTeamsData | null;
  team_play_players: ITournamentTeamPlayPlayersData | null;
  team_play_update_request: boolean;

  team_play_current_group: eTeamsWithPlayersGroups;

  tagged_news: INewsCommonData | null;
  tagged_news_request: boolean;

  standing_page_number: number;
  standing_page_limit: number;

  standing_selected_game: ISubscribedRoundBoard | null;
}

export interface MainState {
  game: IGameState;
}

export type IAppAction = Omit<IStoreAction, 'type'> & {
  type: gameActionTypes;
};

export type ThunkResult<T> = SharedThunkResult<T, MainState>;
