import React, { PropsWithChildren, memo } from 'react';

import { usePopupsActions } from './_actions';
import { PopupsContextReducer, initState } from './_reducer';
import { IPopupsContextProps } from './_types';
import { useFideComputed } from './hooks/_useFideComputed';
import { useRegistrationComputed } from './hooks/_useRegistrationComputed';

export const PopupsContext = React.createContext({} as IPopupsContextProps);

export const PopupsContextProvider: React.FC<PropsWithChildren> = memo(
  function PopupsContextProvider({ children }) {
    const [state, dispatch] = React.useReducer(PopupsContextReducer, initState);

    const actions = usePopupsActions(dispatch);

    const registration = useRegistrationComputed(state);
    const fide = useFideComputed();

    return (
      <PopupsContext.Provider
        value={{
          state,
          actions,
          computed: { registration, fide },
        }}
      >
        {children}
      </PopupsContext.Provider>
    );
  }
);

export const usePopupsContext = () => React.useContext(PopupsContext);
