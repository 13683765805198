import { mainStore } from '@store/storeshed';
import { createAuthActions } from './_auth.actions';
import { createLoginActions } from './_login.actions';
import { createUserDataActions } from './_user_data.actions';
import { createRegistrationActions } from './_registration.actions';
import { createFideSubscriptionActions } from './_fide_subscription.actions';
import { bindActions } from 'storeshed';
import { createPaymentActions } from './_payment.actions';

export const paymentActions = bindActions(mainStore, createPaymentActions());
export const fideSubscriptionActions = bindActions(
  mainStore,
  createFideSubscriptionActions()
);
export const loginActions = bindActions(mainStore, createLoginActions());
export const userDataActions = bindActions(mainStore, createUserDataActions());
export const registrationActions = bindActions(
  mainStore,
  createRegistrationActions()
);
export const authActions = bindActions(mainStore, createAuthActions());
