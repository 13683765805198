import { AccountVerification, eAccessLevel } from 'shared/types';

export enum eEloDelta {
  UP = 'up',
  DOWN = 'down',
}

export type TTimeControl = 'standard' | 'blitz' | 'bullet' | 'rapid' | 'puzzle';
export type TPlayerRatings = {
  [key in TTimeControl]?: number;
};

export interface IPlayerData {
  full_name: string;
  title: string;
  nationality_id: number;
  ratings: TPlayerRatings;
  avatar: {
    small: string;
    full: string;
    medium: string;
  };
  eloDelta?: eEloDelta;
  player_id: number;
  deltas: {
    rating: number;
    rank: number;
  };
  rank: number;
}

export type TPlayerDataByRatings = {
  [key in TTimeControl]?: IPlayerData[];
};

export interface IPlayerInfo {
  data: IPlayerData;
  index: number;
  baseUrl?: string;
  className?: string;
  currentTimeControl?: TTimeControl;
  isCurrentUser?: boolean;
}

export interface ITopFivePlayersLink {
  src: string;
  text: string;
}

export interface IPlayerResponse {
  country_id: number | null;
  is_online: boolean;
  id: number;
  avatar: string;
  rating: number;
  title: string | null;
  board_uid: string;
  full_name: string | null;
}

export interface ITopFivePlayerResponse {
  avatar: string;
  title?: string;
  country_id: number;
  full_name?: string;
  ratings: TPlayerRatings;
  id: number;
  is_online: boolean;
  deltas: {
    rating: number;
    rank: number;
  };
  rank: number;
  rating?: number;
  fide_verified_status: AccountVerification | null;
  access_level?: eAccessLevel.PRO;
}

export type TTopPlayersByRatingResponse = {
  [key in TTimeControl]: ITopFivePlayerResponse[];
};

export interface ITopPlayerResponse {
  id: number;
  avatar: string;
  rating: number;
  country_id: number | null;
  title: string | null;
  is_online: boolean;
  board_uid: string;
  full_name: string | null;
  fide_id?: number | null;
}
