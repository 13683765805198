import React, { memo, useMemo } from 'react';
import styled from 'styled-components';

import {
  ITeamsWithPlayersGroupedResult,
  TTeamsWithPlayersGroups,
  IGroupedTableConfig,
  ITableData,
} from './_types';
import {
  $ScrollContainer,
  tabsStyles,
  preloaderStyles,
  $Wrapper,
  $PreloaderWrapper,
} from './_styles';
import { TabSelector } from 'shared/atoms';
import { useMobile } from 'shared/hooks/_useMobile.hook';
import { Preloader } from 'shared/molecules';
import { textColors, transparentColor, buttonColors } from 'shared/styles';
import { DesktopTable } from './fragments/_DesktopTable';
import { MobileTable } from './fragments/_MobileTable';

/**
 * Компонент: таблица c езультатами серии турниров
 * @param {ITournamentPlayer[]} players - массив юзеров
 * @param {string} profileUrl - базовый урл на профиль
 * @param {ISeriesTournamentPlayer} user - данные текущего юзера
 * @param {string} noPointsText - текст для полей без очков игры
 * @param {string} stages - стадии турнира
 * @param {string} currentTab - выбранная стадия турнира
 * @param {string} setCurrentTab - обработчик выбора стадии турнира
 * @param {IGoesStandingLocalization} translation - объект с локализацией
 * @param {string} className - внешний класс
 */
export const TeamsWithPlayersGroupedResult: React.FC<ITeamsWithPlayersGroupedResult> =
  memo(function TeamsWithPlayersGroupedResult({
    groupedTables,

    tabs,
    currentTab,
    setCurrentTab,

    color = textColors.green,
    profileUrl,
    userPlayerId,
    inRequest,
    className = '',
  }) {
    const isMobile = useMobile();
    const currentData = currentTab
      ? (groupedTables[
          currentTab as TTeamsWithPlayersGroups
        ] as IGroupedTableConfig<ITableData>)
      : {
          columns: [],
          data: {
            count: 0,
            data: [],
          },
        };

    const {
      data: { data: tableData },
      columns,
    } = currentData;

    const Table = useMemo(() => {
      return isMobile ? (
        <MobileTable
          columns={columns}
          data={tableData}
          currentTab={currentTab}
          profileUrl={profileUrl}
          userPlayerId={userPlayerId}
        />
      ) : (
        <DesktopTable
          columns={columns}
          data={tableData}
          currentTab={currentTab}
          profileUrl={profileUrl}
          userPlayerId={userPlayerId}
        />
      );
    }, [columns, currentTab, tableData, isMobile, profileUrl, userPlayerId]);

    return (
      <$ScrollContainer className={className} color={color}>
        {tabs && currentTab && setCurrentTab && (
          <$TabSelector
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            type="white_outline"
            tabsClassName="tab"
          />
        )}

        {inRequest && (
          <$PreloaderWrapper>
            <$Preloader color={transparentColor(buttonColors.black, 0.1)} />
          </$PreloaderWrapper>
        )}

        <$Wrapper inRequest={inRequest}>{Table}</$Wrapper>
      </$ScrollContainer>
    );
  });

const $Preloader = styled(Preloader)`
  ${preloaderStyles};
`;

const $TabSelector = styled(TabSelector)`
  ${tabsStyles}
`;
