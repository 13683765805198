import { outOfBoard } from "./_outOfBoard";
import { SQUARES } from "../_constants";
export const logBoard = (board) => {
    let errorBoard = "\n";
    const indexColor = "\x1b[2m";
    for (let i = 0; i <= SQUARES.h1; i++) {
        if (outOfBoard(i)) {
            errorBoard += "\n" + indexColor;
            i += 7;
            continue;
        }
        if (i.toString().length < 2) {
            errorBoard += indexColor + i + "  : ";
        }
        else if (i.toString().length < 3) {
            errorBoard += indexColor + i + " : ";
        }
        else {
            errorBoard += indexColor + i + ": ";
        }
        const piece = board[i];
        errorBoard += "\x1b[0m" + (!piece ? "_  " : piece + "  ");
    }
};
