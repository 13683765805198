import { Dispatch } from 'react';

import { NotificationsItem } from '@chessarena/components/lib/layout/Notifications/types';

import {
  eNotificationsReducerActionTypes,
  INotificationsReducerAction,
} from './_types';

export const useActions = (dispatch: Dispatch<INotificationsReducerAction>) => {
  return {
    setMounted: (value: boolean): void => {
      return dispatch({
        type: eNotificationsReducerActionTypes.SET_MOUNTED,
        payload: value,
      });
    },

    setItems: (items: NotificationsItem[]): void => {
      return dispatch({
        type: eNotificationsReducerActionTypes.SET_ITEMS,
        payload: items,
      });
    },
  };
};
