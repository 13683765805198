import { chessSide } from "../_constants";
export class PieceColor {
    constructor(value) {
        this.value = value;
    }
    isWhite() {
        return this.value === chessSide.WHITE;
    }
    oposite() {
        return this.value === chessSide.WHITE ? chessSide.BLACK : chessSide.WHITE;
    }
}
