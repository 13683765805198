import { logBoard } from "../stringBoard/_logBoard";
/**
 * Создает удобную запись для вывода ошибок
 */
export function generateError({ message, index, board, targetIndex, }) {
    console.warn(`
  ==== ERROR DATA ====

  ${index ? `Piece current index: ${index}` : ""}
  ${targetIndex ? `Piece target index: ${targetIndex}` : ""}

  `);
    logBoard(board);
    return new Error(message);
}
