export var TournamentJoinLimit;
(function (TournamentJoinLimit) {
    TournamentJoinLimit["NO_USER_DATA"] = "no_user_data";
    TournamentJoinLimit["FIDE_PLAYERS_ONLY"] = "fide_players_only";
    TournamentJoinLimit["SIGNUP_NOT_OPENED_YET"] = "signup_not_opened_yet";
    TournamentJoinLimit["SIGNUP_ALREADY_CLOSED"] = "signup_already_closed";
    TournamentJoinLimit["TOO_MANY_PLAYERS"] = "too_many_players";
    TournamentJoinLimit["SIGNUP_TWICE"] = "signup_twice";
    TournamentJoinLimit["SAME_TIME_TOURNAMENT"] = "same_time_tournament";
    TournamentJoinLimit["SIGNUP_COUNTRY_LIMIT"] = "signup_country_limit";
    TournamentJoinLimit["RATING_TOO_LOW"] = "rating_too_low";
    TournamentJoinLimit["RATING_TOO_HIGH"] = "rating_too_high";
    TournamentJoinLimit["AGE_TOO_HIGH"] = "age_too_high";
    TournamentJoinLimit["TITLE_NOT_ALLOWED"] = "title_not_allowed";
    TournamentJoinLimit["AGE_TOO_LOW"] = "age_too_low";
    TournamentJoinLimit["EVENT_MAX_TOURNAMENT"] = "event_max_tournament_participate";
    TournamentJoinLimit["TOURNAMENT_NOT_PAID"] = "tournament_not_paid";
    TournamentJoinLimit["GENDER_NOT_ALLOWED"] = "gender_not_allowed";
})(TournamentJoinLimit || (TournamentJoinLimit = {}));
