import { GameService, gameService } from '@services/_game.service';
import { ThunkResult, IAppAction, gameActionTypes } from '@types';
import { httpStatuses } from 'shared/constants';

export const createGamePgnActions = ({
  gameService,
}: {
  gameService: GameService;
}) => {
  /**
   * Задает состояние запроса pgn текущей партии.
   * @param {boolean} inRequest - выполняется ли запрос.
   */
  const setPgnRequest = (inRequest: boolean): IAppAction => ({
    type: gameActionTypes.SET_GAME_PGN_REQUEST,
    payload: inRequest,
  });

  /**
   * Задает состояние pgn текущей партии.
   * @param {string} gamePgn - результат.
   */
  const setPgn = (gamePgn: string): IAppAction => ({
    type: gameActionTypes.SET_GAME_PGN,
    payload: gamePgn,
  });

  /**
   * Задает состояние названия pgn файла
   * @param {string} pgnFileName - имя файла.
   */
  const setPgnFileName = (pgnFileName: string): IAppAction => ({
    type: gameActionTypes.SET_PGN_FILE_NAME,
    payload: pgnFileName,
  });

  /**
   * Выполняет запрос на получение pgn файла игры. Перед запросом задает game.pgn_request значение true, после - false.
   * @param {string} boardId - id доски
   * @param {string} pgnFileName - название файла
   */
  const getGamePgn = (
    boardId: string,
    pgnFileName: string
  ): ThunkResult<void> => {
    return async (dispatch, getState) => {
      if (getState().game.pgn_request) return;

      dispatch(setPgnRequest(true));
      try {
        const { status, data } = await gameService.getGamePgn(boardId);

        if (status === httpStatuses.OK) {
          dispatch(setPgnFileName(pgnFileName));
          dispatch(setPgn(data));
        }
      } catch (err) {
        console.log(err);
      }

      dispatch(setPgnRequest(false));
    };
  };

  return { setPgnRequest, setPgn, setPgnFileName, getGamePgn };
};

export const gamePgnActions = createGamePgnActions({ gameService });
