export interface IMember {
  user_id: string;
  avatar: string;
}

export interface IMyCommunity {
  type: number;
  url: string;
  title?: string;
  short_title?: string;
  subtitle?: string;
  description: string;
  about: string;
  location: string;
  logo: string;
}

export interface ICommunityCreateProps {
  title: string;
  short_title: string;
  subtitle: string;
  url: string;
  description?: string;
  // icon: string;
  logo?: string;
  site?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  location?: string;
  published: boolean;
  membership_by_request: boolean;
  // players_age_maximum: string;
  allowed_regions?: number[];
  allowed_countries?: number[];
  allowed_titles?: string[];
  schedule?: string;
  only_kids: boolean;
}

export interface ICommunityCreate {
  community_id: number;
}

export enum eCommunityChip {
  POPULAR = 'popular',
  BIGGEST = 'biggest',
  NEW = 'new',
  TOP_TOURNAMENTS = 'top_tournaments',
  KIDS = 'kids',
  ONLINE = 'online',
  OFFLINE = 'offline',
  AMERICA = 'america',
  ASIA = 'asia',
  EUROPE = 'europe',
  AFRICA = 'africa',
}
