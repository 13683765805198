export const ranks = {
  ACM: { full: 'Candidate Master', min: 'ACM' },
  AFM: { full: 'FIDE Master', min: 'AFM' },
  AIM: { full: 'International Master', min: 'AIM' },
  AGM: { full: 'Grandmaster', min: 'AGM' },
  CM: { full: 'Candidate Master', min: 'CM' },
  FM: { full: 'FIDE Master', min: 'FM' },
  IM: { full: 'International Master', min: 'IM' },
  GM: { full: 'Grandmaster', min: 'GM' },
  WCM: { full: 'Woman Candidate Master', min: 'WCM' },
  WFM: { full: 'Woman FIDE Master', min: 'WFM' },
  WIM: { full: 'Woman International Master', min: 'WIM' },
  WGM: { full: 'Woman Grandmaster', min: 'WGM' },
};
