import React, { Dispatch } from 'react';
import { useForm } from 'shared/hooks/_useForm.hook';

import { IFideInputs, IRegistrationInputs } from '@types';

export interface IRegistrationPopupsStore {
  updates: boolean;
  currentTab: string | null;
  freePro: boolean;
  isVerification: boolean;
}

export interface IFidePopupsStore {
  currentTab: string | null;
  fideId: number | null;
  photo: string;
}

export interface IPopupsContextState {
  registration: IRegistrationPopupsStore;
  fide: IFidePopupsStore;
}

export interface IPopupsContextActions {
  setUpdates: (updates: boolean) => void;
  setRegistrationCurrentTab: (currentTab: string | null) => void;
  setFreePro: (freePro: boolean) => void;
  setIsVerification: (isVerification: boolean) => void;
  closeRegistrationPopup: () => void;

  setFideId: (fideId: number | null) => void;
  setFideCurrentTab: (currentTab: string | null) => void;
  setPhoto: (photo: string) => void;
  closeFidePopup: () => void;
}

export type IPopupsContextDispatch = Dispatch<IPopupsAction>;

type TRegistrationFormData = ReturnType<typeof useForm<IRegistrationInputs>>;
type TFideFormData = ReturnType<typeof useForm<IFideInputs>>;

export interface IFidePopupTab {
  id: number;
  value: string;
  text: string;
}

export interface IRegistrationPopupsComputed {
  paid: boolean;
  price: number;
  stepsCount: number;
  formData: TRegistrationFormData;
}

export interface IFidePopupsComputed {
  freePro: boolean;
  tabs: IFidePopupTab[];
  formData: TFideFormData;
}

export interface IPopupsContextComputed {
  registration: IRegistrationPopupsComputed;
  fide: IFidePopupsComputed;
}

export interface IPopupsContextProps {
  state: IPopupsContextState;
  actions: IPopupsContextActions;
  computed: IPopupsContextComputed;
}

export enum ePopupsContextActionTypes {
  SET_REGISTRATION_POPUPS_UPDATES = 'set registration popups updates',
  SET_REGISTRATION_POPUPS_CURRENT_TAB = 'set registration popups current tab',
  SET_REGISTRATION_POPUPS_FREE_PRO = 'set registration popups free pro',
  SET_REGISTRATION_POPUPS_IS_VERIFICATION = 'set registration popups is verification',
  RESET_REGISTRATION_POPUPS = 'reset registration popups',

  SET_FIDE_POPUPS_FIDE_ID = 'set fide popups fide id',
  SET_FIDE_POPUPS_CURRENT_TAB = 'set fide popups current tab',
  SET_FIDE_POPUPS_PHOTO = 'set fide popups photo',
  RESET_FIDE_POPUPS = 'reset fide popups',
}

export type TPopupsContextReducer = React.Reducer<
  IPopupsContextState,
  IPopupsAction
>;

interface IPopupsActionMessage {
  type: ePopupsContextActionTypes;
}

interface ISetRegistrationPopupsUpdates extends IPopupsActionMessage {
  type: ePopupsContextActionTypes.SET_REGISTRATION_POPUPS_UPDATES;
  payload: boolean;
}

interface ISetRegistrationPopupsCurrentTab extends IPopupsActionMessage {
  type: ePopupsContextActionTypes.SET_REGISTRATION_POPUPS_CURRENT_TAB;
  payload: string | null;
}

interface ISetRegistrationPopupsFreePro extends IPopupsActionMessage {
  type: ePopupsContextActionTypes.SET_REGISTRATION_POPUPS_FREE_PRO;
  payload: boolean;
}

interface ISetRegistrationPopupsIsVerification extends IPopupsActionMessage {
  type: ePopupsContextActionTypes.SET_REGISTRATION_POPUPS_IS_VERIFICATION;
  payload: boolean;
}

interface IResetRegistrationPopups extends IPopupsActionMessage {
  type: ePopupsContextActionTypes.RESET_REGISTRATION_POPUPS;
  payload?: undefined;
}

interface ISetFidePopupsFideId extends IPopupsActionMessage {
  type: ePopupsContextActionTypes.SET_FIDE_POPUPS_FIDE_ID;
  payload: number | null;
}

interface ISetFidePopupsCurrentTab extends IPopupsActionMessage {
  type: ePopupsContextActionTypes.SET_FIDE_POPUPS_CURRENT_TAB;
  payload: string | null;
}

interface ISetFidePopupsPhoto extends IPopupsActionMessage {
  type: ePopupsContextActionTypes.SET_FIDE_POPUPS_PHOTO;
  payload: string;
}

interface IResetFidePopups extends IPopupsActionMessage {
  type: ePopupsContextActionTypes.RESET_FIDE_POPUPS;
  payload?: undefined;
}

export type IPopupsAction =
  | ISetRegistrationPopupsUpdates
  | ISetRegistrationPopupsCurrentTab
  | ISetRegistrationPopupsFreePro
  | ISetRegistrationPopupsIsVerification
  | IResetRegistrationPopups
  | ISetFidePopupsFideId
  | ISetFidePopupsCurrentTab
  | ISetFidePopupsPhoto
  | IResetFidePopups;
