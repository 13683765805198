import React from 'react';
import { chessFigureStyle } from './_types';
/**
 * Иконка: Фигура ладьи
 * @param {string} fill - цвет фигуры
 * @param {number} width - ширина фигуры
 * @param {number} height - высота фигуры
 * @param {string} color - тема фигуры
 * @param {chessFigureStyle} figureStyle - тема фигуры
 * @param {string} className - внешний класс
 */
export const RookIcon = ({ className = '', width = '100%', height = '100%', theme = 'white', fill, figureStyle = chessFigureStyle.FILLED, }) => {
    const rooks = {
        [chessFigureStyle.FILLED]: (React.createElement("svg", { className: className, width: width, height: height, fill: "none", viewBox: "0 0 60 60", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M41.2 21.2l-1 .7v14.2l2.3 2.8c.6.8.7.9.7 1.5v4.7l.8.1c.6.1 1 .6 1 1.4V51H14v-4.3c0-.7.4-1.2 1-1.4h.7v-4.8c0-.6 0-.7.7-1.4l2.3-2.9V22l-1-.7c-1-.7-2.7-2-2.7-2.6v-5.3c0-1.5 3.5-2.2 5.4-2.6v3.7c1.7-.2 3.5-.5 5.3-.5v-3.8l3.8-.1 3.8.1V14c1.8 0 3.6.3 5.3.5v-3.7c2 .4 5.5 1.1 5.5 2.6v5.3c0 .6-1.8 1.9-2.9 2.6zm2 25.4H15.8c-.3 0-.5 0-.5.5v1h28.2v-1c0-.4-.1-.5-.5-.5zm-13.7-29c4.6 0 9 .5 12 1.3.2-.2.7-.5.8-.8.1-.2.1-.3-.1-.5a11 11 0 00-3.4-.8 63.6 63.6 0 00-22 .8c-.3.2-.3.3-.2.5.1.3.6.6.9.8 3-.8 7.4-1.3 12-1.3zM18.2 39.8c-.4 0-.6.1-.6.6v1h24v-1c0-.4-.1-.6-.6-.6H18.2zm2-3l-1 1.3H40l-1-1.4H20.1zm18.7-14v-1.5a54.5 54.5 0 00-18.8 0v1.5a64.9 64.9 0 0118.8 0z", fill: fill ? fill : theme }))),
        [chessFigureStyle.STROKE]: (React.createElement("svg", { className: className, width: width, height: height, viewBox: "0 0 13 13", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M9.51 8.74v1.04h.06c.23.03.32.11.32.37v.9H3.03v-.9c0-.26.09-.32.26-.34l.15-.03V8.74c0-.15.06-.18.17-.3l.44-.51V4.88c-.32-.23-.81-.58-.81-.7V2.9c0-.35 1.07-.58 1.59-.64v.64l.63-.09v-.6c.38-.03.73-.03 1.05-.03.28 0 .57 0 1 .03v.6l.62.09v-.64c.69.09 1.59.32 1.59.64v1.3c0 .12-.52.47-.81.7v2.98l.43.55c.12.14.17.2.17.32Zm-.05 1.93v-.43H3.44v.43h6.02ZM4.3 2.72c-.26.03-.47.11-.67.17v.98a8.76 8.76 0 0 1 2.8-.31c1.1 0 2.26.08 2.81.31v-.95c-.2-.09-.43-.15-.7-.2v.58A9.22 9.22 0 0 0 7 3.15v-.58c-.18-.03-.35-.03-.55-.03-.2 0-.38 0-.58.03v.58c-.58.03-1.01.06-1.56.15v-.58Zm-.58 1.47c.11.12.32.26.52.38l.14.09a12.7 12.7 0 0 1 4.11.02l.17-.11c.24-.15.41-.26.53-.38a11.2 11.2 0 0 0-2.72-.29c-1.02 0-2.03.09-2.75.3Zm5.35 5.67V9h-5.2v.86h5.2Zm-.12-1.24L8.7 8.3H4.22l-.26.32h5ZM4.48 5v2.95h3.96V5.03c-.52-.06-1.15-.11-2-.11-.8-.03-1.47.02-1.96.08Z", fill: fill ? fill : theme }))),
    };
    return rooks[figureStyle];
};
