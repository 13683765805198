import { TMainStore } from '@store/storeshed';
import { eLastMoveTheme, ePossibleMovesTheme } from 'chessgun/ui';
import {
  eAutoPromotionTheme,
  eBoardThemes,
  ePremovesTheme,
  eSoundsTheme,
} from 'shared/types';

export interface IUpdateBoardSettingsProps {
  chessboardTheme: eBoardThemes;
  soundTheme: eSoundsTheme;
  lastMoveTheme: eLastMoveTheme;
  legalMovesTheme: ePossibleMovesTheme;
  premovesTheme: ePremovesTheme;
  autoPromotionTheme: eAutoPromotionTheme;
}

export const createBoardSettingsActions = () => {
  const updateBoardSettings = (
    { boardSettingsStore }: TMainStore,
    {
      chessboardTheme: boardTheme,
      soundTheme: soundsTheme,
      lastMoveTheme,
      legalMovesTheme,
      premovesTheme,
      autoPromotionTheme: autoPromotion,
    }: IUpdateBoardSettingsProps
  ) => {
    boardSettingsStore.updateBoardSettings({
      premovesTheme,
      lastMoveTheme,
      legalMovesTheme,
      soundsTheme,
      boardTheme,
      autoPromotion,
    });
  };

  const setBoardTheme = (
    { boardSettingsStore }: TMainStore,
    boardTheme: eBoardThemes
  ) => {
    boardSettingsStore.boardTheme = boardTheme;
  };

  const setLastMoveTheme = (
    { boardSettingsStore }: TMainStore,
    lastMoveTheme: eLastMoveTheme
  ) => {
    boardSettingsStore.lastMoveTheme = lastMoveTheme;
  };

  const setLegalMovesTheme = (
    { boardSettingsStore }: TMainStore,
    legalMovesTheme: ePossibleMovesTheme
  ) => {
    boardSettingsStore.legalMovesTheme = legalMovesTheme;
  };

  const setSounds = (
    { boardSettingsStore }: TMainStore,
    soundsTheme: eSoundsTheme
  ) => {
    boardSettingsStore.soundsTheme = soundsTheme;
  };

  const setPremoves = (
    { boardSettingsStore }: TMainStore,
    premovesTheme: ePremovesTheme
  ) => {
    boardSettingsStore.premovesTheme = premovesTheme;
  };

  const setAutoPromotion = (
    { boardSettingsStore }: TMainStore,
    autoPromotion: eAutoPromotionTheme
  ) => {
    boardSettingsStore.autoPromotion = autoPromotion;
  };

  return {
    updateBoardSettings,
    setBoardTheme,
    setLastMoveTheme,
    setLegalMovesTheme,
    setSounds,
    setPremoves,
    setAutoPromotion,
  };
};
