import React, { FC, memo } from 'react';
import {
  $Category,
  $Country,
  $Index,
  $ItemBody,
  $PointItem,
  $PointsRow,
  $TeamInfo,
  $TeamItem,
  $TeamName,
} from '../_styles';
import { ITeamsWithPlayersTeamsData } from '../_types';
import { commaNumFormatter } from '../_constants';
import { getPlayerCountry } from 'shared/helpers/_common';
import { getPlayerPoints } from 'shared/helpers/_get_player_points';

interface ITeamsItem {
  data: ITeamsWithPlayersTeamsData;
  index: number;
}

export const TeamsItem: FC<ITeamsItem> = memo(function TeamsItem({
  data,
  index,
}) {
  const { in_team } = data;

  return (
    <$TeamItem isHighlighted={Boolean(in_team)}>
      <$Index>{index}</$Index>

      <$ItemBody>
        <$TeamName>{data.name}</$TeamName>

        <$TeamInfo>
          <$Category>{data.category}</$Category>{' '}
          <$Country>{getPlayerCountry(Number(data.country))}</$Country>{' '}
          {/* <$Text>{data.event_name}</$Text> */}
        </$TeamInfo>

        <$PointsRow>
          {typeof data.points === 'number' && (
            <>
              <$PointItem>
                {getPlayerPoints(Number(data.points))} pts
              </$PointItem>{' '}
            </>
          )}
          {typeof data.tie_break === 'number' && (
            <>
              <$PointItem>
                {commaNumFormatter.format(Number(data.tie_break))} tb
              </$PointItem>{' '}
            </>
          )}
        </$PointsRow>
      </$ItemBody>
    </$TeamItem>
  );
});
