import { ThunkResult } from '@types';
import { gameActions } from '.';

export const createGameReviewActions = () => {
  /**
   * Обработчик изменения индекса выбранного хода в истории
   * @param {number} currentMoveIndex - индекс текущего хода
   * @param {number} lastMoveIndex - индекс последнего хода
   */
  const handleGameReview = ({
    currentMoveIndex,
    lastMoveIndex,
  }: {
    currentMoveIndex: number;
    lastMoveIndex: number;
  }): ThunkResult<void> => {
    return (dispatch) => {
      dispatch(handleGoingGameReview({ currentMoveIndex, lastMoveIndex }));
    };
  };

  /**
   * Обработчик изменения индекса выбранного хода в истории завершенной партии
   * @param {number} currentMoveIndex - индекс текущего хода
   * @param {number} lastMoveIndex - индекс последнего хода
   */
  const handleEndedGameReview = ({
    currentMoveIndex,
    lastMoveIndex,
  }: {
    currentMoveIndex: number;
    lastMoveIndex: number;
  }): ThunkResult<void> => {
    return (dispatch) => {
      //  если игра закончилась, то выйти из режима шагалки можно только принудительно в навигаторе шагов,
      // поэтому не обрабатываем выход из режима при смене хода

      if (currentMoveIndex < lastMoveIndex && lastMoveIndex !== -1) {
        dispatch(gameActions.setGameReviewMode(true));
      }
    };
  };

  /**
   * Обработчик изменения индекса выбранного хода в истории текущей партии
   * @param {number} currentMoveIndex - индекс текущего хода
   * @param {number} lastMoveIndex - индекс последнего хода
   */
  const handleGoingGameReview = ({
    currentMoveIndex,
    lastMoveIndex,
  }: {
    currentMoveIndex: number;
    lastMoveIndex: number;
  }): ThunkResult<void> => {
    return (dispatch) => {
      if (
        currentMoveIndex >= lastMoveIndex ||
        lastMoveIndex === -1 ||
        currentMoveIndex === -1
      ) {
        dispatch(gameActions.setGameReviewMode(false));
      } else {
        dispatch(gameActions.setGameReviewMode(true));
      }
    };
  };

  return {
    handleGameReview,
    handleEndedGameReview,
    handleGoingGameReview,
  };
};

export const gameReviewActions = createGameReviewActions();
