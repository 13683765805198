import { useMemo } from 'react';
import { useAlertPopup } from './_useAlertPopup.hook';
import { useConfirmationPopup } from './_useConfirmationPopup.hook';
import { ISystemPopups } from 'shared/types';

export const useSystemPopups = () => {
  const {
    isConfirmPopupShown,
    confirmPopupRef,
    onAccept,
    onDecline,

    showConfirmationPopup,
    confirmText,
  } = useConfirmationPopup();

  const {
    isAlertPopupShown,
    alertPopupRef,
    onClose,

    alertText,
    showAlertPopup,
  } = useAlertPopup();

  const popups: ISystemPopups = useMemo(
    () => ({
      confirm: showConfirmationPopup,
      alert: showAlertPopup,
    }),
    [showAlertPopup, showConfirmationPopup]
  );

  return {
    confirm: {
      isConfirmPopupShown,
      confirmPopupRef,
      onAccept,
      onDecline,

      showConfirmationPopup,
      confirmText,
    },
    alert: {
      isAlertPopupShown,
      alertPopupRef,
      onClose,

      alertText,
      showAlertPopup,
    },
    popups,
  };
};
