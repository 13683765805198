import Link from 'next/link';
import {
  transparentColor,
  textColors,
  screenSizes,
  baseRow,
  baseColumn,
} from 'shared/styles';
import styled, { css } from 'styled-components';

export const $ScrollContainer = styled.div<{ color?: string }>`
  position: relative;
  width: 100%;
  overflow-x: scroll;
  border-radius: 3px;
  background-color: ${({ color }) => `${color}`};

  /* this will hide the scrollbar in mozilla based browsers */
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`;

export const tabsStyles = css`
  justify-content: flex-start;
  margin-left: 20px;
  margin-top: 10px;
  height: 25px;

  .tab {
    font-size: 15px !important;
    line-height: 22px !important;
    padding: 2px 10px !important;
    border: none !important;
    min-width: 128px;

    &:first-of-type {
      border-radius: 4px 0 0 4px !important;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0 !important;
    }

    &:not(.active) {
      background-color: ${transparentColor(textColors.white, 0.15)} !important;

      &:hover {
        background-color: ${transparentColor(textColors.white, 0.4)} !important;
        color: ${textColors.white} !important;
      }
    }
  }

  @media ${screenSizes.MOBILE} {
    margin-left: 10px;
  }
`;

export const preloaderStyles = css`
  padding: 20px;
`;

export const $Wrapper = styled.div<{ inRequest?: boolean }>`
  min-width: fit-content;
  margin-top: 10px;

  ${({ inRequest }) =>
    inRequest &&
    css`
      display: none;
    `}/* visibility: ${({ inRequest }) =>
    inRequest ? 'hidden' : 'visible'}; */
`;

export const $SelectWrapper = styled.div`
  margin-top: 4px;
  margin-left: 20px;

  @media ${screenSizes.MOBILE} {
    margin-left: 10px;
  }
`;

export const $Select = styled.div`
  width: 140px;
`;

export const $PreloaderWrapper = styled.div`
  position: relative;
  min-height: 200px;
`;

export const tableStyles = css`
  padding: 10px 0px;
`;

export const $EmptyTablePlaceholder = styled.div`
  min-height: 139px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 300;
  color: ${textColors.white};
`;

export const $Link = styled(Link)`
  overflow: hidden;
  text-decoration: none;
  color: ${textColors.white};
  ${baseRow()};
  line-height: 23px;

  &:hover {
    color: ${textColors.black};
  }
`;

export const $TableRow = styled.div`
  &.current_user {
    background-color: ${transparentColor(textColors.white, 0.25)};
  }

  &.new_group_item {
    margin-top: 20px;
  }
`;

export const $DesktopTableRow = styled($TableRow)`
  padding: 1px 20px;

  &:not(:first-child) {
    margin-bottom: 3px;
  }

  &:first-child {
    border-bottom: none;
    margin-bottom: 3px;
    padding-bottom: 0;
  }
`;

export const $BoardPoint = styled.span`
  width: 12px;
  text-align: center;

  &:not(:first-of-type) {
    margin-left: 10px;
  }

  @media ${screenSizes.MOBILE} {
    &:not(:first-of-type) {
      margin-left: 8px;
    }
  }
`;

export const $Points = styled.span`
  margin-left: 2px;
`;

export type TTableLayoutProps = {
  cellSize?: number;
};

export const $TableLayout = styled.div<TTableLayoutProps>`
  /* &:nth-child(1) {
    width: 25px;
    min-width: 25px;
  }

  &:nth-child(2) {
    width: 220px;
    min-width: 220px;
  }

  &:nth-child(3) {
    width: 45px;
    min-width: 45px;
  }

  &:nth-child(4) {
    width: 35px;
    min-width: 35px;
  }

  &:nth-child(5),
  &:nth-child(6) {
    white-space: nowrap;
    min-width: fit-content;
  } */

  width: ${({ cellSize }) => `${cellSize}px`};
  min-width: ${({ cellSize }) => `${cellSize}px`};
`;

export const $TableCell = styled($TableLayout)<{ numeric?: boolean }>`
  color: ${textColors.white};

  ${({ numeric }) =>
    numeric &&
    css`
      font-feature-settings: 'tnum' on, 'lnum' on;
    `}

  &:not(:first-child) {
    margin-left: 20px;
  }
`;

export const $TablePlayer = styled($TableCell)`
  overflow: hidden;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const $List = styled.div`
  /**
  * Делаем границу сверху для более простой стилизации
  * подсвеченного элемента ниже
  */
  padding-right: 10px;
  padding-left: 10px;

  & > *:not(:first-child) {
    border-top: 1px solid ${transparentColor(textColors['white'], 0.2)};

    /* Скрываем бордер у подсвеченного */
    &[data-highlighted='true'] {
      border-color: transparent;

      /* Скрываем бордер у следующего от подсвеченного */
      & + * {
        border-color: transparent;
      }
    }
  }
`;

export const $Index = styled.div<{ rotated?: boolean }>`
  flex-shrink: 0;

  font-size: 15px;
  line-height: 15px;
  font-feature-settings: 'tnum' on, 'lnum' on;

  margin-top: 3px;

  ${({ rotated }) =>
    rotated &&
    css`
      transform-origin: top left;
      transform: rotate(-90deg) translateX(-100%);
    `}
`;

export const $ItemBody = styled.div`
  flex-grow: 1;
  ${baseColumn('flex-start')};
  margin-top: 2px;
`;

export const $TeamItemBody = styled($ItemBody)`
  margin-top: 3px;
`;

export const $NameField = styled.p`
  font-size: 15px;
  line-height: 15px;
`;

export const $TeamName = styled($NameField)``;
export const $PlayerName = styled($NameField)``;

export const $Info = styled.p`
  margin-top: 6px;

  line-height: 18px;
`;

export const $TeamInfo = styled($Info)``;
export const $PlayerInfo = styled($Info)``;

export const $Category = styled.span`
  font-size: 15px;
  line-height: 15px;
  font-feature-settings: 'tnum' on, 'lnum' on;
`;

export const $PlayerTeamName = styled.span`
  font-size: 15px;
  line-height: 15px;
`;

export const $Country = styled.span`
  margin-left: 2px;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.1em;
  opacity: 0.4;
`;

export const $Text = styled.span`
  font-feature-settings: 'tnum' on, 'lnum' on;
`;

export const $PointsRow = styled.p`
  margin-top: 2px;

  font-size: 15px;
  line-height: 18px;
`;

export const $PointItem = styled.span``;

export const $PlayerAvatar = styled.div`
  flex-shrink: 0;
  display: block;
  width: 60px;
  height: 60px;

  margin-right: 10px;
  margin-top: 1px;

  img {
    display: block;
  }
`;

export const $PlayerTeam = styled.p`
  margin-top: 1px;
`;

export const $LazyAvatar = styled.div``;

export const $Item = styled.div<{ isHighlighted?: boolean }>`
  position: relative;

  ${baseRow('stretch', 'flex-start')};

  width: 100%;
  padding-top: 6px;
  padding-left: 0;
  padding-bottom: 14px;

  font-weight: 300;
  color: ${textColors['white']};
  text-decoration: none;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      z-index: 1;

      &::after {
        background-color: ${transparentColor(textColors['white'], 0.25)};
        z-index: -1;
        top: -1px;
        left: -10px;
        position: absolute;
        content: '';
        width: 100vw;
        height: calc(100% + 2px);
      }
    `}
`;

export const $TeamItem = styled($Item)`
  min-height: 81px;
  padding-top: 7px;
  padding-bottom: 12px;

  &:first-of-type {
    min-height: 79px;
    padding-top: 7px;
    padding-bottom: 11px;
  }

  ${$Index} {
    width: 28px;
  }
`;

export const $PlayerItem = styled($Item)`
  padding-top: 8px;
  padding-left: 0;
  padding-bottom: 9px;

  ${$Index} {
    width: 19px;
  }
`;

export const $PlayerLinkWrapper = styled(Link)`
  ${baseRow('stretch', 'flex-start')};

  color: ${textColors['white']};
  text-decoration: none;

  &:first-of-type ${$PlayerItem} {
    padding-top: 9px;
  }
`;
