import {
  IRegistrationData,
  IUserData,
  IRequestPagination,
  IGameData,
  IRatingsData,
  IStatisticRequest,
  IUserUidData,
  IAvailableTitleData,
  ICertificatesData,
  IUpdateFide,
  IFindFidePlayers,
  IFideUsersResponse,
  IChallengesList,
} from 'shared/types';
import { IIsOnline, ITitleProgress, IUpdateMe } from '@types';
import { BaseService } from './_base.service';
import { createAuthorizationHeaders } from '@utils/_requests';
import { httpInstance } from '.';

export interface LoginResult {
  token: string;
}

// Copied from shared
export class UserService extends BaseService {
  /**
   * Осуществляет запрос на вход пользователя
   * @param email - почта пользователя
   * @param password пароль пользователя
   */
  login(email: string, password: string) {
    return this.ajax.post<{ token: string }>('auth/jwt/create/', {
      body: { email, password },
    });
  }

  /**
   * Осуществляет запрос на обновление токена
   * @param token - валидный токен
   */
  refreshToken(token: string) {
    return this.ajax.post<{ token: string }>('auth/jwt/refresh/', {
      body: { token },
    });
  }

  /**
   * Осуществляет запрос о проверке почты на уникальность(зарегистрирована почта, или нет)
   * @param email почта пользователя
   */
  checkEmail(email: string) {
    return this.ajax.post('auth/users/email-check/', { body: { email } });
  }

  /**
   * Осуществляет запрос на отправку проверочного кода на почту
   * @param {string} email - почта пользователя.
   * @param {string} first_name - имя пользователя.
   * @param {string} last_name - фамилия пользователя.
   * @param {string} country - страна пользователя.
   * @param {boolean} is_paid - платная или бесплатная подписка.
   * @param {boolean} password - платная или бесплатная подписка.
   * @param {boolean} free_account_requested - запрос на создание бесплатного про-аккаунта
   */
  authCode({
    email,
    first_name,
    last_name,
    country,
    is_paid,
    password,
    free_account_requested,
  }: {
    email: string;
    first_name: string;
    last_name: string;
    country: string;
    is_paid: boolean;
    password: string;
    free_account_requested: boolean;
  }) {
    return this.ajax.post<LoginResult>('auth/users/simple-auth-code/', {
      body: {
        email,
        first_name,
        last_name,
        country,
        is_paid,
        password,
        free_account_requested,
      },
    });
  }

  /**
   * Осуществляет запрос на проверку введённого кода
   * @param code проверочный код
   * @param email почта пользователя
   */
  activationCode(
    code: string,
    email?: string | null,
    receive_newsletters?: boolean
  ) {
    const body: {
      code: string;
      email?: string;
      receive_newsletters?: boolean;
    } = {
      code,
    };

    if (email) {
      body.email = email;
    }

    if (typeof receive_newsletters === 'boolean') {
      body.receive_newsletters = receive_newsletters;
    }

    return this.ajax.post<IRegistrationData>('auth/users/activation-code/', {
      body,
    });
  }

  /**
   * Осуществляет запрос на повторную отправку проверочного кода на почту
   * @param email почта пользователя
   */
  resendActivationCode(email: string) {
    return this.ajax.get(`auth/users/activation-code/?email=${email}`);
  }

  /**
   * Осуществляет запрос на повторную отправку проверочного кода на почту
   * @param uid uid пользователя
   * @param token JWT пользователя
   * @param new_password новый пароль пользователя
   */
  passwordResetConfirm(uid: string, token: string, new_password: string) {
    return this.ajax.post('auth/password/reset/confirm/', {
      body: { uid, token, new_password },
    });
  }

  /**
   * Осуществляет запрос о данных пользователя.
   */
  me(jwt?: string) {
    return this.ajax.get<IUserData>('me/', createAuthorizationHeaders(jwt));
  }

  /**
   * Осуществляет запрос о данных пользователя.
   */
  updateMe(data: IUpdateMe) {
    return this.ajax.patch<IUserData>('me/', { body: { ...data } });
  }

  /**
   * Осуществляет запрос о изменении статуса free-account-requested
   */
  updateFreeAccount(free_account_requested: boolean) {
    return this.ajax.patch<IUserData>('me/free-account-requested/', {
      body: { free_account_requested },
    });
  }

  /**
   * Осуществляет запрос о последних играх пользователя.
   */
  gameHistory(userId: string | number, offset = 0, limit = 20) {
    return this.ajax.get<IRequestPagination<IGameData>>(
      `/online/players/${userId}/game-history?limit=${limit}&offset=${offset}`
    );
  }

  /**
   * Осуществляет запрос о рейтинге пользователя.
   */
  userRatings() {
    return this.ajax.get<IRatingsData>('me/ratings/');
  }

  /**
   * Осуществляет запрос о статистике игр пользователя.
   * @param playerId - id игрока
   * @param type - worldchess | fide
   */
  userStats(playerId: number | string, type?: string) {
    return this.ajax.get<IStatisticRequest>(
      `online/players/${playerId}/stats/${type ? `?rating_type=${type}` : ''}`
    );
  }

  /**
   * Осуществляет запрос на получение uid пользователя
   */
  getUserUid(uid: string | null) {
    return this.ajax.get<IUserUidData>(`me/uid/${uid ? `?uid=${uid}` : ''}`);
  }

  /**
   * Осуществляет запрос о прогрессе титулов.
   */
  titleProgress() {
    return this.ajax.get<ITitleProgress>('me/title-progress/');
  }

  /**
   * Получает сертфикаты юзера
   */
  getCertificates(jwt?: string) {
    return this.ajax.get<ICertificatesData>(
      `me/title-certificates/`,
      createAuthorizationHeaders(jwt)
    );
  }

  /**
   * Осуществляет запрос о регистрации аккаунта FIDE.
   */
  updateFide(data: IUpdateFide) {
    return this.ajax.post<IAvailableTitleData>('me/fide/', {
      body: { ...data },
      // timeout: 300000, // 5 min
    });
  }

  /**
   * Осуществляет запрос о поиске аккаунтов FIDE.
   */
  findFidePlayers(data: IFindFidePlayers) {
    return this.ajax.get<IFideUsersResponse>(
      `players/?${
        data.fide_id
          ? `fide_id=${data.fide_id}`
          : `full_name=${data.full_name}&birth_year=${data.birth_year}`
      }`
    );
  }

  /**
   * Осуществляет запрос на изменение пароля.
   * @param {string} current_password - Текущий пароль игрока
   * @param {string} new_password - Новый пароль игрока
   */
  passwordChange(current_password: string, new_password: string) {
    return this.ajax.post<Record<string, string>>('auth/password/set/', {
      body: {
        current_password,
        new_password,
      },
    });
  }

  /**
   * Запрос для получаения тарифных планав
   */
  getSpecialPlans() {
    return this.ajax.get<IUserData>('plans/special_plans');
  }

  /**
   * Выполняет запрос на получение данных пользователя по id
   * @param {number | string} userId - id пользователя
   */
  getProfile(userId: string | number) {
    return this.ajax.get<IUserData>(`online/players/${userId}/profile/`);
  }

  /**
   * Выполняет запрос на получение статуса онлайн пользователя по id
   * @param {number | string} userId - id пользователя
   */
  getIsOnline(userId: string | number) {
    return this.ajax.get<IIsOnline>(`online/players/${userId}/is_online/`);
  }

  /**
   * Выполняет запрос на получение списка челенджей
   */
  getChallengesList() {
    return this.ajax.get<IChallengesList>('online/challenges/');
  }

  /**
   * Выполняет запрос на создание исходящего челенджа
   * @param {number} opponent - id пользователя
   * @param {number} timeControl - выбранный тайм контроль
   * @param {string | null} desiredColor - предпочитаемая сторона
   * @param {string | null} ratingType - выбранный тип рейтинга
   */
  createChallenge({
    opponent,
    timeControl,
    desiredColor,
    ratingType,
  }: {
    opponent: number;
    timeControl: number;
    desiredColor?: string | null;
    ratingType?: string | null;
  }) {
    return this.ajax.post('/online/challenges/', {
      body: {
        opponent: opponent,
        time_control: timeControl,
        desired_color: desiredColor,
        rating_type: ratingType,
      },
    });
  }

  /**
   * Выполняет запрос на принятие выбранного челенджа
   * @param {number} id - id челенджа
   */
  acceptChallenge(id: number) {
    return this.ajax.post(`online/challenges/${id}/accept/`);
  }

  /**
   * Выполняет запрос на отклонение выбранного челенджа
   * @param {number} id - id челенджа
   */
  declineChallenge(id: number) {
    return this.ajax.post(`online/challenges/${id}/decline/`);
  }

  /**
   * Выполняет запрос на отмену выбранного челенджа
   * @param {number} id - id челенджа
   */
  deleteChallenge(id: number) {
    return this.ajax.delete(`online/challenges/${id}/`);
  }
}

export const userService = new UserService({ instance: httpInstance });
