import { getFile } from "../stringBoard/_getFile";
import { getRank } from "../stringBoard/_getRank";
/**
 * Переводит индекс позиции в формат IChessPosition
 * @param {number} index - индекс позиции
 * @returns {IChessPosition} position
 */
export function getChessPosByIndex(index) {
    return {
        file: getFile(index),
        rank: getRank(index),
    };
}
