import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { proPlan } from '../constants';
import { getCountryById } from './_get_country_by_id';
dayjs.extend(advancedFormat);
/**
 * Синхронизирует время dayjs с временем сервера
 * @returns {dayjs.Dayjs}
 */
export const syncedMoment = () => dayjs().add(typeof window !== 'undefined' && window['timeDiff']
    ? window['timeDiff']
    : 0, 'ms');
/**
 * Синхронизирует время Date с временем сервера
 * @returns {Date}
 */
export const syncedDate = () => {
    const now = Date.now();
    const diff = typeof window !== 'undefined' && window['timeDiff']
        ? window['timeDiff']
        : 0;
    const date = new Date(now + diff);
    return date;
};
/**
 * возвращает текстовый результат партии
 * @param {number} type - id result
 * @param {boolean} isMyProfile - свой/чужой профиль
 * @returns {result} string
 */
export const getResultText = (result, isMyProfile) => {
    switch (result) {
        case 1:
            return isMyProfile ? 'You Won' : 'Won';
        case 2:
            return 'Draw';
        case 3:
            return isMyProfile ? 'You Lost' : 'Loss';
        case 4:
            return 'Cancelled';
        default:
            return isMyProfile ? 'You Lost' : 'Loss';
    }
};
/**
 * считает возраст от даты рождения
 * @param {string} birthDate - дата рождения
 * @returns {result} string
 */
export const getAge = (birthDate, text) => {
    return birthDate ? `${syncedMoment().diff(birthDate, 'years')} ${text}` : '';
};
/**
 * создает ссылку для скачивания pgn файла
 * @param {string} nBlob - блоб
 * @param {string} nameLink  - название файла
 */
export function createLink(nBlob, nameLink) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(nBlob);
        return;
    }
    const data = window.URL.createObjectURL(nBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = `${nameLink}`;
    link.click();
    setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
    }, 100);
}
/**
 * возвращает округлённое до 100 в большую сторону число
 * @returns {result} number
 */
export const ceil100 = (value) => {
    let result = Math.ceil(value / 100) * 100;
    if (result === value) {
        result = value + 100;
    }
    return result;
};
/**
 * округляет число до нужного количества знаков после точки
 * @param {number} number - число для округления
 * @param {number} fixed - до скольки знаков округлить
 * @returns {result} number
 */
export const formatNumber = (number, fixed) => {
    const value = Number(number);
    return !isNaN(value) ? Number(value.toFixed(fixed)) : 0;
};
/**
 * выполняет функцию по разу в указанный период времени
 * @param {Function} callback - принимаемая функиция
 * @param {number} delay  - задержка
 * @returns {Function} Function
 */
export const throttle = (callback, delay) => {
    let timeout = false;
    return function (...args) {
        if (timeout)
            return;
        timeout = true;
        callback(...args);
        setTimeout(() => {
            timeout = false;
        }, delay);
    };
};
/**
 * блокирует исполнение функции до истечения таймера
 * @param {Function} callback  - принимаемая функиция
 * @param {number} delay  - задержка
 * @returns {Function} Function
 */
export const debounce = (callback, delay) => {
    let timeout = 0;
    return (...args) => {
        let result;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            result = callback(...args);
        }, delay);
        return result;
    };
};
/**
 * возвращает строку с токеном (?t={token})
 * @param {string} token - токен
 * @returns {string}
 */
export const getTokenParam = (token) => {
    return token ? `?t=${token}` : '';
};
/**
 * определяет, с мобильного устройства или нет зашёл пользователь
 * @returns {boolean}
 */
export const detectMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];
    const isIpad = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem) || isIpad;
    });
};
export function youTubeVideoIdReplace(text) {
    const regexp = /<iframe(\s|src=")src="(?:https?:)?(?:\/\/)?(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)*/gim;
    return text.match(regexp);
}
/**
 * Возвращает id борды из урла с id, приходящего с бэка
 * @param {string} boardPgnUrl - url
 * @returns {string}
 */
export const getBoardUidFromUrl = (boardPgnUrl) => {
    const res = boardPgnUrl.split('/');
    return res[4];
};
/**
 * Возвращает возраст пользователя в цифровом формате
 * @param {string} birthDate - дата рождению
 * @returns {number}
 */
export const getAgeNumber = (birthDate) => {
    return syncedMoment().diff(birthDate, 'years');
};
/**
 * Прибавляет к текущей дате время в милллисекундах
 * @param {number} ms - милллисекунды
 * @returns {string}
 */
export const addMilisecondsToNow = (ms) => {
    return syncedMoment().add(ms).toISOString();
};
/**
 * Прибавляет к текущей дате время в милллисекундах
 * @param {string} data - Дата
 * @returns {string}
 */
export const getNewsFormatData = (data) => {
    return data.match(/[0-9]{2}.[a-zA-z]{3}/gm);
};
/**
 * Возвращает строку с большой буквы
 * @param {string} string - слово
 * @returns {string}
 */
export const capitalizeString = (string) => {
    return `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;
};
/**
 * Рассчитывает цену оплаты
 * @param {number} price - изначальная цена
 * @param {number} discount - скидка
 * @returns {string}
 */
export const getPrice = (price, discount) => {
    if (discount) {
        const newPrice = price - (price * discount) / 100;
        return formatNumber(newPrice, 1);
    }
    return price;
};
export const findProPlan = (userData) => {
    let currentFide = null;
    userData.subscriptions.forEach((subscription) => {
        if (proPlan.includes(subscription.plan.stripe_id)) {
            if (!currentFide || !currentFide.is_active) {
                currentFide = subscription;
            }
        }
    });
    return currentFide;
};
/**
 * возвращает страну игрока
 * @param {number} country - страна
 * @returns {result} string
 */
export const getPlayerCountry = (country) => {
    var _a;
    const countryCode = (_a = getCountryById(country)) === null || _a === void 0 ? void 0 : _a.long_code;
    if (countryCode)
        return countryCode;
    return '—';
};
/**
 * Возвращает имя игрока. Если указан ключ (имя или фамилия),
 * и в профиле они заполнены, возвращает по ключу
 * @param {IGamePlayer} player - данные игрока
 * @param {'first_name' | 'last_name'} key - ключ (имя или фамилия)
 * @returns {result} string
 */
export const getPlayerName = (player, key) => {
    var _a, _b;
    const nameByKey = key && player && ((_a = player[key]) === null || _a === void 0 ? void 0 : _a.length) ? player[key] : null;
    if (nameByKey) {
        return nameByKey;
    }
    if ((_b = player === null || player === void 0 ? void 0 : player.full_name) === null || _b === void 0 ? void 0 : _b.length) {
        return player.full_name;
    }
    return 'Anonymous';
};
