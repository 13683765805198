import { bindActions } from 'storeshed';
import { mainStore } from '@store/storeshed';
import { SoundType } from '@types';
import { TMainStore } from '../_types';

const createSoundsActions = () => {
  const playSound = (
    { boardSettingsStore }: TMainStore,
    soundType: SoundType
  ) => {
    boardSettingsStore.soundsController?.playSound(soundType);
  };

  return { playSound };
};

export const soundsActions = bindActions(mainStore, createSoundsActions());
