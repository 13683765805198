var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { shapeColors } from '../../../../styles';
import { $Preloader, spinnerStyles, $IconWrapper, $PreloaderWrapper, } from './_styles';
import { PreloaderIcon } from '../../../atoms/icons/baseIcons/_PreloaderIcon';
/**
 * Прелоадер
 * @param {React.ReactElement} icon - иконка
 * @param {string} size - размер спинера
 * @param {string} className - внешний класс
 * @param {string} color - цвет прелодера
 * @param {object} style - внешние стили прелодера
 * @param {object} otherProps - любые другие значения, передаваемые компоненту
 */
export const Preloader = (_a) => {
    var { icon, size = '57px', theme = 'light', color = shapeColors['grey_deep'], className = '' } = _a, otherProps = __rest(_a, ["icon", "size", "theme", "color", "className"]);
    return (React.createElement($Preloader, Object.assign({ className: className }, otherProps),
        React.createElement($PreloaderWrapper, { style: { width: size, height: size } },
            React.createElement($Spinner, { width: size, height: size, fill: color })),
        icon && React.createElement($IconWrapper, null, icon)));
};
const $Spinner = styled(PreloaderIcon) `
  ${spinnerStyles}
`;
