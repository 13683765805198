import { calculatePiecePossibleMoves } from "../calculations";
import { outOfBoard } from "../stringBoard/_outOfBoard";
import { B_PAWN, chessSide, DEFAULT_CASTLINGS, SQUARES, W_PAWN } from "../_constants";
/**
 * Генерирует fen на основе входных данных
 * @param param0
 * @returns
 */
export function generateFen({ board, nextTurn, enPassant = undefined, movesWithoutCapture = 0, possibleCastlings = DEFAULT_CASTLINGS, halfMove = 1, }) {
    const positionsString = createPositions(board);
    const castlingsString = createCastlings(possibleCastlings);
    const enPassantString = createEnPassant({ enPassant, board, nextTurn });
    const move = Math.ceil((halfMove + 1) / 2);
    return [
        positionsString,
        nextTurn[0],
        castlingsString,
        enPassantString,
        movesWithoutCapture,
        move,
    ].join(" ");
}
/**
 * Создает запись формата "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR" на основе массива фигур
 * @param {string[]}board - массив с фигурами
 * @returns {string} positionsString
 */
const createPositions = (board) => {
    let empty = 0;
    let positions = "";
    for (let i = 0; i < board.length; i++) {
        if (outOfBoard(i)) {
            i += 7;
            if (empty) {
                positions += empty;
                empty = 0;
            }
            if (i !== SQUARES.h1) {
                positions += "/";
            }
            continue;
        }
        const piece = board[i];
        if (!piece) {
            empty++;
            continue;
        }
        if (empty > 0) {
            positions += empty;
            empty = 0;
        }
        if (!piece) {
            throw new Error(`generating fen error. piece: ${piece}`);
        }
        positions += piece;
    }
    if (empty > 0) {
        positions += empty;
    }
    return positions;
};
/**
 * Создает запись формата "KQkq или -" в зависимости от возможных рокировок
 * @param possibleCastlings
 * @returns
 */
const createCastlings = (possibleCastlings) => {
    let castlings = "";
    castlings += (possibleCastlings[chessSide.WHITE].kingSide && "K") || "";
    castlings += (possibleCastlings[chessSide.WHITE].queenSide && "Q") || "";
    castlings += (possibleCastlings[chessSide.BLACK].kingSide && "k") || "";
    castlings += (possibleCastlings[chessSide.BLACK].queenSide && "q") || "";
    if (castlings === "")
        return "-";
    return castlings;
};
/**
 * Создает запись формата "e3 | -" на основе следующих правил:
 * - наличие возможности взятия на проходе
 * - есть ли пешка, которая может осущиствить взятие
 * @returns enPassantString - если два условия не выполнены, вернет "-",
 * в противном случае вернет поле формата "[file][rank]"
 */
const createEnPassant = ({ enPassant, board, nextTurn, }) => {
    if (enPassant === undefined ||
        !shouldIncludeEnPassant([...board], nextTurn, SQUARES[(enPassant.file + enPassant.rank)]))
        return "-";
    return enPassant.file + enPassant.rank;
};
/**
 * Определяет, необходимо ли указывать поле для взятия на проходе.
 * Возвращает  true, если какая то пешка может пойти на это поле.
 */
const shouldIncludeEnPassant = (board, nextTurn, enPassant) => {
    const pawnSymbol = nextTurn === chessSide.WHITE ? W_PAWN : B_PAWN;
    for (let i = 0; i < board.length; i++) {
        if (outOfBoard(i)) {
            i += 7;
            continue;
        }
        if (board[i] !== pawnSymbol)
            continue;
        if (calculatePiecePossibleMoves({
            index: i,
            enPassant,
            board,
        }).includes(enPassant)) {
            return true;
        }
    }
    return false;
};
