import { useEffect, useMemo } from 'react';
import { eAccessLevel } from 'shared/types';
import {
  CB_SETTINGS,
  PRO_CB_SETTINGS,
} from '@store/storeshed/stores/_boardSettings/_constants';
import {
  boardSettingsActions,
  defaultSettings,
  useBoardSettingsData,
  useUserDataStore,
} from '@store/storeshed';
import { useSettingsBoardThemes } from '@components/molecules/popups/ChessboardSettingsPopup/internal/context/hooks/_useSettingsBoardThemes.hook';

/**
 * Хук проверяющий про настройки для доски
 * Если у пользователя выбрана платная тема доски, но истекла подписка, то мы сбрасываем платную тему на бесплатную дефолтную world chess, данная платная тема доски сохраняется и после продления подписки вернется
 */

export const useProBoardSettings = () => {
  const userData = useUserDataStore('data');
  const boardTheme = useBoardSettingsData('boardTheme');

  const isPro = useMemo(
    () => userData?.access_level === eAccessLevel.PRO,
    [userData]
  );

  const boardsThemes = useSettingsBoardThemes();

  const boardThemeData = useMemo(
    () => boardsThemes.find((item) => item.value === boardTheme),
    [boardTheme, boardsThemes]
  );

  useEffect(() => {
    if (!boardThemeData)
      boardSettingsActions.setBoardTheme(defaultSettings.boardTheme);

    if (boardThemeData?.paid && !isPro) {
      const settings = localStorage.getItem(CB_SETTINGS);
      if (settings) localStorage.setItem(PRO_CB_SETTINGS, settings);
      boardSettingsActions.setBoardTheme(defaultSettings.boardTheme);
    }

    if (isPro) {
      const proSettings = localStorage.getItem(PRO_CB_SETTINGS);
      if (proSettings) {
        const proBoardTheme = JSON.parse(proSettings).boardTheme;
        if (proBoardTheme !== boardTheme)
          boardSettingsActions.setBoardTheme(proBoardTheme);
        localStorage.removeItem(PRO_CB_SETTINGS);
      }
    }
  }, [boardTheme, boardThemeData, isPro]);
};
