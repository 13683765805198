import { IGameInQueueDataResponse } from '@store/context/lobby_context/_lobby_context.types';
import { chessSide } from 'chessgun/core';
import { IRatingLimits } from 'shared/atoms';
import {
  GameRatingMode,
  GameResult,
  IPgnMove,
  ITimeControl,
  eChessColor,
  oppMode,
} from 'shared/types';

// export enum GameResult {
//   WHITE_WIN = 1,
//   DRAW = 2,
//   BLACK_WIN = 3,
//   ABORTED = 4,
// }

export enum GameType {
  SINGLE_GAME = 'single game',
  TOURNAMENT = 'tournament',
  BROADCAST = 'broadcast',
  NONE = 'none',
}

export enum GameUserType {
  PLAYER = 'player',
  VIEWER = 'viewer',
  NONE = 'none',
}

export enum EndReason {
  TIMEOUT = 'TIMEOUT',
  CLASSIC = 'CLASSIC',
  TIME_CONTROL = 'TIME_CONTROL',
  RESIGN = 'RESIGN',
  DRAW = 'DRAW',
  ABORT = 'ABORT',
  DISCONNECT = 'DISCONNECT',
  DRAW_OFFER = 'DRAW_OFFER',
  FOLD_REPETITION = 'FOLD_REPETITION', // Draw because of 5 repetitions,
  THREEFOLD_REPETITION = 'THREEFOLD_REPETITION',
  ALREDY_ENDED = 'ALREADY ENDED',
  FAIR_PLAY_VIOLATION = 'FAIR_PLAY_VIOLATION', // bot extesions
}

export enum TimeLimitWarning {
  NoTimeLimitWarning = 0,
  IdleTimeLimitWarning = 1,
  EndGameTimeLimitWarning = 2,
}

export enum InviteErrors {
  FIDE_RATED_GAME_UNAVAILABLE_FOR_USER = 'non_premium_available_rating',
  ANON_AVAILABLE_RATING = 'anon_available_rating',
  REQUEST_NOT_VERIFIED = 'request_not_verified',
  INVALID_CODE = 'friend_invite_code_is_invalid',
  OWNER_DISCONNECTED = 'owner_disconnected',
  TOO_MANY_ACTIVE_GAMES = 'player_has_too_many_active_single_game_boards',
  CHALLENGE_NOT_FOUND = 'challenge_not_found',
}

export interface IInviteData {
  uid: string;
  created: string;
  player_rating: number;
  invite_code: string;
  player_uid: string;
  rating: GameRatingMode[];
  opp_mode: oppMode;
  time_control: ITimeControl;
  rating_limits: {
    lower: string;
    upper: string;
    bounds: string;
  };
  desired_color: eChessColor | null;
  is_new_gaming?: boolean;
}

export interface IInviteBody {
  player_uid: string;
  rating: string[]; // GameRatingMode
  opp_mode: string; // oppMode
  time_control: number[]; // ITimeControl
  rating_limits?: IRatingLimits;
  desired_color?: eChessColor | null;
  opp_uid?: string;
  rematch?: boolean;
}

export interface IInviteGameData {
  player_uid: string;
  opp_mode: string;
  rating: string;
  time_control: number;
  ratingLimits?: IRatingLimits;
  desired_color?: eChessColor;
  opp_uid?: string;
  rematch?: boolean;
  is_new_gaming?: boolean;
  is_multiregion?: boolean;
}

export type CreateInviteBodyParams = {
  desired_color?: 'white' | 'black';
  rating_from?: number | undefined;
  rating_to?: number | undefined;
  time_control_id?: number;
  owner_anonymous_uid?: string | undefined;
  with_bot?: number;
};

export type Invite = {
  desiredColor?: 'white' | 'black';
  inviteType: 'bot' | ''; // add later
  ratingFrom?: number;
  ratingTo?: number;
  timeControlId: number;
  ownerAnonymousUid?: string;
};

export type CreateInviteParams = Invite;
export interface IBoardResult {
  result: GameResult;
  white_uid: string;
  white_elo: number;
  white_delta: number;
  white_elo_result: number;
  white_calories_loss: number;
  black_elo: number;
  black_delta: number;
  black_elo_result: number;
  black_calories_loss: number;
}

export interface IInviteErrorData {
  code: InviteErrors;
  detail: string;
  friend_name?: string | null;
}

export interface IBoardEndedData {
  black_ms_left: number;
  reason: EndReason;
  result: GameResult;
  white_ms_left: number;
  finished_at?: string | null;
  board_id: string;
}

export enum PolygonColors {
  WHITE = '#8E8378',
  BLACK = 'black',
  RED = '#F4574D',
  GREEN = '#9FC620',
}

export interface IPolygon {
  color: PolygonColors;
  startPoints: string;
  points: string;
}

export interface ITimeSpentItem {
  fen: string;
  is_white_move: boolean;
  ms_spent: number;
}

export interface IClassifiedPolygon extends IPolygon {
  from?: { x: number; y: number };
  to?: { x: number; y: number };
}

export interface IKeyMomentInfo {
  isWhiteMove: boolean;
  color: PolygonColors;
  san: string | null;
  lan: string | null;
  mateIn: number | null;
  fen: string;
  moveNumber: number;
  moveEval: number;
  left: number;
  claclassification: 'good' | 'bad' | null;
}

export interface IChartData {
  baseItems: IPolygon[];
  classifiedItems: IClassifiedPolygon[];
  d: string;
  startD: string;
  keyMoments: IKeyMomentInfo[] | null;
}

export interface IChartBoundares {
  maxTimeSpent: number;
  maxEval: number;
}

export interface IAnalysisMove {
  eval?: number | null;
  long_san: string;
  mate_in?: number | null;
  fen: string;
  san: string | null;
  is_white_move: boolean;
  classification?: 'bad' | 'good' | '' | null;
  key_moment?: boolean | null;
}

export interface IMyMove {
  created: string;
  fen: string;
  san: string;
  lan: string;
}

export interface ILineMoves {
  [chessSide.WHITE]: IPgnMove | null;
  [chessSide.BLACK]: IPgnMove | null;
}

export interface IShortChallengeRequestParams {
  desiredColor?: eChessColor;
  ratingType: GameRatingMode;
  ratingFrom?: number;
  ratingTo?: number;
  timeControlId: number;
  ownerAnonymousUid?: string | null;
  inviteType?: 'bot' | 'challenge';
}

export interface IInviteChallengeRequestParams {
  desiredColor?: eChessColor;
  timeControlId: number | null;
  ratingFrom?: number;
  ratingTo?: number;
  inviteType?: 'bot' | 'challenge';
  withOpponentUid?: string;
  ratingType?: GameRatingMode;
}

export interface ILongChallengeRequestParams {
  opponentId: number;
  timeControlId: number;
  desiredColor?: eChessColor;
  ratingType?: GameRatingMode;
}

export interface IChallengesQueueRequestData {
  count: number;
  next: string | null;
  previous: string | null;
  results: IGameInQueueDataResponse[];
}

export interface IAcceptShortChallengeRequestParams {
  inviteId: number;
}

export interface ISuspicionReportRequestParams {
  boardUid: string;
  senderId: number;
  userEstimation: number;
}
