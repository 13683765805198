import { closePopup } from '@utils/_router';
import {
  IPopupsContextDispatch,
  IPopupsContextActions,
  ePopupsContextActionTypes,
} from './_types';

export const usePopupsActions = (
  dispatch: IPopupsContextDispatch
): IPopupsContextActions => {
  const setUpdates = (updates: boolean) => {
    dispatch({
      type: ePopupsContextActionTypes.SET_REGISTRATION_POPUPS_UPDATES,
      payload: updates,
    });
  };

  const setRegistrationCurrentTab = (currentTab: string | null) => {
    dispatch({
      type: ePopupsContextActionTypes.SET_REGISTRATION_POPUPS_CURRENT_TAB,
      payload: currentTab,
    });
  };

  const setFreePro = (freePro: boolean) => {
    dispatch({
      type: ePopupsContextActionTypes.SET_REGISTRATION_POPUPS_FREE_PRO,
      payload: freePro,
    });
  };

  const setIsVerification = (isVerification: boolean) => {
    dispatch({
      type: ePopupsContextActionTypes.SET_REGISTRATION_POPUPS_IS_VERIFICATION,
      payload: isVerification,
    });
  };

  const resetRegistrationData = () => {
    dispatch({
      type: ePopupsContextActionTypes.RESET_REGISTRATION_POPUPS,
    });
  };

  const closeRegistrationPopup = () => {
    closePopup();
    resetRegistrationData();
  };

  const setFideId = (fideId: number | null) => {
    dispatch({
      type: ePopupsContextActionTypes.SET_FIDE_POPUPS_FIDE_ID,
      payload: fideId,
    });
  };

  const setFideCurrentTab = (currentTab: string | null) => {
    dispatch({
      type: ePopupsContextActionTypes.SET_FIDE_POPUPS_CURRENT_TAB,
      payload: currentTab,
    });
  };

  const setPhoto = (photo: string) => {
    dispatch({
      type: ePopupsContextActionTypes.SET_FIDE_POPUPS_PHOTO,
      payload: photo,
    });
  };

  const resetFideData = () => {
    dispatch({
      type: ePopupsContextActionTypes.RESET_FIDE_POPUPS,
    });
  };

  const closeFidePopup = () => {
    closePopup();
    resetFideData();
  };

  return {
    setUpdates,
    setRegistrationCurrentTab,
    setFreePro,
    setIsVerification,
    closeRegistrationPopup,

    setFideId,
    setFideCurrentTab,
    setPhoto,
    closeFidePopup,
  };
};
