import { outOfBoard } from "../stringBoard/_outOfBoard";
import { BLACK_SYMBOLS, chessSide, SQUARES, WHITE_SYMBOLS } from "../_constants";
import { getPawnPossibleAttacks } from "./_getPawnPossibleAttacks";
import { getPawnPossibleMoves } from "./_getPawnPossibleMoves";
import { getPiecePossibleAttacks } from "./_getPiecePossibleAttacks";
import { getPiecePossibleMoves } from "./_getPiecePossibleMoves";
/**
 * Проверяет, под атакой ли поле
 * @param {number} index - индекс поля на доске
 * @param {string[]} board - доска
 * @returns {chessSide} attackerscolor - цвет атакующей стороны
 */
export const isSquareUnderAttack = ({ index, board, attackersColor, }) => {
    const figSymbols = attackersColor === chessSide.WHITE ? WHITE_SYMBOLS : BLACK_SYMBOLS;
    for (let i = 0; i <= SQUARES.h1; i++) {
        if (outOfBoard(i)) {
            i += 7;
            continue;
        }
        let possibleAttacks = [];
        if (figSymbols.includes(board[i])) {
            if (board[i].toLowerCase() === "p") {
                possibleAttacks = [
                    ...getPawnPossibleMoves({
                        index: i,
                        board,
                    }),
                    ...getPawnPossibleAttacks({ index: i, board }),
                ];
            }
            else {
                possibleAttacks = [
                    ...getPiecePossibleMoves({ index: i, board }),
                    ...getPiecePossibleAttacks(i, board),
                ];
            }
            if (possibleAttacks.includes(index)) {
                return true;
            }
        }
    }
    return false;
};
