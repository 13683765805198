// ==================== ENUMS ==================== //
export var engineGameMode;
(function (engineGameMode) {
    engineGameMode["DEBUG"] = "DEBUG";
    engineGameMode["ANALYSIS"] = "ANALYSIS";
    engineGameMode["ONLINE"] = "ONLINE";
    engineGameMode["OFFLINE"] = "OFFLINE";
    engineGameMode["STREAM"] = "STREAM";
    engineGameMode["STOCKFISH"] = "STOCKFISH";
    engineGameMode["STOCKFISH_VS_STOCKFISH"] = "STOCKFISH VS STOCKFISH";
})(engineGameMode || (engineGameMode = {}));
export var figureType;
(function (figureType) {
    figureType["PAWN"] = "p";
    figureType["KING"] = "k";
    figureType["QUEEN"] = "q";
    figureType["ROOK"] = "r";
    figureType["BISHOP"] = "b";
    figureType["KNIGHT"] = "n";
})(figureType || (figureType = {}));
export var chessSide;
(function (chessSide) {
    chessSide["WHITE"] = "w";
    chessSide["BLACK"] = "b";
})(chessSide || (chessSide = {}));
export var moveTypes;
(function (moveTypes) {
    moveTypes["PAWN"] = "pawn";
    moveTypes["PIECE"] = "piece";
    moveTypes["CASTLING"] = "castling";
})(moveTypes || (moveTypes = {}));
export const DEFAULT_POSITION = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";
// ==================== WHITE PIECE SYMBOLS ==================== //
export const W_KING = "K";
export const W_QUEEN = "Q";
export const W_PAWN = "P";
export const W_KNIGHT = "N";
export const W_BISHOP = "B";
export const W_ROOK = "R";
export const WHITE_SYMBOLS = [W_KING, W_QUEEN, W_PAWN, W_KNIGHT, W_BISHOP, W_ROOK];
// ==================== BLACK PIECES SYMBOLS ==================== //
export const B_KING = "k";
export const B_QUEEN = "q";
export const B_PAWN = "p";
export const B_KNIGHT = "n";
export const B_BISHOP = "b";
export const B_ROOK = "r";
export const BLACK_SYMBOLS = [B_KING, B_QUEEN, B_PAWN, B_KNIGHT, B_BISHOP, B_ROOK];
// ==================== ALL PIECES SYMBOLS ==================== //
export const SYMBOLS = [...BLACK_SYMBOLS, ...WHITE_SYMBOLS];
// ==================== CASTLINGS ==================== //
export const CASTLING_SANS = ["O-O", "O-O-O", "O-O+", "O-O-O+"];
export const KING_CASTLING_SANS = ["O-O", "O-O+"];
export const QUEEN_CASTLING_SANS = ["O-O-O", "O-O-O+"];
export const DEFAULT_CASTLINGS = {
    [chessSide.WHITE]: { kingSide: true, queenSide: true },
    [chessSide.BLACK]: { kingSide: true, queenSide: true },
};
// ==================== RESULTS ==================== //
export const POSSIBLE_RESULTS = ["1-0", "0-1", "1/2-1/2", "*"];
// TODO: refactor
export const castleSquares = {
    [chessSide.WHITE]: {
        kingSide: [
            { index: 116, piece: "K", validate: true },
            { index: 117, piece: "", validate: true },
            { index: 118, piece: "", validate: true },
            { index: 119, piece: "R", validate: false },
        ],
        queenSide: [
            { index: 112, piece: "R", validate: false },
            { index: 113, piece: "", validate: false },
            { index: 114, piece: "", validate: true },
            { index: 115, piece: "", validate: true },
            { index: 116, piece: "K", validate: true },
        ],
    },
    [chessSide.BLACK]: {
        kingSide: [
            { index: 4, piece: "k", validate: true },
            { index: 5, piece: "", validate: true },
            { index: 6, piece: "", validate: true },
            { index: 7, piece: "r", validate: false },
        ],
        queenSide: [
            { index: 0, piece: "r", validate: false },
            { index: 1, piece: "", validate: false },
            { index: 2, piece: "", validate: true },
            { index: 3, piece: "", validate: true },
            { index: 4, piece: "k", validate: true },
        ],
    },
};
export const PIECES = ["Q", "R", "N", "B"];
export const PIECE_OFFSETS = {
    n: [-18, -33, -31, -14, 18, 33, 31, 14],
    b: [-17, -15, 17, 15],
    r: [-16, 1, 16, -1],
    q: [-17, -16, -15, 1, 17, 16, 15, -1],
    k: [-17, -16, -15, 1, 17, 16, 15, -1],
};
// prettier-ignore
export const SQUARES = {
    a8: 0, b8: 1, c8: 2, d8: 3, e8: 4, f8: 5, g8: 6, h8: 7,
    a7: 16, b7: 17, c7: 18, d7: 19, e7: 20, f7: 21, g7: 22, h7: 23,
    a6: 32, b6: 33, c6: 34, d6: 35, e6: 36, f6: 37, g6: 38, h6: 39,
    a5: 48, b5: 49, c5: 50, d5: 51, e5: 52, f5: 53, g5: 54, h5: 55,
    a4: 64, b4: 65, c4: 66, d4: 67, e4: 68, f4: 69, g4: 70, h4: 71,
    a3: 80, b3: 81, c3: 82, d3: 83, e3: 84, f3: 85, g3: 86, h3: 87,
    a2: 96, b2: 97, c2: 98, d2: 99, e2: 100, f2: 101, g2: 102, h2: 103,
    a1: 112, b1: 113, c1: 114, d1: 115, e1: 116, f1: 117, g1: 118, h1: 119
};
/** columns */
export const FILES = ["a", "b", "c", "d", "e", "f", "g", "h"];
/** rows */
export const RANKS = ["1", "2", "3", "4", "5", "6", "7", "8"];
