import { SocketStatus } from 'shared/types';
import { DependencyController } from '../hooks/_dependency.controller';
import { eGameServerPayloadType, MessageType } from './_types';

export abstract class WSController {
  public onOpen: (e: Event) => void = () => {};
  public onMessage: (e: MessageType) => void = () => {};
  public pageOnMessage: (e: MessageType) => void = () => {};
  public onError: (e: Event) => void = () => {};
  public onClose: (e: CloseEvent) => void = () => {};
  public onStatusChange: (status: SocketStatus) => void = () => {};

  protected _socket: WebSocket | null = null;
  protected _status: SocketStatus = SocketStatus.DISCONNECTED;
  protected _isInitialConnectionSetup = true;
  protected _dependencies = new DependencyController();

  readonly PING_DELAY = 5000; // задержка пинга в ms
  readonly RECONNECT_DELAY = 6000; // задержка переподключения в ms

  private reconnectTimeout: NodeJS.Timeout | null = null;
  private pingTimeout: NodeJS.Timeout | null = null;

  abstract PONG_MESSAGE: string;

  get status(): SocketStatus {
    return this._status;
  }

  set status(status: SocketStatus) {
    this._status = status;
    this.onStatusChange(status);
  }

  get isInitialConnectionSetup() {
    return this._isInitialConnectionSetup;
  }

  get dependencies() {
    return this._dependencies;
  }

  abstract get shouldReconnect(): boolean;

  abstract get url(): string;

  public connect(): void {
    console.log(
      `%c~ws~`,
      'color: lightgreen; background-color: black; padding: 0 8px;font-size: 14px',
      `Connecting to server ${this.url}`
    );
    this.status = SocketStatus.CONNECTING;

    this._connect();
  }

  protected _connect() {
    this.clearTimeouts();

    if (this._socket && this._socket.readyState !== WebSocket.CLOSED) {
      this._socket.close();
    }

    this._socket = new WebSocket(this.url);

    this._socket.onopen = this._onOpen.bind(this);
    this._socket.onmessage = this._onMessage.bind(this);
    this._socket.onerror = this._onError.bind(this);
    this._socket.onclose = this._onClose.bind(this);

    this.setDelayedReconnect();
  }

  private _onOpen(e: Event) {
    if (this._isInitialConnectionSetup) {
      this._isInitialConnectionSetup = false;
    } else {
      this.dependencies.applyDependencies();
    }

    this.status = SocketStatus.CONNECTED;

    this.onOpen(e);
    this._ping();

    console.log(
      `%c~ws~`,
      'color: lightgreen; background-color: black; padding: 0 8px;font-size: 14px',
      `Connection established! ${this.url}`
    );
  }

  private _onClose(e: CloseEvent) {
    this.status = SocketStatus.DISCONNECTED;
    this.clearTimeouts();
    this.onClose(e);
    console.log(
      `%c~ws~`,
      'color: lightgreen; background-color: black; padding: 0 8px;font-size: 14px',
      `Connection was closed ${this.url}`
    );

    if (this.shouldReconnect) this.setDelayedReconnect();
  }

  private _onError() {
    console.log(
      `%c~ws~`,
      'color: lightgreen; background-color: black; padding: 0 8px;font-size: 14px',
      `Error. Attempting to reconnect ${this.url}`
    );
  }

  clearTimeouts() {
    if (this.reconnectTimeout !== null) clearTimeout(this.reconnectTimeout);
    if (this.pingTimeout) clearTimeout(this.pingTimeout);
  }

  private setDelayedReconnect(delay = this.RECONNECT_DELAY) {
    if (this.reconnectTimeout !== null) clearTimeout(this.reconnectTimeout);

    this.reconnectTimeout = setTimeout(() => {
      this.connect();
    }, delay);
  }

  protected reconnect() {
    if (this.reconnectTimeout !== null) clearTimeout(this.reconnectTimeout);
    this.connect();
  }

  private setDelayedPing() {
    if (this.pingTimeout) clearTimeout(this.pingTimeout);

    this.pingTimeout = setTimeout(() => {
      this._ping();
    }, this.PING_DELAY);
  }

  private _ping() {
    this.ping();
    this.setDelayedReconnect();
  }

  abstract ping(): void;

  protected _onMessage(e: MessageEvent) {
    if (!e.data) return;

    const { payloadType, payload } = JSON.parse(e.data) as MessageType;

    if (payloadType === this.PONG_MESSAGE) {
      this.clearTimeouts();
      this.setDelayedPing();
    }

    if (payloadType === eGameServerPayloadType.LATENCY_REQ) {
      this.send(eGameServerPayloadType.LATENCY_RSP, { key: payload.key });
    }

    this.onMessage({
      payloadType,
      payload,
    } as MessageType);

    this.pageOnMessage({
      payloadType,
      payload,
    } as MessageType);
  }

  disconnect(code = 1000) {
    if (this._socket) {
      this._socket.close(code);
    }
  }

  send<T>(payloadType: string, payload: T | null = null) {
    try {
      const message = payload ? { payloadType, payload } : { payloadType };
      this._socket?.send(JSON.stringify(message));
    } catch (error) {
      console.log('send error', error);
    }
  }
}
