import { eCommunityChip } from '../types/pages/_communities.types';

export const chipsItemList = [
  { label: 'WCCM', value: 'WCCM' },
  { label: 'Harvard', value: 'Harvard' },
  { label: 'CEL', value: 'CEL' },
  { label: 'NYC', value: 'NYC' },
];

/**
 * popular - сортировка по наибольшему количеству сыгранных партий игроками клуба за месяц
 * biggest - сортировка по наибольшему количеству участников
 * new - в ответе на запрос буду только клубы, которые были созданы до 2х месяцев назад
 * online - клубы, у которых нет физического местоположения
 * offline - клубы, у которых есть физическое местоположение
 * america/asia/europe/africa - клубы принадлежащие определенному региону
 * top_tournaments - сортировка по наибольшему количество турниров от клуба за последние 2 месяца
 * kids - только клубы для детей
 */
export const chipsPresetList = [
  { label: 'Popular', value: eCommunityChip.POPULAR },
  { label: 'Biggest', value: eCommunityChip.BIGGEST },
  { label: 'New', value: eCommunityChip.NEW },
  { label: 'Top tournaments', value: eCommunityChip.TOP_TOURNAMENTS },
  { label: 'Kids', value: eCommunityChip.KIDS },
  { label: 'Online', value: eCommunityChip.ONLINE },
  { label: 'Offline', value: eCommunityChip.OFFLINE },
  { label: 'Americas', value: eCommunityChip.AMERICA },
  { label: 'Asia & Oceania', value: eCommunityChip.ASIA },
  { label: 'Europe', value: eCommunityChip.EUROPE },
  { label: 'Africa', value: eCommunityChip.AFRICA },
];
