import { Store, useStore } from 'storeshed';

export interface IAuthStore {
  logged: boolean;
  token: null | string;
  uid: null | string;
}

export const authInitialState = {
  logged: false,
  token: null,
  uid: null, // TODO: предусмотреть сохранение uid в ls и удаление при разлогине/логине пользователя, чтобы незалогинненый пользователь мог восстановить коннект по websocket при игре с компом
};

export const authStore = new Store<IAuthStore>({ ...authInitialState });

export const useAuthStore = <K extends keyof IAuthStore>(
  key: K
): IAuthStore[K] => useStore(authStore, key);
