import { Dispatch } from 'react';
import { IRatingLimits } from 'shared/atoms';
import {
  GameRatingMode,
  BoardType,
  TUserGameRating,
  IGroupedTimeControl,
  IAdvancedTimeControl,
  IRatingsData,
} from 'shared/types';

export enum eGameAllowanceStatus {
  FIND_OPPONENT = 1,
  CREATE_ACCOUNT = 2,
  FILL_FORM = 3,
  UPGRADE_NOW = 4,
}

export type TGameLobbyRatingModes =
  | GameRatingMode.FIDERATED
  | GameRatingMode.RATED
  | GameRatingMode.UNRATED;

export type TGameLobbyTimeControls =
  | BoardType.BLITZ
  | BoardType.RAPID
  | BoardType.BULLET;

export interface IGameLobbyContextState {
  currentUserRating: TUserGameRating;
  gameIsStarting: boolean;
  gameRatingMode: TGameLobbyRatingModes;
  timeControls: IGroupedTimeControl[];
  currentTimeControl: IAdvancedTimeControl;
  gameRatingLimits: {
    [n in TGameLobbyRatingModes]: {
      [a in TGameLobbyTimeControls]: { upper: number; lower: number };
    };
  };
}

export interface IGameLobbyContextComputed {
  gameAllowanceStatus: eGameAllowanceStatus;
  currentRatingLimits: IRatingLimits;
}

export interface IGameLobbyActions {
  setRatingByUser: (
    ratingLimits: IRatingLimits,
    state: IGameLobbyContextState
  ) => void;
  setTimeControl: (
    timeControl: IAdvancedTimeControl,
    state: IGameLobbyContextState
  ) => void;
  setRatingMode: (
    ratingMode: GameRatingMode,
    state: IGameLobbyContextState
  ) => void;
  setCurrentUserRating: (
    ratings: IRatingsData,
    gameRatingMode: TGameLobbyRatingModes,
    boardType: BoardType
  ) => void;
  resetGameSettings: (timeControl: IAdvancedTimeControl) => void;
  inviteAFriend: () => void;
}

export enum eGameLobbyReducerActionType {
  KeyOf,
  KeysOf,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TGameLobbyReducerActionKeyPayload = { key: string; value: any };
export type TGameLobbyReducerActionKeysPayload =
  TGameLobbyReducerActionKeyPayload[];

export type TGameLobbyReducerActionPayload =
  | TGameLobbyReducerActionKeyPayload
  | TGameLobbyReducerActionKeysPayload;

export type IGameLobbyContextDispatch = Dispatch<{
  type: eGameLobbyReducerActionType;
  payload: TGameLobbyReducerActionPayload;
}>;

export interface IGameLobbyContextProps {
  state: IGameLobbyContextState;
  computed: IGameLobbyContextComputed;
  actions: IGameLobbyActions;
}
