import React, { memo } from 'react';
// import styled from 'styled-components';

import { ITeamsTableRow } from '../_types';
import { $DesktopTableRow, $TableCell } from '../_styles';
import { commaNumFormatter } from '../_constants';
import { TR, TD } from 'shared/atoms';
import { getPlayerCountry } from 'shared/helpers/_common';
import { getPlayerPoints } from 'shared/helpers/_get_player_points';
import { useClassNames } from 'shared/hooks/_useClassNames.hook';

/**
 * Компонент: строка таблицы с участиником турнира
 * @param {ITournamentPlayer} player - данные игрока из таблицы
 * @param {string} profileUrl - базовый урл на профиль
 * @param {ITournamentPlayer} user - данные текущего юзера
 * @param {string} eventName - название текущего ивента
 */
export const TeamsTableRow: React.FC<ITeamsTableRow> = memo(
  function TeamsTableRow({ data, index, columns }) {
    // const hasLeft = (player: ITournamentPlayer): boolean => player.has_left;

    const { in_team } = data;
    const className = useClassNames({
      current_user: Boolean(in_team),
      // player_left: hasLeft(player),
    });

    const [name, score, tb, category, country] = columns;

    return (
      <$DesktopTableRow as={TR} className={className}>
        <$TableCell as={TD} cellSize={12}>
          {index}
        </$TableCell>
        <$TableCell as={TD} cellSize={name.cellSize}>
          {data.name}
        </$TableCell>
        <$TableCell as={TD} cellSize={score.cellSize}>
          {typeof data.points === 'number'
            ? getPlayerPoints(Number(data.points))
            : ''}
        </$TableCell>
        <$TableCell as={TD} cellSize={tb.cellSize}>
          {typeof data.tie_break === 'number'
            ? commaNumFormatter.format(Number(data.tie_break))
            : ''}
        </$TableCell>
        <$TableCell as={TD} cellSize={category.cellSize} numeric>
          {data.category}
        </$TableCell>
        <$TableCell
          as={TD}
          cellSize={country.cellSize}
          style={{ letterSpacing: `0.1em` }}
        >
          {getPlayerCountry(Number(data.country))}
        </$TableCell>
      </$DesktopTableRow>
    );
  }
);

// const $Avatar = styled(Avatar)`
//   margin-right: 8px;
//   min-width: 23px;
// `;
