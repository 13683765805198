import { useRouter } from 'next/router';
import { useCallback } from 'react';

const ROUTES_TO_IGNORE_REGEXP = /\?popup=(registration|confirm)/;
const ROUTE_PARTS_TO_CLEAN_REGEXP =
  /\?popup=(sign-in(-invite)?|tournament-payment\&id=\w*$)/;

/**
 * Хук возвращающий функцию, с помощью которой можно запросить данные с сервера о текущей страничке
 * Вдохновлено: https://www.joshwcomeau.com/nextjs/refreshing-server-side-props/
 *
 * 1) Игнорирует рефреш данных на попапах с заполнением данных регистрации и активационным кодом, так как они устанавливают
 * авторизационные данные и на этих этапах нам не нужно чтобы юзер был залогинен
 * 2) Убирает из пути всё что подходит под ROUTE_PARTS_TO_CLEAN_REGEXP, чтобы при авторизации через Sign In popup при перезапросе мы не открывали
 * этот попап снова
 */
export const useRefreshServerData = () => {
  const router = useRouter();

  const refreshData = useCallback(() => {
    const currentRoute = router.asPath;

    const shouldIgnoreRefresh = ROUTES_TO_IGNORE_REGEXP.test(currentRoute);

    if (shouldIgnoreRefresh) return;

    const cleanedRoute = currentRoute.replace(ROUTE_PARTS_TO_CLEAN_REGEXP, '');

    router.replace(cleanedRoute);
  }, [router]);

  return refreshData;
};
