import { outOfBoard } from "../stringBoard/_outOfBoard";
import { chessSide } from "../_constants";
import { getPawnPossibleAttacks } from "./_getPawnPossibleAttacks";
import { getPiecePossibleAttacks } from "./_getPiecePossibleAttacks";
/**
 * Проверяет, под атакой ли король
 * @param {string[]} board - доска
 * @returns {chessSide} kingColor - цвет проверяемого короля
 */
export const isKingUnderAttack = ({ board, kingColor, }) => {
    const kingSymbol = kingColor === chessSide.WHITE ? "K" : "k";
    const kingIndex = board.findIndex((fig) => fig === kingSymbol);
    const pawnSymbol = kingSymbol === "K" ? "p" : "P";
    const piecesSymbols = kingSymbol === "K" ? ["r", "n", "q", "b", "k"] : ["R", "N", "Q", "B", "K"];
    for (let i = 0; i < board.length; i++) {
        if (outOfBoard(i)) {
            i += 7;
            continue;
        }
        const fig = board[i];
        if (fig === "")
            continue;
        if (fig === pawnSymbol) {
            const attacks = getPawnPossibleAttacks({
                index: i,
                board,
            });
            if (attacks.includes(kingIndex)) {
                return true;
            }
        }
        if (piecesSymbols.includes(fig)) {
            const attacks = getPiecePossibleAttacks(i, board);
            if (attacks.includes(kingIndex))
                return true;
        }
    }
    return false;
};
