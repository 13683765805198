import {
  ITournamentRound,
  ITournamentTeamPlayTeamsData,
  ITournamentTeamPlayPlayersData,
} from 'shared/types';

import {
  IRoundBoardsParams,
  ITournamentYou,
  IRoundBoard,
  IRoundBoardData,
  ITournamentExpandedData,
} from '@types';
import { BaseService } from './_base.service';
import { createAuthorizationHeaders } from '@utils/_requests';
import { combineURLParamsByMap } from '@utils/_url_helpers';
import { httpInstance } from '.';

export class TournamentService extends BaseService {
  /**
   * Выполняет запрос на получение информации о турнире
   * @param {number | string} tournamentId - id турнира
   */
  getTournament(tournamentId: number | string, jwt?: string) {
    return this.ajax.get<ITournamentExpandedData>(
      `/online/tournaments/${tournamentId}/`,
      createAuthorizationHeaders(jwt)
    );
  }

  /**
   * Осуществляет запрос на получение пользователя в турнире по id.
   * @param {number | string} tournamentId - id турнира
   */
  getTournamentStandingYou(tournamentId: number | string, jwt?: string) {
    return this.ajax.get<ITournamentYou>(
      `online/tournaments/${tournamentId}/standing/me/`,
      createAuthorizationHeaders(jwt)
    );
  }

  /**
   * Выполняет запрос на присоединение к турниру
   * @param {number | string} tournamentId - id турнира
   */
  joinTournament(tournamentId: number | string) {
    return this.ajax.post<ITournamentExpandedData>(
      `online/tournaments/${tournamentId}/signup/`
    );
  }

  /**
   * Осуществляет запрос на выход из турнира во время регистрации
   * @param {number | string} tournamentId - id турнира
   */
  signoutTournament(tournamentId: number | string) {
    return this.ajax.post<ITournamentExpandedData>(
      `online/tournaments/${tournamentId}/signout/`
    );
  }

  /**
   * Осуществляет запрос на выход из турнира во время турнира
   * @param {number | string} tournamentId - id турнира
   */
  leaveTournament(tournamentId: number | string) {
    return this.ajax.post<ITournamentExpandedData>(
      `online/tournaments/${tournamentId}/leave/`
    );
  }

  /**
   * Осуществляет запрос на получение раундов текущего турнира
   * @param {number | string} tournamentId - id турнира
   */
  getTournamentRounds(tournamentId: number | string) {
    return this.ajax.get<ITournamentRound[]>('tours/', {
      query: {
        ordering: 'number',
        tournament: tournamentId,
      },
    });
  }

  /**
   * Осуществляет запрос на получение досок текущего раунда
   * @param {number | string} tournamentId - id турнира
   * @param {number | string} roundId - id раунда
   */
  getRoundBoards({
    tournamentId,
    roundId,
    limit,
    offset,
    status,
  }: IRoundBoardsParams) {
    const url = combineURLParamsByMap('online/tournament/gaming/', {
      exclude_odd_boards: 'true',
      tournament_id: tournamentId,
      tour_id: roundId,
      limit: limit || 0,
      offset: offset || 0,
      status: status || undefined,
    });

    return this.ajax.get<IRoundBoardData>(url);
  }

  /**
   * Осуществляет запрос на получение лучших досок текущего раунда
   * @param {number | string} roundId - id раунда
   */
  getRoundBestBoards(roundId: number) {
    return this.ajax.get<IRoundBoard[]>(
      `online/tournament/top-tour-boards/${roundId}/`
    );
  }

  /**
   * Осуществляет запрос на получение турнирного сертификата в формате pdf
   * @param {number} tournamentId - id турнира
   * @param {number} playerId - id пользователя
   */
  getTournamentCertificatePdf(tournamentId: number, playerId: number) {
    return this.ajax.get<string>(
      `online/tournament-certificate/${tournamentId}/${playerId}/`,
      {
        headers: {
          'Content-Type': 'application/pdf',
        },
        query: {
          new: 1,
        },
        // responseType: 'blob',
      }
    );
  }

  /**
   * Осуществляет запрос на получение турнирного сертификата в формате image
   * @param {number} tournamentId - id турнира
   * @param {number} playerId - id пользователя
   */
  getTournamentCertificateImage(tournamentId: number, playerId: number) {
    return this.ajax.get<{ link: string }>(
      `online/tournament-certificate/${tournamentId}/${playerId}/`,
      {
        query: {
          image: 1,
        },
      }
    );
  }

  /**
   * Выполняет запрос на получение pgn файла турнира
   * @param {number | string} tournamentId - id турнира
   */
  getTournamentPgnResult(tournamentId: number | string) {
    return this.ajax.get<{ link?: string; error?: string }>(
      `/online/tournament/gaming/${tournamentId}/pgn_result/`
    );
  }

  /**
   * Осуществляет запрос на остановку участия в турнире
   * @param {number | string} tournamentId - id турнира
   */
  pauseTournament(tournamentId: number | string) {
    return this.ajax.post<ITournamentExpandedData>(
      `online/tournaments/${tournamentId}/pause/`
    );
  }

  /**
   * Осуществляет запрос на возобновление участия в турнире (арена)
   * @param {number | string} tournamentId - id турнира
   */
  resumeTournament(tournamentId: number | string) {
    return this.ajax.post<ITournamentExpandedData>(
      `online/tournaments/${tournamentId}/resume/`
    );
  }

  /**
   * Осуществляет запрос на имейл подписку на событие
   */
  signUpForOpeningInvitation(model: {
    user_email: string;
    nickname: string;
    message_type: string;
  }) {
    return this.ajax.post(`online/tournaments/contact-mts/`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(model),
    });
  }

  /**
   * Выполняет запрос на получение информации о командах на командном турнире
   * @param {number | string} tournamentId - id турнира
   */
  getTournamentTeamPlayTeams({
    tournamentId,
    limit,
    offset,
    userPlayerId,
  }: {
    tournamentId: number | string;
    limit?: number;
    offset?: number;
    userPlayerId?: number;
  }) {
    const url = combineURLParamsByMap(
      `/online/tournaments/${tournamentId}/teams-standing/`,
      {
        player_id: userPlayerId,
        limit,
        offset,
      }
    );

    return this.ajax.get<ITournamentTeamPlayTeamsData>(url);
  }

  /**
   * Выполняет запрос на получение информации о командах на командном турнире
   * @param {number | string} tournamentId - id турнира
   */
  getTournamentTeamPlayPlayers({
    tournamentId,
    limit,
    offset,
  }: {
    tournamentId: number | string;
    limit?: number;
    offset?: number;
  }) {
    const url = combineURLParamsByMap(
      `/online/tournaments/${tournamentId}/standing/`,
      {
        limit,
        offset,
      }
    );

    return this.ajax.get<ITournamentTeamPlayPlayersData>(url);
  }
}

export const tournamentService = new TournamentService({
  instance: httpInstance,
});
