import { getCheckMates } from "../stringBoard/_getCheckmates";
import { getStringBoard } from "../stringBoard/_getStringBoard";
import { chessSide, SQUARES } from "../_constants";
import { getFiguresFromFen } from "./_getFiguresFromFen";
/**
 * Парсит данные о текущей позиции из fen
 * @param {string} fen
 *
 * Состав fen:
 * rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR - расположение фигур
 * w - сторона следующего хода
 * KQkq - возможные рокировки
 * d3 - en passant, ставится прочерк, если нет такого поля
 * 1 - число полуходов, прошедших с последнего хода пешки или взятия фигуры
 * 1 - номер хода. Увеличивается после хода черных
 *
 */
export function parseFen(fen) {
    const [$positions, $nextTurn, $castlings, $enPassant, $movesWithoutCapture, $moveNumber] = fen.split(" ");
    const { figures, capturedFigures } = getFiguresFromFen($positions);
    const enPassant = getEnPassantFromFenData($enPassant);
    return {
        figures,
        enPassant,
        capturedFigures,
        nextTurn: $nextTurn,
        checkmate: getCheckMates(getStringBoard(figures), enPassant),
        movesWithoutCapture: parseInt($movesWithoutCapture),
        possibleCastlings: getPossibleCastlingsFromFenData($castlings),
        halfMoveNumber: getHalfMoveNumberFromFenData($moveNumber, $nextTurn),
    };
}
const getHalfMoveNumberFromFenData = (moveNumber, nextTurn) => parseInt(moveNumber) * 2 - (nextTurn === chessSide.WHITE ? 2 : 1);
const getEnPassantFromFenData = (enPassant) => enPassant.length === 2 ? SQUARES[(enPassant[0] + enPassant[1])] : null;
/**
 * Проверяет часть фена, отвечающую за рокировки
 * @param {string} castlings - имеет вид KQkq | Kq  | - | q и т.д.
 * @returns {IPossibleCastlings} possibleCastlings
 */
const getPossibleCastlingsFromFenData = (castlings) => {
    return {
        [chessSide.WHITE]: {
            kingSide: castlings.includes("K"),
            queenSide: castlings.includes("Q"),
        },
        [chessSide.BLACK]: {
            kingSide: castlings.includes("k"),
            queenSide: castlings.includes("q"),
        },
    };
};
