"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.$LoaderSvg = exports.$LoaderInner = exports.$Loader = exports.$LoaderWrapper = void 0;
exports.getSize = getSize;
const styled_components_1 = __importStar(require("styled-components"));
const constants_1 = require("../../common/constants");
const animate = (0, styled_components_1.keyframes) `from { transform: rotate(0deg); } to { transform: rotate(360deg); }`;
function getTheme(theme) {
    return {
        test: theme.colors.text.white,
        sizes: { xs: '16px', s: '20px', m: '24px', l: '28px', xl: '32px' },
        display: Object.assign({}, constants_1.display),
    };
}
function getDisplay(props) {
    return getTheme(props.theme).display[props.$display];
}
function getSize(props) {
    return getTheme(props.theme).sizes[props.$size];
}
exports.$LoaderWrapper = styled_components_1.default.div `
  position: absolute;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
exports.$Loader = styled_components_1.default.div `
  position: relative;
  box-sizing: border-box;
  vertical-align: middle;
  display: ${(props) => getDisplay(props)};
  width: ${(props) => getSize(props)};
  height: ${(props) => getSize(props)};
`;
exports.$LoaderInner = styled_components_1.default.div `
  position: relative;
  box-sizing: border-box;
  display: block;
  height: 100%;
  width: 100%;
  transform-origin: center center;
  transform: rotate(30deg);

  ${({ $isStatic }) => !$isStatic &&
    (0, styled_components_1.css) `
      will-change: transform;
      animation: ${animate} 2s infinite linear;
    `}
`;
exports.$LoaderSvg = styled_components_1.default.svg `
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  fill: currentColor;
`;
