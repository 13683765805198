import { ISystemPopups } from 'shared/types';
import { TChallengeRequestError } from '../_lobby_context.types';

const challengeErrors = [
  'max_short_challenges_reached',
  'not_allowed_any_rated',
  'not_allowed_foa_rated',
  'player_has_too_many_active_single_game_boards',
];

const ChallengeErrors = new Set(challengeErrors);

export const handleChallengeErrors = (
  error?: TChallengeRequestError,
  alert?: ISystemPopups['alert']
) => {
  if (!error || !error.data) return;

  if (ChallengeErrors.has(error?.data?.code)) {
    alert?.(error?.data?.detail);
  }
};
