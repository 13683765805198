/**
 * Объект, повторяющий основные методы Console,
 * но методы не выполняются на проде
 */
export const devConsole = {
    _isProduction: process.env.NODE_ENV === 'production',
    log: function (...values) {
        if (this._isProduction)
            return;
        console.log(...values);
    },
    warn: function (...values) {
        if (this._isProduction)
            return;
        console.warn(...values);
    },
    error: function (message) {
        if (this._isProduction)
            return;
        console.error(message);
    },
    table: function (obj) {
        if (this._isProduction)
            return;
        console.table(obj);
    },
};
