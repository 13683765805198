import React from 'react';
/**
 * Обертка таблицы
 * @param {ReactNode} children - пробрасываемый компонент
 * @param {string} className - внешний класс
 * @param {string} width - ширина таблицы
 */
export const Table = ({ children, className = '', width = '100%', }) => {
    return (React.createElement("div", { style: { width: width }, className: className }, children));
};
