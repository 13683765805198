import { bindActions } from 'storeshed';
import { httpStatuses } from 'shared/constants';
import { IUserData } from 'shared/types';
import { IRequestError, IUpdateMeError, IUpdateMe } from '@types';
import { mainStore } from '@store/storeshed';
import { registrationActions } from './_user';
import { TMainStore } from '../_types';
import { userService } from '@services/_user.service';

export const createEditProfileActions = () => {
  /**
   * Устанавливает значение ошибки
   * @param editProfileStore
   * @param error
   */
  const setError = (
    { editProfileStore }: TMainStore,
    error: IUpdateMeError | undefined
  ) => {
    editProfileStore.dispatch('error', error);
  };

  /**
   * Обновленеие данных пользовтеля
   * @param editProfileStore
   * @param setUserUpdateData
   */
  const setUserUpdateData = (
    { editProfileStore }: TMainStore,
    data: IUserData
  ) => {
    editProfileStore.dispatch('edit_profile_data', data);
  };

  /**
   * Запрос на имзенение профиля
   * @param editProfileStore
   * @param editProfileRequest
   */
  const setEditProfileRequest = (
    { editProfileStore }: TMainStore,
    editProfileRequest: boolean
  ) => {
    editProfileStore.dispatch('edit_profile_request', editProfileRequest);
  };

  /**
   * Выполняет запрос обновления информации о пользователе
   * @param mainStore - Основной стор
   * @param userData - Данные которые нужно обновить
   * @param nextStep - Функция со следующим действием
   */
  const updateProfile = async (
    {}: TMainStore,
    userData: IUpdateMe,
    nextStep: (data: IUserData) => void
  ) => {
    editProfileActions.setEditProfileRequest(true);
    try {
      const { ok, data } = await userService.updateMe(userData);

      if (ok) {
        editProfileActions.setUserUpdateData(data);
        editProfileActions.setError(undefined);
        nextStep(data);
      }
    } catch (err) {
      const error = err as IRequestError<{ [key: string]: string[] }>; // ??? какой тип нужен ???
      if (error.status === httpStatuses.TOO_LARGE) {
        const err = {
          avatar: 'error',
        };
        editProfileActions.setError(err);
      } else {
        const messages: IUpdateMeError = {};
        const message = error.message;

        // Показываются валидационные сообщения в нотификейшенах как toast
        if (!message?.length) {
          Object.entries(error.data).forEach(
            ([key, value]: [string, string[]]) => {
              messages[key] = value[0];
            }
          );
        }
        editProfileActions.setError(messages);
      }
    }
    editProfileActions.setEditProfileRequest(false);
  };

  /**
   * Выполняет запрос для измения пароля пользователя
   * @param mainStore - Основной стор
   * @param {string} currentPassword - Текущий пароль
   * @param {string} newPassword - Новый пароль
   * @param {function} nextStep - Функция со следующим действием
   */
  const changePassword = async (
    {}: TMainStore,
    {
      currentPassword,
      newPassword,
      nextStep,
    }: {
      currentPassword: string;
      newPassword: string;
      nextStep: () => void;
    }
  ) => {
    editProfileActions.setEditProfileRequest(true);
    registrationActions.setPasswordError(null);
    try {
      const { ok } = await userService.passwordChange(
        currentPassword,
        newPassword
      );

      if (ok) {
        nextStep();
      }
    } catch (err) {
      const error = err as IRequestError<{
        error: string;
        current_password: string[];
      }>;
      if (error.data.error) {
        registrationActions.setPasswordError(error.data.error);
      } else if (error.data.current_password?.length) {
        registrationActions.setCurrentPasswordError(
          error.data.current_password[0]
        );
      } else {
        console.log(error);
      }
    }
    editProfileActions.setEditProfileRequest(false);
  };

  return {
    setError,
    setUserUpdateData,
    setEditProfileRequest,
    updateProfile,
    changePassword,
  };
};

export const editProfileActions = bindActions(
  mainStore,
  createEditProfileActions()
);
