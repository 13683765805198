"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ratingTypes = exports.timeControls = exports.overflows = exports.textTransform = exports.display = exports.fontStyles = exports.fontWeights = exports.textAligns = exports.fonts = exports.sizes = exports.media = exports.mediaSizes = exports.mediaBreakpoints = exports.eMediaBreakPoints = void 0;
var eMediaBreakPoints;
(function (eMediaBreakPoints) {
    eMediaBreakPoints["PHONE"] = "phone";
    eMediaBreakPoints["LARGE_PHONE"] = "largePhone";
    eMediaBreakPoints["TABLET"] = "tablet";
    eMediaBreakPoints["SMALL_DESKTOP"] = "smallDesktop";
    eMediaBreakPoints["DESKTOP"] = "desktop";
    eMediaBreakPoints["LARGE_DESKTOP"] = "largeDesktop";
    eMediaBreakPoints["WIDE_SCREEN"] = "wideScreen";
})(eMediaBreakPoints || (exports.eMediaBreakPoints = eMediaBreakPoints = {}));
exports.mediaBreakpoints = {
    phone: 320,
    largePhone: 480,
    tablet: 768,
    smallDesktop: 992,
    desktop: 1180,
    largeDesktop: 1360,
    wideScreen: 1920,
};
exports.mediaSizes = {
    s: 's', // 320 - 768      / PHONE
    m: 'm', // 768 - 1200     / TABLET
    l: 'l', // 1200 - ∞       / DESKTOP
};
exports.media = {
    largePhone: `min-width: ${exports.mediaBreakpoints.largePhone}px`,
    tablet: `min-width: ${exports.mediaBreakpoints.tablet}px`,
    smallDesktop: `min-width: ${exports.mediaBreakpoints.smallDesktop}px`,
    desktop: `min-width: ${exports.mediaBreakpoints.desktop}px`,
    largeDesktop: `min-width: ${exports.mediaBreakpoints.largeDesktop}px`,
    wideScreen: `min-width: ${exports.mediaBreakpoints.wideScreen}px`,
};
exports.sizes = { xs: 'xs', s: 's', m: 'm', l: 'l', xl: 'xl' };
exports.fonts = {
    primary: '"Gerbera", sans-serif',
    secondary: '"Worldchess", sans-serif',
    tertiary: '"Caveat Brush", cursive',
    rightGrotesk: '"Right Grotesk", sans-serif',
    rightGroteskTall: '"Right Grotesk Tall", sans-serif',
    rightGroteskTight: '"Right Grotesk Tight", sans-serif',
    rightGroteskWide: '"Right Grotesk Wide", sans-serif',
};
exports.textAligns = {
    left: 'left',
    center: 'center',
    right: 'right ',
};
exports.fontWeights = {
    black: 900,
    extraBold: 800,
    bold: 700,
    semiBold: 600,
    medium: 500,
    regular: 400,
    light: 300,
    extraLight: 200,
    thin: 100,
};
exports.fontStyles = {
    normal: 'normal',
    italic: 'italic',
};
exports.display = {
    block: 'block',
    inline: 'inline-block',
};
exports.textTransform = {
    none: 'none',
    capitalize: 'capitalize',
    uppercase: 'uppercase',
    lowercase: 'lowercase',
};
exports.overflows = {
    visible: 'visible',
    hidden: 'hidden',
    scroll: 'scroll',
    auto: 'auto',
    inherit: 'inherit',
};
exports.timeControls = {
    standard: 'standard',
    rapid: 'rapid',
    blitz: 'blitz',
    bullet: 'bullet',
    classic: 'classic',
    daily: 'daily',
    puzzle: 'puzzle',
};
exports.ratingTypes = {
    'non-rated': 'non-rated',
    otb: 'otb',
    fide: 'fide',
    worldchess: 'worldchess',
};
