export const cgDataTypes = {
    BOARD: "board",
    PIECE: "piece",
    PROMOTION: "promotion",
    TITLES: "titles",
    SQUARES: "squares",
    squares: {
        SELECTED: "selected-square",
        HOVER: "hover-square",
        LAST_MOVE: "last-move-square",
        PREMOVE: "premove-square",
        POSSIBLE_MOVE: "possible-move-square",
        CHECK: "check-square",
    },
};
