import { POSSIBLE_RESULTS } from "../_constants";
const getComments = (str) => {
    // const dataComments = str.match(/\{.*?\}/g);
    const timeMatch = str.match(/\[%clk.*?\]/);
    const time = timeMatch ? timeMatch[0].replace(/\[%clk|\]/g, "").trim() : null;
    // const linesMatch = str.match(/\(.*?\)/);
    // const evals = [];
    // const lines = linesMatch
    // ? linesMatch
    // .filter((line) => !line.includes("%eval"))
    // .map((line) => line.replace(/\(|\)/g, "").trim())
    // : null;
    return { time };
};
function $mask(str, options = { newline_char: "\r?\n" }) {
    return str.replace(/\\/g, "\\");
}
export function parseHeaders(pgn, options) {
    const newline_char = typeof options === "object" && typeof options.newline_char === "string"
        ? options.newline_char
        : "\r?\n";
    function $parse_pgn_header(header) {
        const header_obj = {};
        const headers = header.split(new RegExp($mask(newline_char)));
        let key = "";
        let value = "";
        for (let i = 0; i < headers.length; i++) {
            key = headers[i].replace(/^\[([A-Z][A-Za-z]*)\s.*\]$/, "$1");
            value = headers[i].replace(/^\[[A-Za-z]+\s"(.*)"\ *\]$/, "$1");
            if (key.trim().length > 0) {
                header_obj[key] = value;
            }
        }
        return header_obj;
    }
    const header_regex = new RegExp("(\\[((?:" + $mask(newline_char) + ")|.)*\\])(?:" + $mask(newline_char) + "){2}");
    const header_strings = header_regex.test(pgn) ? header_regex.exec(pgn) : "";
    if (Array.isArray(header_strings)) {
        return {
            headers: $parse_pgn_header(header_strings[1]),
            headerString: header_strings[1],
        };
    }
    return {
        headerString: "",
        headers: {},
    };
}
export function parsePgn(pgn, options = { newline_char: "\r?\n" }) {
    // function to_hex(str: string) {
    //   return Array.from(str)
    //     .map(function (c) {
    //       /* encodeURI doesn't transform most ASCII characters,
    //        * so we handle these ourselves */
    //       return c.charCodeAt(0) < 128
    //         ? c.charCodeAt(0).toString(16)
    //         : encodeURIComponent(c).replace(/\%/g, "").toLowerCase();
    //     })
    //     .join("");
    // }
    // function from_hex(str: string) {
    //   return str.length == 0 ? "" : decodeURIComponent("%" + str.match(/.{1,2}/g)?.join("%"));
    // }
    /** Comments encoding/decoding */
    // function encode_comment(str: string) {
    //   str = str.replace(new RegExp($mask(newline_char), "g"), " ");
    //   return `{${to_hex(str.slice(1, str.length - 1))}}`;
    // }
    // function decode_comment(str: string) {
    //   if (str.startsWith("{") && str.endsWith("}")) {
    //     return from_hex(str.slice(1, str.length - 1));
    //   }
    // }
    // const newline_char = options.newline_char;
    const { headers, headerString } = parseHeaders(pgn);
    let msc = pgn.replace(headerString, "");
    POSSIBLE_RESULTS.forEach((res) => (msc = msc.replace(res, "")));
    msc = msc.replace(/\n/g, "");
    const commentsReg = /\{.*?\}|\(.*?\)/g;
    const comments = msc.match(commentsReg);
    if (!comments) {
        return {
            headers,
            moves: msc
                .replace(/\d+(\.|\.{3})/g, "")
                .trim()
                .split(/\s+/)
                .map((move) => ({
                san: move,
                comments: null,
            })),
        };
    }
    comments === null || comments === void 0 ? void 0 : comments.forEach((c) => {
        msc = msc.replace(c, c.replace(/\d+(\.{3}|\.)/g, ""));
    });
    const $moves = msc
        .split(/\d+(\.{3}|\.)/)
        .filter((move) => move !== "." && move !== "..." && move !== "");
    return {
        headers,
        moves: $moves.map((move) => ({
            san: move.replace(commentsReg, "").replace(/\?|\!/g, "").trim(),
            comments: getComments(move),
        })),
    };
}
