import { WHITE_SYMBOLS, BLACK_SYMBOLS } from "../_constants";
/**
 * Возвращает возможные атаки пешки
 * @param {number} index - индекс пешки на доске
 * @param {string[]} board - доска
 * @param {number} enPassant - индекс поля взятия на проходе
 */
export const getPawnPossibleAttacks = ({ index, board, enPassant, }) => {
    const possibleAttacks = [];
    const PAWN_ATTACKS = {
        p: [17, 15],
        P: [-17, -15],
    };
    const shifts = PAWN_ATTACKS[board[index]];
    const opositeFigures = WHITE_SYMBOLS.includes(board[index]) ? BLACK_SYMBOLS : WHITE_SYMBOLS;
    shifts.forEach((shift) => {
        const newPos = index + shift;
        if (newPos === enPassant) {
            possibleAttacks.push(newPos);
        }
        if (opositeFigures.includes(board[newPos])) {
            possibleAttacks.push(newPos);
        }
    });
    return possibleAttacks;
};
