import { useCallback, useState } from 'react';
import { useDropdown } from './_useDropdown.hook';

export interface IAlertPopup {
  isAlertPopupShown: boolean;
  alertPopupRef: React.RefObject<HTMLDivElement>;
  openAlertPopup: () => void;
  onClose: () => void;

  alertText: string;
  showAlertPopup: (text: string, close?: () => void) => void;
}

/**
 * Хук для использования попапа предупреждения, схожем с window.alert
 * @param alertAction — действие после закрытия
 */
export const useAlertPopup = (alertAction?: () => void): IAlertPopup => {
  const [alertText, setAlertText] = useState('');

  const {
    isDropdownShown: isAlertPopupShown,
    dropdownRef: alertPopupRef,
    openDropdown: openAlertPopup,
    closeDropdown: closeAlertPopup,
    setCloseHandler,
  } = useDropdown();

  const showAlertPopup = useCallback(
    (text: string, close?: () => void) => {
      setAlertText(text);
      setCloseHandler(close || alertAction);
      openAlertPopup();
    },
    [alertAction]
  );

  const onClose = useCallback(() => {
    closeAlertPopup();
  }, [closeAlertPopup]);

  return {
    isAlertPopupShown,
    alertPopupRef,
    openAlertPopup,
    onClose,

    alertText,
    showAlertPopup,
  };
};
