import React from 'react';
import { TFen } from '../../_types';
import { usePieces } from '../hooks/usePieces.hook';

import { $Piece } from './_styles';
import { cgDataTypes } from 'chessgun/ui';

export const Pieces: React.FC<{ cellSize: number; fenPos: TFen }> = ({
  cellSize,
  fenPos,
}) => {
  const pieces = usePieces(fenPos);

  return (
    <>
      {pieces.map(({ className, ...otherProps }, index) => (
        <$Piece
          key={index}
          data-cg-type={cgDataTypes.PIECE}
          className={className}
          size={cellSize}
          {...otherProps}
        />
      ))}
    </>
  );
};
