import { IChallengeItem, IRequestPagination } from 'shared/types';
import {
  IAcceptShortChallengeRequestParams,
  IChallengesQueueRequestData,
  IInviteChallengeRequestParams,
  ILongChallengeRequestParams,
  IShortChallengeRequestParams,
} from '@types';
import { IChallengesList } from 'shared/types';

import { BaseService } from './_base.service';
import { httpInstance } from '.';
import { combineURLParamsByMap, IURLMap } from '@utils/_url_helpers';

export class ChallengesService extends BaseService {
  /**
   * Выполняет запрос на создание challenge на игру в общей очереди
   *  Если пользователь авторизован и заголовок авторизации присуствует в запросе, то challenge будет создан от его имени
   *  @param {string} desiredColor - цвет фигур игрока необязательный параметр
   *  @param {string} ratingType - тип рейтинга
   *  @param {number} ratingFrom - нижний рейтинг пользователя
   *  @param {number} ratingTo - верхний рейтинг пользователя
   *  @param {number} timeControlId - id таймконтрола обязательный параметр
   *  @param {string} playerUid - нужно передавать только в случае запроса от анонимного пользователя
   *  @param {string} jwt - token
   */
  createShortChallenge(
    {
      desiredColor,
      ratingType,
      ratingFrom,
      ratingTo,
      timeControlId,
    }: IShortChallengeRequestParams,
    playerUid?: string | null,
    jwt?: string | null
  ) {
    const body = {
      desired_color: desiredColor,
      rating_type: ratingType,
      rating_from: ratingFrom,
      rating_to: ratingTo,
      time_control_id: timeControlId,
      owner_anonymous_uid: jwt ? undefined : playerUid,
      platform: 'web',
    };
    return this.ajax.post<IChallengeItem>(`online/challenges/short/`, {
      headers: {
        Authorization: jwt ? `JWT ${jwt}` : '',
        'Content-Type': 'application/json',
      },
      body,
    });
  }

  /**
   * Выполняет запрос на создание challenge на игру в общей очереди
   *  Если пользователь авторизован и заголовок авторизации присуствует в запросе, то challenge будет создан от его имени
   *  @param {string} desiredColor - цвет фигур игрока необязательный параметр
   *  @param {string} ratingType - тип рейтинга
   *  @param {number} ratingFrom - нижний рейтинг пользователя
   *  @param {number} ratingTo - верхний рейтинг пользователя
   *  @param {number} timeControlId - id таймконтрола обязательный параметр
   *  @param {string} playerUid - нужно передавать только в случае запроса от анонимного пользователя
   *  @param {string} jwt - token
   */
  acceptShortChallenge(
    { inviteId }: IAcceptShortChallengeRequestParams,
    playerUid?: string | null,
    jwt?: string | null
  ) {
    const body = {
      opponent_anonymous_uid: jwt ? undefined : playerUid,
    };
    return this.ajax.post<IChallengeItem>(
      `online/challenges/short/${inviteId}/accept/`,
      {
        headers: {
          Authorization: jwt ? `JWT ${jwt}` : '',
          'Content-Type': 'application/json',
        },
        body,
      }
    );
  }

  /**
   * Выполняет запрос на удаление собственного challenge из общей очереди
   * @param {string} id  - uid challenge
   * @param {string} jwt - token
   * @param {string} playerUid - нужно передавать только в случае запроса от анонимного пользователя
   */
  cancelShortChallenge(
    id: number,
    playerUid?: string | null,
    jwt?: string | null
  ) {
    const body = {
      owner_anonymous_uid: jwt ? undefined : playerUid,
    };
    return this.ajax.delete(`online/challenges/short/${id}/`, {
      headers: {
        Authorization: jwt ? `JWT ${jwt}` : '',
        'Content-Type': 'application/json',
      },
      body,
    });
  }

  /**
   * Выполняет запрос на удаление всех short challenge из общей очереди
   * @param {string} jwt - token
   * @param {string} playerUid - нужно передавать только в случае запроса от анонимного пользователя
   */
  clearShortChallenges(playerUid?: string | null, jwt?: string | null) {
    const body = {
      owner_anonymous_uid: jwt ? undefined : playerUid,
    };
    return this.ajax.delete(`online/challenges/short/clear/`, {
      headers: {
        Authorization: jwt ? `JWT ${jwt}` : '',
        'Content-Type': 'application/json',
      },
      body,
    });
  }

  /**
   * Выполняет запрос на получение списка моих short challenges
   * @param {number} limit - количество запрашиваемых данных
   * @param {number} offset - пагинация
   * @param {string} jwt - token
   * @param {string} playerUid - нужно передавать только в случае запроса от анонимного пользователя
   */
  getMyShortChallenges(
    { limit, offset }: { limit?: number; offset?: number },
    playerUid?: string | null,
    jwt?: string | null
  ) {
    const urlMap: IURLMap = { limit, offset };
    if (!jwt && playerUid) {
      urlMap['owner_anonymous_uid'] = playerUid;
    }

    urlMap['platform'] = 'web';

    return this.ajax.get<IRequestPagination<IChallengeItem>>(
      combineURLParamsByMap(`online/challenges/short/my/`, urlMap),
      {
        headers: {
          Authorization: jwt ? `JWT ${jwt}` : '',
          'Content-Type': 'application/json',
        },
      }
    );
  }

  /**
   * Выполняет запрос на создание challenge invite.
   *  Используется для создания приглашения, рематча, игры с ботом
   *  Если пользователь авторизован и заголовок авторизации присуствует в запросе, то challenge будет создан от его имени
   *  @param {string} desiredColor - цвет фигур игрока необязательный параметр
   *  @param {number} timeControlId - id таймконтрола обязательный параметр
   *  @param {string} withOpponentUid - id оппонента
   *  @param {string} playerUid - нужно передавать только в случае запроса от анонимного пользователя
   *  @param {string} jwt - token
   */
  createInviteChallenge(
    {
      desiredColor,
      timeControlId,
      withOpponentUid,
      inviteType = 'challenge',
      ratingFrom,
      ratingTo,
      ratingType,
    }: IInviteChallengeRequestParams,
    playerUid?: string | null,
    jwt?: string | null
  ) {
    const body = {
      desired_color: desiredColor,
      time_control_id: timeControlId,
      rating_from: ratingFrom,
      rating_to: ratingTo,
      owner_anonymous_uid: jwt ? undefined : playerUid,
      with_opponent_uid: withOpponentUid,
      platform: 'web',
      rating_type: ratingType,
    };

    const isBotGame = inviteType === 'bot';

    const urlInviteType = isBotGame ? 'with-bot/' : '';

    return this.ajax.post<IChallengeItem>(
      `online/challenges/invite/${urlInviteType}`,
      {
        headers: {
          Authorization: jwt ? `JWT ${jwt}` : '',
          'Content-Type': 'application/json',
        },
        body,
      }
    );
  }

  /**
   * Получаем челленжи (заявки на игру) по HTTP
   */
  getChallenges() {
    return this.ajax.get<IChallengesQueueRequestData>(
      `online/challenges/short/?limit=50&offset=0`
    );
  }

  /**
   * Список приглашений, отправленных текущим пользователем для других пользователей
   */
  getOutcomingInviteChallenges(params: {
    owner_anonymous_uid?: string | null;
    opponent_uid?: string | null;
    time_control_id?: number | null;
    limit?: number;
    offset?: number;
  }) {
    return this.ajax.get<IRequestPagination<IChallengeItem>>(
      combineURLParamsByMap(
        `online/challenges/invite/list-outcoming/`,
        params as IURLMap
      )
    );
  }

  /**
   * Список приглашений текущего пользователя, полученных от других пользователей
   */
  getIncomingInviteChallenges(params: {
    owner_anonymous_uid?: string | null;
    opponent_uid?: string | null;
    time_control_id?: number | null;
    limit?: number;
    offset?: number;
  }) {
    return this.ajax.get<IRequestPagination<IChallengeItem>>(
      combineURLParamsByMap(
        `online/challenges/invite/list-incoming/`,
        params as IURLMap
      )
    );
  }

  /**
   * Выполняет запрос на удаление challenge invite приглашения.
   *  @param {number} id - цвет фигур игрока необязательный параметр
   *  @param {string} playerUid - нужно передавать только в случае запроса от анонимного пользователя
   *  @param {string} jwt - token
   */
  cancelInviteChallenge(
    id: number,
    playerUid?: string | null,
    jwt?: string | null
  ) {
    const body = {
      owner_anonymous_uid: jwt ? undefined : playerUid,
    };
    return this.ajax.delete(`online/challenges/invite/${id}/`, {
      headers: {
        Authorization: jwt ? `JWT ${jwt}` : '',
        'Content-Type': 'application/json',
      },
      body,
    });
  }

  /**
   * Выполняет запрос на принятие приглашения пользователем.
   *  @param {string} inviteCode - код инвайта
   *  @param {string} playerUid - нужно передавать только в случае запроса от анонимного пользователя
   *  @param {string} jwt - token
   */
  acceptInviteChallenge(
    inviteCode: string,
    playerUid?: string | null,
    jwt?: string | null
  ) {
    const body = {
      invite_code: inviteCode,
      opponent_anonymous_uid: jwt ? undefined : playerUid,
    };
    return this.ajax.post(`online/challenges/invite/accept/`, {
      headers: {
        Authorization: jwt ? `JWT ${jwt}` : '',
        'Content-Type': 'application/json',
      },
      body,
    });
  }

  /**
   * Выполняет запрос на отклонение приглашения пользователем.
   *  @param {string} inviteCode - код инвайта
   */
  declineInviteChallenge(inviteCode: string) {
    const body = {
      invite_code: inviteCode,
    };
    return this.ajax.post(`online/challenges/invite/decline/`, {
      body,
    });
  }

  /**
   * Выполняет запрос на создание long challenge
   *  Запросы для этого типа должны всегда быть с заголовком авторизации
   *  @param {number} opponentId - id оппонента
   *  @param {string} desiredColor - цвет фигур игрока необязательный параметр
   *  @param {string} ratingType - тип рейтинга
   *  @param {number} timeControlId - id таймконтрола обязательный параметр
   *  @param {string} jwt - token
   */
  createLongChallenge(
    {
      opponentId,
      desiredColor,
      ratingType,
      timeControlId,
    }: ILongChallengeRequestParams,
    jwt?: string | null
  ) {
    const body = {
      opponent_id: opponentId,
      desired_color: desiredColor,
      rating_type: ratingType,
      time_control_id: timeControlId,
      platform: 'web',
    };
    return this.ajax.post<IChallengeItem>(`online/challenges/long/`, {
      headers: {
        Authorization: jwt ? `JWT ${jwt}` : '',
        'Content-Type': 'application/json',
      },
      body,
    });
  }

  /**
   * Выполняет запрос на удаление challenge long
   * @param {string} id  - uid challenge
   * @param {string} jwt - token
   * */
  cancelLongChallenge(id: number, jwt?: string | null) {
    return this.ajax.delete(`online/challenges/long/${id}/`, {
      headers: {
        Authorization: jwt ? `JWT ${jwt}` : '',
        'Content-Type': 'application/json',
      },
    });
  }

  /**
   * Выполняет запрос на принятие приглашения пользователем.
   *  @param {string} id - id челленджа
   *  @param {string} jwt - token
   */
  acceptLongChallenge(id: number, jwt?: string | null) {
    return this.ajax.post(`online/challenges/long/${id}/accept/`, {
      headers: {
        Authorization: jwt ? `JWT ${jwt}` : '',
        'Content-Type': 'application/json',
      },
    });
  }

  /**
   * Выполняет запрос на отклонение приглашения пользователем.
   *  @param {string} id - id челленджа
   *  @param {string} jwt - token
   */
  declineLongChallenge(id: number, jwt?: string | null) {
    return this.ajax.post(`online/challenges/long/${id}/decline/`, {
      headers: {
        Authorization: jwt ? `JWT ${jwt}` : '',
        'Content-Type': 'application/json',
      },
    });
  }

  /**
   * Выполняет запрос на получение списка своих long challenges
   *  @param {string} jwt - token
   */
  getLongChallenges(jwt: string | null) {
    return this.ajax.get<IChallengesList>(`online/challenges/long/`, {
      headers: {
        Authorization: jwt ? `JWT ${jwt}` : '',
        'Content-Type': 'application/json',
      },
    });
  }
}

export const challengesService = new ChallengesService({
  instance: httpInstance,
});
