import { calculatePiecePossibleMoves, getPieceData } from "../calculations";
import { getFile } from "../stringBoard/_getFile";
import { getRank } from "../stringBoard/_getRank";
import { chessSide, figureType } from "../_constants";
import { isLegalMove } from "./_isLegalMove";
export const generateSan = ({ board, from, to, enPassant, promotion, check, checkmate, }) => {
    const piece = board[from];
    const pieceData = getPieceData({ piece, index: from });
    const toChessPosition = getFile(to) + getRank(to);
    let san = "";
    const samePieces = [];
    if (pieceData.type !== figureType.KING) {
        board.forEach((p, index) => {
            if (!p)
                return;
            const data = getPieceData({ piece: p, index });
            if (data.color === pieceData.color && data.type === pieceData.type) {
                samePieces.push(data);
            }
        });
    }
    switch (pieceData.type) {
        case figureType.PAWN: {
            const capture = enPassant ? (to === enPassant ? true : !!board[to]) : !!board[to];
            const canDoSameMovePawn = samePieces.find((data) => calculatePiecePossibleMoves({
                index: data.index,
                enPassant,
                board: [...board],
            }).includes(to) && data.index !== pieceData.index);
            if (canDoSameMovePawn !== null &&
                (canDoSameMovePawn === null || canDoSameMovePawn === void 0 ? void 0 : canDoSameMovePawn.position.rank) === pieceData.position.rank) {
                san += pieceData.position.file;
            }
            if (capture) {
                san += "x";
            }
            san += toChessPosition;
            if (promotion) {
                san += `=${promotion.toUpperCase()}`;
            }
            break;
        }
        case figureType.QUEEN:
        case figureType.ROOK:
        case figureType.KNIGHT:
        case figureType.BISHOP: {
            san += pieceData.type.toUpperCase();
            const capture = !!board[to];
            const canDoSameMovePieces = samePieces.filter((data) => {
                return (isLegalMove({
                    index: data.index,
                    targetIndex: to,
                    board: [...board],
                }) && data.index !== pieceData.index);
            });
            if (canDoSameMovePieces.length) {
                let file = "";
                let rank = "";
                canDoSameMovePieces.forEach((data) => {
                    if (data.position.rank === pieceData.position.rank) {
                        file = pieceData.position.file;
                    }
                    if (data.position.file === pieceData.position.file) {
                        rank = pieceData.position.rank;
                    }
                });
                san += file + rank;
                if (!file && !rank) {
                    san += pieceData.position.file;
                }
            }
            if (capture) {
                san += "x";
            }
            san += toChessPosition;
            break;
        }
        default: {
            const castlingIndexes = {
                from: pieceData.color === chessSide.WHITE ? 116 : 4,
                k: pieceData.color === chessSide.WHITE ? 118 : 6,
                q: pieceData.color === chessSide.WHITE ? 114 : 2,
            };
            if (from === castlingIndexes.from) {
                if (to === castlingIndexes.k)
                    return "O-O";
                if (to === castlingIndexes.q)
                    return "O-O-O";
            }
            const capture = !!board[to];
            san += `${pieceData.type.toUpperCase()}${capture ? "x" : ""}${toChessPosition}`;
        }
    }
    if (check || checkmate) {
        san += "+";
    }
    return san;
};
