import { outOfBoard } from "./_outOfBoard";
import { chessSide, SQUARES } from "../_constants";
/**
 * Создает массив с фигурами
 * @param figures
 * @returns
 */
export function getStringBoard(figures) {
    const board = [];
    for (let i = SQUARES.a8; i <= SQUARES.h1; i++) {
        if (outOfBoard(i)) {
            i += 7;
            continue;
        }
        const fig = figures.find((f) => f.position === i);
        if (fig) {
            board[i] = fig.color === chessSide.WHITE ? fig.type.toUpperCase() : fig.type;
        }
        else {
            board[i] = "";
        }
    }
    return board;
}
