import {
  IChallengeItem,
  ITimeControl,
  TournamentRoundStatus,
  TournamentStatus,
} from 'shared/types';

import {
  IBroadcastBoardStatePayload,
  IGameState,
  IUserNotificationNewPayload,
} from '../../_types';
import { IGameInQueueDataResponse } from '../_lobby_context.types';

export enum eLobbyServerPayloadType {
  RSVD_RSP_TAG_LIST = 'RSVD_RSP_TAG_LIST',
  RSVD_PING = 'RSVD_PING',
  RSVD_PONG = 'RSVD_PONG',
  RSVD_TEST_PAYLOAD_TYPE = 'RSVD_TEST_PAYLOAD_TYPE',
  GAME_STATE = 'GAME_STATE',
  GAME_REQUEST_STATE = 'GAME_REQUEST_STATE',
  GAME_ANALYSIS_READY = 'GAME_ANALYSIS_READY',
  NO_GAME_ANALYSIS = 'NO_GAME_ANALYSIS',
  GAME_REQUEST_QUEUE_UPDATED = 'GAME_REQUEST_QUEUE_UPDATED',
  USER_DISQUALIFIED = 'USER_DISQUALIFIED',
  TOURNAMENT_BOARDS_COUNT = 'TOURNAMENT_BOARDS_COUNT',
  GAMING_BOARD_FINISHED = 'GAMING_BOARD_FINISHED',
  GAMING_PGN_CREATED = 'GAMING_PGN_CREATED',
  TOURNAMENT_OVER = 'TOURNAMENT_OVER',

  TOURNAMENT_STATE_UPDATE = 'TOURNAMENT_STATE_UPDATE',
  TOURNAMENT_STANDING_UPDATED = 'TOURNAMENT_STANDING_UPDATED',
  TOURNAMENT_BOARDS_UPDATED = 'TOURNAMENT_BOARDS_UPDATED',

  TOURNAMENT_ROUND_CREATED = 'TOURNAMENT_ROUND_CREATED',
  TOURNAMENT_ROUND_STARTED = 'TOURNAMENT_ROUND_STARTED',
  TOURNAMENT_ROUND_ENDED = 'TOURNAMENT_ROUND_ENDED',

  TOURNAMENT_ROUND_SCHEDULED = 'TOURNAMENT_ROUND_SCHEDULED',

  BROADCAST_BOARD_STATE = 'BROADCAST_BOARD_STATE',

  USER_NOTIFICATION_NEW = 'USER_NOTIFICATION_NEW',

  CHALLENGE_CREATED = 'CHALLENGE_CREATED',
  CHALLENGE_ACCEPTED = 'CHALLENGE_ACCEPTED',
  CHALLENGE_CANCELED = 'CHALLENGE_CANCELED',
  CHALLENGE_DECLINED = 'CHALLENGE_DECLINED',
  CHALLENGE_TIMEOUT = 'CHALLENGE_TIMEOUT',

  // new api
  // todo: remove those that are not used
  CHALLENGE_SHORT_DELETED = 'CHALLENGE_SHORT_DELETED',
  CHALLENGE_SHORT_CREATED = 'CHALLENGE_SHORT_CREATED',
  CHALLENGE_SHORT_ACCEPTED = 'CHALLENGE_SHORT_ACCEPTED',
  CHALLENGE_SHORT_UPDATED = 'CHALLENGE_SHORT_UPDATED',

  CHALLENGE_INVITE_CREATED = 'CHALLENGE_INVITE_CREATED',
  CHALLENGE_INVITE_DELETED = 'CHALLENGE_INVITE_DELETED',
  CHALLENGE_INVITE_DECLINED = 'CHALLENGE_INVITE_DECLINED',
  CHALLENGE_SHORT_MATCHING_STOPPED = 'CHALLENGE_SHORT_MATCHING_STOPPED',

  CHALLENGE_LONG_CREATED = 'CHALLENGE_LONG_CREATED',
  CHALLENGE_LONG_DELETED = 'CHALLENGE_LONG_DELETED',
  CHALLENGE_LONG_ACCEPTED = 'CHALLENGE_LONG_ACCEPTED',
  CHALLENGE_LONG_DECLINED = 'CHALLENGE_LONG_DECLINED',
}

export enum eGameServerPayloadType {
  STATE = 'STATE',
  LATENCY_REQ = 'LATENCY_REQ',
  LATENCY_RSP = 'LATENCY_RSP',
  PING = 'PING',
  PONG = 'PONG',
  MOVE = 'MOVE',
  ABORT = 'ABORT',
  RESIGN = 'RESIGN',
  DRAW_OFFER = 'DRAW_OFFER',
  DRAW_REJECT = 'DRAW_REJECT',
  NON_CHESS = 'NON_CHESS',
}

export enum eGameRequestState {
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  CANCELED = 'CANCELED',
  REMATCH = 'REMATCH',
  NO_MATCH = 'NO_MATCH',
}

export interface IWSMessage<Type, Payload = undefined> {
  payloadType: Type;
  payload: Payload;
}

export interface IGameRequestState {
  challengerId: string;
  duelChallengeId: string;
  inviteCode: string;
  state: eGameRequestState;
  acceptorId?: string;
  gameId?: string;
  oppId?: string;
}

export interface IGameReactionRequestState {
  id: string;
}

export enum eGameServerNonChessPayloadType {
  REACTION = 'REACTION',
}

export interface IGameNonChessRequestState {
  payloadType: eGameServerNonChessPayloadType;
  payload: IGameReactionRequestState;
  sender?: { clr: boolean };
}

export interface IGameAnalysisMaches {
  tag_value: {
    game_analysis: string[];
  };
}

export type TTournamentRoundPayloadType =
  | eLobbyServerPayloadType.TOURNAMENT_ROUND_CREATED
  | eLobbyServerPayloadType.TOURNAMENT_ROUND_STARTED
  | eLobbyServerPayloadType.TOURNAMENT_ROUND_ENDED;

export interface ITournamentRoundPayload {
  start: string;
  finish: string | null;
  id: number;
  status: TournamentRoundStatus;
  time_control: ITimeControl;
  number: number;
  name: string | null;
  tournament_id: number;
}

export interface ITournamentStateUpdatedPayload {
  state: {
    available: boolean;
    current_tour_number: number;
    start: string;
    id: number;
    signed_up_amount: number;
    signup_opened: boolean;
    signup_start: string;
    signup_finish: string;
    status: TournamentStatus;
    unsigned_amount: number;
  };
}

export interface ITournamentBoardsUpdatedPayload {
  tour_id: number;
  tournament_id: number;
}

export interface ITournamentRoundScheduledPayload {
  last_tour_id: number;
  tournament_id: number;
  pause_end_datetime: string;
}

export type TChallengePayloadType =
  | eLobbyServerPayloadType.CHALLENGE_CREATED
  | eLobbyServerPayloadType.CHALLENGE_ACCEPTED
  | eLobbyServerPayloadType.CHALLENGE_CANCELED
  | eLobbyServerPayloadType.CHALLENGE_DECLINED
  | eLobbyServerPayloadType.CHALLENGE_TIMEOUT;

export interface IChallengePayload {
  challenge_id: number;
  incoming?: number;
  outcoming?: number;
}

export type TLobbyIncomingMessage =
  | IWSMessage<
      eLobbyServerPayloadType.RSVD_PING,
      { key?: string; getTs?: boolean }
    >
  | IWSMessage<eLobbyServerPayloadType.RSVD_PONG, { key?: string; ts?: number }>
  | IWSMessage<
      eLobbyServerPayloadType.RSVD_RSP_TAG_LIST,
      { tag_value: { tag: string[]; playerUid?: string[] } }
    >
  | IWSMessage<
      eLobbyServerPayloadType.RSVD_TEST_PAYLOAD_TYPE,
      { description: string }
    >
  | IWSMessage<eLobbyServerPayloadType.GAME_STATE, IGameState>
  | IWSMessage<eLobbyServerPayloadType.GAME_REQUEST_STATE, IGameRequestState>
  | IWSMessage<eLobbyServerPayloadType.NO_GAME_ANALYSIS, { boardUid: string }>
  | IWSMessage<
      eLobbyServerPayloadType.GAME_ANALYSIS_READY,
      { boardUid: string }
    >
  | IWSMessage<eLobbyServerPayloadType.GAME_REQUEST_QUEUE_UPDATED>
  | IWSMessage<
      eLobbyServerPayloadType.GAMING_PGN_CREATED,
      { board_id: string; pgn_download_name: string }
    >
  | IWSMessage<
      eLobbyServerPayloadType.TOURNAMENT_OVER,
      { tournament_id: number }
    >
  | IWSMessage<
      eLobbyServerPayloadType.GAMING_BOARD_FINISHED,
      { board_id: string }
    >
  | IWSMessage<eLobbyServerPayloadType.USER_DISQUALIFIED, { board_id: string }>
  | IWSMessage<
      eLobbyServerPayloadType.TOURNAMENT_BOARDS_COUNT,
      {
        tournament_id: number;
        tournament_slug: string;
        active_boards_count: number;
      }
    >
  | IWSMessage<TTournamentRoundPayloadType, ITournamentRoundPayload>
  | IWSMessage<
      eLobbyServerPayloadType.TOURNAMENT_STATE_UPDATE,
      ITournamentStateUpdatedPayload
    >
  | IWSMessage<
      eLobbyServerPayloadType.TOURNAMENT_STANDING_UPDATED,
      { tournament_id: number }
    >
  | IWSMessage<
      eLobbyServerPayloadType.TOURNAMENT_BOARDS_UPDATED,
      ITournamentBoardsUpdatedPayload
    >
  | IWSMessage<
      eLobbyServerPayloadType.TOURNAMENT_ROUND_SCHEDULED,
      ITournamentRoundScheduledPayload
    >
  | IWSMessage<
      eLobbyServerPayloadType.BROADCAST_BOARD_STATE,
      IBroadcastBoardStatePayload
    >
  | IWSMessage<
      eLobbyServerPayloadType.USER_NOTIFICATION_NEW,
      IUserNotificationNewPayload
    >
  | IWSMessage<TChallengePayloadType, IChallengePayload>
  | IWSMessage<eLobbyServerPayloadType.CHALLENGE_SHORT_DELETED, IChallengeItem>
  | IWSMessage<
      eLobbyServerPayloadType.CHALLENGE_SHORT_UPDATED,
      IGameInQueueDataResponse[]
    >
  // TODO: unknown to a type
  | IWSMessage<eLobbyServerPayloadType.CHALLENGE_SHORT_CREATED, unknown>
  | IWSMessage<eLobbyServerPayloadType.CHALLENGE_SHORT_ACCEPTED, unknown>
  | IWSMessage<eLobbyServerPayloadType.CHALLENGE_INVITE_CREATED, IChallengeItem>
  | IWSMessage<eLobbyServerPayloadType.CHALLENGE_INVITE_DELETED, IChallengeItem>
  | IWSMessage<
      eLobbyServerPayloadType.CHALLENGE_INVITE_DECLINED,
      IChallengeItem
    >
  | IWSMessage<eLobbyServerPayloadType.CHALLENGE_LONG_CREATED, IChallengeItem>
  | IWSMessage<eLobbyServerPayloadType.CHALLENGE_LONG_DELETED, IChallengeItem>
  | IWSMessage<eLobbyServerPayloadType.CHALLENGE_LONG_ACCEPTED, IChallengeItem>
  | IWSMessage<eLobbyServerPayloadType.CHALLENGE_LONG_DECLINED, IChallengeItem>
  | IWSMessage<
      eLobbyServerPayloadType.CHALLENGE_SHORT_MATCHING_STOPPED,
      IChallengeItem
    >;

export type TGameIncomingMessage =
  | IWSMessage<eGameServerPayloadType.STATE, IGameState>
  | IWSMessage<
      eGameServerPayloadType.LATENCY_REQ,
      { key: string; latency: number; ts: string }
    >
  | IWSMessage<
      eGameServerPayloadType.PING,
      { key: string; latency: number; serverTs: string }
    >
  | IWSMessage<eGameServerPayloadType.MOVE, IGameRequestState>
  | IWSMessage<eGameServerPayloadType.NON_CHESS, IGameNonChessRequestState>;

export type TGameOutcomingMessage = IWSMessage<
  eGameServerPayloadType.LATENCY_RSP,
  { key?: string }
>;

export type MessageType = TGameIncomingMessage | TLobbyIncomingMessage;

export enum eWSStatus {
  CONNECTED = 'connected',
  CONNECTING = 'connecting',
  DISCONNECTED = 'disconnected',
}

export type Dependency = {
  id?: string;
  pages: Set<string>; // Пустой Set — применять глобально
  callback: () => void;
};
