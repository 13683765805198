// TODO !!!!
// FIXME !!!!!!!!!!!!!!!!!!!!!!
/**
 * Это всё — хак, так как сторшед обновляется слишком быстро для САФАРИ из-за чего UI не поспевает за состоянием
 * В местах, где используется функция ниже надо переделать после того как у нас появится
 * возможность перенести запросы на сервер и получать авторизацию там
 */

export const delayedUpdate = (cb: () => void, delay = 100) =>
  setTimeout(cb, delay);
