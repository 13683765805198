import { UseFormState } from 'shared/hooks/_useForm.hook';
import {
  IStripeError,
  ICouponData,
  IProfileData,
  ICardInputs,
  IBuyData,
} from 'shared/types';
import { IRequestError } from '@types';
import { fideSubscriptionActions, paymentActions } from './index';
import { TMainStore } from '@store/storeshed';
import { paymentService } from '@services/_payment.service';
import { userService } from '@services/_user.service';

export const createPaymentActions = () => {
  /**
   * Задает состояние запроса на покупку титула.
   * @param {boolean} inRequest - выполняется ли запрос на покупку ранга.
   */
  const setBuyTitleRequest = (
    { paymentStore }: TMainStore,
    inRequest: boolean
  ) => {
    paymentStore.dispatch('buy_title_request', inRequest);
  };

  /**
   * Задает stripe-токен на покупку продукта/подписки.
   * @param {string} token - токен.
   */
  const setStripeToken = (
    { paymentStore }: TMainStore,
    token: string | null
  ) => {
    paymentStore.dispatch('stripe_token', token);
  };

  /**
   * Задает ошибку от запроса на оплату.
   * @param {object} error - текст ошибки.
   */
  const setPaymentError = (
    { paymentStore }: TMainStore,
    error: IStripeError | null
  ) => {
    paymentStore.dispatch('payment_error', error);
  };

  /**
   * Задает скидку от промокода (user.promocode).
   * @param {object} promocode - процент скидки.
   */
  const setPromocode = (
    { paymentStore }: TMainStore,
    promocode: ICouponData | null
  ) => {
    paymentStore.dispatch('promocode', promocode);
  };

  /**
   * Задает состояние запроса на проверку промокода.
   * @param {boolean} inRequest - выполняется ли запрос на проверку промокода.
   */
  const setPromocodeRequest = (
    { paymentStore }: TMainStore,
    inRequest: boolean
  ) => {
    paymentStore.dispatch('promocode_request', inRequest);
  };

  /**
   * Задает состояние запроса на проверку промокода.
   * @param {string}  error - текст ошибки.
   */
  const setPromocodeError = (
    { paymentStore }: TMainStore,
    error: string | null
  ) => {
    paymentStore.dispatch('promocode_error', error);
  };

  /**
   * Задает состояние запроса на данные пользователя для попапа.
   * @param {boolean} inRequest - выполняется ли запрос на автопродние подписки.
   */
  const setPopupProfileDataRequest = (
    { paymentStore }: TMainStore,
    inRequest: boolean
  ) => {
    paymentStore.dispatch('popup_profile_request', inRequest);
  };

  /**
   * Задает данные пользователя для попапа (кому дарится подписка).
   * @param {boolean} inRequest - выполняется ли запрос на автопродние подписки.
   */
  const setPopupProfileData = (
    { paymentStore }: TMainStore,
    data: IProfileData | null
  ) => {
    paymentStore.dispatch('popup_profile', data);
  };

  /**
   * Выполняет запрос на покупку продукта. Перед запросом задает user.rank_request значение true, после - false.
   * Сначала выполняется запрос на stripe-токен, и, в случае успешного получения выполняет запрос на покупку.
   * Если оплата прошла успешно, то выполняет коллбэк.
   * @param {string} stripe_key - ключ для оплаты.
   * @param {string} card - данные карты.
   * @param {string} sku - stripe-id товара.
   * @param {Function} successCallback  - функция коллбэк для успешной оплаты.
   * @param {string} coupon - промокод.
   */
  const buyProduct = async (
    { paymentStore }: TMainStore,
    {
      stripe_key,
      card,
      sku,
      successCallback,
      coupon,
      fideId,
      gameId,
    }: {
      stripe_key: string;
      card: UseFormState<ICardInputs>;
      sku: string;
      successCallback?: () => void;
      coupon?: string;
      fideId?: number;
      gameId?: string;
    }
  ) => {
    paymentActions.setBuyTitleRequest(true);

    await paymentActions.getStripeToken({ stripe_key, card });

    try {
      const token = paymentStore.get('stripe_token');

      if (token) {
        const { ok } = await paymentService.buyProduct({
          stripeToken: token,
          sku,
          coupon,
          fideId,
          gameId,
        });

        if (ok) {
          if (successCallback) {
            successCallback();
          }
        }
      }
    } catch (err) {
      console.log(err);
      const data = (err as IRequestError<{ error: string }>).data;

      paymentActions.setPaymentError({ message: data.error });
    }

    paymentActions.setStripeToken(null);
    paymentActions.setBuyTitleRequest(false);
  };

  /**
   * Выполняет запрос на проверку промокода.
   * Перед запросом задает user.promocode_request значение true, после - false.
   * Если промокод валиден, то применяет скидку.
   * @param {string} coupon - промокод.
   */
  const checkCoupon = async ({}: TMainStore, coupon: string) => {
    paymentActions.setPromocodeRequest(true);

    try {
      const { ok, data } = await paymentService.checkCoupon(coupon);

      if (ok) {
        if (data.valid) {
          paymentActions.setPromocode(data);
        } else {
          paymentActions.setPromocodeError('This promo code is expired');
        }
      }
    } catch (err) {
      paymentActions.setPromocodeError('Unknown promo code');
    }

    paymentActions.setPromocodeRequest(false);
  };

  /**
   * Выполняет запрос на получение stripe-токена.
   * В случае успешного получения задаёт токен.
   * @param {string} stripe_key - stripe api key.
   * @param {function} failCallback - callback функция
   * @param {string} card - данные карты.
   */
  const getStripeToken = async (
    { paymentStore }: TMainStore,
    {
      stripe_key,
      failCallback,
      card,
    }: {
      stripe_key: string;
      failCallback?: () => void;
      card: UseFormState<ICardInputs>;
    }
  ) => {
    const paymentError = paymentStore.get('payment_error');
    if (paymentError) {
      paymentActions.setPaymentError(null);
    }

    try {
      const data = new URLSearchParams();

      data.append('card[number]', card.card_number.value.replace(/\s+/g, ''));
      data.append('card[exp_month]', card.exp_month.value);
      data.append('card[exp_year]', card.exp_year.value);
      data.append('card[cvc]', card.securitycode.value);
      data.append('card[name]', card.name.value);

      const { ok, data: stripeData } = await paymentService.getStripeToken(
        data,
        stripe_key
      );

      if (ok) {
        paymentActions.setStripeToken(stripeData.id);
      }
    } catch (err) {
      const data = (err as IRequestError<{ error: IStripeError }>).data;

      paymentActions.setPaymentError(data.error);
      if (failCallback) {
        failCallback();
      }
    }
  };

  /**
   * Выполняет запрос на покупку подписки Перед запросом задает user.product_request значение true, после - false.
   * Сначала выполняется запрос на stripe-токен, и, в случае успешного получения выполняет запрос на покупку.
   * Если оплата прошла успешно, то выполняет callback.
   * @param {string} stripe_key - api ключ stripe.
   * @param {string} card - данные карты.
   * @param {string} plan - stripe-id товара.
   * @param {string} coupon - промокод.
   * @param {string | number} playerId - id юзера, которому покупается подписка
   * @param {Function} successCallback - callback функция.
   * @param {Function} invoiceCallback - callback функция для оплаты через инвойс (3d security).
   * @param {Function} failCallback - callback функция.
   */
  const buy = async (
    { paymentStore }: TMainStore,
    {
      stripe_key,
      card,
      plan,
      playerId,
      coupon,
      successCallback,
      invoiceCallback,
      failCallback,
    }: IBuyData
  ) => {
    fideSubscriptionActions.setProductRequest(true);

    await paymentActions.getStripeToken({ stripe_key, card, failCallback });

    try {
      const token = paymentStore.get('stripe_token');

      if (token) {
        const { status, data } = !playerId
          ? await paymentService.buyPlan(token, plan, coupon)
          : await paymentService.buyGiftPlan({
              stripeToken: token,
              gift_for: playerId,
              plan,
              coupon,
            });

        if (status === 202) {
          invoiceCallback(data.invoice_url);
        } else {
          successCallback();
        }
      }
    } catch (err) {
      console.log(err);
      const data = (err as IRequestError<{ error: string }>).data;
      paymentActions.setPaymentError({ message: data.error });
      failCallback();
    }

    paymentActions.setStripeToken(null);
    fideSubscriptionActions.setProductRequest(false);
  };

  const getPopupProfile = async ({}: TMainStore, userId: number | string) => {
    paymentActions.setPopupProfileDataRequest(true);

    try {
      const { ok, data } = await userService.getProfile(userId);

      if (ok) {
        paymentActions.setPopupProfileData(data);
      }
    } catch (err) {
      console.log(err);
    }

    paymentActions.setPopupProfileDataRequest(false);
  };

  return {
    setBuyTitleRequest,
    setStripeToken,
    setPaymentError,
    setPromocode,
    setPromocodeRequest,
    setPromocodeError,
    setPopupProfileDataRequest,
    setPopupProfileData,

    buyProduct,
    checkCoupon,
    buy,
    getStripeToken,
    getPopupProfile,
  };
};
