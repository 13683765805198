import { BoardAppearance } from '@types';
import { ITabElement, TwoBoardsIcon, MultiboardIcon } from 'shared/atoms';

export const tournamentBoardAppearances: ITabElement[] = [
  {
    id: BoardAppearance.TWO_BOARDS,
    text: 'two_boards',
    icon: TwoBoardsIcon,
    iconWidth: '15px',
    iconHeight: '15px',
  },
  {
    id: BoardAppearance.MULTIBOARD,
    text: 'multiboard',
    icon: MultiboardIcon,
    iconWidth: '15px',
    iconHeight: '15px',
  },
];

export const MULTIBOARD_BOARDS_LIMIT = 32;
