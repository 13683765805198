import { textColors, transparentColor } from 'shared/styles';
import styled from 'styled-components';

export const $Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 20px 15px;

  background-color: ${textColors.white};
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
`;

export const $Blocklayer = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const $PopupWrapper = styled($Card)`
  z-index: 10000;
  position: fixed;
  top: 50%;
  left: 50%;

  transform: translateX(-50%) translateY(-50%);

  width: 208px;
  padding: 0;
  color: ${textColors.black};
`;

export const $PopupContent = styled.div`
  padding-top: 24px;
  padding-right: 14px;
  padding-bottom: 32px;
  padding-left: 14px;

  text-align: center;
  border-bottom: 1px solid ${transparentColor(textColors.black, 0.1)};
`;

export const $PopupButtons = styled.div`
  display: flex;
`;

export const $PopupButton = styled.a`
  flex-grow: 1;

  display: flex;
  justify-content: center;
  width: 100%;
  padding: 8px 20px 11px 19px;

  font-size: 15px;
  color: ${textColors.black};
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  &:active {
    opacity: 0.4;
  }

  &:not(:last-of-type) {
    border-right: 1px solid ${transparentColor(textColors.black, 0.1)};
  }
`;
