import { flipTurn } from "./common";
import { getLanFromSan } from "./fen/_getLanFromSan";
import { isLan } from "./fen/_isLan";
import { isSan } from "./fen/_isSan";
import { getMoveInfo } from "./move";
import { getHistoryTree, getIsSilentMove, getMoveCurrentFen } from "./history";
import { setPiecePositions } from "./_setPiecePositions";
export class MoveController {
    constructor(store) {
        this.store = store;
    }
    /**
     *
     * @param notations  - sans or lans
     */
    moveMany(notations) {
        let i = 0;
        const move = () => {
            if (i < notations.length) {
                this.move(notations[i]);
                i++;
                move();
            }
            else {
                return;
            }
        };
        move();
    }
    /**
     *
     * @param notation - san or lan
     * @param mode
     * @param isUserMove
     */
    move(notation, mode, isUserMove) {
        if (!isLan(notation) && !isSan(notation))
            throw new Error(`The move "${notation}" is neither san or lan`);
        const { commenting, turn, currentFen, history, selectedMoveAddress, lastMove, mainLineLastMove, } = this.store.get();
        const cCurrentFen = getMoveCurrentFen({ currentFen, isUserMove, mainLineLastMove, commenting });
        const lan = isLan(notation) ? notation : getLanFromSan(cCurrentFen, notation);
        const { nextTurn, moveData, san, fen, capturedFigures, moveNumber, movesWithoutCapture, enPassant, possibleCastlings, checkmateData, } = getMoveInfo(cCurrentFen, lan);
        const isSilentMove = getIsSilentMove(lastMove, mainLineLastMove, commenting, isUserMove);
        if (isSilentMove) {
            this.updatePiecesPositionsSilent({
                fen,
                moveData,
                checkmateData,
                prevFen: cCurrentFen,
            });
        }
        else {
            this.updatePiecesPositions({
                fen,
                moveData,
                checkmateData,
            });
        }
        const $historyItem = {
            san,
            lan,
            fen,
            moveData,
            enPassant,
            checkmateData,
            capturedFigures,
            possibleCastlings,
            movesWithoutCapture,
            turn: nextTurn,
            prevFen: cCurrentFen,
            variations: [],
        };
        const { result, newMoveAddress } = getHistoryTree({
            move: $historyItem,
            history,
            selectedMoveAddress,
            mode,
            commenting,
            isUserMove,
        });
        if (isSilentMove) {
            this.store.dispatch({ history: result });
            return;
        }
        const $newState = {
            enPassant,
            capturedFigures,
            possibleCastlings,
            movesWithoutCapture,
            currentFen: fen,
            turn: flipTurn(turn),
            currentMove: moveNumber,
            checkmateData,
            history: result,
            selectedMoveAddress: newMoveAddress,
        };
        this.store.dispatch($newState);
    }
    updatePiecesPositions({ fen, moveData, checkmateData, }) {
        const pieces = this.store.get("pieces").filter((piece) => !piece.dead);
        setPiecePositions({ pieces, fen, moveData });
        this.store.get("pieces").forEach((piece) => {
            if (checkmateData.checkmate && piece.position === checkmateData.kingIndex) {
                piece.fallen = true;
            }
            piece.updateHistory(fen);
        });
    }
    updatePiecesPositionsSilent({ fen, prevFen, moveData, checkmateData, }) {
        const pieces = this.store.get("pieces");
        const $Pieces = pieces.map((item) => {
            const { color } = item;
            const history = item.history.find((el) => el.fen === prevFen);
            if (history)
                return Object.assign(Object.assign({ color }, history), { history: item.history });
            return null;
        });
        const result = setPiecePositions({ fen, moveData, pieces: $Pieces });
        result.forEach((piece) => {
            if (checkmateData.checkmate && piece.position === checkmateData.kingIndex) {
                piece.fallen = true;
            }
        });
        this.store.get("pieces").forEach((piece, i) => {
            const item = result[i];
            const { type, position, dead, fallen } = item;
            piece.uploadHistory([{ fen, position, type, dead, fallen }]);
        });
    }
    undo() {
        const currentFen = this.store.get("currentFen");
        const history = this.store.get("history");
        const prevState = history[history.length - 2];
        if (!prevState)
            return;
        const { turn, fen, possibleCastlings, capturedFigures, checkmateData, enPassant } = prevState;
        history.pop();
        this.store.dispatch({
            turn,
            history,
            enPassant,
            checkmateData,
            capturedFigures,
            possibleCastlings,
            currentFen: fen,
            selectedMoveAddress: [history.length - 1],
        });
        this.store.get("pieces").forEach((piece) => {
            piece.setCurrentFen(prevState.fen);
            piece.deleteHistoryItem(currentFen);
        });
    }
}
