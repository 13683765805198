import { getFile } from "../stringBoard/_getFile";
import { getRank } from "../stringBoard/_getRank";
import { getStringBoard } from "../stringBoard/_getStringBoard";
import { outOfBoard } from "../stringBoard/_outOfBoard";
import { BLACK_SYMBOLS, CASTLING_SANS, chessSide, KING_CASTLING_SANS, SQUARES, WHITE_SYMBOLS, } from "../_constants";
import { isLegalMove } from "./_isLegalMove";
import { parseFen } from "./_parseFen";
import { parseSan } from "./_parseSan";
export function getLanFromSan(fen, san) {
    const { figures, nextTurn, enPassant } = parseFen(fen);
    if (CASTLING_SANS.includes(san)) {
        const rank = nextTurn === chessSide.WHITE ? "1" : "8";
        return KING_CASTLING_SANS.includes(san) ? `e${rank}g${rank}` : `e${rank}c${rank}`;
    }
    const $pieces = nextTurn === chessSide.WHITE ? WHITE_SYMBOLS : BLACK_SYMBOLS;
    const $board = getStringBoard(figures);
    const { promotionFigure, to, from, piece } = parseSan(san);
    if (from.file && from.rank)
        return `${from.file}${from.rank}${to.rank}${to.file}`;
    for (let i = SQUARES.a8; i <= SQUARES.h1; i++) {
        if (outOfBoard(i)) {
            i += 7;
            continue;
        }
        const $item = $board[i];
        if (!$pieces.includes($item) || piece !== $item.toLowerCase())
            continue;
        const $isLigal = isLegalMove({
            index: i,
            board: $board,
            enPassant,
            targetIndex: SQUARES[(to.file + to.rank)],
        });
        if (!$isLigal)
            continue;
        if ((from.file && from.file !== getFile(i)) || (from.rank && from.rank !== getRank(i)))
            continue;
        return `${getFile(i)}${getRank(i)}${to.file}${to.rank}${promotionFigure ? promotionFigure.toUpperCase() : ""}`;
    }
    throw new Error(`Can't parse san move: ${san}`);
}
