import React, { FC, memo, useMemo } from 'react';
import { $EmptyTablePlaceholder, $List } from '../_styles';
import {
  ITableColumn,
  ITableData,
  ITeamsWithPlayersPlayersData,
  ITeamsWithPlayersTeamsData,
  TTeamsWithPlayersGroups,
} from '../_types';
import { TeamsItem } from './_TeamsItem';
import { PlayersItem } from './_PlayersItem';

interface IMobileTable {
  columns: ITableColumn[];
  data: ITableData[];
  currentTab: TTeamsWithPlayersGroups;
  userPlayerId?: number;
  profileUrl: string;
}

export const MobileTable: FC<IMobileTable> = memo(function MobileTable({
  currentTab,
  data,
  profileUrl,
  userPlayerId,
}) {
  const tableRows = useMemo(() => {
    if (data?.length === 0) {
      return <$EmptyTablePlaceholder>No standings</$EmptyTablePlaceholder>;
    }

    switch (currentTab) {
      case 'teams':
        return (
          <>
            {(data as ITeamsWithPlayersTeamsData[]).map((team, idx) => (
              <TeamsItem key={team.id} data={team} index={idx + 1} />
            ))}
          </>
        );
      case 'players':
        return (
          <>
            {(data as ITeamsWithPlayersPlayersData[]).map((player, idx) => (
              <PlayersItem
                key={player.player_id}
                data={player}
                profileUrl={profileUrl}
                userPlayerId={userPlayerId}
                index={idx + 1}
              />
            ))}
          </>
        );
    }
  }, [currentTab, data, profileUrl, userPlayerId]);

  return <$List>{tableRows}</$List>;
});
