/* eslint-disable prefer-rest-params */
/* eslint-disable no-var */
import { useUserDataStore } from '@store/storeshed';
import { isProPlan } from '@utils/_helpers';
import { useRouter } from 'next/router';
import { FC, PropsWithChildren, useEffect } from 'react';
import { IUserData } from 'shared/types';

export const APP_ID =
  process.env.NEXT_PUBLIC_ENV === 'master' ? 'u3zerh7o' : 'jp1qpa4f';

// Loads Intercom with the snippet
// This must be run before boot, it initializes window.Intercom
// prettier-ignore
export const loadIntercom = () => {
  // console.log('%cintercom log loadIntercom', 'color: lightgreen; background-color: black');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

const getUserIntercomOptions = (userData: IUserData) => {
  return {
    email: userData.email,
    sub_status: isProPlan(userData.subscriptions) ? 'active' : '',
    FIDE_ID: userData.fide_id,
    title: userData.player?.fide_title || userData.player?.foa_title,
  };
};

const getIntercomOptions = (userData: IUserData | null) => {
  let intercomOptions: Record<string, string | number | null> = {
    app_id: APP_ID,
    api_base: 'https://api-iam.intercom.io',
  };

  if (userData) {
    intercomOptions = {
      ...intercomOptions,
      ...getUserIntercomOptions(userData),
    };
  }

  return intercomOptions;
};

// Initializes Intercom
export const bootIntercom = (userData: IUserData | null) => {
  if (window && window.Intercom) {
    const intercomOptions = getIntercomOptions(userData);

    // console.log(
    //   '%cintercom log bootIntercom boot',
    //   'color: lightgreen; background-color: black'
    // );
    window.Intercom('boot', intercomOptions);
  }
};

export const updateIntercom = () => {
  window && window.Intercom && window.Intercom('update');
};

export const IntercomProvider: FC<PropsWithChildren> = ({ children }) => {
  const userData = useUserDataStore('data');

  const router = useRouter();

  useEffect(() => {
    loadIntercom();
    bootIntercom(userData);
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      if (typeof window !== 'undefined') {
        // console.log(
        //   '%cintercom log handleRouteChange updateIntercom',
        //   'color: lightgreen; background-color: black'
        // );

        updateIntercom();
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (!userData) return;

    if ('Intercom' in window) {
      // console.log(
      //   '%cintercom log update userInfo',
      //   'color: lightgreen; background-color: black',
      //   { userData }
      // );

      window.Intercom('update', getUserIntercomOptions(userData));
    }
  }, [userData]);

  return <>{children}</>;
};
