import React from 'react';
import { chessFigureStyle } from './_types';
/**
 * Иконка: Фигура коня
 * @param {string} fill - цвет фигуры
 * @param {number} width - ширина фигуры
 * @param {number} height - высота фигуры
 * @param {string} color - тема фигуры
 * @param {chessFigureStyle} figureStyle - тема фигуры
 * @param {string} className - внешний класс
 */
export const KnightIcon = ({ className = '', width = '100%', height = '100%', theme = 'white', fill, figureStyle = chessFigureStyle.FILLED, }) => {
    const knight = {
        [chessFigureStyle.FILLED]: (React.createElement("svg", { className: className, width: width, height: height, fill: "none", viewBox: "0 0 60 60", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M18.5 17.4c-.2-.6-.5-1.9-.5-4L18 10l.1-.3s2.2 0 3.8 1.4c1.5 1.1 2.3 2.3 2.6 2.8h.2c.4-3 2.8-4.9 2.8-4.9h.5s1.9 2 2.5 4.6C41.2 15 50.2 26.8 50.2 42c0 5.4-.4 8.9-.4 8.9H21.6s-.2-2.1.4-4c2-6 7.8-9 7.8-14.5 0-.5-.3-.6-.5-.6-.2 1.4-5 2.5-7.2 4.4-2.1 1.9-2.8 5.4-4.8 5.4-.8 0-1.6-.6-1.6-1.3l-1.5.1c-2.4 0-4.2-2.6-4.2-6 0-2 1.8-4.9 4.3-9 .8-1.4.6-2.8 1.4-5l2.8-3.1zM13 35.6c0 .7.4 1 .7 1 .5 0 1-.5 1-1s.8-.7.8-1.2c0-.4 0-1.3-1-1.3s-1.5 1.6-1.5 2.5zm3 2.2c0 .6.3.8 1 .8.6 0 1-.8 1-1.3s-.4-.8-.7-.8-1.2.7-1.2 1.3zm3-13.1c.3 0 .8-.4.8-.6.1-.6.6 0 1.6-.6 1.1-.5.6-1.4.6-1.9 0-.7.9-.5.9-1 0-.2 0-.7-.7-.7a4 4 0 00-4 4c.2.4.5.8.7.8zm3.4-6s1-.7 2.7-1.3l-.3-1.2c-2 .7-3.1 1.7-3.1 1.6l.7 1zm8.7 7.1c-.1 1.6-.7 3-.7 3l1.4.3s1-1 1-2.7c0-1.7-.8-2.7-.8-2.7h-1s.2 1 0 2.1zM47 48.9c.4-1.6.6-3.7.6-6.5 0-15.6-9-25.7-16.6-27 0 .8-.2 1.3-.3 1.5 8.6 1.2 14.4 14.5 14.4 24.7 0 2.7-.2 5.4-.6 7.3H47z", fill: fill ? fill : theme }))),
        [chessFigureStyle.STROKE]: (React.createElement("svg", { className: className, width: width, height: height, fill: "none", viewBox: "0 0 13 13", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M10.93 11.05H4.3c-.03-.41-.03-.91.32-1.56.43-.82 1.41-1.26 1.47-2.43-.35.23-.72.38-1.07.58-.6.36-.6 1.27-1.38 1.36-.3.02-.47-.1-.55-.24h-.15c-.6 0-.98-.47-.98-1.44 0-.4.4-.97.95-1.85.18-.26.09-.53.3-1 .08-.23.25-.44.48-.7-.03-.15-.08-.35-.08-.65 0-.32 0-.67.14-1 0 0 .58-.02 1.04.36.15.12.26.23.35.32.23-.47.63-.8.8-.85.18.12.61.5.79 1.03 2.34.44 4.33 3 4.33 6.28 0 1-.06 1.58-.12 1.79Zm-5.98-.47h5.38c.06-.26.14-.76.14-1.47 0-3.1-1.9-5.52-4.04-5.84l-.15-.03-.03-.15a1.4 1.4 0 0 0-.43-.67 1.2 1.2 0 0 0-.43.85v.15c-.26.09-.5.23-.67.38l-.14-.18c.09-.14.26-.32.32-.38-.03-.12-.26-.35-.4-.44-.24-.15-.44-.2-.59-.2v.44c0 .38.1.64.15.88-.29.2-.46.58-.52.76-.17.5-.15.76-.32 1.06-.46.76-.84 1.26-.84 1.61 0 .56.09.97.52.97.12 0 .3 0 .44-.06 0 .15.14.27.26.27.37 0 .43-.8.86-1.06.93-.65 2.2-.7 2.2-2.23h.29c0 .76-.2 1.2-.46 1.52.08 1.44-.81 1.91-1.33 2.94-.2.35-.2.73-.2.88ZM3.4 7.47c0 .12-.17.12-.17.23 0 .12-.11.24-.23.24-.09 0-.17-.09-.17-.24 0-.14.14-.52.34-.52s.23.17.23.29Zm1.33-3.02c.15 0 .15.11.15.17 0 .12-.2.03-.2.2 0 .12.11.3-.15.42s-.35 0-.37.12c0 .02-.12.11-.2.11-.1 0-.15-.09-.15-.17.06-.47.49-.85.92-.85Z", fill: fill ? fill : theme }))),
    };
    return knight[figureStyle];
};
