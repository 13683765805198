import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';
import { getMyColor } from '@utils/_getMyColor';
import { useMemo } from 'react';
import { ActiveGame, IActiveGamesContextState } from './_types';
import { getChessSideFromBoolean, getFilteredGames } from './_utils';
import { ePhase } from '../_common.types';

const filterMyActiveGames = (game: ActiveGame, uid: string | null) => {
  return (
    (game?.gameData?.state?.cfg?.bPId === uid ||
      game?.gameData?.state?.cfg?.wPId === uid) &&
    game.gameData.state?.currPh !== ePhase.DONE
  );
};

const filterEmptyBoardData = (game: Pick<ActiveGame, 'boardData'>) =>
  Boolean(game.boardData);

export const useComputed = ({
  filterGameType,
  filterRatingType,
  filterTimeControl,
}: IActiveGamesContextState) => {
  const {
    state: { games, gamesData, uid },
  } = useLobbyContext();

  const activeGames = useMemo(() => {
    const newGames = Object.entries(games).map(([boardId, gameData]) => ({
      boardId,
      gameData,
      boardData: gamesData[boardId]?.boardData,
      engine: gameData.chessgun,
    }));

    const filteredGames = getFilteredGames(newGames, {
      filterGameType,
      filterRatingType,
      filterTimeControl,
    })
      .filter(filterEmptyBoardData)
      .filter((game) => filterMyActiveGames(game, uid));

    const sorted = filteredGames.sort((gameA, gameB) => {
      const gameAMyColor = getMyColor(uid, gameA.gameData.state);
      const gameBMyColor = getMyColor(uid, gameB.gameData.state);

      const gameALastMoveColor = getChessSideFromBoolean(
        Boolean(gameA.gameData.state?.playingPh?.currTurn?.clr)
      );
      const gameBLastMoveColor = getChessSideFromBoolean(
        Boolean(gameB.gameData.state?.playingPh?.currTurn?.clr)
      );

      if (gameAMyColor === gameALastMoveColor) {
        return -1;
      }

      if (gameBMyColor === gameBLastMoveColor) {
        return 1;
      }

      return 0;
    });

    return sorted;
  }, [
    filterGameType,
    filterRatingType,
    filterTimeControl,
    games,
    gamesData,
    uid,
  ]);

  const totalGamesCount = Object.keys(games)
    .map((boardId) => ({
      boardData: gamesData[boardId]?.boardData,
    }))
    .filter(filterEmptyBoardData).length;

  const computed = useMemo(
    () => ({ activeGames, playerUid: uid, totalGamesCount }),
    [activeGames, totalGamesCount, uid]
  );

  return computed;
};
