import { generateClassNames } from './_generate_classNames';
/**
 * Альтернатива хуку useClassNames
 * Причина появления: ошибки рода rendered fewer hooks than expected
 *
 * Функция для конкатенации классов CSS. Принимает на вход:
 * @param {object} classes  - ключ: название класса, значение: истинность (активный ли класс).
 * @returns {string} result - строка активных классов
 */
export const classNames = (classes) => {
    return generateClassNames(classes);
};
