import { ITurn } from '@store/context/_types';
import { IChessgunHistoryItem } from 'chessgun/core';

export const getIsMissingLastMove = (
  history: (IChessgunHistoryItem | null)[],
  turns: ITurn[]
) => {
  if (!history?.length || !turns?.length) return false;
  if (history.length > turns?.length)
    return turns.every((item, i) => item.end.uci === history[i]?.lan);
  return false;
};
