import { SocketStatus } from 'shared/types';
import { dateIsAfterNow } from 'shared/helpers/_game';

import { getRandomNumber } from '@utils/_getRandomNumber';
import { eLobbyServerPayloadType } from './_types';
import { WSController } from './_base.controller';
import { syncedDate } from 'shared/helpers/_common';

export class LobbyWSController extends WSController {
  uid: string | null = null;

  PONG_MESSAGE = eLobbyServerPayloadType.RSVD_PONG;

  private timeoutTime = 900000; // 15 min
  private lastGetTsTime: number | null = null;

  get shouldReconnect(): boolean {
    return true;
  }

  get url(): string {
    return `${process.env.NEXT_PUBLIC_NG_LOBBY_SOCKET_URL}/?playerUid=${this.uid}`;
  }

  ping(): void {
    let payload = undefined;
    // если прошло больше 15 мин с последнего обновления времени, обновляем снова
    if (
      !this.lastGetTsTime ||
      !dateIsAfterNow(this.lastGetTsTime + this.timeoutTime)
    ) {
      payload = {
        key: String(getRandomNumber(0, 1000)),
        getTs: true,
      };
      this.lastGetTsTime = syncedDate().getTime();
    }

    this.send(eLobbyServerPayloadType.RSVD_PING, payload);
  }

  public reconnectCauseOfUidChange(uid: string | null = null) {
    this.uid = uid;
    this.status =
      uid === null ? SocketStatus.CONNECTING : SocketStatus.LOGIN_RECONNECTING;
    this._connect();
  }
}
