import { ChessgunStore } from "../../store";
import { compareFen } from "../fen/_compareFen";
import { parseFen } from "../fen/_parseFen";
import { BLACK_SYMBOLS, chessSide, figureType } from "../_constants";
import { PieceColor } from "./_PieceColor";
import { BishopType, KingType, KnightType, PawnType, QueenType, RookType, } from "./_PIeceType";
export const pieceToType = {
    [figureType.PAWN]: PawnType,
    [figureType.KNIGHT]: KnightType,
    [figureType.BISHOP]: BishopType,
    [figureType.ROOK]: RookType,
    [figureType.QUEEN]: QueenType,
    [figureType.KING]: KingType,
};
export class Piece {
    constructor({ piece, position, fen, dead = false, fallen = false, }) {
        const color = new PieceColor(BLACK_SYMBOLS.includes(piece) ? chessSide.BLACK : chessSide.WHITE);
        const type = pieceToType[piece.toLowerCase()];
        this.state = new ChessgunStore({
            type,
            dead,
            color,
            fallen,
            position,
            history: [{ fen, type, position, dead, fallen }],
        });
    }
    get color() {
        return this.state.get("color");
    }
    get history() {
        return this.state.get("history");
    }
    get fallen() {
        return this.state.get("fallen");
    }
    set fallen(fallen) {
        this.state.dispatch("fallen", fallen);
    }
    get position() {
        return this.state.get("position");
    }
    set position(pos) {
        this.state.dispatch("position", pos);
    }
    get type() {
        return this.state.get("type");
    }
    set type(type) {
        this.state.dispatch("type", type);
    }
    get dead() {
        return this.state.get("dead");
    }
    set dead(dead) {
        this.state.dispatch("dead", dead);
    }
    updateHistory(fen) {
        const { position, type, history, dead, fallen } = this.state.get();
        this.state.dispatch("history", [...history, { fen, position, type, dead, fallen }]);
    }
    uploadHistory(history) {
        const { history: $history } = this.state.get();
        const $newHistory = [...history, ...$history];
        this.state.dispatch("history", $newHistory);
    }
    setCurrentFen(fen) {
        const { history } = this.state.get();
        const historyItem = history.find((i) => compareFen(i.fen, fen));
        if (!historyItem)
            return;
        this.state.dispatch(Object.assign({}, historyItem));
    }
    deleteHistoryItem(fen) {
        const { history } = this.state.get();
        const index = history.findIndex((item) => compareFen(item.fen, fen));
        if (!index)
            throw new Error(`There is no history item with fen: ${fen}`);
        history.splice(index, 1);
        this.state.dispatch({ history });
    }
    boardNotation() {
        return this.color.isWhite() ? this.type.name.toUpperCase() : this.type.name;
    }
    toString() {
        const { type, color, position } = this.state.get();
        return `type: ${type.name} color: ${color.value} position: ${position}`;
    }
    static createFromFen(fen) {
        return parseFen(fen).figures.map((fig) => new Piece({ piece: fig.char, position: fig.position, fen }));
    }
    static createPiecesFromParsedData({ figures, checkmateData, fen, }) {
        const { kingIndex, checkmate } = checkmateData;
        return figures.map((fig) => new Piece({
            piece: fig.char,
            position: fig.position,
            fallen: (checkmate && kingIndex === fig.position) || undefined,
            fen,
        }));
    }
}
