import { useCallback, useEffect, useRef, useState } from 'react';
import { useDropdown } from './_useDropdown.hook';

/**
 * Хук для использования попапа подтверждения, схожем с window.confirm
 * @param confirmationAction — действие после подтверждения
 */
export const useConfirmationPopup = (confirmationAction?: () => void) => {
  const {
    isDropdownShown: isConfirmPopupShown,
    dropdownRef: confirmPopupRef,
    openDropdown: openConfirmPopup,
    closeDropdown: closeConfirmPopup,
    setCloseHandler,
  } = useDropdown();

  const [confirmText, setConfirmText] = useState('');
  const confirmHandler = useRef<(() => void) | undefined>(confirmationAction);

  useEffect(() => {
    if (confirmationAction) {
      confirmHandler.current = confirmationAction;
    }
  }, [confirmationAction]);

  const showConfirmationPopup = useCallback(
    (text: string, confirm: () => void, decline?: () => void) => {
      setConfirmText(text);

      const confirmCallback = () => confirm;
      confirmHandler.current = confirmCallback;

      setCloseHandler(decline);
      openConfirmPopup();
    },
    []
  );

  const onAccept = useCallback(() => {
    confirmHandler.current && confirmHandler.current();

    // Не выполняем события по закрытию
    setCloseHandler(undefined);

    closeConfirmPopup();
  }, [closeConfirmPopup, confirmHandler]);

  const onDecline = useCallback(() => {
    closeConfirmPopup();
  }, [closeConfirmPopup]);

  return {
    isConfirmPopupShown,
    confirmPopupRef,
    openConfirmPopup,
    onAccept,
    onDecline,

    showConfirmationPopup,
    confirmText,
    confirmHandler,
  };
};
