import React from 'react';
import { shapeColors } from '../../../../styles';
/**
 * Иконка: Мультиборд
 * @param {string} fill - цвет иконки
 * @param {number} width - ширина иконки
 * @param {number} height - высота иконки
 * @param {string} className - внешний класс
 */
export const MultiboardIcon = ({ fill = shapeColors['white'], width = '100%', height = '100%', className = '', }) => (React.createElement("svg", { className: className, width: width, height: height, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.8 2h5.9v6h-6V2zm13 6V2h-6v6h6zm1 0V1H.6v15h15.1V8zm-7 7h6V9h-6v6zM7.7 9h-6v6h6V9z", fill: fill })));
