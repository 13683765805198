export const createAuthorizationOption = (jwt?: string) => {
  const options: RequestInit = {};

  if (jwt) {
    options.headers = {
      Authorization: `JWT ${jwt}`,
    };
  }

  return options;
};

export const createAuthorizationHeaders = (jwt?: string) => ({
  headers: jwt ? { Authorization: `JWT ${jwt}` } : undefined,
});

export const getFulfilledResult = <T>(
  settledResult: PromiseSettledResult<T>
) => {
  return settledResult.status === 'fulfilled' ? settledResult.value : null;
};
