import {
  eAccessLevel,
  GameResult,
  IImageThumbnails,
  IProfileData,
} from 'shared/types';

export enum CommunityType {
  OFFLINE_CLUB = 0,
  ONLINE_CLUB = 1,
  FEDERATION = 2,
}

export enum CommunityCardType {
  TEXT_IMAGE = 0,
  IMAGE = 1,
  MAP = 2,
  VIDEO = 3,
}

export interface ICommunityTopPlayerData {
  onlineplayer_id: string | number;
  avatar_thumbnails?: IImageThumbnails;
  full_name: string;
  rating_type: string;
  rating: number;
  is_online: boolean;
}

export interface ICommunityTopPlayersByTimeControl {
  blitz: ICommunityTopPlayerData[];
  bullet: ICommunityTopPlayerData[];
  rapid: ICommunityTopPlayerData[];
}

export interface ICommunityData {
  id: number;
  type: CommunityType;
  title: string;
  created: string;
  subtitle: string | null;
  description: string;
  about: string | null;
  logo: string | null;
  icon: string | null;
  location: string | null;
  country: number | null;
  country_code: string | null;
  schedule: string | null;
  site: string | null;
  url: string;
  social_media: ICommunitySocialMedia[];
  member_count: number | null;
  membership_by_request: boolean;
  highest_rating: number | null;
  games_played: number | null;
  top_players: ICommunityTopPlayersByTimeControl;
  fide_bullet_rank: number | null;
  fide_blitz_rank: number | null;
  fide_rapid_rank: number | null;
  member_count_by_rating: { [key: string]: number };
}

export interface ICommunityAdminData {
  id: number;
  title: string;
  short_title: string;
  subtitle?: string | null;
  description?: string;
  logo?: string | null;
  icon?: string | null;
  about?: string;
  location?: string | null;
  country?: number | null;
  schedule?: string | null;
  site?: string | null;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  membership_by_request?: boolean;
}

export interface ICommunityMembersData {
  count: number;
  country_count: number;
  results: ICommunityMember[];
}

export interface ICommunityAdminMembersData {
  count: number;
  country_count: number;
  results: ICommunityAdminMember[];
}

export interface ICommunityEvent {
  id?: number;
  link_text: string;
  link: string;
  start: string;
}

export interface ICommunityEventData {
  count: number;
  next: string;
  previous: string;
  results: ICommunityEvent[];
}

export interface ICommunityCard {
  id: number;
  type: CommunityCardType;
  image: string;
  link: string | null;
  link_text: string | null;
  text: string | null;
  order?: number;
}

export interface ICommunityCardData {
  count: number;
  next: string;
  previous: string;
  results: ICommunityCard[];
}

export interface ICommunityMember {
  id: number;
  full_name: string;
  age: number | null;
  avatar_thumbnails: IImageThumbnails | null;
  nationality_id: number | null;
  rating: ICommunityMemberRatings;
  avatar: string;
  foa_title: string | null;
  fide_title: string | null;
  fide_id: number | null;
  status?: eRequestStatus;
  group?: number;
}

export interface ICommunityYou {
  you: ICommunityMember | null;
}

export interface ICommunityAdminMember {
  id: number;
  member_id: number;
  full_name: string;
  age: number | null;
  avatar_thumbnails: IImageThumbnails | null;
  nationality_id: number | null;
  rating: ICommunityMemberRatings;
  avatar: string;
  foa_title: string | null;
  fide_title: string | null;
  access_level: eAccessLevel | null;
  group: number;
}

export interface ICommunityMemberRatings {
  fide_blitz: number | null;
  fide_bullet: number | null;
  fide_rapid: number | null;
  worldchess_blitz: number;
  worldchess_bullet: number;
  worldchess_rapid: number;
}

export interface ICommunitySocialMedia {
  link: string;
  name: string;
}

export interface ICommunitySocialMediaIcon {
  icon: React.FC;
  iconWidth: number;
  iconHeight: number;
  name: string;
}

export interface ICommunityRequest {
  id: number;
  community_id: number;
  user_id: number;
  online_player_id: number;
  full_name: string;
  created: string;
  status: number;
}

export interface ICommunityDraftTournament {
  id: number;
  community_id: number;
  user_id: number;
  online_player_id: number;
  full_name: string;
  created: string;
  status: number;
}

export interface ICommunityRequestUpdate {
  status: number;
  community_id: number;
}

export interface ICommunityBestToday {
  blitz?: number;
  bullet?: number;
  rapid?: number;
  profile?: IProfileData;
  stats?: { [key: string]: number };
  rating_type?: string;
}

export interface ICommunityRatingHistory {
  bullet: number[];
  blitz: number[];
  rapid: number[];
}

export interface ICommunityNewTitle {
  title: string;
  profile: IProfileData;
}

export interface ICommunityToday {
  'best-today': ICommunityBestToday;
  birthdays: IProfileData[];
  'new-titles': ICommunityNewTitle[];
}

export interface ICommunityAdminSetResultsItem {
  uid: string; // board uid
  result: GameResult;
}

export interface ICommunityAdminSetResultsData {
  tour_id: number;
  results: ICommunityAdminSetResultsItem[];
}

export enum eRequestStatus {
  WAITING_FOR_APPROVE = 0,
  APPROVED = 1,
  DECLINED = 2,
}

export enum eUsersGroup {
  MEMBER = 0,
  ADMIN = 1,
}

export enum eBlocks {
  RATING_BLITZ = 'ratingBlitz',
  RATING_BULLET = 'ratingBullet',
  RATING_RAPID = 'ratingRapid',
  MEMBERS_COUNT = 'membersCount',
  CLUB_MEMBER_STATS = 'clubMembersStats',
}
