import {
  IUserData,
  IRatingsData,
  IStatisticData,
  IRatingsChartsData,
  IChallengesList,
  IProfileData,
  GameRatingMode,
  ISystemPopups,
  IInviteData,
  IChallengeItem,
} from 'shared/types';
import { httpStatuses } from 'shared/constants';
import { findProPlan } from 'shared/helpers/_common';

import {
  ILongChallengeRequestParams,
  IRequestError,
  ITitleProgress,
} from '@types';
import { authActions, fideSubscriptionActions, userDataActions } from './index';
import { mainStore, TMainStore } from '@store/storeshed';
import { userService } from '@services/_user.service';
import { challengesService } from '@services/_challenges.service';

export const createUserDataActions = () => {
  /**
   * Задает токен пользователя.
   * @param {string} token - токен пользователя.
   */
  const setDataRequest = ({ userDataStore }: TMainStore, status: boolean) => {
    userDataStore.dispatch('data_request', status);
  };

  /**
   * Задает данные пользователя (user.data).
   * @param {IUserData} userData - данные пользователя.
   */
  const setUserData = ({ userDataStore }: TMainStore, userData: IUserData) => {
    userDataStore.dispatch('data', userData);
  };

  /**
   * Задает состояние запроса на рейтинг пользователя.
   * @param {boolean} inRequest - выполняется ли запрос на рейтинг пользователя.
   */
  const setRatingsRequest = (
    { userDataStore }: TMainStore,
    inRequest: boolean
  ) => {
    userDataStore.dispatch('ratings_request', inRequest);
  };

  /**
   * Задает рейтинг пользователя (user.ratings).
   * @param {IRatingsData} ratings - объект значений рейтингов пользователя.
   */
  const setUserRatings = (
    { userDataStore }: TMainStore,
    ratings: IRatingsData
  ) => {
    userDataStore.dispatch('ratings', ratings);
  };

  /**
   * Задает состояние запроса на статистику игр пользователя.
   * @param {boolean} inRequest - выполняется ли запрос на статистику игр пользователя.
   */
  const setStatsRequest = (
    { userDataStore }: TMainStore,
    inRequest: boolean
  ) => {
    userDataStore.dispatch('stats_request', inRequest);
  };

  /**
   * Задает статистику игр WorldChess пользователя (user.wc_stats).
   * @param {IStatisticData} stats - объект статистики игр пользователя.
   */
  const setUserWCStats = (
    { userDataStore }: TMainStore,
    stats: IStatisticData
  ) => {
    userDataStore.dispatch('wc_stats', stats);
  };

  /**
   * Задает статистику игр FIDE пользователя (user.fide_stats).
   * @param {IStatisticData} stats - объект статистики игр пользователя.
   */
  const setUserFideStats = (
    { userDataStore }: TMainStore,
    stats: IStatisticData
  ) => {
    userDataStore.dispatch('fide_stats', stats);
  };

  /**
   * Задает статистику игр OTB пользователя (user.otb_stats).
   * @param {IStatisticData} stats - объект статистики игр пользователя.
   */
  const setUserOtbStats = (
    { userDataStore }: TMainStore,
    stats: IStatisticData
  ) => {
    userDataStore.dispatch('otb_stats', stats);
  };

  /**
   * Задает данные для графиков WorldChess рейтинга (user.wc_charts).
   * @param {IRatingsChartsData} charts - данные графика.
   */
  const setUserWCCharts = (
    { userDataStore }: TMainStore,
    charts: IRatingsChartsData
  ) => {
    userDataStore.dispatch('wc_charts', charts);
  };

  /**
   * Задает данные для графиков FIDE рейтинга (user.fide_charts).
   * @param {IRatingsChartsData} charts  - данные графика.
   */
  const setUserFideCharts = (
    { userDataStore }: TMainStore,
    charts: IRatingsChartsData
  ) => {
    userDataStore.dispatch('fide_charts', charts);
  };

  /**
   * Задает данные для графиков OTB рейтинга (user.otb_charts).
   * @param {IRatingsChartsData} otb_charts - данные графика.
   */
  const setUserOtbCharts = (
    { userDataStore }: TMainStore,
    charts: IRatingsChartsData
  ) => {
    userDataStore.dispatch('otb_charts', charts);
  };

  /**
   * Задает состояние запроса на прогресс титулов пользователя.
   * @param {boolean} inRequest - выполняется ли запрос на прогресс титулов пользователя
   */
  const setTitleProgressRequest = (
    { userDataStore }: TMainStore,
    inRequest: boolean
  ) => {
    userDataStore.dispatch('title_progress_request', inRequest);
  };

  /**
   * Задает прогресс титулов пользователя.
   * @param {ITitleProgress | null} titleProgress - прогресс титулов пользователя
   */
  const setTitleProgress = (
    { userDataStore }: TMainStore,
    titleProgress: ITitleProgress | null
  ) => {
    userDataStore.dispatch('title_progress', titleProgress);
  };

  /**
   * Задает ранг пользователя (user.wc_charts).
   * @param {string} rank  - текущий ранг.
   */
  const setRank = (
    { userDataStore }: TMainStore,
    rank: 'ACM' | 'AFM' | 'AIM' | 'AGM' | null
  ) => {
    const data = userDataStore.get('data');
    let updatedRankData: typeof data = null;

    if (data) {
      updatedRankData = {
        ...data,
        player: {
          ...data.player,
          foa_title: rank,
        },
      };
    }

    userDataStore.dispatch('data', updatedRankData);
  };

  /**
   * Задает данные по челенджам для текущего игрока.
   * @param {IChallengesList} data - данные челендей.
   */
  const setChallengesList = (
    { userDataStore }: TMainStore,
    data: IChallengesList
  ) => {
    userDataStore.dispatch('challenges', data);
  };

  /**
   * Задает данные по челенджам для текущего игрока.
   * @param {IProfileData} data - выполняется ли запрос на поиск FIDE аккаунта.
   */
  const setChallengeOpponentProfileData = (
    { userDataStore }: TMainStore,
    data: IProfileData
  ) => {
    userDataStore.dispatch('challenge_opponent_data', data);
  };

  /**
   * Сбрасывает данные по челенджам для текущего игрока.
   */
  const resetChallengeOpponentProfileData = ({ userDataStore }: TMainStore) => {
    userDataStore.dispatch('challenge_opponent_data', null);
  };

  /**
   * Задает состояние запроса на запрос списка челенджей.
   * @param {boolean} inRequest - состояние запроса.
   */
  const setChallengesRequest = (
    { userDataStore }: TMainStore,
    inRequest: boolean
  ) => {
    userDataStore.dispatch('challenges_request', inRequest);
  };

  /**
   * Выполняет добавление long челенджа в список.
   * @param {IChallengeItem} challenge - челендж.
   */
  const addLongChallenge = (
    { userDataStore }: TMainStore,
    challenge: IChallengeItem
  ) => {
    const userData = userDataStore.get('data');
    const challenges = userDataStore.get('challenges');

    if (userData && challenges) {
      const incoming = challenge.opponent?.uid === userData.uid;
      const outcoming = challenge.owner?.uid === userData.uid;

      if (incoming) {
        userDataStore.dispatch('challenges', {
          ...challenges,
          incoming: [...challenges.incoming, challenge],
        });
      }

      if (outcoming) {
        userDataStore.dispatch('challenges', {
          ...challenges,
          outcoming: [...challenges.outcoming, challenge],
        });
      }
    }
  };

  /**
   * Выполняет удаление long челенджа из списока.
   * @param {IChallengeItem} challenge - челендж.
   */
  const removeLongChallenge = (
    { userDataStore }: TMainStore,
    challenge: IChallengeItem
  ) => {
    const userData = userDataStore.get('data');
    const challenges = userDataStore.get('challenges');

    if (userData && challenges) {
      const incoming = challenge.opponent?.uid === userData.uid;
      const outcoming = challenge.owner?.uid === userData.uid;

      if (incoming) {
        userDataStore.dispatch('challenges', {
          ...challenges,
          incoming: challenges.incoming.filter(
            (item) => item.id !== challenge.id
          ),
        });
      }
      if (outcoming) {
        userDataStore.dispatch('challenges', {
          ...challenges,
          outcoming: challenges.outcoming.filter(
            (item) => item.id !== challenge.id
          ),
        });
      }
    }
  };

  /**
   * Выполняет обновление количества входящих челенджей для пользователя.
   * @param {number} incomingNumber - число входящих челенджей.
   */
  const updateIncomingChallenges = (
    { userDataStore }: TMainStore,
    incomingNumber: number
  ) => {
    const data = userDataStore.get('data');

    if (data) {
      const newUserDataChallenges = { ...data.challenges };
      newUserDataChallenges.incoming = incomingNumber;

      userDataStore.dispatch('data', {
        ...data,
        challenges: newUserDataChallenges,
      });
    }
  };

  /**
   * Выполняет обновление количества исходящих челенджей для пользователя.
   * @param {number} outcomingNumber - число исходящих челенджей.
   */
  const updateOutcomingChallenges = (
    { userDataStore }: TMainStore,
    outcomingNumber: number
  ) => {
    const data = userDataStore.get('data');

    if (data) {
      const newUserDataChallenges = { ...data.challenges };
      newUserDataChallenges.outcoming = outcomingNumber;

      userDataStore.dispatch('data', {
        ...data,
        challenges: newUserDataChallenges,
      });
    }
  };

  /**
   * Выполняет запросы на данные пользователя, рейтинг и статистику.
   * Если данные получены, то задает эти данные, а также переводит состояние пользователя logged в true.
   */
  const getMe = async ({}: TMainStore, iteration?: number) => {
    userDataActions.setDataRequest(true);

    try {
      const { ok, data: userData } = await userService.me();

      if (ok) {
        userDataActions.setUserData(userData);
        authActions.setUserUid(userData.uid);
        authActions.setLogged(true);

        userDataActions.getStats(userData.player.player_id);
        userDataActions.getRatings();
        userDataActions.getTitleProgress();

        const currentFide = findProPlan(userData);
        fideSubscriptionActions.setProSubscription({
          fide_id: userData.fide_id,
          is_active: currentFide ? currentFide.is_active : false,
          verified: userData.fide_verified_status,
          free_requested: userData.free_account_requested,
          data: currentFide,
        });
      }
    } catch (err) {
      console.log(err);

      if (
        (err as IRequestError).status === httpStatuses.UNAUTHORIZED ||
        (iteration !== undefined && iteration >= 5)
      ) {
        authActions.setToken(null);
      } else {
        setTimeout(() => {
          userDataActions.getMe(iteration ? iteration + 1 : 1);
        }, 10000);
        return;
      }
    }

    setTimeout(() => {
      userDataActions.setDataRequest(false);
    }, 30);
  };

  /**
   * Выполняет запрос на данные пользователя.
   * Если данные получены, то задает эти данные, а также переводит состояние пользователя logged в true.
   */
  const getMeData = async ({}: TMainStore) => {
    userDataActions.setDataRequest(true);

    try {
      const { ok, data: userData } = await userService.me();

      if (ok) {
        userDataActions.setUserData(userData);
        authActions.setUserUid(userData.uid);
        authActions.setLogged(true);

        const currentFide = findProPlan(userData);

        fideSubscriptionActions.setProSubscription({
          fide_id: userData.fide_id,
          is_active: currentFide ? currentFide.is_active : false,
          verified: userData.fide_verified_status,
          free_requested: userData.free_account_requested,
          data: currentFide,
        });
      }
    } catch (err) {
      console.log(err);
      if ((err as IRequestError).status === httpStatuses.UNAUTHORIZED) {
        authActions.setToken(null);
      }
    }

    setTimeout(() => {
      userDataActions.setDataRequest(false);
    }, 30);
  };

  /**
   * Выполняет запрос на данные пользователя.
   * Если данные получены, то задает эти данные, а также переводит состояние пользователя logged в true.
   * ex getMyAccount
   */
  const getUserAccount = async (
    { authStore, userDataStore, fideSubscriptionStore }: TMainStore,
    token?: string
  ) => {
    // const { tournament_data: tournamentData } = getState().tournament;
    // const { pathname } = getState().router.location;

    userDataStore.dispatch('data_request', true);

    try {
      const { ok, data: userData } = await userService.me();

      if (ok) {
        userDataStore.dispatch({
          data: userData,
        });
        authStore.dispatch({
          uid: userData.uid,
          logged: true,
          token: token || null,
        });

        userDataActions.getStats(userData.player.player_id);
        userDataActions.getRatings();

        const currentFide = findProPlan(userData);

        fideSubscriptionStore.dispatch({
          pro_subscription: {
            fide_id: userData.fide_id,
            is_active: currentFide ? currentFide.is_active : false,
            verified: userData.fide_verified_status,
            free_requested: userData.free_account_requested,
            data: currentFide,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }

    setTimeout(() => {
      userDataStore.dispatch('data_request', false);
    }, 30);
  };

  /**
   * Выполняет запрос на рейтинги пользователя. Перед запросом задает user.ratings-request значение true, после - false.
   * Если данные получены, то задает эти данные.
   */
  const getRatings = async ({ userDataStore }: TMainStore) => {
    const ratingsRequest = userDataStore.get('ratings_request');
    if (ratingsRequest) return;

    userDataActions.setRatingsRequest(true);

    try {
      const { ok, data: ratings } = await userService.userRatings();

      if (ok) {
        userDataActions.setUserRatings(ratings);
      }
    } catch (err) {
      console.log(err);
    }

    userDataActions.setRatingsRequest(false);
  };

  /**
   * Обновляет рейтинги пользователя
   * @param {number} newRating - новый рейтинг
   * @param {IRatingsData} ratings - текущие рейтинги пользователя
   * @param {IInviteData} inviteData - данные игры
   */
  const updateUserRatings = (
    {}: TMainStore,
    {
      newRating,
      ratings,
      inviteData,
    }: {
      newRating: number;
      ratings: IRatingsData | null;
      inviteData: IInviteData | null;
    }
  ) => {
    const boardTypeName = inviteData?.time_control.board_type_name;
    const ratingMode = inviteData?.rating[0];

    if (ratingMode && boardTypeName && ratings) {
      const newRatings: IRatingsData = {
        ...ratings,
      };

      let ratingNameType: 'worldchess' | 'fide' | null = null;

      if (ratingMode === GameRatingMode.RATED) {
        ratingNameType = 'worldchess';
      }

      if (ratingMode === GameRatingMode.FIDERATED) {
        ratingNameType = 'fide';
      }

      if (
        ratingNameType &&
        (boardTypeName === 'blitz' ||
          boardTypeName === 'rapid' ||
          boardTypeName === 'bullet')
      ) {
        const ratingName: keyof IRatingsData = `${ratingNameType}_${boardTypeName}`;

        if (
          ratingMode === GameRatingMode.RATED &&
          ratings.worldchess_rating === ratings[ratingName]
        ) {
          newRatings.worldchess_rating = newRating;
        }

        if (
          ratingMode === GameRatingMode.FIDERATED &&
          ratings.fide_rating === ratings[ratingName]
        ) {
          newRatings.fide_rating = newRating;
        }

        newRatings[ratingName] = newRating;

        userDataActions.setUserRatings(newRatings);
      }
    }
  };

  /**
   * Выполняет запрос на статистику игр пользователя. Перед запросом
   * задает user.stats-request значение true, после - false.
   * Если данные получены, то задает эти данные.
   * @param {number} playerId - id игрока.
   */
  const getStats = async (
    { userDataStore }: TMainStore,
    playerId: number | string
  ) => {
    const statsRequest = userDataStore.get('stats_request');
    if (statsRequest) return;

    userDataActions.setStatsRequest(true);

    try {
      const { ok: wcOkay, data: wcStats } = await userService.userStats(
        playerId,
        'worldchess'
      );
      const { ok: fideOk, data: fideStats } = await userService.userStats(
        playerId,
        'fide'
      );
      const { ok: otbOk, data: otbStats } = await userService.userStats(
        playerId,
        'otb'
      );

      if (wcOkay && fideOk && otbOk) {
        userDataActions.setUserWCStats(wcStats.game_stats);
        userDataActions.setUserFideStats(fideStats.game_stats);
        userDataActions.setUserOtbStats(otbStats.game_stats);

        userDataActions.setUserWCCharts(wcStats.ratings);
        userDataActions.setUserFideCharts(fideStats.ratings);
        userDataActions.setUserOtbCharts(otbStats.ratings);
      }
    } catch (err) {
      console.log(err);
    }

    userDataActions.setStatsRequest(false);
  };

  /**
   * Выполняет запрос на доступные к покупке титулы.
   * Перед запросом задает user.available_title_request значение true, после - false.
   * Если данные получены, то задает эти данные.
   */
  const getTitleProgress = async ({}: TMainStore) => {
    userDataActions.setTitleProgressRequest(true);

    try {
      const { ok, data } = await userService.titleProgress();

      if (ok) {
        userDataActions.setTitleProgress(data);
      }
    } catch (err) {
      console.log(err);
    }

    userDataActions.setTitleProgressRequest(false);
  };

  /**
   * Выполняет запрос на данные по челенджам для текущего игрока. Необходима авторизация
   */
  const getChallengesList = async ({}: TMainStore) => {
    userDataActions.setChallengesRequest(true);
    try {
      const token = mainStore.authStore.get('token');
      const { ok, data } = await challengesService.getLongChallenges(token);

      if (ok) {
        userDataActions.setChallengesList(data);
      }
    } catch (error) {
      console.log(error);
    }

    userDataActions.setChallengesRequest(false);
  };

  /**
   * Выполняет запрос на создание исходящего челенджа
   * @param {number} opponent - id пользователя
   * @param {number} timeControl - выбранный тайм контроль
   * @param {string | null} desiredColor - предпочитаемая сторона
   * @param {string | null} ratingType - выбранный тип рейтинга
   */
  const createChallenge = async (
    {}: TMainStore,
    {
      params,
      popups = { alert: () => {} },
    }: {
      params: ILongChallengeRequestParams;
      popups: Pick<ISystemPopups, 'alert'>;
    }
  ) => {
    userDataActions.setChallengesRequest(true);
    try {
      const token = mainStore.authStore.get('token');
      const { ok, data } = await challengesService.createLongChallenge(
        params,
        token
      );

      if (ok) {
        userDataActions.updateOutcomingChallenges(1);
        userDataActions.addLongChallenge(data);
      }
    } catch (error) {
      console.log(error);

      const errorData = (
        error as IRequestError<{ code: string; detail?: string }>
      ).data;

      if (errorData.detail) {
        popups.alert(errorData.detail);
      }
    }

    userDataActions.setChallengesRequest(false);
  };

  /**
   * Выполняет запрос на получение данных профиля опонент по челенджам для текущего игрока.
   * @param {IChallengesList} data - выполняется ли запрос на поиск FIDE аккаунта.
   */
  const getChallengeOpponentProfileData = async (
    {}: TMainStore,
    id: number | string
  ) => {
    userDataActions.setChallengesRequest(true);
    try {
      const { data } = await userService.getProfile(id);

      const {
        data: { is_online },
      } = await userService.getIsOnline(id);

      const profileData = { ...data, is_online };

      userDataActions.setChallengeOpponentProfileData(profileData);
    } catch (error) {
      console.log(error);
    }

    userDataActions.setChallengesRequest(false);
  };

  /**
   * Выполняет запрос на принятие выбранного челенджа.
   * @param {number} id - id выбранного челенджа.
   */
  const acceptChallenge = async (
    {}: TMainStore,
    id: number,
    { alert }: Pick<ISystemPopups, 'alert'> = { alert: () => {} }
  ) => {
    userDataActions.setChallengesRequest(true);
    try {
      const token = mainStore.authStore.get('token');
      const { ok } = await challengesService.acceptLongChallenge(id, token);

      if (ok) {
        console.log(ok);
      }
    } catch (error) {
      console.log(error);

      const errorData = (error as IRequestError<{ code: string }>).data;

      if (errorData.code === 'owner_disconnected') {
        alert(
          "Oops! This opponent is offline now, you can't accept this challenge"
        );
        userDataActions.getChallengesList();
      }

      if (errorData.code === 'player_has_too_many_active_single_game_boards') {
        alert(
          'You have reached the maximum number of active games. Please complete one of your ongoing games.'
        );
      }
    }

    userDataActions.setChallengesRequest(false);
  };

  /**
   * Выполняет запрос на отклонение выбранного челенджа.
   * @param {number} id - id выбранного челенджа.
   */
  const declineChallenge = async ({}: TMainStore, id: number) => {
    userDataActions.setChallengesRequest(true);
    try {
      const token = mainStore.authStore.get('token');
      const { ok } = await challengesService.declineLongChallenge(id, token);

      if (ok) {
        userDataActions.getChallengesList();
      }
    } catch (error) {
      console.log(error);
    }

    userDataActions.setChallengesRequest(false);
  };

  /**
   * Выполняет запрос на удаление выбранного челенджа.
   * @param {number} id - id выбранного челенджа.
   */
  const deleteChallenge = async ({}: TMainStore, id: number) => {
    userDataActions.setChallengesRequest(true);
    try {
      const token = mainStore.authStore.get('token');
      const { ok } = await challengesService.cancelLongChallenge(id, token);

      if (ok) {
        userDataActions.updateOutcomingChallenges(0);
        userDataActions.getChallengesList();
      }
    } catch (error) {
      console.log(error);
    }

    userDataActions.setChallengesRequest(false);
  };

  return {
    setDataRequest,
    setUserData,
    setRatingsRequest,
    setUserRatings,
    setStatsRequest,
    setUserWCStats,
    setUserFideStats,
    setUserOtbStats,
    setUserWCCharts,
    setUserFideCharts,
    setUserOtbCharts,
    setTitleProgressRequest,
    setTitleProgress,
    setRank,
    setChallengesList,
    setChallengeOpponentProfileData,
    resetChallengeOpponentProfileData,
    setChallengesRequest,
    addLongChallenge,
    removeLongChallenge,
    updateIncomingChallenges,
    updateOutcomingChallenges,

    getUserAccount,
    getMe,
    getMeData,
    getRatings,
    updateUserRatings,
    getStats,
    getTitleProgress,
    getChallengesList,
    createChallenge,
    getChallengeOpponentProfileData,
    acceptChallenge,
    declineChallenge,
    deleteChallenge,
  };
};
