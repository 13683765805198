import {
  ITournamentSponsorsData,
  ITournamentStream,
  IBroadcastsData,
  ITournamentGameTourResult,
  IPulseItem,
  ITournamentExpandedData,
} from '@types';
import { BaseService } from './_base.service';
import {
  IRequestPagination,
  IStandingParams,
  ITourGameData,
  ITournamentParams,
  ITournamentPlayersData,
} from 'shared/types';
import { httpInstance } from '.';

export class BroadcastService extends BaseService {
  /**
   * Выполняет запрос на получение списка бродкаста турниров
   */
  getBroadcastsTournaments({
    minStartTime,
    maxStartTime,
    minFinishTime,
    maxFinishTime,
    limit,
    offset,
    sortBy,
    community,
    statuses,
    history,
  }: ITournamentParams) {
    let queryStr = `?hidden=false&ordering=${sortBy ? sortBy : ''},${
      history ? '-' : ''
    }start`;

    if (minStartTime) {
      queryStr += `&start_time_after=${minStartTime}`;
    }

    if (maxStartTime) {
      queryStr += `&start_time_before=${maxStartTime}`;
    }

    if (minFinishTime) {
      queryStr += `&finish_time_after=${minFinishTime}`;
    }

    if (maxFinishTime) {
      queryStr += `&finish_time_before=${maxFinishTime}`;
    }

    if (community) {
      queryStr += `&community=${community}`;
    }

    if (statuses) {
      statuses.forEach((status) => {
        queryStr += `&status=${status}`;
      });
    }

    if (typeof history !== undefined) {
      queryStr += `&history=${history ? 1 : 0}`;
    }

    if (limit) {
      queryStr += `&limit=${limit}`;
    }

    if (offset) {
      queryStr += `&offset=${offset}`;
    }

    return this.ajax.get<IBroadcastsData>(
      `broadcast/tournaments/short/${queryStr}`
    );
  }

  /**
   * Выполняет запрос на получение информации о бродкасте турнира
   * @param {number | string} tournamentId - id турнира
   */
  getBroadcastTournament(tournamentId: number | string) {
    return this.ajax.get<ITournamentExpandedData>(
      `/broadcast/tournaments/${tournamentId}/`
    );
  }

  /**
   * Выполняет запрос на получение информации о спонсорах бродкаста
   * @param {number | string} tournamentId - id турнира
   */
  getBroadcastSponsors(tournamentId: number | string) {
    return this.ajax.get<ITournamentSponsorsData>(
      `/broadcast/tournaments/${tournamentId}/sponsors/`
    );
  }

  /**
   * Выполняет запрос на получение информации о стримах бродкаста
   * @param {number | string} tournamentId - id турнира
   */
  getBroadcastStreams(tournamentId: number | string) {
    return this.ajax.get<ITournamentStream[]>(
      `/broadcast/tournaments/${tournamentId}/streams/`
    );
  }

  /**
   * Осуществляет запрос на получение участников бродкаста по id.
   * @param {number | string} id - id турнира
   * @param {number} limit - количество запрашиваемых данных
   * @param {number} offset - пагинация
   * @param {string} stage - выбранная стадия
   */
  getBroadcastStanding({ id, limit, offset, stage }: IStandingParams) {
    const query: { limit?: number; offset?: number; stage?: string } = {};

    if (limit) {
      query.limit = limit;
      query.offset = offset ? offset : 0;
    }

    if (stage) {
      query.stage = stage;
    }

    return this.ajax.get<ITournamentPlayersData>(
      `/broadcast/tournaments/${id}/standing/`,
      { query }
    );
  }

  /**
   * Осуществляет запрос на получение данных о доске в бродкасте
   * @param {string} boardId - id доски
   */
  getBroadcastBoardData(boardId: string) {
    return this.ajax.get<ITourGameData>(
      `broadcast/tournament/gaming/${boardId}/`
    );
  }

  /**
   * Осуществляет запрос на получение результата доски в бродкасте
   * @param {string} boardId - id доски
   */
  getBroadcastBoardResult(boardId: string) {
    return this.ajax.get<ITournamentGameTourResult>(
      `online/tournament/gaming/${boardId}/result/`
    );
  }

  /**
   * Осуществляет запрос на получение количества зрителей в бродкасте
   * @param {string} boardId - id доски
   */
  getBroadcastActiveViewers(boardId: string) {
    return this.ajax.get<{ active_viewers_amount: string }>(
      `broadcast/tournament/gaming/${boardId}/active_viewers/`
    );
  }

  /**
   * Осуществляет запрос истории пульса
   * @param {number} playerId - id игрока
   */
  getPulseHistory(playerId: number, filterFrom?: string, filterTo?: string) {
    return this.ajax.get<IRequestPagination<IPulseItem>>(
      `online/players/${playerId}/heart-rates${
        filterFrom ? `?filter_from=${filterFrom}&filter_to=${filterTo}` : ''
      }`
    );
  }
}

export const broadcastService = new BroadcastService({
  instance: httpInstance,
});
