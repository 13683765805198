import React from 'react';
import { chessFigureStyle } from './_types';
/**
 * Иконка: Фигура пешки
 * @param {string} fill - цвет фигуры
 * @param {number} width - ширина фигуры
 * @param {number} height - высота фигуры
 * @param {string} color - тема фигуры
 * @param {chessFigureStyle} figureStyle - тема фигуры
 * @param {string} className - внешний класс
 */
export const PawnIcon = ({ className = '', width = '100%', height = '100%', theme = 'white', fill, figureStyle = chessFigureStyle.FILLED, }) => {
    const pawns = {
        [chessFigureStyle.FILLED]: (React.createElement("svg", { className: className, width: width, height: height, fill: "none", viewBox: "0 0 60 60", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M26.1 20c.3-.2.4-.5 0-.8-1-1-1.7-2.3-1.7-3.6A6 6 0 0130 10c2.7 0 5 2.7 5 5 0 1.8-1 3.2-2.4 4.2-.3.3-.1.5.3.6 2.7 1 5.3 3 5.3 6.5 0 3-1.3 5-3.3 6.2-.3.2-.3.6 0 .7 5.4 2.8 8.4 8.1 8.4 13.5 0 2.1-.3 3.4-.9 4.3H17c-.6-1-.9-2.3-.9-4.3 0-5 2.7-10.7 8-13.3.4-.2.5-.6 0-.9a6.6 6.6 0 01-2.8-5.5c0-3.7 2.5-5.7 5-7z", fill: fill ? fill : theme }))),
        [chessFigureStyle.STROKE]: (React.createElement("svg", { className: className, width: width, height: height, fill: "none", viewBox: "0 0 13 13", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M5.66 4.27a.95.95 0 0 1-.37-.8c0-.75.62-1.3 1.3-1.3a1.19 1.19 0 0 1 .78 2.1c.65.25 1.14.78 1.14 1.55 0 .59-.25.99-.68 1.3 1.2.53 1.77 1.7 1.77 2.75 0 .53-.1.87-.31 1.18H3.75a2.01 2.01 0 0 1-.28-1.18c0-1.05.58-2.32 1.73-2.75a1.5 1.5 0 0 1-.65-1.2c0-.81.43-1.37 1.11-1.65Zm.16 2.85v.12a2.91 2.91 0 0 0-1.83 2.63c0 .28.06.5.13.68h4.83c.06-.15.12-.37.12-.68 0-1.2-.87-2.35-1.92-2.63v-.12c.65-.31.87-.8.87-1.3 0-.68-.4-1.24-1.21-1.46v-.09a.9.9 0 0 0 .56-.83c0-.53-.34-.84-.78-.84-.4 0-.86.28-.86.9 0 .28.15.59.4.77v.13C5.45 4.67 5 5.2 5 5.9c.04.5.31.93.8 1.2Z", fill: fill ? fill : theme }))),
    };
    return pawns[figureStyle];
};
