import { countries, regions } from '../constants';
/**
 * ищет страну по id
 * @param {number} countryId - id страны
 * @returns {country} object | undefined
 */
export const getCountryById = (countryId) => {
    return countries.find((country) => country.id === countryId);
};
/**
 * ищет регион по id
 * @param {number} regionId - id региона
 * @returns {region} object | undefined
 */
export const getRegionById = (regionId) => {
    return regions.find((region) => region.id === regionId);
};
