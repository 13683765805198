import { useCallback } from 'react';

import {
  calcRatingLimitsByGameModeAndTimeControl,
  getUserGameRating,
} from './helpers/_calc_rating_limits_by_game_mode_and_time_control';
import { STORAGE_KEYS } from './_constants';
import {
  eGameLobbyReducerActionType,
  IGameLobbyActions,
  IGameLobbyContextDispatch,
  IGameLobbyContextState,
  TGameLobbyRatingModes,
  TGameLobbyReducerActionKeysPayload,
} from './_types';
import { TGameLobbyTimeControls } from './_types';
import { IRatingLimits } from 'shared/atoms';
import {
  IAdvancedTimeControl,
  GameRatingMode,
  oppMode,
  IRatingsData,
  BoardType,
} from 'shared/types';
import { useAuthStore } from '@store/storeshed';
import { useUserDataStore } from '@store/storeshed';
import { setLSItem } from '@utils/_local_storage';
import { gameService } from '@services/_game.service';
import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';

export interface IUseGameLobbyActionsProps {
  state: IGameLobbyContextState;
  dispatch: IGameLobbyContextDispatch;
}

export const useGameLobbyActions = ({
  state,
  dispatch,
}: IUseGameLobbyActionsProps): IGameLobbyActions => {
  const uid = useAuthStore('uid');
  const ratings = useUserDataStore('ratings');
  const userData = useUserDataStore('data');

  const {
    actions: { setInviteData },
  } = useLobbyContext();

  const setRatingByUser = (
    newRatingLimits: IRatingLimits,
    state: IGameLobbyContextState
  ) => {
    const { gameRatingLimits, gameRatingMode, currentTimeControl } = state;
    const newGameRatingLimits = {
      ...gameRatingLimits,
      [gameRatingMode]: {
        ...gameRatingLimits[gameRatingMode],
        [currentTimeControl.board_type]: newRatingLimits,
      },
    };

    setLSItem(STORAGE_KEYS.RATING_LIMITS, newGameRatingLimits);
    dispatch({
      type: eGameLobbyReducerActionType.KeyOf,
      payload: { key: 'gameRatingLimits', value: newGameRatingLimits },
    });
  };

  const setTimeControl = (
    newTimeControl: IAdvancedTimeControl,
    state: IGameLobbyContextState
  ) => {
    const { gameRatingLimits, gameRatingMode } = state;
    const { board_type } = newTimeControl;
    const { lower, upper } =
      gameRatingLimits[gameRatingMode][board_type as TGameLobbyTimeControls];
    const dispatchPayloadList: TGameLobbyReducerActionKeysPayload = [];
    const ratingIsNotSet = lower === 0 && upper === 0;

    if (!ratingIsNotSet) {
      setLSItem(STORAGE_KEYS.TIME_CONTROL, newTimeControl);
      dispatch({
        type: eGameLobbyReducerActionType.KeyOf,
        payload: { key: 'currentTimeControl', value: newTimeControl },
      });
      return;
    }

    let currentUserRating = state.currentUserRating;
    if (currentUserRating === 0) {
      currentUserRating = getUserGameRating(
        ratings,
        gameRatingMode,
        board_type
      );

      dispatchPayloadList.push({
        key: 'currentUserRating',
        value: currentUserRating,
      });
    }

    const newRatingLimits = calcRatingLimitsByGameModeAndTimeControl(
      currentUserRating,
      gameRatingMode
    );
    const newGameRatingLimits = {
      ...gameRatingLimits,
      [gameRatingMode]: {
        ...gameRatingLimits[gameRatingMode],
        [board_type]: newRatingLimits,
      },
    };

    dispatchPayloadList.push({
      key: 'gameRatingLimits',
      value: newGameRatingLimits,
    });
    dispatchPayloadList.push({
      key: 'currentTimeControl',
      value: newTimeControl,
    });
    setLSItem(STORAGE_KEYS.RATING_LIMITS, newGameRatingLimits);
    setLSItem(STORAGE_KEYS.TIME_CONTROL, newTimeControl);
    dispatch({
      type: eGameLobbyReducerActionType.KeysOf,
      payload: dispatchPayloadList,
    });
  };

  const setRatingMode = (
    newRatingMode: GameRatingMode,
    state: IGameLobbyContextState
  ) => {
    const { gameRatingLimits, currentTimeControl } = state;
    const { board_type } = currentTimeControl;
    const { lower, upper } =
      gameRatingLimits[newRatingMode as TGameLobbyRatingModes][
        board_type as TGameLobbyTimeControls
      ];
    const dispatchPayloadList: TGameLobbyReducerActionKeysPayload = [];
    const ratingIsNotSet = lower === 0 && upper === 0;
    const currentUserRating = getUserGameRating(
      ratings,
      newRatingMode,
      board_type
    );

    if (currentUserRating !== state.currentUserRating) {
      dispatchPayloadList.push({
        key: 'currentUserRating',
        value: currentUserRating,
      });
    }

    if (!ratingIsNotSet) {
      setLSItem(STORAGE_KEYS.RATING_MODE, newRatingMode);
      dispatchPayloadList.push({ key: 'gameRatingMode', value: newRatingMode });
    } else {
      const newRatingLimits = calcRatingLimitsByGameModeAndTimeControl(
        currentUserRating,
        newRatingMode
      );
      const newGameRatingLimits = {
        ...gameRatingLimits,
        [newRatingMode]: {
          ...gameRatingLimits[newRatingMode as TGameLobbyRatingModes],
          [board_type]: newRatingLimits,
        },
      };

      setLSItem(STORAGE_KEYS.RATING_MODE, newRatingMode);
      setLSItem(STORAGE_KEYS.RATING_LIMITS, newGameRatingLimits);
      dispatchPayloadList.push({ key: 'gameRatingMode', value: newRatingMode });
      dispatchPayloadList.push({
        key: 'gameRatingLimits',
        value: newGameRatingLimits,
      });
    }

    dispatch({
      type: eGameLobbyReducerActionType.KeysOf,
      payload: dispatchPayloadList,
    });
  };

  const inviteAFriend = useCallback(async () => {
    dispatch({
      type: eGameLobbyReducerActionType.KeyOf,
      payload: { key: 'gameIsStarting', value: true },
    });

    if (uid === null) return;

    const gameData = {
      player_uid: uid,
      opp_mode: oppMode.FRIEND,
      rating: GameRatingMode.UNRATED,
      time_control: state.currentTimeControl.id,
      ratingLimits:
        state.gameRatingLimits[state.gameRatingMode][
          state.currentTimeControl.board_type as TGameLobbyTimeControls
        ],
    };
    try {
      const { status, data } = await gameService.createGameInvite(gameData);
      console.log(status);
      gtmPush({
        event: GTM_EVENTS.START_GAME,
        paramUserId: userData?.player.player_id,
      });
      setInviteData(data);
    } catch (err) {
      console.log(err);
    }

    dispatch({
      type: eGameLobbyReducerActionType.KeyOf,
      payload: { key: 'gameIsStarting', value: false },
    });
  }, [state, uid]);

  const setCurrentUserRating = (
    ratings: IRatingsData,
    gameRatingMode: TGameLobbyRatingModes,
    boardType: BoardType
  ) => {
    const newCurrentUserRating = getUserGameRating(
      ratings,
      gameRatingMode,
      boardType
    );

    dispatch({
      type: eGameLobbyReducerActionType.KeyOf,
      payload: {
        key: 'currentUserRating',
        value: newCurrentUserRating,
      },
    });
  };

  const resetGameSettings = (timeControl: IAdvancedTimeControl) => {
    const dispatchPayload: TGameLobbyReducerActionKeysPayload = [
      {
        key: 'gameRatingMode',
        value: GameRatingMode.UNRATED,
      },
      {
        key: 'currentTimeControl',
        value: timeControl,
      },
    ];

    dispatch({
      type: eGameLobbyReducerActionType.KeysOf,
      payload: dispatchPayload,
    });
  };

  return {
    setRatingByUser,
    setTimeControl,
    setRatingMode,
    inviteAFriend,
    setCurrentUserRating,
    resetGameSettings,
  };
};
