import {
  ICommunityMemberRatings,
  ICommunitySocialMedia,
  ICommunitySocialMediaIcon,
} from '@types';
import {
  ArenaTitleType,
  eAccessLevel,
  GameRatingMode,
  ICountry,
  IRegion,
  ITimeControl,
  TournamentMode,
  TournamentStatus,
} from 'shared/types';

export enum CommunityInfoType {
  TEXT = 'text',
  LINK = 'link',
  LINKS = 'links',
}

export enum TournamentParticipantStatus {
  REGISTERED = 0,
  CHECKED_IN = 1,
  CHECKED_OUT = 2,
  LEFT_TOURNAMENT,
}

export type ICommunityInfoLink = ICommunitySocialMedia &
  ICommunitySocialMediaIcon;

export interface ICommunityInfo {
  title: string;
  title_icon?: string | null;
  dataType: CommunityInfoType;
  text?: string;
  links?: ICommunityInfoLink[];
  link?: string;
}

export enum eClubAdminPages {
  info = 'info',
  gallery = 'gallery',
  cards = 'cards',
  events = 'events',
  members = 'members',
  requests = 'requests',
  tournaments = 'tournaments',
  tournaments_item = 'tournaments_item',
}

export interface IClubAdminTournamentData {
  id: number;
  draft_id: number | null;
  title: string;
  kind: number;
  mode: TournamentMode;
  status: TournamentStatus;
  time_control: ITimeControl;
  tours_count: number;
  main_image: string | null;
  main_image_medium: string | null;
  main_image_small: string | null;
  description: string | null;
  start: string | null;
  slug: string;
  finish: string | null;
  participants_count: number;
  location?: string | null;

  // todo удалить, когда избавимся от редактирования/создания онлайн-турниров (отдельно)
  max_participants: number;
  subtitle?: string | null;
  entry_price?: TEntryFee | null;
  prize_fund?: number | null;
  prize_fund_currency?: string | null;
  age_max?: number | null;
  rating_type?: GameRatingMode;
  rating_max?: number | null;
  rating_min?: number | null;
  allowed_regions?: IRegion[];
  allowed_countries?: ICountry[];
  allowed_titles?: ArenaTitleType[];
  only_community_members?: boolean;
  faq?: string | null;
  arbitrators?: string | Arbitrator[];
  prize?: ClubTournamentPrize | null;
}

export type TEditClubAdminTournamentData = Omit<
  Partial<IClubAdminTournamentData>,
  'time_control' //| 'prize'
> & {
  time_control?: number;
  // prize?: string;
};

export type TEntryFee = {
  id?: string;
  amount?: number;
  currency?: 'eur' | 'usd';
};

export interface IClubAdminTournamentParticipant {
  player_id: number;
  status: TournamentParticipantStatus;
  full_name: string | null;
  avatar_small: string | null;
  nationality_id: string | null;
  fide_id: string | null;
  fide_title: string | null;
  foa_title: string | null;
  has_left: boolean | null;
  access_level: eAccessLevel | null;
  rating: ICommunityMemberRatings | null;
}

export type ICommunityAdminTournamentsItem =
  Partial<IClubAdminTournamentData> & {
    id: number;
    kind: number;
    slug: string;
  };

export type Arbitrator = {
  name: string;
};

export type Prize = {
  rank: string | number;
  label: string;
  name?: string;
  value: string;
};

export type ClubTournamentPrizePlace = {
  id?: number;
  rank?: number;
  name?: string;
  value?: string;
};

export type ClubTournamentPrizeType =
  | 'cash'
  | 'custom'
  | 'subscriptions'
  | 'championship_goods';

export type ClubTournamentPrize = {
  id?: number;
  prizes_by_rank?: ClubTournamentPrizePlace[];
  prize_type?: ClubTournamentPrizeType;
  fund?: number;
  currency?: 'EUR' | 'USD';
};
